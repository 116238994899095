
import * as React from 'react'
import Button, { IButtonProps } from './Button'
import ActionList, { IActionListItem } from '../ActionList/ActionList'
import styled from 'styled-components'
import Popover from '../Popover/Popover'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  a {
    max-height: 38px;
  }

  > a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 38px;
  }

  > div {
    a {
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 10px;
      padding-right: 10px;
      border-left: 1px solid white;

      svg {
        margin-right: 0;
      }

      span {
        margin-right: 0;
      }
    }
  }
`

const ActionListContainer = styled.div`
  margin: 10px;
`


type IProps = {
  buttonProps: IButtonProps
  actions: IActionListItem[]
}

const ButtonWithActions = (props: IProps) => {
  const { buttonProps, actions } = props
  const [state, setState] = React.useState({
    popoverActive: false
  })
  const { popoverActive } = state

  const onPopoverToggle = () => {
    setState({ popoverActive: !state.popoverActive })
  }

  const onClosePopover = () => {
    setState({ popoverActive: false })
  }

  return (
    <Container>
      <Button {...buttonProps} />
      <Popover
        activator={
          <div>
            <Button
              type={buttonProps.type}
              icon='chevron-down'
              disabled={buttonProps.disabled}
              onClick={onPopoverToggle}
            />
          </div>
        }
        onClose={onClosePopover}
        active={popoverActive}
        placement='top'
      >
        <ActionListContainer>
          <ActionList
            actions={actions}
            onClick={onClosePopover}
          />
        </ActionListContainer>
      </Popover>
    </Container>
  )
}

export default ButtonWithActions