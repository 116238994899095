import * as React from 'react'
import { closeProjectModal, showContactModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { ProjectsController } from '../../controllers'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'
import SwatchPicker from '../Swatch/SwatchPicker'
import MoneyInput from '../Form/MoneyInput'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ProjectHelper from '../../helpers/ProjectHelper'
import PowerSelect from '../Form/PowerSelect'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import ProjectStatusSelectComponents from '../Powerselect/ProjectStatusSelectComponents'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import CustomFieldModalInputs from '../CustomFields/CustomFieldModalInputs'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { NOTES_EDITOR_CONFIG } from '../Editor/Editor'
import { Contact, CurrentUser, CustomField, RateType, Project, ProjectBillableType, ProjectBudgetType, ProjectStatus, UserWorkspaceSettingScope } from '../../types'
import Tooltip from '../Tooltips/Tooltip'
import PercentInput from '../Form/PercentInput'
import Alert from '../Alert/Alert'
import InputGroup from '../Forms/InputGroup'
import CheckboxInput from '../Form/CheckboxInput'
import SettingHelper from '../../helpers/SettingHelper'
import RateHelper from '../../helpers/RateHelper'

export type ProjectModalTab = 'details' | 'custom_fields' | 'notes' | 'schedule' | 'billing' | 'budget'

interface IStateToProps {
  project: Project
  contactDisabled?: boolean
  statusDisabled?: boolean
  activeTab?: ProjectModalTab
  onSubmit?: (project: Project) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeProjectModal,
  showContactModal: typeof showContactModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  activeTab: ProjectModalTab
  project: Project | null
  statuses: ProjectStatus[]
  customFields: CustomField[]
  errors: any
}

class ProjectModal extends React.Component<IProps, IState> {
  static defaultProps = {
    contactDisabled: false
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: 'details',
      project: null,
      customFields: [],
      statuses: [],
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this)
    this.onContactModalSubmit = this.onContactModalSubmit.bind(this)
    this.onProjectNameChange = this.onProjectNameChange.bind(this)
    this.onProjectPoNumberChange = this.onProjectPoNumberChange.bind(this)
    this.onProjectColorChange = this.onProjectColorChange.bind(this)
    this.onProjectContactChange = this.onProjectContactChange.bind(this)
    this.onProjectStatusChange = this.onProjectStatusChange.bind(this)
    this.onProjectCustomFieldValueChange = this.onProjectCustomFieldValueChange.bind(this)
    this.onProjectNotesChange = this.onProjectNotesChange.bind(this)
    this.onProjectContactsChange = this.onProjectContactsChange.bind(this)
    this.onEstimatedStartOnChange = this.onEstimatedStartOnChange.bind(this)
    this.onEstimatedFinishOnChange = this.onEstimatedFinishOnChange.bind(this)
    this.onStartedOnChange = this.onStartedOnChange.bind(this)
    this.onFinishedOnChange = this.onFinishedOnChange.bind(this)
    this.onCancelledOnChange = this.onCancelledOnChange.bind(this)
    this.onProjectBillableTypeChange = this.onProjectBillableTypeChange.bind(this)
    this.onProjectBudgetTypeChange = this.onProjectBudgetTypeChange.bind(this)
    this.onBudgetHoursChange = this.onBudgetHoursChange.bind(this)
    this.onProjectBudgetAlertTreshold = this.onProjectBudgetAlertTreshold.bind(this)
    this.onBudgetResetChange = this.onBudgetResetChange.bind(this)
    this.onEstimatedIncomeChange = this.onEstimatedIncomeChange.bind(this)
    this.onIntervalChange = this.onIntervalChange.bind(this)
    this.onBudgetChange = this.onBudgetChange.bind(this)
    this.onHourlyRateChange = this.onHourlyRateChange.bind(this)
    this.onDayRateChange = this.onDayRateChange.bind(this)
    this.onRateTypeChange = this.onRateTypeChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  fetchForm() {
    const { project, activeTab: propActiveTab } = this.props

    ProjectsController
      .getForm(project.id)
      .then(response => {
        const { activeTab } = this.state
        const { statuses, project: responseProject, custom_fields } = response

        this.setState({
          statuses: statuses,
          project: {
            ...responseProject,
            ...project,
          },
          customFields: custom_fields,
          didInitialLoad: true,
          activeTab: propActiveTab ? propActiveTab : activeTab,
        })
      })
      .catch(console.error)
  }

  onNavigationItemClick(e) {
    const activeTab = e.currentTarget.getAttribute('data-tab');

    this.setState({
      activeTab: activeTab
    });
  }

  onContactModalSubmit(contact: Contact) {
    const { project } = this.state

    const updatedProject: Project = { ...project, contact_id: contact.id }

    this.setState({ project: updatedProject })
  }

  onProjectNameChange(e) {
    const { project } = this.state;
    const name = e.currentTarget.value;

    this.setState({
      project: Object.assign({}, project, { name: name })
    });
  }

  onProjectPoNumberChange(e) {
    const { project } = this.state
    const newValue = e.currentTarget.value

    this.setState({
      project: {
        ...project,
        po_number: newValue
      }
    })
  }

  onProjectColorChange(color) {
    const { project } = this.state;

    this.setState({
      project: Object.assign({}, project, { color: color })
    });
  }

  async onProjectContactChange(value?: string, resource?: any) {
    const { currentUser: { workspace: { setting } } } = this.props
    const { project } = this.state;

    const contactId = value || null
    const updatedProject: Project = {
      ...project,
      contact_id: contactId,
      contact: resource,
    }

    this.setState({ project: updatedProject });
  }

  onProjectStatusChange(option) {
    const { project } = this.state;

    this.setState({
      project: {
        ...project,
        status: option.value,
      }
    });
  }

  onProjectCustomFieldValueChange(key: string, value: any) {
    const { project } = this.state

    project.custom_fields[key] = value

    this.setState({
      project: {
        ...project,
      },
    })
  }

  onProjectNotesChange(notes: string) {
    const { project } = this.state;

    this.setState({
      project: {
        ...project,
        notes: notes,
      }
    });
  }

  onProjectContactsChange(contacts) {
    const { project } = this.state;

    const newContact = Object.assign({}, project, { contacts: contacts });

    this.setState({
      project: newContact
    });
  }

  onEstimatedStartOnChange(value) {
    const { project } = this.state;
    const estimatedStartOn = moment(value);


    this.setState({
      project: {
        ...project,
        estimated_start_date: estimatedStartOn.isValid() ? estimatedStartOn.format('YYYY-MM-DD') : null
      }
    });
  }

  onEstimatedFinishOnChange(value) {
    const { project } = this.state;
    const estimatedFinishOn = moment(value);

    this.setState({
      project: {
        ...project,
        estimated_end_date: estimatedFinishOn.isValid() ? estimatedFinishOn.format('YYYY-MM-DD') : null,
      }
    });
  }

  onStartedOnChange(value) {
    const { project } = this.state;
    const startedOn = moment(value);

    this.setState({
      project: {
        ...project,
        start_date: startedOn.isValid() ? startedOn.format('YYYY-MM-DD') : null,
      }
    });
  }

  onFinishedOnChange(value) {
    const { project } = this.state;
    const finishedOn = moment(value);

    this.setState({
      project: {
        ...project,
        end_date: finishedOn.isValid() ? finishedOn.format('YYYY-MM-DD') : null
      }
    });
  }

  onCancelledOnChange(value) {
    const { project } = this.state;
    const cancelledOn = moment(value);

    this.setState({
      project: {
        ...project,
        cancel_date: cancelledOn.isValid() ? cancelledOn.format('YYYY-MM-DD') : null
      }
    });
  }

  onProjectBillableTypeChange(option) {
    const { project } = this.state;

    let updatedProject: Project = {
      ...project,
      billable_type: option.value,
    }

    switch (option.value as ProjectBillableType) {
      case ProjectBillableType.NON_BILLABLE:
        updatedProject = {
          ...updatedProject,
          hourly_rate: null,
          day_rate: null,
        }
        break
    }

    this.setState({ project: { ...updatedProject } })
  }

  onProjectBudgetTypeChange(option) {
    const { project } = this.state;

    let updatedProject: Project = {
      ...project,
      budget_type: option.value,
    }

    switch (option.value as ProjectBudgetType) {
      case ProjectBudgetType.NONE:
        updatedProject = {
          ...updatedProject,
          budget_hours: null,
          budget_alert_treshold: null,
        }
        break
    }

    this.setState({ project: { ...updatedProject } })
  }

  onBudgetHoursChange(e) {
    const { project } = this.state

    this.setState({
      project: {
        ...project,
        budget_hours: e.currentTarget.value
      }
    })
  }

  onProjectBudgetAlertTreshold(budgetAlertTreshold) {
    const { project } = this.state

    this.setState({
      project: {
        ...project,
        budget_alert_treshold: budgetAlertTreshold === 0 ? null : budgetAlertTreshold
      }
    })
  }

  onBudgetResetChange() {
    const { project } = this.state

    this.setState({
      project: {
        ...project,
        budget_resets_monthly: !project.budget_resets_monthly
      }
    })
  }

  onEstimatedIncomeChange(e) {
    const { project } = this.state;
    const estimatedIncome = e.currentTarget.value;

    this.setState({
      project: Object.assign({}, project, { estimated_income: estimatedIncome })
    });
  }

  onIntervalChange(e) {
    const { project } = this.state;
    const interval = e.currentTarget.value;

    this.setState({
      project: Object.assign({}, project, { interval: interval })
    });
  }

  onHourlyRateChange(hourlyRate) {
    const { project } = this.state;

    this.setState({
      project: {
        ...project,
        hourly_rate: hourlyRate
      },
    })
  }

  onBudgetChange(budget) {
    const { project } = this.state;

    this.setState({
      project: {
        ...project,
        budget: budget
      },
    })
  }

  onDayRateChange(dayRate) {
    const { project } = this.state;

    this.setState({
      project: {
        ...project,
        day_rate: dayRate,
      },
    })
  }

  onRateTypeChange(option) {
    const { project } = this.state;

    this.setState({
      project: {
        ...project,
        rate_type: option.value
      }
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { project } = this.state;
    const { close, onSubmit, t } = this.props

    if (project.id) {
      ProjectsController
        .update(project)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('ProjectModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('ProjectModal::Project successfully updated'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(error => console.error(error))
    }
    else {
      ProjectsController
        .create(project)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('ProjectModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('ProjectModal::Project successfully created'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(console.error)
    }
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderNavigation() {
    const { t } = this.props
    const { activeTab, customFields } = this.state

    return (
      <ModalNavigation>
        <ModalNavigationItem active={activeTab === 'details'} data-tab='details' onClick={this.onNavigationItemClick}>
          <Icon icon='info' />
          <span>
            {t('ProjectModal::Details')}
          </span>
        </ModalNavigationItem>

        {customFields.length > 0 && <ModalNavigationItem active={activeTab === 'custom_fields'} data-tab='custom_fields' onClick={this.onNavigationItemClick}>
          <Icon icon='magic' />
          <span>
            {t('ProductModal::Custom fields')}
          </span>
        </ModalNavigationItem>}

        <ModalNavigationItem active={activeTab === 'notes'} data-tab='notes' onClick={this.onNavigationItemClick}>
          <Icon icon='pencil' />
          <span>
            {t('ProjectModal::Notes')}
          </span>
        </ModalNavigationItem>
        <ModalNavigationItem active={activeTab === 'schedule'} data-tab='schedule' onClick={this.onNavigationItemClick}>
          <Icon icon='calendar' />
          <span>
            {t('ProjectModal::Planning')}
          </span>
        </ModalNavigationItem>
        {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <ModalNavigationItem active={activeTab === 'billing'} data-tab='billing' onClick={this.onNavigationItemClick}>
          <Icon icon='banknote' />
          <span>
            {t('ProjectModal::Billing')}
          </span>
        </ModalNavigationItem>}
        <ModalNavigationItem active={activeTab === 'budget'} data-tab='budget' onClick={this.onNavigationItemClick}>
          <Icon icon='chart-pie' />
          <span>
            {t('ProjectModal::Budget')}
          </span>
        </ModalNavigationItem>
      </ModalNavigation>
    )
  }

  renderDetails() {
    const {
      activeTab,
      project,
      statuses,
    } = this.state;
    const { contactDisabled, statusDisabled, t, currentUser } = this.props
    const { setting } = currentUser.workspace

    if (activeTab !== 'details') return null;

    const statusOptions = ProjectHelper.getStatusOptions()
    const selectedStatusOption = project ? ProjectHelper.getStatusOption(statuses, project.status) : null

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProjectModal::Name')}<span>*</span>
              </label>
              <input
                type='text'
                name='name'
                onChange={this.onProjectNameChange}
                value={project.name}
                placeholder={'LDL Equipment'}
                required
              />
            </div>
          </div>
          {setting.po_number_enabled && <div className='grid-cell with-4col'>
            <div className='form-item'>
              <label>{t('ProjectModal::PO number')}</label>
              <input
                type='text'
                name='po_number'
                value={project.po_number}
                placeholder='PO26032021-000001'
                onChange={this.onProjectPoNumberChange}
              />
            </div>
          </div>}
          <div className='grid-cell with-2col'>
            <div className='form-item'>
              <label>{t('ProjectModal::Color')}</label>
              <SwatchPicker
                color={project.color}
                onChange={this.onProjectColorChange}
              />
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ProjectModal::Contact')} <span>*</span></label>
              <ResourceCreatablePowerSelect
                type='contact'
                value={project.contact_id}
                params={{ archived: false }}
                onChange={this.onProjectContactChange}
                isDisabled={contactDisabled}
                isClearable={true}
              />
            </div>
          </div>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ProjectModal::Status')}</label>
              <PowerSelect
                options={statusOptions}
                value={selectedStatusOption}
                onChange={this.onProjectStatusChange}
                isDisabled={statusDisabled}
                components={ProjectStatusSelectComponents}
                theme={ReactSelectTheme}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCustomFields() {
    const { activeTab, project, customFields } = this.state;

    if (activeTab !== 'custom_fields') return null;

    return (
      <div data-tab='custom_fields'>
        <CustomFieldModalInputs
          customFields={customFields}
          modelCustomFields={project.custom_fields}
          onCustomFieldValueChange={this.onProjectCustomFieldValueChange}
          onCustomFieldsChange={(customFields: CustomField[]) => this.setState({ customFields: customFields })}
        />
      </div>
    )
  }

  renderNotes() {
    const { t } = this.props
    const { activeTab, project } = this.state;

    if (activeTab !== 'notes') return null;

    return (
      <div data-tab='notes'>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <EditorContainer>
                <Editor
                  model={project.notes}
                  onModelChange={this.onProjectNotesChange}
                  config={{
                    ...NOTES_EDITOR_CONFIG,
                    placeholderText: t('ProjectModal::Add notes for this project (only visible to you)'),
                    heightMin: 120,
                    heightMax: 265
                  }}
                />
              </EditorContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPlanning() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { project, activeTab } = this.state;

    if (activeTab !== 'schedule') return null;

    return (
      <div data-tab='schedule'>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ProjectModal::Estimated start on')}</label>
              <DateInput
                name='estimated_start_date'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(project.estimated_start_date)}
                inputProps={{ placeholder: setting.date_format }}
                onChange={this.onEstimatedStartOnChange}
                closeOnSelect
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ProjectModal::Estimated finish on')}</label>
              <DateInput
                name='estimated_end_on'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(project.estimated_end_date)}
                inputProps={{ placeholder: setting.date_format }}
                onChange={this.onEstimatedFinishOnChange}
                closeOnSelect
              />
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ProjectModal::Start on')}</label>
              <DateInput
                name='start_date'
                dateFormat={setting.date_format}
                inputProps={{ placeholder: setting.date_format }}
                timeFormat={false}
                initialValue={moment(project.start_date)}
                onChange={this.onStartedOnChange}
                closeOnSelect
              />
            </div>
          </div>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ProjectModal::Finished on')}</label>
              <DateInput
                name='end_date'
                dateFormat={setting.date_format}
                timeFormat={false}
                inputProps={{ placeholder: setting.date_format }}
                initialValue={moment(project.end_date)}
                onChange={this.onFinishedOnChange}
                closeOnSelect
              />
            </div>
          </div>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ProjectModal::Cancelled on')}</label>
              <DateInput
                name='cancel_date'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(project.cancel_date)}
                inputProps={{ placeholder: setting.date_format }}
                onChange={this.onCancelledOnChange}
                closeOnSelect
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBilling() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { project, activeTab } = this.state;

    if (activeTab !== 'billing') return null;

    const projectBillableTypeOptions = ProjectHelper.getProjectBillableTypeOptions()
    const selectedProjectBillableTypeOption = projectBillableTypeOptions.find((option) => option.value === project.billable_type)
    const rateTypeOptions = SettingHelper.getRateTypeOptions()
    const selectedRateTypeOption = rateTypeOptions.find(option => option.value === project.rate_type)

    return (
      <div data-tab='budget'>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProjectModal::Billing')}
                <Tooltip
                  content={t('ProjectModal::Determines how the project and all its associated resources will be billed. (Fixed price, hourly, etc.)')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <PowerSelect
                options={projectBillableTypeOptions}
                value={selectedProjectBillableTypeOption}
                onChange={this.onProjectBillableTypeChange}
                theme={ReactSelectTheme}
              />
            </div>
          </div>

          {project?.billable_type === ProjectBillableType.FIXED_BUDGET && <>
            <div className='grid-cell with-6col'>
              <div className='form-item'>
                <label>{t('ProjectModal::Budget')} <span>*</span></label>
                <MoneyInput
                  name='budget'
                  currency={setting.default_currency}
                  numberFormat={setting.number_format}
                  placeholderValue={setting.default_hourly_rate}
                  value={project.budget}
                  onBlur={this.onBudgetChange}
                  optional
                />
              </div>
            </div>
          </>}

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ProjectModal::Rate determined by')}
                <Tooltip
                  content={t('ProjectModal::Determines what rate will be applied when logging time.')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <PowerSelect
                options={rateTypeOptions}
                value={selectedRateTypeOption}
                onChange={this.onRateTypeChange}
                noOptionsMessage={(value) => t('ProjectModal::No invoice pricing found.')}
                theme={ReactSelectTheme}
              />
            </div>
          </div>

          {project.rate_type === RateType.FIXED && [ProjectBillableType.TIME_AND_MATERIALS, ProjectBillableType.FIXED_BUDGET].includes(project.billable_type) && <>
            <div className='grid-cell with-12col'>
              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('ProjectModal::Hourly rate')}
                      {project?.hourly_rate === null && <>
                        {!Boolean(project?.contact?.hourly_rate) && <Tooltip
                          content={t('ProjectModal::The hourly rate is automatically inherited from your settings.')}
                          containerStyle={{ marginLeft: 8 }}
                        />}
                        {Boolean(project?.contact?.hourly_rate) && <Tooltip
                          content={t('ProjectModal::The hourly rate is automatically inherited from your contact.')}
                          containerStyle={{ marginLeft: 8 }}
                        />}
                      </>}
                    </label>
                    <MoneyInput
                      name='hourly_rate'
                      currency={setting.default_currency}
                      numberFormat={setting.number_format}
                      placeholderValue={RateHelper.getRateForProject('hourly', project)}
                      value={project.hourly_rate}
                      onBlur={this.onHourlyRateChange}
                      optional
                    />
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('ProjectModal::Day rate')}
                      {project?.day_rate === null && <>
                        {!Boolean(project?.contact?.day_rate) && <Tooltip
                          content={t('ProjectModal::The day rate is automatically inherited from your settings.')}
                          containerStyle={{ marginLeft: 8 }}
                        />}
                        {Boolean(project?.contact?.day_rate) && <Tooltip
                          content={t('ProjectModal::The day rate is automatically inherited from your contact.')}
                          containerStyle={{ marginLeft: 8 }}
                        />}
                      </>}
                    </label>
                    <MoneyInput
                      name='day_rate'
                      currency={setting.default_currency}
                      numberFormat={setting.number_format}
                      placeholderValue={RateHelper.getRateForProject('day', project)}
                      value={project.day_rate}
                      onBlur={this.onDayRateChange}
                      optional
                    />
                  </div>
                </div>
              </div>
            </div>
          </>}
        </div>
      </div>
    )
  }

  renderBudget() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { project, activeTab } = this.state;

    if (activeTab !== 'budget') return null;

    const projectBudgetTypeOptions = ProjectHelper.getBudgetTypeOptions()
    const selectedBudgetTypeOption = projectBudgetTypeOptions.find((option) => option.value === project.budget_type)

    return (
      <div data-tab='budget'>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='grid'>
              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>
                    {t('ProjectModal::Budget by')}
                  </label>
                  <PowerSelect
                    options={projectBudgetTypeOptions}
                    value={selectedBudgetTypeOption}
                    onChange={this.onProjectBudgetTypeChange}
                    theme={ReactSelectTheme}
                  />
                </div>
              </div>

              {project.budget_type !== ProjectBudgetType.NONE && <>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    {project.budget_type === ProjectBudgetType.TOTAL_COSTS && <>
                      {project.billable_type === ProjectBillableType.FIXED_BUDGET && <>
                        <label>&nbsp;</label>
                        <Alert
                          type='warning'
                          text={t('ProjectModal::We\'ll use your project budget to track progress.')}
                        />
                      </>}

                      {project.billable_type !== ProjectBillableType.FIXED_BUDGET && <>
                        <label>{t('ProjectModal::Budget')} <span>*</span></label>
                        <MoneyInput
                          name='budget'
                          currency={setting.default_currency}
                          numberFormat={setting.number_format}
                          value={project.budget}
                          onBlur={this.onBudgetChange}
                          optional
                        />
                      </>}
                    </>}
                    {project.budget_type === ProjectBudgetType.TOTAL_HOURS && <>
                      <label>&nbsp;</label>
                      <InputGroup>
                        <input
                          type='number'
                          value={project.budget_hours}
                          onChange={this.onBudgetHoursChange}
                          placeholder='0'
                        />
                        <span>{t('ProjectModal::hours')}</span>
                      </InputGroup>
                    </>}
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('ProjectModal::Budget alert')}
                      <Tooltip
                        content={t('ProjectModal::Send email alerts if projects budget exceeds the budget alert treshold.')}
                        containerStyle={{ marginLeft: 8 }}
                      />
                    </label>
                    <PercentInput
                      name='budget'
                      defaultValue={project?.budget_alert_treshold || null}
                      placeholder='80 %'
                      onChange={this.onProjectBudgetAlertTreshold}
                      onBlur={this.onProjectBudgetAlertTreshold}
                    />
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('ProjectModal::Budget')}</label>
                    <CheckboxInput
                      onChange={this.onBudgetResetChange}
                      checked={project.budget_resets_monthly}
                      label={t('ProjectModal::Monthly reset')}
                    />
                  </div>
                </div>
              </>}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { project, close, t } = this.props
    const { didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={project.id ? t('ProjectModal::Edit project') : t('ProjectModal::Create project')}
          onCloseClick={close}
          navigation={this.renderNavigation()}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              {this.renderCustomFields()}
              {this.renderNotes()}
              {this.renderPlanning()}
              {this.renderBilling()}
              {this.renderBudget()}
              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('ProjectModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      projectModal: {
        project,
        contactDisabled,
        statusDisabled,
        activeTab,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    project: project,
    contactDisabled: contactDisabled,
    statusDisabled: statusDisabled,
    activeTab: activeTab,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeProjectModal()),
    showContactModal: (options) => dispatch(showContactModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectModal))