import * as React from 'react'
import Icon from '../components/Icons/Icon'
import store from '../store'
import { ExpenseCategory } from '../types'

export default class ExpenseCategoryHelper {
	static getMainId(category: ExpenseCategory) {
		return category.parent_id ? category.parent_id : category.id
	}

	static getIcon(category: ExpenseCategory, color: string) {
		const categoryId = this.getMainId(category)

		switch (categoryId) {
			// Advertising
			case '8ea0bae1-4cb8-4c98-95ce-370d7207dcf8':
				return <Icon icon='bullhorn' style={{ color: color }} />
			// Car and truck
			case '110de2f4-3761-4aa2-b67f-efe19f2176e3':
				return <Icon icon='car' style={{ color: color }} />
			// Contractors
			case 'a7a755ed-2769-4325-99d9-edbbb67a0665':
				return <Icon icon='contractor' style={{ color: color }} />
			// Education and Training
			case 'f65f9f7d-dda4-4452-b3b8-6833106a6521':
				return <Icon icon='education' style={{ color: color }} />
			// Employee benefits
			case '12094926-5fbe-481c-9be6-ba4cc6ad013b':
				return <Icon icon='stethoscope' style={{ color: color }} />
			// Meals & Entertainment
			case '1ca583b2-2a21-4298-a17f-3be8a0583677':
				return <Icon icon='utensils' style={{ color: color }} />
			// Office Expenses & Postage
			case '5f4386c6-0b12-4ec5-a7be-cfabedc4756b':
				return <Icon icon='building' style={{ color: color }} />
			// Other Expenses
			case 'dcc0dcc8-0c9b-4be3-a679-a8292979c549':
				return <Icon icon='boxes' style={{ color: color }} />
			// Personal
			case 'a7a3ce8f-fe39-4565-84db-45f88bd91e2f':
				return <Icon icon='personal' style={{ color: color }} />
			// Professional Services
			case '4b0d42c4-5972-4c44-9a98-c42878fe4de8':
				return <Icon icon='personal' style={{ color: color }} />
			// Rent or Lease
			case '4a11056e-af59-42db-ba68-0d94e0fd2006':
				return <Icon icon='home' style={{ color: color }} />
			// Supplies
			case 'bcfe9c92-047d-48a8-83b6-ed5518280a59':
				return <Icon icon='supplies' style={{ color: color }} />
			// Travel
			case 'c50f7934-ea79-4f3b-be4d-aa5dba1792d9':
				return <Icon icon='travel' style={{ color: color }} />
			// Utilities
			case '23da9635-cb9b-4bb8-aa9f-0c8a5b7b9849':
				return <Icon icon='utilities' style={{ color: color }} />
			// Sale
			case 'e337ef90-abc0-4932-ad59-64875e16b2e8':
				return <Icon icon='handshake' style={{ color: color }} />
			default:
				return <Icon icon='star' style={{ color: color }} />
		}
	}

	static getCategoryOptions(categories: ExpenseCategory[]) {
		const parentCategories = categories.filter(c => c.parent_id === null)

		return parentCategories.map((parentCategory, index) => {
			if ([
				// Advertising
				'8ea0bae1-4cb8-4c98-95ce-370d7207dcf8',
				// Contractors
				'a7a755ed-2769-4325-99d9-edbbb67a0665',
				// Education and Training
				'f65f9f7d-dda4-4452-b3b8-6833106a6521',
				// Personal
				'a7a3ce8f-fe39-4565-84db-45f88bd91e2f',
				// Supplies
				'bcfe9c92-047d-48a8-83b6-ed5518280a59',
			].includes(parentCategory.id)) {
				return {
					label: parentCategory.name,
					value: parentCategory.id,
					editable: parentCategory.editable,
					options: [
						{
							label: parentCategory.name,
							value: parentCategory.id,
							editable: parentCategory.editable
						}
					]
				}
			} else {
				const childCategoriesOptions = categories
					.filter(c => c.parent_id === parentCategory.id)
					.map(childCategory => {
						return {
							label: childCategory.name,
							value: childCategory.id,
							editable: childCategory.editable,
							color: childCategory.color,
						}
					})

				return {
					label: parentCategory.name,
					value: parentCategory.id,
					editable: parentCategory.editable,
					color: parentCategory.color,
					options: childCategoriesOptions.length > 0 ? childCategoriesOptions : [{ placeholder: true }],
				}
			}
		})
	}

	static getCategoryOption(categories: ExpenseCategory[], value: string): { label: string, value: string } | null {
		const options = categories.map(c => ({ label: c.name, value: c.id, editable: c.editable, color: c.color }))
		const selectedCategoryOption = options.find(o => o.value === value)

		if (selectedCategoryOption) return selectedCategoryOption

		return null
	}
}