import * as React from 'react'
import { closeMobileAppModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalContent from './Parts/ModalContent'
import styled from 'styled-components'
import Images from '../../images'
import { Style } from '../../styles'
import { WithTranslation, withTranslation } from 'react-i18next'

const Container = styled(ModalWindow)`
  max-width: 445px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: initial;
  }
`

const IntroText = styled.p`
  text-align: center;
  font-size: 16px;
`

const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Style.spacing.x1};

  img {
    max-width: 200px;
    max-height: 200px;
    width: 100%;
  }
`

const AppStoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
  }

  img {
    max-width: 150px;

    &:last-child {
      margin-left: ${Style.spacing.x1};

      @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        margin-left: 0;
        margin-top: ${Style.spacing.x2};
      }
    }
  }
`

interface IStateToProps { }

interface IDispatchToProps {
  close: typeof closeMobileAppModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState { }

class MobileAppModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {}

    this.onModalCloseClick = this.onModalCloseClick.bind(this)
  }

  onModalCloseClick() {
    const { close } = this.props
    close()
  }

  render() {
    const { t } = this.props
    return (
      <Container>
        <ModalHeader title={t('MobileAppModal::Download our iOS or Android app')} onCloseClick={this.onModalCloseClick} />
        <ModalContent>
          <IntroText>
            {t('MobileAppModal::Scan the QR Code with your phone\'s camera app, so you can be sure you\'re keeping your work on hand, even when you\'re on the road.')}
          </IntroText>

          <QRCodeContainer>
            <img src={Images.DOWNLOAD_MOBILE_APP} />
          </QRCodeContainer>

          <AppStoreContainer>
            {/* <a href="https://itunes.apple.com/be/app/bizzey/id1464502725"> */}
            <a href="https://www.bizzey.com/en/blog/installing-bizzey-on-your-smartphone">
              <img alt="Get it on the App Store" src={Images.APP_STORE} />
            </a>

            <a href="https://www.bizzey.com/en/blog/installing-bizzey-on-your-smartphone">
              <img alt="Get it on Google Play" src={Images.PLAY_STORE} />
            </a>
          </AppStoreContainer>
        </ModalContent>
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  return {}
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeMobileAppModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MobileAppModal))