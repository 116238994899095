import styled, { css } from 'styled-components'
import { Style } from '../../../styles'

interface IProps {
  active?: boolean
}

export default styled.li<IProps>`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0 20px;
  border-bottom: none;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  box-shadow: inset 0 -3px 0 transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  white-space: nowrap;

  svg, i {
    position: relative;
    width: 16px;
    height: 16px;
    font-size: 16px;
    fill: ${Style.color.brandPrimary};
    color: ${Style.color.brandPrimary};
    margin-right: ${Style.spacing.x1};

    &.is-contact {
      top: 3px;
      left: -1px;
    }

    &.is-project {
      top: 3px;
      fill: ${Style.color.brandSuccess};
      color: ${Style.color.brandSuccess};
    }

    &.is-invoice {
      top: 1px;
      left: -1px;
      fill: ${Style.color.brandWarning};
      color: ${Style.color.brandWarning};
    }

    &.is-stopwatch {
      position: relative;
      top: 3px;
      width: 16px;
      height: 16px;
      fill: ${Style.color.brandPrimary};
      color: ${Style.color.brandPrimary};
    }
  }

	${props => props.active && css`
		box-shadow: inset 0 -3px 0 ${Style.color.brandPrimary};
    font-weight: 700;

		svg {
			width: 22px;
		}
	`}

	${props => !props.active && css`
		&:hover {
			color: #212b36;
			background-color: rgba(223,227,232,.3);
			box-shadow: inset 0 -3px 0 #dfe3e8;
		}
	`}
`