import * as React from 'react'
import { closeSequenceStepModal, showConfirmModal, showContactModal, showEmailTemplateModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalMiddle from './Parts/ModalMiddle'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalLoader from './Parts/ModalLoader'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import ReactTooltip from 'react-tooltip'
import { AutomatedEmailSequenceStep, CurrentUser, SequenceStep, SequenceStepType } from '../../types'
import ModalWindow from './Parts/ModalWindow'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import SequenceStepsController from '../../controllers/SequenceStepsController'
import SequenceStepTaskModalContent from '../Sequences/SequenceStepTaskModalContent'
import SequenceStepEmailModalContent from '../Sequences/SequenceStepEmailModalContent'

const LoaderModalWindow = styled(ModalWindow)`
  height: 100vh;
  max-width: 400px;
  max-width: initial;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: initial;
  }

  ${ModalMiddle} {
    overflow-x: hidden;
    overflow-y: auto;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  sequenceStep: SequenceStep
  index?: number
  onSubmit?: (sequenceStep: SequenceStep) => void
}

interface IDispatchToProps {
  close: typeof closeSequenceStepModal
  showContactModal: typeof showContactModal
  showEmailTemplateModal: typeof showEmailTemplateModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation & RouteComponentProps

interface IState {
  didInitialLoad: boolean
  sequenceStep?: SequenceStep
  variables: object
  errors: any
  isSaving: boolean
}

class SequenceStepModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      sequenceStep: null,
      variables: {},
      errors: {},
      isSaving: false,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  async fetchForm() {
    const { sequenceStep } = this.props
    const { didInitialLoad } = this.state

    if (!didInitialLoad) {
      try {
        const { sequence_step: formSequenceStep, variables } = await SequenceStepsController.getForm(sequenceStep.sequence_id, sequenceStep.id)

        this.setState({
          didInitialLoad: true,
          variables: variables,
          sequenceStep: formSequenceStep
        })
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  async onFormSubmit(updatedSequenceStep: SequenceStep) {
    const { onSubmit, close, t } = this.props
    const { sequenceStep } = this.state;

    try {
      const updatedSequenceStepResponse = await SequenceStepsController.update(updatedSequenceStep.sequence_id, updatedSequenceStep)

      if (updatedSequenceStepResponse.errors) {

      } else {
        if (onSubmit) onSubmit(updatedSequenceStepResponse)
        close()
      }


    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isSaving: false })
    }
  }

  onCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderSequenceStepContent() {
    const { index } = this.props
    const { sequenceStep, variables } = this.state

    switch (sequenceStep.type) {
      case SequenceStepType.AUTOMATED_EMAIL:
        return (
          <SequenceStepEmailModalContent
            step={sequenceStep}
            emailIndex={index}
            variables={variables}
            onSubmit={this.onFormSubmit}
            onClose={this.onCloseClick}
          />
        )
      case SequenceStepType.MANUAL_EMAIL:
      case SequenceStepType.CALL:
      case SequenceStepType.TASK:
        return (
          <SequenceStepTaskModalContent
            step={sequenceStep}
            variables={variables}
            onSubmit={this.onFormSubmit}
            onClose={this.onCloseClick}
          />
        )
    }

    return null
  }

  render() {
    const { t } = this.props
    const { didInitialLoad } = this.state

    if (!didInitialLoad) {
      return (
        <LoaderModalWindow>
          <ModalHeader
            title={t('SequenceStepModal::Edit')}
            onCloseClick={this.onCloseClick}
          />
          <ModalLoader />
        </LoaderModalWindow>
      )
    }


    return this.renderSequenceStepContent()
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      sequenceStepModal: {
        sequenceStep,
        index,
        onSubmit,
      }
    }
  } = state

  return {
    sequenceStep: sequenceStep,
    index: index,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeSequenceStepModal()),
    showContactModal: (options) => dispatch(showContactModal(options)),
    showEmailTemplateModal: (options) => dispatch(showEmailTemplateModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SequenceStepModal)))