import * as React from 'react'
import styled from "styled-components"

const DropdownButton = styled.span`
  border: .3125em solid transparent;
  display: inline-block;
  vertical-align: middle;
  border-top-color: currentColor;
  margin-top: .3125em;
  align-self: center;
  flex-shrink: 0;
`

export default DropdownButton