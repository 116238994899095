import styled, { css } from "styled-components";
import { Style } from "../../styles";

const SidebarFlyoutWrapper = styled.div`
  position: relative;
  width: 300px;
  min-width: 550px;
  min-height: 620px;
  height: 620px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid ${Style.color.border};
  pointer-events: all;
`

export default SidebarFlyoutWrapper;