export default class LocationHelper {
	static getLocation(): Promise<{ latitude: number; longitude: number } | null> {
		return new Promise((resolve, reject) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						resolve({ latitude: position.coords.latitude, longitude: position.coords.longitude });
					},
					(error) => {
						resolve(null);
					}
				);
			} else {
				resolve(null)
			}
		});
	}
}