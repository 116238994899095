import * as React from 'react'
import styled from 'styled-components'
import ResourceCheckbox from '../../Resource/ResourceCheckbox'
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next'
import { Style } from '../../../styles'
import Popover from '../../Popover/Popover'
import TaskPopoverContainer from '../../TaskModal/TaskPopoverContainer'
import ActionList, { IActionListItem } from '../../ActionList/ActionList'
import moment from '../../../utilities/Moment'
import { CurrentUser, FollowUpActionType, Task } from '../../../types'
import { Moment } from 'moment'
import TaskHelper from '../../../helpers/TaskHelper'
import DateInput from '../../Form/DateInput'
import { connect } from 'react-redux'
import { AppState } from '../../../store'
import Dropdown from '../../Form/Dropdown'
import DropdownButton from '../../Form/DropdownButton'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
`

const Content = styled.div`
  padding-left: ${Style.spacing.x1};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`

const ActionListScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: visible;
  max-height: 228px;
`

interface IStateToProps {
  currentUser: CurrentUser
}

type IProps = {
  onFollowUpActionChange: (task?: Task) => void
} & WithTranslation & IStateToProps

interface IState {
  taskActive: boolean
  taskTypeActive: boolean
  taskTypePopoverActive: boolean
  taskType: FollowUpActionType
  datePopoverActive: boolean
  customDateActive: boolean
  onFollowUpActionChange?: (action: FollowUpActionType) => void
  task?: Task
}

class ModalActionFollowUp extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      taskActive: false,
      taskTypeActive: false,
      taskTypePopoverActive: false,
      datePopoverActive: false,
      customDateActive: false,
      taskType: FollowUpActionType.CALL,
      task: {
        name: `${this.getFollowUpActionLabel(FollowUpActionType.CALL)}:`,
        due_on: moment().add(2, 'day').format('YYYY-MM-DD'),
        assignee_ids: [props.currentUser.id]
      }
    }

    this.onToggleFollowUpAction = this.onToggleFollowUpAction.bind(this)
    this.onFollowUpActionPopoverClose = this.onFollowUpActionPopoverClose.bind(this)
    this.onFollowUpActionPopoverToggle = this.onFollowUpActionPopoverToggle.bind(this)
    this.onFollowUpActionChange = this.onFollowUpActionChange.bind(this)
    this.onDatePopoverClose = this.onDatePopoverClose.bind(this)
    this.onDatePopoverToggle = this.onDatePopoverToggle.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.onCustomDateChange = this.onCustomDateChange.bind(this)
  }

  getFollowUpActionLabel(action: FollowUpActionType) {
    return this.props.t(`FollowUpActionType::${action}`)
  }

  onToggleFollowUpAction(e) {
    const { onFollowUpActionChange } = this.props
    const { taskActive, task } = this.state

    const updatedTaskActive = !taskActive

    this.setState({ taskActive: updatedTaskActive }, this.syncTask)
  }

  onFollowUpActionPopoverClose() {
    this.setState({
      taskTypePopoverActive: false
    })
  }

  onFollowUpActionPopoverToggle(e) {
    e.preventDefault()
    e.stopPropagation()
    const { taskTypePopoverActive } = this.state
    this.setState({ taskTypePopoverActive: !taskTypePopoverActive })
  }

  onFollowUpActionChange(action: FollowUpActionType) {
    const { task } = this.state

    this.setState({
      taskTypePopoverActive: false,
      taskType: action,
      task: {
        ...task,
        name: `${this.getFollowUpActionLabel(action)}:`
      }
    }, this.syncTask)
  }

  onDatePopoverClose() {
    this.setState({
      datePopoverActive: false
    })
  }

  onDatePopoverToggle(e) {
    e.preventDefault()
    e.stopPropagation()

    const { datePopoverActive } = this.state

    this.setState({
      datePopoverActive: !datePopoverActive
    })
  }

  onDateChange(date: string) {
    const { task } = this.state

    this.setState({
      datePopoverActive: false,
      task: {
        ...task,
        due_on: date === 'custom' ? moment().add(1, 'month').format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD')
      },
      customDateActive: date === 'custom'
    }, this.syncTask)
  }

  onCustomDateChange(date: Moment) {
    const { task } = this.state
    const newDate = moment(date)

    if (newDate.isValid()) {
      this.setState({
        task: {
          ...task,
          due_on: newDate.format('YYYY-MM-DD')
        }
      }, this.syncTask)
    }
  }

  syncTask() {
    const { onFollowUpActionChange } = this.props
    const { taskActive, task } = this.state

    onFollowUpActionChange(taskActive ? task : null)
  }

  render() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const {
      taskActive,
      taskTypePopoverActive,
      datePopoverActive,
      customDateActive,
      task,
      taskType
    } = this.state

    const dateActions: IActionListItem[] = TaskHelper.getFollowUpActionDates().map((dateItem) => ({
      key: dateItem.date,
      content: dateItem.content,
      onClick: () => this.onDateChange(dateItem.date)
    }))

    return (
      <Container onClick={this.onToggleFollowUpAction}>
        <ResourceCheckbox
          checked={taskActive}
          onCheckedChange={() => { }}
        />
        <Content>
          <div>
            <span>{t('ModalActionFollowUp::Create a')}</span>

            <Popover
              activator={
                <Dropdown onClick={this.onFollowUpActionPopoverToggle}> {this.getFollowUpActionLabel(taskType)} <DropdownButton /> </Dropdown>
              }
              onClose={this.onFollowUpActionPopoverClose}
              active={taskTypePopoverActive}
              placement='top'
            >
              <TaskPopoverContainer style={{ maxWidth: 144 }}>
                <ActionList
                  actions={[
                    { key: FollowUpActionType.CALL, content: this.getFollowUpActionLabel(FollowUpActionType.CALL), onClick: () => this.onFollowUpActionChange(FollowUpActionType.CALL), icon: 'phone' },
                    { key: FollowUpActionType.EMAIL, content: this.getFollowUpActionLabel(FollowUpActionType.EMAIL), onClick: () => this.onFollowUpActionChange(FollowUpActionType.EMAIL), icon: 'envelope' },
                    { key: FollowUpActionType.TODO, content: this.getFollowUpActionLabel(FollowUpActionType.TODO), onClick: () => this.onFollowUpActionChange(FollowUpActionType.TODO), icon: 'tasks' },
                  ]}
                />
              </TaskPopoverContainer>
            </Popover>

            <span style={{ marginRight: 4 }}>{t('ModalActionFollowUp::task to follow up')}</span>
          </div>

          <div>
            <Popover
              activator={
                <div>
                  {customDateActive && <div>
                    <DateInput
                      name='date'
                      dateFormat={setting.date_format}
                      timeFormat={false}
                      initialValue={moment(task.due_on)}
                      inputProps={{ placeholder: setting.date_format }}
                      onChange={this.onCustomDateChange}
                      closeOnSelect
                      open={false}
                    />
                  </div>}
                  {!customDateActive && <Dropdown onClick={this.onDatePopoverToggle}>
                    {dateActions.find((action) => action.key === task.due_on).content} <DropdownButton />
                  </Dropdown>}
                </div>
              }
              onClose={this.onDatePopoverClose}
              active={datePopoverActive}
              placement='top'
            >
              <TaskPopoverContainer style={{ maxWidth: 200 }}>
                <ActionListScrollContainer>
                  <ActionList actions={dateActions} />
                </ActionListScrollContainer>
              </TaskPopoverContainer>
            </Popover>
          </div>
        </Content>
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
  } = state

  return {
    currentUser: currentUser,
  }
}

export default connect(mapStateToProps, null)(withTranslation()(ModalActionFollowUp))