import * as React from 'react'
import styled from 'styled-components';

interface IProps {
  name?: string
  minRows?: number
  maxRows?: number
  onChange: (value: string) => void
  onKeyPress?: (e: any) => void
  value?: string | string[] | number;
  placeholder?: string
  autoFocus?: boolean
}

interface IState {
  rows: number
}

const TextArea = styled.textarea`
	min-height: initial;
`

export default class AutogrowTextarea extends React.PureComponent<IProps, IState> {
  private input = React.createRef<HTMLTextAreaElement>()

  static defaultProps = {
    minRows: 1,
    maxRows: 5,
  }

  constructor(props: IProps) {
    super(props);

    this.state = {
      rows: props.minRows,
    };

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    this.calculateContentRows()
  }

  calculateContentRows() {
    const textareaLineHeight = 16
    const { minRows, maxRows } = this.props

    const target = this.input.current
    const previousRows = target.rows
    target.rows = minRows;

    const currentRows = ~~(target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      target.rows = currentRows
    }

    if (currentRows >= maxRows) {
      target.rows = maxRows
      target.scrollTop = target.scrollHeight
    }

    this.setState({
      rows: currentRows < maxRows ? currentRows : maxRows,
    })
  }

  select() {
    if (this.input && this.input.current) {
      this.input.current.select()
    }
  }

  focus() {
    if (this.input && this.input.current) {
      this.input.current.focus()
    }
  }

  onChange(event) {
    const { onChange } = this.props
    this.calculateContentRows()

    onChange(event)
  }

  render() {
    const { placeholder, autoFocus } = this.props
    const { rows } = this.state

    return (
      <TextArea
        {...this.props}
        ref={this.input}
        rows={rows}
        onChange={this.onChange}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
    );
  }
}