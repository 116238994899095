import * as React from 'react'
import { closeContactModal, closeTimeEntryModal, showContactModal, showDirectionsModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { TimeEntriesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import MoneyInput from '../Form/MoneyInput'
import CheckboxInput from '../Form/CheckboxInput'
import DateInput, { DateInput as DateInputClass } from '../Form/DateInput'
import moment from '../../utilities/Moment'
import { showProjectModal } from '../../store/modals/actions'
import TimeFormatter from '../../utilities/TimeFormatter'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import * as momentType from 'moment'
import NumberFormatter from '../../utilities/NumberFormatter'
import TimeHelper from '../../helpers/TimeHelper'
import SummaryContainer from '../Summary/SummaryContainer'
import SummaryItem from '../Summary/SummaryItem'
import TimeEntryHelper from '../../helpers/TimeEntryHelper'
import CustomFieldModalInputs from '../CustomFields/CustomFieldModalInputs'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import InputGroup from '../Forms/InputGroup'
import { Contact, CurrentUser, CustomField, Project, ProjectBillableType, ProjectStatus, TimeEntry, UserWorkspaceSettingScope, WorkType } from '../../types'
import ProjectHelper from '../../helpers/ProjectHelper'
import MaskedInput from 'react-text-mask'
import Utils from '../../utilities/Utils'
import WorkspaceHelper from '../../helpers/WorkspaceHelper'
import ButtonPanel from '../Button/ButtonPanel'
import ContactHelper from '../../helpers/ContactHelper'
import Tooltip from '../Tooltips/Tooltip'

const TimeAdjustmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  a {
    color: ${Style.color.brandPrimary};
    font-size: 12px;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }

    &:not(:first-child):not(:last-child) {
      margin: 4px 4px;
    }
  }
`

const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:first-child {
    width: 100%;
    margin-right: ${Style.spacing.x0_5};
  }
`

const CalculateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  ${InputGroup} {
    width: 100%;
    margin-right: ${Style.spacing.x1};
  }

  input {
    width: 100%;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  timeEntry?: TimeEntry
  contactDisabled?: boolean,
  projectDisabled?: boolean,
  onSubmit?: (timeEntry: TimeEntry) => void
}

interface IDispatchToProps {
  close: typeof closeContactModal
  showContactModal: typeof showContactModal
  showProjectModal: typeof showProjectModal
  showDirectionsModal: typeof showDirectionsModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

export enum TimeEntryModalTab {
  DETAILS = 'details',
  CUSTOM_FIELDS = 'custom_fields',
  INVOICE = 'invoice',
}

interface IState {
  didInitialLoad: boolean
  activeTab: TimeEntryModalTab,
  timeEntry: TimeEntry | null
  hasWorkTypes: boolean
  customFields: CustomField[]
  errors: any
}

class TimeEntryModal extends React.Component<IProps, IState> {
  private startedAtDateInput = React.createRef<DateInputClass>()
  private endedAtDateInput = React.createRef<DateInputClass>()
  private startedAtHourInput = React.createRef<MaskedInput>()
  private endedAtHourInput = React.createRef<MaskedInput>()
  private rateInput = React.createRef<MoneyInput>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: TimeEntryModalTab.DETAILS,
      timeEntry: null,
      hasWorkTypes: false,
      customFields: [],
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this);
    this.onTimeEntryModalCloseClick = this.onTimeEntryModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)

    this.onContactChange = this.onContactChange.bind(this);
    this.onProjectChange = this.onProjectChange.bind(this);
    this.onWorkTypeChange = this.onWorkTypeChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onTravelDistanceChange = this.onTravelDistanceChange.bind(this);
    this.onTravelDistanceCalculateClick = this.onTravelDistanceCalculateClick.bind(this)
    this.onStartedAtChange = this.onStartedAtChange.bind(this);
    this.onStartedAtHourFocus = this.onStartedAtHourFocus.bind(this)
    this.onStartedAtHourChange = this.onStartedAtHourChange.bind(this)
    this.onStartedAtHourBlur = this.onStartedAtHourBlur.bind(this)
    this.onEndedAtChange = this.onEndedAtChange.bind(this);
    this.onEndedAtHourFocus = this.onEndedAtHourFocus.bind(this)
    this.onEndedAtHourChange = this.onEndedAtHourChange.bind(this)
    this.onEndedAtHourBlur = this.onEndedAtHourBlur.bind(this)
    this.onAddUnitTimeToStartedAtClick = this.onAddUnitTimeToStartedAtClick.bind(this)
    this.onDeductUnitTimeToStartedAtClick = this.onDeductUnitTimeToStartedAtClick.bind(this)
    this.onAddUnitTimeToEndedAtClick = this.onAddUnitTimeToEndedAtClick.bind(this)
    this.onDeductUnitTimeToEndedAtClick = this.onDeductUnitTimeToEndedAtClick.bind(this)
    this.onCustomFieldValueChange = this.onCustomFieldValueChange.bind(this)
    this.onBillableChange = this.onBillableChange.bind(this);
    this.onBilledChange = this.onBilledChange.bind(this);
    this.onApplyFixedRate = this.onApplyFixedRate.bind(this)
    this.onBaseRateChange = this.onBaseRateChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  syncInputsWithState() {
    const { timeEntry } = this.state
    const startedAtMoment = moment(timeEntry.started_at)
    const endedAtMoment = moment(timeEntry.ended_at)

    if (this.startedAtHourInput.current && startedAtMoment.isValid()) {
      (this.startedAtHourInput.current.inputElement as HTMLInputElement).value = moment(timeEntry.started_at).format('HH:mm')
    }

    if (this.endedAtHourInput.current && endedAtMoment.isValid()) {
      (this.endedAtHourInput.current.inputElement as HTMLInputElement).value = moment(timeEntry.ended_at).format('HH:mm')
    }
  }

  async fetchForm() {
    const { timeEntry } = this.props

    try {
      const response = await TimeEntriesController.getForm(timeEntry.id)
      const { entry: responseTimeEntry, custom_fields, work_type } = response;

      const updatedTimeEntry: TimeEntry = {
        ...responseTimeEntry,
        ...timeEntry
      }

      if (!updatedTimeEntry.id) {
        const { base_rate, rate } = await TimeEntryHelper.getRate(updatedTimeEntry)
        updatedTimeEntry.base_rate = base_rate
        updatedTimeEntry.rate = rate
      }

      requestAnimationFrame(() => {
        this.setState({
          didInitialLoad: true,
          timeEntry: updatedTimeEntry,
          hasWorkTypes: Boolean(work_type),
          customFields: custom_fields,
        }, () => {
          this.syncInputsWithState()
        })
      })
    }
    catch (ex) {
      console.error(ex)
    }
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { timeEntry } = this.state;
    const { close, onSubmit, t } = this.props

    if (timeEntry.id) { // Do update
      TimeEntriesController
        .update(timeEntry)
        .then(response => {
          const { errors, error_full_messages } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('TimeEntryModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('TimeEntryModal::Time entry successfully updated'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(error => console.error(error))
    }
    else {
      TimeEntriesController
        .create(timeEntry)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('TimeEntryModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('TimeEntryModal::Time entry successfully created'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(console.error)
    }
  }

  onNavigationItemClick(e) {
    const activeTab = e.currentTarget.getAttribute('data-tab');

    this.setState({
      activeTab: activeTab
    });
  }

  onTimeEntryModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  async onWorkTypeChange(workTypeId?: string, workType?: WorkType) {
    const { timeEntry } = this.state;

    try {
      const updatedTimeEntry: TimeEntry = {
        ...timeEntry,
        work_type_id: workTypeId,
        work_type: workType,
        multiplier: workType?.multiplier || 1,
        billable: workType ? workType.billable : (timeEntry.project ? timeEntry?.project?.billable_type === ProjectBillableType.TIME_AND_MATERIALS : timeEntry.billable)
      }

      // Get rate value from time entry
      const { base_rate, rate } = await TimeEntryHelper.getRate(updatedTimeEntry)

      updatedTimeEntry.base_rate = base_rate
      updatedTimeEntry.rate = rate

      this.setState({ timeEntry: updatedTimeEntry, })
    } catch (ex) {

      console.error(ex)
    }
  }

  async onContactChange(contactId?: string, contact?: Contact) {
    const { timeEntry } = this.state;

    try {
      const updatedTimeEntry: TimeEntry = {
        ...timeEntry,
        contact_id: contactId,
        contact: contact,
        project_id: null,
      }

      // Get rate value from time entry
      const { base_rate, rate } = await TimeEntryHelper.getRate(updatedTimeEntry)

      updatedTimeEntry.base_rate = base_rate
      updatedTimeEntry.rate = rate

      this.setState({ timeEntry: updatedTimeEntry, })
    } catch (ex) {

      console.error(ex)
    }
  }

  async onProjectChange(projectId?: string, project?: Project) {
    const { currentUser: { workspace: { setting } } } = this.props
    const { timeEntry } = this.state;

    let billable = ProjectHelper.isBillable(project)

    try {
      const updatedTimeEntry = {
        ...timeEntry,
        project_id: projectId,
        project: project,
        billable: billable
      }

      // Get rate value from time entry
      const { base_rate, rate } = await TimeEntryHelper.getRate(updatedTimeEntry)

      updatedTimeEntry.base_rate = base_rate
      updatedTimeEntry.rate = rate

      this.setState({ timeEntry: updatedTimeEntry });
    } catch (ex) {
      console.error(ex)
    }
  }

  onDescriptionChange(e) {
    const { timeEntry } = this.state;
    const description = e.currentTarget.value;

    this.setState({
      timeEntry: {
        ...timeEntry,
        description: description
      }
    });
  }

  onTravelDistanceChange(e) {
    const { timeEntry } = this.state

    this.setState({
      timeEntry: {
        ...timeEntry,
        travel_distance: e.currentTarget.value
      }
    })
  }

  onTravelDistanceCalculateClick() {
    const { currentUser: { workspace } } = this.props
    const { timeEntry } = this.state

    this.props.showDirectionsModal({
      origin: WorkspaceHelper.getAddress(workspace),
      destination: timeEntry.contact ? ContactHelper.getFullAddress(timeEntry.contact.billing_address) : '',
      onSubmit: (result) => {
        const { distance } = result

        this.setState({
          timeEntry: {
            ...this.state.timeEntry,
            travel_distance: distance
          }
        })
      }
    })
  }

  onStartedAtChange(value) {
    const { timeEntry } = this.state;

    const newStartedAt = value ? moment(value).startOf('minute').toISOString() : null
    const newEndedAt = timeEntry.ended_at ? moment(timeEntry.ended_at).startOf('minute').toISOString() : null

    this.setState({
      timeEntry: {
        ...timeEntry,
        started_at: newStartedAt,
        ended_at: newEndedAt
      }
    });
  }

  onStartedAtHourFocus(e) {
    this.syncInputsWithState()
    if (this.startedAtHourInput.current) (this.startedAtHourInput.current.inputElement as HTMLInputElement).select()
  }

  onStartedAtHourChange(e) {
    const value = e.currentTarget.value

    if (value === '' || Utils.isValidTime(value)) {
      this.onStartedAtHourBlur()
    }
  }

  onStartedAtHourBlur(e?) {
    const { timeEntry: stateEntry } = this.state

    if (this.startedAtHourInput.current) {
      const startedAtHourInput = this.startedAtHourInput.current.inputElement as HTMLInputElement

      const time = startedAtHourInput.value

      if (Utils.isValidTime(time)) {
        const valueParts = time.split(':').filter(v => v !== '')
        const hour = Number(valueParts[0])
        const minutes = Number(valueParts[1])

        const newStartedAt = moment(stateEntry.started_at)
          .set('hour', hour)
          .set('minutes', minutes)
          .startOf('minute')

        this.setState({
          timeEntry: {
            ...stateEntry,
            started_at: newStartedAt.toISOString()
          }
        }, () => {
          this.syncInputsWithState()
        })
      } else {
        this.syncInputsWithState()
      }
    }
  }

  onEndedAtChange(value) {
    const { timeEntry } = this.state;

    const newStartedAt = timeEntry.started_at ? moment(timeEntry.started_at).startOf('minute').toISOString() : null
    const newEndedAt = value ? moment(value).startOf('minute').toISOString() : null

    this.setState({ timeEntry: { ...timeEntry, started_at: newStartedAt, ended_at: newEndedAt } });
  }

  onEndedAtHourFocus(e) {
    this.syncInputsWithState()
    if (this.endedAtHourInput.current) (this.endedAtHourInput.current.inputElement as HTMLInputElement).select()
  }

  onEndedAtHourChange(e) {
    const value = e.currentTarget.value

    if (value === '' || Utils.isValidTime(value)) {
      this.onEndedAtHourBlur()
    }
  }

  onEndedAtHourBlur(e?) {
    const { timeEntry: stateEntry } = this.state

    if (this.endedAtHourInput.current) {
      const endedAtHourInput = this.endedAtHourInput.current.inputElement as HTMLInputElement

      const time = endedAtHourInput.value

      if (Utils.isValidTime(time)) {
        const valueParts = time.split(':').filter(v => v !== '')
        const hour = Number(valueParts[0])
        const minutes = Number(valueParts[1])

        const newEndedAt = moment(stateEntry.ended_at)
          .set('hour', hour)
          .set('minutes', minutes)
          .startOf('minute')

        this.setState({
          timeEntry: {
            ...stateEntry,
            ended_at: newEndedAt.toISOString()
          }
        }, () => {
          this.syncInputsWithState()
        })
      } else {
        this.syncInputsWithState()
      }
    }

  }

  onAddUnitTimeToStartedAtClick(amount: momentType.DurationInputArg1, unit: momentType.DurationInputArg2) {
    const { timeEntry } = this.state

    const currentStartedAt = timeEntry.started_at
    const currentStartedAtMoment = moment(currentStartedAt)

    if (currentStartedAtMoment.isValid()) {
      const newStartedAtMoment = currentStartedAtMoment.add(amount, unit)
      this.setState({
        timeEntry: {
          ...timeEntry, started_at: newStartedAtMoment.toDate()
        }
      }, () => {
        if (this.startedAtDateInput.current) this.startedAtDateInput.current.setSelectedDate(newStartedAtMoment)
        if (this.startedAtHourInput.current) (this.startedAtHourInput.current.inputElement as HTMLInputElement).value = newStartedAtMoment.format('HH:mm')
      })
    }
  }

  onDeductUnitTimeToStartedAtClick(amount: momentType.DurationInputArg1, unit: momentType.DurationInputArg2) {
    const { timeEntry } = this.state

    const currentStartedAt = timeEntry.started_at
    const currentStartedAtMoment = moment(currentStartedAt)

    if (currentStartedAtMoment.isValid()) {
      const newStartedAtMoment = currentStartedAtMoment.subtract(amount, unit)
      this.setState({
        timeEntry: { ...timeEntry, started_at: newStartedAtMoment.toDate() }
      }, () => {
        if (this.startedAtDateInput.current) this.startedAtDateInput.current.setSelectedDate(newStartedAtMoment)
        if (this.startedAtHourInput.current) (this.startedAtHourInput.current.inputElement as HTMLInputElement).value = newStartedAtMoment.format('HH:mm')
      })
    }
  }

  onAddUnitTimeToEndedAtClick(amount: momentType.DurationInputArg1, unit: momentType.DurationInputArg2) {
    const { timeEntry } = this.state

    const currentEndedAt = timeEntry.ended_at
    const currentEndedAtMoment = moment(currentEndedAt)

    if (currentEndedAtMoment.isValid()) {
      const newEndedAtMoment = currentEndedAtMoment.add(amount, unit)
      this.setState({
        timeEntry: {
          ...timeEntry,
          ended_at: newEndedAtMoment.toDate()
        }
      }, () => {
        if (this.endedAtDateInput.current) this.endedAtDateInput.current.setSelectedDate(newEndedAtMoment)
        if (this.endedAtHourInput.current) (this.endedAtHourInput.current.inputElement as HTMLInputElement).value = newEndedAtMoment.format('HH:mm')
      })
    }
  }

  onDeductUnitTimeToEndedAtClick(amount: momentType.DurationInputArg1, unit: momentType.DurationInputArg2) {
    const { timeEntry } = this.state

    const currentEndedAt = timeEntry.ended_at
    const currentEndedAtMoment = moment(currentEndedAt)

    if (currentEndedAtMoment.isValid()) {
      const newEndedAtMoment = currentEndedAtMoment.subtract(amount, unit)
      this.setState({
        timeEntry: { ...timeEntry, ended_at: newEndedAtMoment.toDate() }
      }, () => {
        if (this.endedAtDateInput.current) this.endedAtDateInput.current.setSelectedDate(newEndedAtMoment)
        if (this.endedAtHourInput.current) (this.endedAtHourInput.current.inputElement as HTMLInputElement).value = newEndedAtMoment.format('HH:mm')
      })
    }
  }

  onCustomFieldValueChange(key: string, value: any) {
    const { timeEntry } = this.state

    timeEntry.custom_fields[key] = value

    this.setState({
      timeEntry: {
        ...timeEntry,
      },
    })
  }

  onBillableChange(billable) {
    const { timeEntry } = this.state;

    this.setState({
      timeEntry: Object.assign({}, timeEntry, { billable })
    });
  }

  onBaseRateChange(rate) {
    const { timeEntry } = this.state;

    this.setState({
      timeEntry: {
        ...timeEntry,
        base_rate: rate,
      },
    });
  }

  onBilledChange(billed: boolean) {
    const { timeEntry } = this.state;

    this.setState({
      timeEntry: {
        ...timeEntry,
        billed: billed,
        // line_item_id: billed === false ? null : timeEntry.line_item_id
      },
    });
  }

  async onApplyFixedRate(applyDayRate: boolean) {
    const { currentUser: { workspace: { setting } } } = this.props
    const { timeEntry } = this.state;

    try {
      const updatedTimeEntry: TimeEntry = {
        ...timeEntry,
        fixed_rate: applyDayRate,
        multiplier: applyDayRate ? 1 : timeEntry.multiplier
      }

      // Get rate value from time entry
      const { base_rate, rate } = await TimeEntryHelper.getRate(updatedTimeEntry)

      updatedTimeEntry.base_rate = base_rate
      updatedTimeEntry.rate = rate

      this.setState({
        timeEntry: updatedTimeEntry,
      }, () => {
        // Re-render input
        if (this.rateInput && this.rateInput.current) {
          this.rateInput.current.value(updatedTimeEntry.base_rate)
        }
      });

    } catch (ex) {
      console.error(ex)
    }
  }

  renderNavigation(): JSX.Element {
    const { t } = this.props
    const { activeTab, customFields, timeEntry } = this.state

    return (
      <ModalNavigation>
        <ModalNavigationItem active={activeTab === TimeEntryModalTab.DETAILS} data-tab={TimeEntryModalTab.DETAILS} onClick={this.onNavigationItemClick}>
          <Icon icon='info' />
          <span>{t('TimeEntryModal::Details')}</span>
        </ModalNavigationItem>

        {customFields.length > 0 && <ModalNavigationItem active={activeTab === TimeEntryModalTab.CUSTOM_FIELDS} data-tab={TimeEntryModalTab.CUSTOM_FIELDS} onClick={this.onNavigationItemClick}>
          <Icon icon='magic' />
          <span>
            {t('TimeEntryModal::Custom fields')}
          </span>
        </ModalNavigationItem>}

        {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && timeEntry?.project?.billable_type !== ProjectBillableType.NON_BILLABLE && <ModalNavigationItem active={activeTab === TimeEntryModalTab.INVOICE} data-tab={TimeEntryModalTab.INVOICE} onClick={this.onNavigationItemClick}>
          <Icon icon='banknote' />
          <span>{t('TimeEntryModal::Billing')}</span>
        </ModalNavigationItem>}
      </ModalNavigation>
    )
  }

  renderDetails() {
    const { contactDisabled, projectDisabled, currentUser: { workspace: { setting } }, t } = this.props;
    const { activeTab, timeEntry, hasWorkTypes } = this.state;

    if (activeTab !== 'details') return null;

    const projectSelectDisabled = !Boolean(timeEntry.contact_id) || projectDisabled

    const startedAtMoment = moment(timeEntry.started_at)
    const endedAtMoment = moment(timeEntry.ended_at)

    let durationInSeconds = null
    let exclusiveAmount = 0
    let summaryColumnCount = 1

    if (timeEntry) {
      const startedAtMoment = moment(timeEntry.started_at)
      const endedAtMoment = moment(timeEntry.ended_at)

      if (timeEntry.fixed_rate) {
        if (startedAtMoment.isValid() && endedAtMoment.isValid()) {
          durationInSeconds = endedAtMoment.diff(startedAtMoment, 'seconds')
        }
        exclusiveAmount = Number(timeEntry.rate)
      } else if (startedAtMoment.isValid() && endedAtMoment.isValid()) {
        durationInSeconds = endedAtMoment.diff(startedAtMoment, 'seconds')
        const durationInHours = TimeHelper.convertSecondsToHours(durationInSeconds);
        exclusiveAmount = Number(durationInHours) * Number(timeEntry.rate)
      }

      if (timeEntry.travel_distance > 0) {
        exclusiveAmount += Number(timeEntry.travel_distance) * Number(setting.travel_cost)
      }

      if (timeEntry.billable) {
        if (timeEntry.travel_distance > 0) summaryColumnCount += 1
        if (timeEntry.multiplier > 1) summaryColumnCount += 1
        if (UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE)) summaryColumnCount += 1
      }
    }

    return (
      <div data-tab={TimeEntryModalTab.DETAILS}>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('TimeEntryModal::Contact')}
              </label>

              <ResourceCreatablePowerSelect
                type='contact'
                params={{ archived: false }}
                value={timeEntry.contact_id}
                onChange={this.onContactChange}
                isDisabled={contactDisabled}
                isClearable={true}
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('TimeEntryModal::Project')}</label>

              <ResourceCreatablePowerSelect
                type='project'
                value={timeEntry.project_id}
                onChange={this.onProjectChange}
                isDisabled={projectSelectDisabled}
                params={{ 'contact_id': timeEntry.contact_id, 'status[in]': [ProjectStatus.PROPOSAL, ProjectStatus.ACTIVE] }}
                createParams={{ contact_id: timeEntry.contact_id }}
                isClearable={true}
              />
            </div>
          </div>

          {hasWorkTypes && <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('TimeEntryModal::Work type')}</label>

              <ResourceCreatablePowerSelect
                type='work_type'
                value={timeEntry.work_type_id}
                onChange={this.onWorkTypeChange}
                isValidNewOption={() => false}
                isClearable={true}
              />
            </div>
          </div>}
        </div>

        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('TimeEntryModal::Started on')}
              </label>

              <DateTimeContainer>
                <DateInput
                  ref={this.startedAtDateInput}
                  name='started_at'
                  initialValue={moment(timeEntry.started_at)}
                  dateFormat={setting.date_format}
                  timeFormat={false}
                  onChange={this.onStartedAtChange}
                  inputProps={{
                    disabled: timeEntry.active,
                    placeholder: setting.date_format,
                  }}
                  closeOnSelect
                />

                <MaskedInput
                  ref={this.startedAtHourInput}
                  type='text'
                  defaultValue={startedAtMoment.format('HH:mm')}
                  placeholder='00:00'
                  guide={false}
                  showMask={false}
                  mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                  onFocus={this.onStartedAtHourFocus}
                  onBlur={this.onStartedAtHourBlur}
                  onChange={this.onStartedAtHourChange}
                  style={{ width: timeEntry.active ? 70 : 60 }}
                  disabled={timeEntry.active}
                />
              </DateTimeContainer>

              <TimeAdjustmentContainer>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToStartedAtClick(15, 'minutes')}>{t('TimeEntryModal::+15m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToStartedAtClick(30, 'minutes')}>{t('TimeEntryModal::+30m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToStartedAtClick(45, 'minutes')}>{t('TimeEntryModal::+45m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToStartedAtClick(1, 'hour')}>{t('TimeEntryModal::+1h')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToStartedAtClick(2, 'hours')}>{t('TimeEntryModal::+2h')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToStartedAtClick(8, 'hours')}>{t('TimeEntryModal::+8h')}</a>
              </TimeAdjustmentContainer>
              <TimeAdjustmentContainer>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToStartedAtClick(15, 'minutes')}>{t('TimeEntryModal::-15m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToStartedAtClick(30, 'minutes')}>{t('TimeEntryModal::-30m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToStartedAtClick(45, 'minutes')}>{t('TimeEntryModal::-45m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToStartedAtClick(1, 'hour')}>{t('TimeEntryModal::-1h')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToStartedAtClick(2, 'hours')}>{t('TimeEntryModal::-2h')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToStartedAtClick(8, 'hours')}>{t('TimeEntryModal::-8h')}</a>
              </TimeAdjustmentContainer>
            </div>
          </div>

          {!timeEntry.active && <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('TimeEntryModal::Ended at')}</label>
              <DateTimeContainer>
                <DateInput
                  ref={this.endedAtDateInput}
                  name='ended_at'
                  initialValue={moment(timeEntry.ended_at)}
                  dateFormat={setting.date_format}
                  timeFormat={false}
                  onChange={this.onEndedAtChange}
                  inputProps={{
                    placeholder: setting.date_format
                  }}
                  closeOnSelect
                />
                <MaskedInput
                  ref={this.endedAtHourInput}
                  type='text'
                  defaultValue={endedAtMoment.format('HH:mm')}
                  placeholder='00:00'
                  guide={false}
                  showMask={false}
                  mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                  onFocus={this.onEndedAtHourFocus}
                  onBlur={this.onEndedAtHourBlur}
                  onChange={this.onEndedAtHourChange}
                  style={{ width: timeEntry.active ? 70 : 60 }}
                />
              </DateTimeContainer>
              <TimeAdjustmentContainer>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToEndedAtClick(15, 'minutes')}>{t('TimeEntryModal::+15m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToEndedAtClick(30, 'minutes')}>{t('TimeEntryModal::+30m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToEndedAtClick(45, 'minutes')}>{t('TimeEntryModal::+45m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToEndedAtClick(1, 'hour')}>{t('TimeEntryModal::+1h')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToEndedAtClick(2, 'hours')}>{t('TimeEntryModal::+2h')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onAddUnitTimeToEndedAtClick(8, 'hours')}>{t('TimeEntryModal::+8h')}</a>
              </TimeAdjustmentContainer>
              <TimeAdjustmentContainer>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToEndedAtClick(15, 'minutes')}>{t('TimeEntryModal::-15m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToEndedAtClick(30, 'minutes')}>{t('TimeEntryModal::-30m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToEndedAtClick(45, 'minutes')}>{t('TimeEntryModal::-45m')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToEndedAtClick(1, 'hour')}>{t('TimeEntryModal::-1h')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToEndedAtClick(2, 'hours')}>{t('TimeEntryModal::-2h')}</a>
                <a href='javascript://' tabIndex={-1} onClick={() => this.onDeductUnitTimeToEndedAtClick(8, 'hours')}>{t('TimeEntryModal::-8h')}</a>
              </TimeAdjustmentContainer>
            </div>
          </div>}
        </div>

        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('TimeEntryModal::Description')}</label>
              <textarea value={timeEntry.description} onChange={this.onDescriptionChange} placeholder={t('TimeEntryModal::Description')} />
            </div>
          </div>
        </div>

        <div className='grid'>
          {timeEntry?.project?.billable_type !== ProjectBillableType.NON_BILLABLE &&
            <div className='grid-cell with-12col'>
              <div className='form-item'>
                <label>{t('TimeEntryModal::Travel distance')}</label>
                <CalculateContainer>
                  <InputGroup>
                    <input
                      type='number'
                      value={timeEntry.travel_distance}
                      placeholder={t('TimeEntryModal::Mileage')}
                      onChange={this.onTravelDistanceChange}
                      min={0}
                    />
                    <span>{t('TimeEntryModal::km')}</span>
                  </InputGroup>
                  <ButtonPanel
                    text={t('TimeEntryModal::Calculate')}
                    onClick={this.onTravelDistanceCalculateClick} />
                </CalculateContainer>
              </div>
            </div>}
        </div>

        {
          !timeEntry.active && <div className='grid'>
            <div className='grid-cell with-12col'>
              <SummaryContainer columnCount={summaryColumnCount}>
                <SummaryItem
                  title={t('TimeEntryModal::Duration')}
                  label={`${TimeFormatter.durationFormat(durationInSeconds, setting.time_format)}`}
                />
                {timeEntry.billable && <>
                  {timeEntry.travel_distance && timeEntry.travel_distance > 0 && <SummaryItem
                    title={`${t('TimeEntryModal::Travel distance')}`}
                    label={`${NumberFormatter.formatNumber(setting.number_format, timeEntry.travel_distance)} ${t('TimeEntryModal::km')}`}
                  />}
                  {timeEntry?.multiplier > 1 && <SummaryItem
                    title={<>
                      {t('TimeEntryModal::Multiplier')}
                      <Tooltip
                        content={t('WorkTypeModal::Applies a multiplier to the standard rate, adjusting for cases like overtime or special shifts.')}
                        containerStyle={{ marginLeft: 8 }}
                      />
                    </>}
                    label={`${NumberFormatter.formatNumber(setting.number_format, timeEntry.multiplier)}`}
                  />}
                  {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <SummaryItem
                    title={t('TimeEntryModal::Total (excl. VAT)')}
                    label={`${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, exclusiveAmount, { forcePrecision: 2 })}`}
                  />}
                </>}
              </SummaryContainer>
            </div>
          </div>
        }
      </div >
    );
  }

  renderCustomFields() {
    const { activeTab, timeEntry, customFields } = this.state;

    if (activeTab !== TimeEntryModalTab.CUSTOM_FIELDS) return null;

    return (
      <div data-tab={TimeEntryModalTab.CUSTOM_FIELDS}>
        <CustomFieldModalInputs
          customFields={customFields}
          modelCustomFields={timeEntry.custom_fields}
          onCustomFieldValueChange={this.onCustomFieldValueChange}
          onCustomFieldsChange={(customFields: CustomField[]) => this.setState({ customFields: customFields })}
        />
      </div>
    )
  }

  renderBilling() {
    const { activeTab, timeEntry } = this.state;
    const { currentUser: { workspace: { setting } }, t } = this.props;

    if (activeTab !== TimeEntryModalTab.INVOICE) return null;

    return (
      <div className='grid'>
        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('TimeEntryModal::Billable')}</label>
            <CheckboxInput
              onChange={this.onBillableChange}
              checked={timeEntry.billable}
              label={t('TimeEntryModal::Billable')}
            />
          </div>
        </div>
        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('TimeEntryModal::Billed')}</label>
            <CheckboxInput
              onChange={this.onBilledChange}
              checked={timeEntry.billed}
              label={t('TimeEntryModal::Billed')}
            />
          </div>
        </div>

        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('TimeEntryModal::Apply day rate')}</label>
            <CheckboxInput
              onChange={this.onApplyFixedRate}
              checked={timeEntry.fixed_rate}
              label={t('TimeEntryModal::Apply day rate')}
            />
          </div>
        </div>

        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{timeEntry.fixed_rate ? t('TimeEntryModal::Day rate') : t('TimeEntryModal::Hourly rate')}</label>
            <MoneyInput
              ref={this.rateInput}
              name='rate'
              currency={setting.default_currency}
              numberFormat={setting.number_format}
              placeholderValue={timeEntry.base_rate}
              value={timeEntry.base_rate}
              onBlur={this.onBaseRateChange}
              disabled
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { timeEntry: propsTimeEntry, t, currentUser: { workspace: { setting } } } = this.props
    const { timeEntry: stateTimeEntry, didInitialLoad, errors } = this.state

    let title = t('TimeEntryModal::Time entry')

    if (propsTimeEntry) {
      title = propsTimeEntry.id ? t('TimeEntryModal::Edit time entry') : t('TimeEntryModal::Create time entry')

      if (propsTimeEntry.active) {
        title = propsTimeEntry.id ? t('TimeEntryModal::Edit timer') : t('TimeEntryModal::Start timer')
      }
    }

    return (
      <ModalWindow>
        <ModalHeader
          title={title}
          onCloseClick={this.onTimeEntryModalCloseClick}
          navigation={this.renderNavigation()}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              {this.renderCustomFields()}
              {this.renderBilling()}

              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('TimeEntryModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      timeEntryModal: {
        timeEntry,
        contactDisabled,
        projectDisabled,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    timeEntry: timeEntry,
    contactDisabled: contactDisabled,
    projectDisabled: projectDisabled,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeTimeEntryModal()),
    showContactModal: (options) => dispatch(showContactModal(options)),
    showProjectModal: (options) => dispatch(showProjectModal(options)),
    showDirectionsModal: (options) => dispatch(showDirectionsModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimeEntryModal))