import styled from "styled-components";
import { Style } from "../../styles";

const InputGroup = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	input {
		&:not(:first-child){
			border-top-left-radius: 0px !important;
			border-bottom-left-radius: 0px !important;
		}

		&:not(:last-child){
			border-top-right-radius: 0px !important;
			border-bottom-right-radius: 0px !important;
		}
	}

	> span {
		display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid ${Style.color.border};
    border-radius: 0.375rem;
		height: 38px;

		&:first-child {
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
			border-right: none;
		}

		&:last-child {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			border-left: none;
		}
	}
`

export default InputGroup