import * as React from 'react'
import * as accounting from 'accounting'

interface IProps {
	name?: string
	defaultValue: string | number
	onChange: (value: string | number) => void
	onBlur: (value: string | number) => void
	placeholder?: string | number
	disabled?: boolean
	style?: object
}

export default class PercentInput extends React.PureComponent<IProps> {
	private input = React.createRef<HTMLInputElement>()

	static defaultProps = {
		onChange: value => `PercentInput change: ${value}`,
		onBlur: value => `PercentInput blur: ${value}`
	}

	constructor(props) {
		super(props);

		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.getFormattedValue = this.getFormattedValue.bind(this);
		this.unformatValue = this.unformatValue.bind(this);
		this.focus = this.focus.bind(this);
		this.setValue = this.setValue.bind(this)
	}

	componentDidMount() {
		const { defaultValue } = this.props;

		if (defaultValue) {
			this.input.current.value = this.getFormattedValue(defaultValue);
		}
	}

	onBlur(e) {
		const { onBlur } = this.props
		const currentValue = this.unformatValue(e.currentTarget.value) / 100;

		if (onBlur) onBlur(currentValue);

		if (currentValue) {
			this.input.current.value = this.getFormattedValue(currentValue);
		}
	}

	onChange(e) {
		const { onChange } = this.props;

		const currentValue = this.unformatValue(e.currentTarget.value) / 100;

		if (onChange) {
			this.props.onChange(currentValue);
		}
	}

	setValue(value, triggerOnChange = true) {
		const { onChange } = this.props

		this.input.current.value = this.getFormattedValue(value)

		if (triggerOnChange && onChange) {
			onChange(value)
		}
	}

	getFormattedValue(value) {
		const newValue = value * 100;
		return String(newValue).concat(' %');
	}

	unformatValue(value) {
		return accounting
			.unformat(value
				.replace(/ /g, '')
				.replace(/%/g, ''));
	}

	focus() {
		this.input.current.focus();
	}

	render() {
		const { name, defaultValue, placeholder = '', style = {}, disabled } = this.props;

		return (
			<input
				ref={this.input}
				name={name}
				defaultValue={defaultValue ? `${defaultValue}` : ''}
				placeholder={`${placeholder}`}
				className='percent-input'
				type='text'
				onBlur={this.onBlur}
				onChange={this.onChange}
				style={style}
				disabled={disabled}
				autoComplete='off'
			/>
		);
	}
}