import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Project } from '../../types'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'

interface IProps {
	activator: JSX.Element
	contactId: string
	projectId?: string
	projectDisabled?: boolean
	onSubmit: (projectId?: string, project?: Project) => void
}

const ProjectPopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator, contactId, projectDisabled } = props

	const [popoverActive, setPopoverActive] = React.useState(false)
	const [projectId, setProjectId] = React.useState(null)

	const onTogglePopover = () => {
		if (!popoverActive) {
			setProjectId(props.projectId)
		}

		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
	}

	const onProjectChange = (projectId, project) => {
		setProjectId(projectId)
	}

	const onFormSubmit = () => {
		// Submit form
		props.onSubmit(projectId)

		// Close popover
		onPopoverClose()
	}

	const onRemoveClick = () => {
		// Submit form
		props.onSubmit(null)

		// Close popover
		onPopoverClose()
	}

	return (
		<Popover
			active={popoverActive}
			activator={
				<div onClick={onTogglePopover}>
					{activator}
				</div>
			}
			onClose={onPopoverClose}
			placement='bottom-start'
		>
			<TaskPopoverContainer>
				<TaskPopoverHeader>
					<TaskPopoverTitle>{t('TaskModal::Project')}</TaskPopoverTitle>
				</TaskPopoverHeader>
				<TaskPopoverContent>
					<ResourceCreatablePowerSelect
						type='project'
						value={projectId}
						onChange={onProjectChange}
						isClearable={true}
						params={{ 'contact_id': contactId, }}
						createParams={{ contact_id: contactId }}
						isDisabled={!contactId || Boolean(projectDisabled)}
					/>

					{!projectDisabled && <>
						<TaskButton
							onClick={onFormSubmit}
							style={{ marginTop: 12 }}
							success
							center
						>
							{t('TaskModal::Save')}
						</TaskButton>

						<TaskButton
							onClick={onRemoveClick}
							style={{ marginTop: 8 }}
							center
						>
							{t('TaskModal::Remove')}
						</TaskButton>
					</>}
				</TaskPopoverContent>
			</TaskPopoverContainer>
		</Popover>
	)
}

export default ProjectPopover