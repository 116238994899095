import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px;

	&:first-child {
		border-top-left-radius: ${Style.variables.baseBorderRadius};
		border-top-right-radius: ${Style.variables.baseBorderRadius};
	}

	&:last-child {
		border-bottom-left-radius: ${Style.variables.baseBorderRadius};
		border-bottom-right-radius: ${Style.variables.baseBorderRadius};
	}

	&:not(:first-child) {
		padding-top: ${Style.spacing.x1_5};
	}

	&:not(:last-child) {
		border-bottom: 1px solid #ebebeb;
	}

	&:hover {
		background: #fafbfb;
	}
`

export const Label = styled.div`
	flex: 1 1 auto;
	color: #737373;
	font-weight: 500;
	font-size: 16px;
	padding-right: ${Style.spacing.x1};
`

export const Value = styled.div`
	flex: 0 00 auto;
	color: #737373;
	font-weight: 500;
	font-size: 16px;
`

interface IProps {
	label: string
	value: string
	labelStyles?: any
	valueStyles?: any
}

const BalanceListItem = (props: IProps) => {
	const { label, labelStyles, value, valueStyles } = props

	return (
		<Container>
			<Label
				dangerouslySetInnerHTML={{ __html: label }}
				style={labelStyles}
			/>
			<Value
				style={valueStyles}
			>
				{value}
			</Value>
		</Container>
	)
}

export default BalanceListItem