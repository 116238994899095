import { CurrentUser, Settings, Subscription, Workspace } from '../../types';
import { AuthenticationActionTypes, SET_CURRENT_USER, UPDATE_WORKSPACE, UPDATE_SETTINGS, UPDATE_SUBSCRIPTION } from './types';

export function setCurrentUser(currentUser: CurrentUser): AuthenticationActionTypes {
	return {
		type: SET_CURRENT_USER,
		currentUser: currentUser
	}
}

export function updateWorkspace(workspace: Workspace): AuthenticationActionTypes {
	return {
		type: UPDATE_WORKSPACE,
		workspace: workspace
	}
}

export function updateSettings(settings: Settings): AuthenticationActionTypes {
	return {
		type: UPDATE_SETTINGS,
		settings: settings
	}
}

export function updateSubscription(subscription: Subscription): AuthenticationActionTypes {
	return {
		type: UPDATE_SUBSCRIPTION,
		subscription: subscription
	}
}