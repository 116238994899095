import styled from 'styled-components'

const TaskSectionIcon = styled.div`
	position: absolute;
	top: 4px;
	left: -30px;
	color: #42526e;

	svg {
			width: 16px;
			height: 16px;
			fill: #42526e;
	}
`

export default TaskSectionIcon