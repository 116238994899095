import { Api } from ".";
import { SequenceStep } from "../types";
import Utils from "../utilities/Utils";

export default class SequenceStepsController extends Api {
  static create(sequenceId: string, sequenceStep: SequenceStep) {
    return fetch(`/api/sequence_steps`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        sequence_id: sequenceId,
        sequence_step: sequenceStep,
      }),
    }).then((res) => res.json());
  }

  static update(sequenceId: string, sequenceStep: SequenceStep) {
    return fetch(`/api/sequence_steps/${sequenceStep.id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        sequence_id: sequenceId,
        sequence_step: sequenceStep,
      }),
    }).then((res) => res.json());
  }

  static delete(sequenceId: string, id: string) {
    return fetch(`/api/sequence_steps/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.token,
      },
      credentials: "same-origin",
      body: JSON.stringify({ sequence_id: sequenceId }),
    }).then((res) => res.json());
  }

  static getForm(sequenceId: string, id: string) {
    return fetch(
      `/api/sequence_steps/form?${Utils.encodeQueryData({
        sequence_id: sequenceId,
        id: id,
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        credentials: "same-origin",
      }
    ).then((res) => res.json());
  }
}
