import * as React from 'react'
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'
import styled from 'styled-components'
import { Style } from '../../styles'
import { BoardListsController } from '../../controllers'
import { BoardListMetadata } from '../../types'
import BoardHelper from '../../helpers/BoardHelper'

const ListInputContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	> div:first-child {
		width: 100%;
		flex: 1;
	}

	> div:last-child {
		margin-left: ${Style.spacing.x1};

		select {
			max-width: 70px;
			text-align: center !important;
		}
	}
`

type IProps = {
	activator: JSX.Element
	boardId?: string
	listId?: string
	position: number
	onSubmit: (boardId: string, listId: string, position: number) => void
} & WithTranslation

interface IState {
	popoverActive: boolean
	boardId: string
	listId: string
	position: number
	listMetadata?: BoardListMetadata
}

class MovePopover extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			popoverActive: false,
			boardId: props.boardId || '',
			listId: props.listId || '',
			position: props.position,
			listMetadata: null,
		}

		this.fetchBoardListMetadata = this.fetchBoardListMetadata.bind(this)
		this.onTogglePopover = this.onTogglePopover.bind(this)
		this.onPopoverClose = this.onPopoverClose.bind(this)
		this.onBoardChange = this.onBoardChange.bind(this)
		this.onListChange = this.onListChange.bind(this)
		this.onPositionChange = this.onPositionChange.bind(this)
		this.onFormSubmit = this.onFormSubmit.bind(this)
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		// Fetch board list metadata when popover is opened
		if (!prevState.popoverActive && this.state.popoverActive) {
			this.setState({
				boardId: this.props.boardId,
				listId: this.props.listId,
				position: this.props.position
			}, this.fetchBoardListMetadata)
		}
	}

	async fetchBoardListMetadata() {
		const { boardId, listId, position } = this.state

		if (listId) {
			try {
				const metadata = await BoardListsController.metadata(boardId, listId)

				this.setState({ listMetadata: metadata })
			} catch (ex) {
				console.error(ex)
			}
		} else {
			this.setState({ listMetadata: null })
		}
	}

	onTogglePopover() {
		const { popoverActive } = this.state

		this.setState({ popoverActive: !popoverActive })
	}

	onPopoverClose() {
		this.setState({
			popoverActive: false,
			boardId: null,
			listId: null,
			position: null
		})
	}

	onBoardChange(boardId, board) {
		this.setState({
			boardId: boardId,
			listId: null,
			listMetadata: null,
			position: BoardHelper.POSITION_STEP_INDEX,
		})
	}

	onListChange(listId, list) {
		const { boardId } = this.state
		const { listId: currentListId, position: currentPosition } = this.props
		try {
			this.setState({ listId: listId }, async () => {
				if (listId) {
					if (currentListId === listId) {
						this.setState({
							listMetadata: null,
							position: currentPosition
						}, this.fetchBoardListMetadata)
					} else {
						const metadata = await BoardListsController.metadata(boardId, listId)
						const positions = metadata.positions

						// Switch task to new list (add an additional position to the list)
						const newPositions = [
							...positions,
							...(positions.length > 0 ? ([positions[positions.length - 1] + BoardHelper.POSITION_STEP_INDEX]) : [BoardHelper.POSITION_STEP_INDEX])
						]

						// Fetch list metadata and determine new position
						this.setState({
							listMetadata: {
								...metadata,
								positions: newPositions,
							},
							position: newPositions[newPositions.length - 1]
						})
					}
				} else {
					this.setState({
						listMetadata: null,
						position: BoardHelper.POSITION_STEP_INDEX
					})
				}
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	onPositionChange(e) {
		const newPosition = Number(e.currentTarget.value)
		this.setState({ position: newPosition })
	}

	onFormSubmit() {
		const { boardId, listId, position } = this.state
		// Submit form
		this.props.onSubmit(boardId, listId, position * BoardHelper.POSITION_STEP_INDEX)

		// Close popover
		this.setState({ popoverActive: false })
	}

	render() {
		const { activator, t } = this.props
		const { popoverActive, boardId, listId, position, listMetadata } = this.state
		return (
			<Popover
				active={popoverActive}
				activator={
					<div onClick={this.onTogglePopover}>
						{activator}
					</div>
				}
				onClose={this.onPopoverClose}
				placement='bottom-start'
			>
				<TaskPopoverContainer>
					<TaskPopoverHeader>
						<TaskPopoverTitle>{t('TaskModal::Move task')}</TaskPopoverTitle>
					</TaskPopoverHeader>
					<TaskPopoverContent>
						<div className='form-item'>
							<label>{t('TaskPopover::Board')}</label>
							<ResourceCreatablePowerSelect
								type='board'
								value={boardId}
								onChange={this.onBoardChange}
								isClearable={true}
								isValidNewOption={() => false}
							/>
						</div>

						<ListInputContainer>
							<div className='form-item'>
								<label>{t('TaskPopover::List')}</label>
								<ResourceCreatablePowerSelect
									type='board_list'
									value={listId}
									onChange={this.onListChange}
									params={{ 'board_id': boardId }}
									isClearable={true}
									isValidNewOption={() => false}
									isDisabled={!boardId}
								/>
							</div>
							<div className='form-item'>
								<label>{t('TaskPopover::Position')}</label>
								<select
									value={position}
									onChange={this.onPositionChange}
									disabled={!listId}
								>
									{listMetadata && listMetadata.positions.map((position, index) => (
										<option key={index + 1} value={position}>
											{(index + 1)}
										</option>
									))}
								</select>
							</div>
						</ListInputContainer>

						{
							// No board and list selected
							((!boardId && !listId) ||
								// Board & List selected
								(boardId && listId && position)) &&
							<TaskButton
								onClick={this.onFormSubmit}
								style={{ marginTop: 12 }}
								success
								center
							>
								{t('TaskModal::Save')}
							</TaskButton>}
					</TaskPopoverContent>
				</TaskPopoverContainer>
			</Popover >
		)
	}
}

export default withTranslation()(MovePopover)