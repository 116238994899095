import styled from 'styled-components'
import { Style } from '../../styles'

const MenuEditorItemDrag = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
  cursor: ns-resize;
	margin-right: ${Style.spacing.x1};
	margin-top: ${Style.spacing.x0_5};

  svg {
    width: 10px;
    color: #d6d6d6;
  }
`

export default MenuEditorItemDrag