import { Moment } from "moment"
import i18n from "../translations"
import { ExportTimePeriod } from "../types"
import moment from "../utilities/Moment"

class ExportHelper {
	static getPeriodOptions(): Array<{ label: string, value: string }> {
		return Object.keys(ExportTimePeriod).map(key => (
			{ label: i18n.t(`ExportTimePeriod::${ExportTimePeriod[key]}`), value: ExportTimePeriod[key] }
		))
	}

	static getPeriodRange(exportPeriod: ExportTimePeriod): { start: string, end: string } {
		switch (exportPeriod) {
			case ExportTimePeriod.ALL:
				return { start: moment("1992-11-25").toISOString(), end: moment().toISOString() }
			case ExportTimePeriod.CURRENT_MONTH:
				const startCurrentMonth = moment().startOf('month')
				return { start: startCurrentMonth.toISOString(), end: startCurrentMonth.endOf('month').toISOString() }
			case ExportTimePeriod.PREVIOUS_MONTH:
				const startPreviousMonth = moment().subtract(1, 'month').startOf('month')
				return { start: startPreviousMonth.toISOString(), end: startPreviousMonth.endOf('month').toISOString() }
			case ExportTimePeriod.CURRENT_QUARTER:
				const startCurrentQuarter = moment().startOf('quarter')
				return { start: startCurrentQuarter.toISOString(), end: startCurrentQuarter.endOf('quarter').toISOString() }
			case ExportTimePeriod.PREVIOUS_QUARTER:
				const startPreviousQuarter = moment().subtract(1, 'quarter').startOf('quarter')
				return { start: startPreviousQuarter.toISOString(), end: startPreviousQuarter.endOf('quarter').toISOString() }
			case ExportTimePeriod.CURRENT_YEAR:
				const startCurrentYear = moment().startOf('year')
				return { start: startCurrentYear.toISOString(), end: startCurrentYear.endOf('year').toISOString() }
			case ExportTimePeriod.PREVIOUS_YEAR:
				const startPreviousYear = moment().subtract(1, 'year').startOf('year')
				return { start: startPreviousYear.toISOString(), end: startPreviousYear.endOf('quarter').toISOString() }
			case ExportTimePeriod.CUSTOM:
				return { start: moment().startOf('month').toISOString(), end: moment().toISOString() }
		}
	}
}

export default ExportHelper