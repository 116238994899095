import * as React from 'react'
import { closeTimeEntryExportModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import { TimeEntriesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'
import { Moment } from 'moment';
import { saveAs } from 'file-saver'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import PowerSelect from '../Form/PowerSelect'
import { WithTranslation, withTranslation } from 'react-i18next'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import SpreadsheetHelper from '../../helpers/SpreadsheetHelper'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import { CurrentUser, SpreadsheetExportType, TimeEntryExportType } from '../../types'

interface IStateToProps {
  currentUser: CurrentUser
  contactId?: string
  contactDisabled?: boolean
  projectId?: string
  projectDisabled?: boolean
  start?: Moment
  end?: Moment
  type?: TimeEntryExportType
  onSubmit?: () => void
}

interface IDispatchToProps {
  close: typeof closeTimeEntryExportModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  types: { label: string, value: TimeEntryExportType }[]
  type: TimeEntryExportType
  startedAt: Moment | null
  endedAt: Moment | null
  contactIds: string[]
  projectIds: string[]
  spreadsheetType: SpreadsheetExportType
  errors: any
}

class TimeEntryExportModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      types: [],
      type: TimeEntryExportType.ALL,
      startedAt: null,
      endedAt: null,
      contactIds: props.contactId ? [props.contactId] : null,
      projectIds: props.projectId ? [props.projectId] : null,
      spreadsheetType: LocalStorage.get(LocalStorageKey.PREFERRED_EXPORT_FORMAT, SpreadsheetExportType.EXCEL),
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onTimeEntryExportModalClose = this.onTimeEntryExportModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)

    this.onContactChange = this.onContactChange.bind(this);
    this.onProjectChange = this.onProjectChange.bind(this);
    this.onStartedAtChange = this.onStartedAtChange.bind(this);
    this.onEndedAtChange = this.onEndedAtChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onSpreadsheetTypeChange = this.onSpreadsheetTypeChange.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  fetchForm() {
    const { start, end, contactId, projectId, type } = this.props

    TimeEntriesController
      .getExportForm()
      .then(response => {
        const { types } = response;

        this.setState({
          didInitialLoad: true,
          types,
          contactIds: contactId ? [contactId] : null,
          projectIds: projectId ? [projectId] : null,
          startedAt: start ? moment(start).startOf('month') : moment().startOf('month'),
          endedAt: end ? moment(end).endOf('month') : moment().endOf('month'),
          type: type ? type : TimeEntryExportType.ALL,
        });
      })
      .catch(console.error)
  }

  async onFormSubmit(e) {
    e.preventDefault();

    const { contactIds, projectIds, startedAt, endedAt, type, spreadsheetType } = this.state;

    try {
      let range = `${moment(startedAt).format('DD-MM-YYYY')}..${moment(endedAt).format('DD-MM-YYYY')}`

      let queryData = {}
      if (contactIds && contactIds.length > 0) queryData = { ...queryData, 'contact_id[in]': contactIds }
      if (projectIds && projectIds.length > 0) queryData = { ...queryData, 'project_id[in]': projectIds }
      if (range) queryData = { ...queryData, range: range }
      if (type) queryData = { ...queryData, type: type }
      if (spreadsheetType) queryData = { ...queryData, spreadsheet_type: spreadsheetType }

      const file = await TimeEntriesController.export(queryData)

      const { blob, filename } = file

      saveAs(blob, filename)
      this.props.close()
    } catch (ex) {
      console.error(ex)
    }
  }

  onTimeEntryExportModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onContactChange(value?: string[]) {
    this.setState({ contactIds: value, projectIds: [] })
  }

  onProjectChange(value?: string[]) {
    this.setState({ projectIds: value });
  }

  onStartedAtChange(startedAt) {
    const { t } = this.props
    const { endedAt } = this.state

    if (moment(startedAt).isBefore(moment(endedAt))) {
      this.setState({
        startedAt: startedAt
      });
    } else {
      this.setState({
        errors: {
          [t('TimeEntryExportModal::Started on')]: [t('TimeEntryExportModal::must be smaller then end date')]
        }
      })
    }
  }

  onEndedAtChange(endedAt) {
    const { t } = this.props
    const { startedAt } = this.state;

    if (moment(endedAt).isSameOrAfter(startedAt)) {
      this.setState({
        endedAt: endedAt
      });
    } else {
      this.setState({
        errors: {
          [t('TimeEntryExportModal::Finish on')]: [t('TimeEntryExportModal::must be later then start date')]
        }
      })
    }
  }

  onTypeChange(option) {
    this.setState({ type: option.value })
  }

  onSpreadsheetTypeChange(option) {
    const spreadsheetType = option.value
    LocalStorage.set(LocalStorageKey.PREFERRED_EXPORT_FORMAT, spreadsheetType)
    this.setState({ spreadsheetType: spreadsheetType })
  }

  render() {
    const {
      currentUser: { workspace: { setting } },
      contactDisabled,
      projectDisabled,
      t
    } = this.props
    const {
      type,
      contactIds,
      projectIds,
      didInitialLoad,
      errors,
      types,
      startedAt,
      endedAt,
      spreadsheetType,
    } = this.state

    const typeOptions = types
    const selectedTypeOption = typeOptions.find(option => option.value == type)
    const spreadsheetTypeOptions = SpreadsheetHelper.getOptions()
    const selectedSpreadsheetTypeOption = spreadsheetTypeOptions.find(option => option.value == spreadsheetType)

    return (
      <ModalWindow>
        <ModalHeader
          title={t('TimeEntryExportModal::Export time entries')}
          onCloseClick={this.onTimeEntryExportModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit} style={{ width: '100%' }}>
              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('TimeEntryExportModal::Contact')}
                    </label>

                    <ResourceCreatablePowerSelect
                      type='contact'
                      value={contactIds}
                      params={{ archived: false }}
                      onChange={this.onContactChange}
                      isDisabled={contactDisabled}
                      isClearable={true}
                      isMulti={true}
                      isValidNewOption={() => false}
                    />
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('TimeEntryExportModal::Project')}
                    </label>

                    <ResourceCreatablePowerSelect
                      type='project'
                      value={projectIds}
                      onChange={this.onProjectChange}
                      isDisabled={projectDisabled}
                      params={{ 'contact_id[in]': contactIds }}
                      isClearable={true}
                      isMulti={true}
                      isValidNewOption={() => false}
                    />
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('TimeEntryExportModal::Started on')}
                    </label>
                    <DateInput
                      name='started_at'
                      dateFormat={setting.date_format}
                      timeFormat={false}
                      initialValue={moment(startedAt)}
                      inputProps={{ placeholder: setting.date_format }}
                      onChange={this.onStartedAtChange}
                      closeOnSelect
                    />
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('TimeEntryExportModal::Ended at')}</label>
                    <DateInput
                      name='ended_at'
                      dateFormat={setting.date_format}
                      inputProps={{ placeholder: setting.date_format }}
                      timeFormat={false}
                      initialValue={moment(endedAt)}
                      onChange={this.onEndedAtChange}
                      closeOnSelect
                    />
                  </div>
                </div>
              </div>

              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('TimeEntryExportModal::Type')}</label>

                    <PowerSelect
                      options={typeOptions}
                      value={selectedTypeOption}
                      onChange={this.onTypeChange}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('TimeEntryExportModal::Format')}</label>

                    <PowerSelect
                      options={spreadsheetTypeOptions}
                      value={selectedSpreadsheetTypeOption}
                      onChange={this.onSpreadsheetTypeChange}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>
              </div>
              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('TimeEntryExportModal::Export')}
                </a>
              </div>
            </div>
          </div>

        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      timeEntryExportModal: {
        contactId,
        contactDisabled,
        projectId,
        projectDisabled,
        start,
        end,
        type,
        onSubmit,
      }
    }
  } = state

  return {
    contactId,
    contactDisabled,
    projectId,
    projectDisabled,
    start: start,
    end: end,
    type: type,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeTimeEntryExportModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimeEntryExportModal))