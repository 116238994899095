import * as React from 'react'
import { closeCalculateAmountModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import TooltipError from '../Tooltips/ErrorTooltip'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import NumberFormatter from '../../utilities/NumberFormatter'
import SummaryContainer from '../Summary/SummaryContainer'
import SummaryItem from '../Summary/SummaryItem'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import MoneyInput from '../Form/MoneyInput'
import Switch from '../Form/Switch'
import PercentInput from '../Form/PercentInput'
import { CurrentUser } from '../../types'

interface IStateToProps {
  currentUser: CurrentUser
  title?: string
  amount: number
  onSubmit?: (advanceAmount: number) => void
}

interface IDispatchToProps {
  close: typeof closeCalculateAmountModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  percentage: number
  fixedAmountEnabled: boolean
  fixedAmount: number
}

class CalculateAmountModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      fixedAmountEnabled: false,
      fixedAmount: 0,
      percentage: 0.3,
    }

    this.onCalculateAmountModalCloseClick = this.onCalculateAmountModalCloseClick.bind(this)
    this.onToggleFixedAmount = this.onToggleFixedAmount.bind(this)
    this.onFixedAmountChange = this.onFixedAmountChange.bind(this)
    this.onPercentageChange = this.onPercentageChange.bind(this)

    // Form Handlers
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { close, onSubmit } = this.props

    if (onSubmit) onSubmit(this.getBillingAmount())
    close()
  }

  componentDidMount() {
    this.setState({ didInitialLoad: true })
  }

  onCalculateAmountModalCloseClick() {
    this.props.close()
  }

  onToggleFixedAmount(e) {
    e.preventDefault()

    const { fixedAmountEnabled } = this.state

    this.setState({ fixedAmountEnabled: !fixedAmountEnabled })
  }

  onFixedAmountChange(fixedAmount: number) {
    const { amount } = this.props
    this.setState({ fixedAmount: fixedAmount >= amount ? amount : fixedAmount })
  }

  onPercentageChange(percentage: number) {
    this.setState({ percentage: percentage })
  }

  getBillingAmount() {
    const { amount } = this.props
    const { fixedAmountEnabled, fixedAmount, percentage } = this.state

    if (fixedAmountEnabled) {
      return fixedAmount
    } else {
      return amount * percentage
    }
  }

  render() {
    const { title, amount, currentUser: { workspace: { setting } }, t } = this.props
    const { didInitialLoad, fixedAmount, percentage, fixedAmountEnabled } = this.state

    const billingAmount = this.getBillingAmount()
    const remainingAmount = amount - billingAmount

    return (
      <ModalWindow>
        <ModalHeader
          title={title ? title : t('CalculateAmountModal::Calculate amount')}
          onCloseClick={this.onCalculateAmountModalCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('CalculateAmountModal::Remaining amount (excl. VAT)')}</label>
                    <MoneyInput
                      name='remaining_amount'
                      currency={setting.default_currency}
                      numberFormat={setting.number_format}
                      placeholderValue={amount}
                      value={amount}
                      onBlur={() => { }}
                      disabled={true}
                    />

                    <div style={{ marginTop: 10 }}>
                      <Switch
                        name='toggle_percent'
                        label={t('CalculateAmountModal::Fixed amount')}
                        checked={fixedAmountEnabled}
                        onClick={this.onToggleFixedAmount}
                      />
                    </div>
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    {!fixedAmountEnabled && <>
                      <label>{t('CalculateAmountModal::Percent')}</label>
                      <PercentInput
                        name='percentage'
                        defaultValue={percentage}
                        placeholder={String(t('CalculateAmountModal::30%'))}
                        onChange={this.onPercentageChange}
                      />
                    </>}

                    {fixedAmountEnabled && <>
                      <label>{t('CalculateAmountModal::Fixed amount')}</label>
                      <MoneyInput
                        name='remaining_amount'
                        currency={setting.default_currency}
                        numberFormat={setting.number_format}
                        placeholderValue={this.props.amount}
                        value={fixedAmount}
                        onBlur={this.onFixedAmountChange}
                        max={amount}
                      />
                    </>}
                  </div>
                </div>
              </div>

              <div className='fields-section is-last'>
                <div className='fieldset'>
                  <SummaryContainer columnCount={3}>
                    <SummaryItem
                      title={t('CalculateAmountModal::Billable amount')}
                      label={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, amount)}
                    />
                    <SummaryItem
                      title={t('CalculateAmountModal::Billing amount')}
                      label={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, billingAmount)}
                    />
                    <SummaryItem
                      title={t('CalculateAmountModal::Remaining amount')}
                      label={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, remainingAmount)}
                    />
                  </SummaryContainer>
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>
          <div className='modal-footer'>
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('CalculateAmountModal::Bill')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      calculateAmountModal: {
        title,
        amount,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    title: title,
    amount: amount,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeCalculateAmountModal()),
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CalculateAmountModal))