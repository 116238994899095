import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const Label = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	line-height: 0;
	font-size: 15px;
	padding: 8px 0;
	color: #858C99;
	margin: 0;
	margin-left: ${Style.spacing.x1};
	cursor: pointer;
`


interface IProps {
	name: string
	label?: string
	checked?: boolean
	onClick: (event: any) => void
}

const Switch = (props: IProps) => {
	const {
		name,
		label,
		checked,
		onClick
	} = props

	return (
		<Container>
			<div className='switch' onClick={onClick}>
				<input
					type="checkbox"
					id={name}
					checked={checked}
					readOnly
					style={{ display: 'none' }}
				/>
				<label>Toggle</label>
			</div>
			{label && <Label onClick={onClick}>{label}</Label>}
		</Container>
	)
}

export default Switch