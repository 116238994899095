import * as React from 'react'
import { closeQuestionAndAnswerBlockSettingModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import PowerSelect from '../Form/PowerSelect'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import ModalLoader from './Parts/ModalLoader'
import { useDebouncedCallback } from 'use-debounce'
import { QuestionAndAnswerType, QuestionAndAnswerBlock as QuestionAndAnswerBlockType, QuestionAndAnswerBlock } from '../../types'
import CheckboxInput from '../Form/CheckboxInput'
import Tooltip from '../Tooltips/Tooltip'
import Button from '../Button/Button'
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import OptionsContainer from '../BlockEditor/InputBlocks/components/OptionsContainer'
import OptionInputContainer from '../BlockEditor/InputBlocks/components/OptionInputContainer'
import OptionInputDrag from '../BlockEditor/InputBlocks/components/OptionInputDrag'
import OptionInput from '../BlockEditor/InputBlocks/components/OptionInput'
import Icon from '../Icons/Icon'
import OptionRemove from '../BlockEditor/InputBlocks/components/OptionRemove'

interface IStateToProps {
  questionAndAnswerBlock: QuestionAndAnswerBlock
  onSubmit: (questionAndAnswerBlock: QuestionAndAnswerBlock) => void
}

interface IDispatchToProps {
  close: typeof closeQuestionAndAnswerBlockSettingModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

const QuestionAndAnswerSettingModal = (props: IProps) => {
  const { t } = useTranslation()
  const [questionAndAnswerBlock, setQuestionAndAnswerBlock] = React.useState<QuestionAndAnswerBlock>(props.questionAndAnswerBlock)
  const [didInitialLoad, setDidInitialLoad] = React.useState(false)
  const debouncedContactEditableChange = useDebouncedCallback(
    () => setQuestionAndAnswerBlock({ ...questionAndAnswerBlock }), 50
  );

  React.useEffect(() => {
    setTimeout(() => setDidInitialLoad(true), 500)
  }, [])

  const onCloseClick = () => {
    props.close()
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    props.onSubmit(questionAndAnswerBlock)
    props.close()
  }

  const onTitleChange = (e) => {
    setQuestionAndAnswerBlock({
      ...questionAndAnswerBlock,
      title: e.target.value
    })
  }

  const onRequiredChange = (checked: boolean) => {
    setQuestionAndAnswerBlock({
      ...questionAndAnswerBlock,
      required: checked
    })
  }

  const onAnswerTypeChange = (option) => {
    setQuestionAndAnswerBlock({
      ...questionAndAnswerBlock,
      answer_type: option.value,
      options: [],
      allow_multiple_selection: false,
    })
  }

  const answerTypeOptions = [
    { label: t('QuestionAndAnswerSettingModal::Open text'), value: QuestionAndAnswerType.OPEN },
    { label: t('QuestionAndAnswerSettingModal::Multiple selection allowed'), value: QuestionAndAnswerType.LIST },
  ]
  const selectedAnswerTypeOption = answerTypeOptions.find(option => option.value === String(questionAndAnswerBlock.answer_type))

  const onAllowMultipleSelectionChange = () => {
    const newAllowMultipleSelection = !questionAndAnswerBlock.allow_multiple_selection

    setQuestionAndAnswerBlock({
      ...questionAndAnswerBlock,
      allow_multiple_selection: newAllowMultipleSelection

    })
  }

  const onAddOptionClick = () => {
    setQuestionAndAnswerBlock({
      ...questionAndAnswerBlock,
      options: [...questionAndAnswerBlock.options, '']
    })
  }

  const onOptionsDragEnd = (dropResult: DropResult, provided: ResponderProvided) => {
    const { destination, source, draggableId } = dropResult

    if (!destination) return

    if (
      destination.droppableId !== source.droppableId &&
      destination.index !== source.index
    ) {
      return
    }

    const updatedOptions = [...questionAndAnswerBlock.options]
    const movedOption = questionAndAnswerBlock.options[source.index]

    updatedOptions.splice(source.index, 1)
    updatedOptions.splice(destination.index, 0, movedOption)

    setQuestionAndAnswerBlock({
      ...questionAndAnswerBlock,
      options: updatedOptions
    })
  }

  const onOptionChange = (newOptionName: string, index: number) => {
    const newOptions = [...questionAndAnswerBlock.options]

    newOptions[index] = newOptionName

    setQuestionAndAnswerBlock({
      ...questionAndAnswerBlock,
      options: [...newOptions]
    })
  }

  const onOptionRemoveClick = (index: number) => {
    const newOptions = [...questionAndAnswerBlock.options]

    newOptions.splice(index, 1);

    setQuestionAndAnswerBlock({
      ...questionAndAnswerBlock,
      options: [...newOptions]
    })
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={questionAndAnswerBlock.id ? t('QuestionAndAnswerSettingModal::Update question') : t('QuestionAndAnswerSettingModal::Create question')}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('QuestionAndAnswerSettingModal::Question')}<span>*</span></label>
                  <textarea
                    value={questionAndAnswerBlock.title}
                    placeholder={t('QuestionAndAnswer::What are your company goals?')}
                    onChange={onTitleChange}
                  />
                </div>
              </div>

              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>
                    {t('QuestionAndAnswer::Required')}
                    <Tooltip
                      content={t('QuestionAndAnswerSettingModal::Users will need to answer this question before they can submit the playbook.')}
                      containerStyle={{ marginLeft: 8 }}
                    />
                  </label>
                  <CheckboxInput
                    label={t('QuestionAndAnswer::Required')}
                    checked={questionAndAnswerBlock.required}
                    onChange={onRequiredChange}
                  />
                </div>
              </div>
              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>{t('QuestionAndAnswerSettingModal::Answer type')}</label>
                  <PowerSelect
                    options={answerTypeOptions}
                    value={selectedAnswerTypeOption}
                    onChange={onAnswerTypeChange}
                    theme={ReactSelectTheme}
                  />
                </div>
              </div>

              {questionAndAnswerBlock.answer_type === QuestionAndAnswerType.LIST && <>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('InputBlock::Allow multiple')}
                    </label>

                    <CheckboxInput
                      label={t('InputBlock::Multiple selection')}
                      checked={questionAndAnswerBlock.allow_multiple_selection}
                      onChange={onAllowMultipleSelectionChange}
                    />
                  </div>
                </div>


                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('InputBlock::Options')}</label>
                    <DragDropContext onDragEnd={onOptionsDragEnd}>
                      <Droppable droppableId={`options-${questionAndAnswerBlock.title}`}>
                        {(provided) => {
                          return (
                            <OptionsContainer ref={provided.innerRef} {...provided.droppableProps}>
                              {questionAndAnswerBlock.options.map((option, index) => {
                                return (
                                  <Draggable key={index} draggableId={String(index)} index={index}>
                                    {(provided) => {
                                      return (
                                        <OptionInputContainer {...provided.draggableProps} ref={provided.innerRef}>
                                          <OptionInputDrag {...provided.dragHandleProps}>
                                            <Icon icon='grip-vertical' />
                                          </OptionInputDrag>
                                          <OptionInput
                                            type='text'
                                            value={option}
                                            onChange={(e) => onOptionChange(e.currentTarget.value, index)}
                                            placeholder={t('OptionsSection::Option {{index}}', { index: index + 1 })}
                                          />
                                          <OptionRemove className='button-icon button-danger' onClick={() => onOptionRemoveClick(index)}>
                                            <Icon icon='close' />
                                          </OptionRemove>
                                        </OptionInputContainer>
                                      )
                                    }}
                                  </Draggable>
                                )
                              })}
                            </OptionsContainer>
                          )
                        }}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <Button
                    type='success'
                    icon='plus'
                    onClick={onAddOptionClick}
                    text={t('OptionsSection::Add option')}
                  />
                </div>
              </>}
            </div>

            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <div />
          <div className='modal-footer-actions'>
            <div key='main-action' className='popover-wrapper'>
              <a href='javascript://' className='button button-success' onClick={onFormSubmit}>
                {t('QuestionAndAnswerSettingModal::Save')}
              </a>
            </div>
          </div>
        </div>
      </ModalMiddle>}
    </ModalWindow>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    modals: {
      questionAndAnswerBlockSettingModal: {
        show,
        questionAndAnswerBlock,
        onSubmit,
      }
    }
  } = state

  return {
    questionAndAnswerBlock: questionAndAnswerBlock,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeQuestionAndAnswerBlockSettingModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuestionAndAnswerSettingModal))