import * as React from 'react'
import ModalHeader from './Parts/ModalHeader'
import ModalLoader from './Parts/ModalLoader'
import ModalMiddle from './Parts/ModalMiddle'
import ModalWindow from './Parts/ModalWindow'
import ModalContent from './Parts/ModalContent'
import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { closeLedgerItemTransactionHistoryModal, showConfirmModal, showLedgerItemPaymentModal } from '../../store/modals/actions'
import { AppState } from '../../store'
import { CurrentUser, LedgerItemPayment } from '../../types'
import ResourceModalTable from '../Resource/Modal/ResourceModalTable'
import ResourceModalTableRow from '../Resource/Modal/ResourceModalTableRow'
import ResourceModalTableRowHeader from '../Resource/Modal/ResourceModalTableRowHeader'
import ResourceModalTableRowData from '../Resource/Modal/ResourceModalTableRowData'
import ResourceModalTableRowActions from '../Resource/Modal/ResourceModalTableRowActions'
import moment from '../../utilities/Moment'
import NumberFormatter from '../../utilities/NumberFormatter'
import { LedgerItemPaymentsController } from '../../controllers'
import CardEmptyInfo from '../Card/CardEmptyInfo'

interface IStateToProps {
  currentUser: CurrentUser
  ledgerItemId: string
  onSync: () => void
}

interface IDispatchToProps {
  close: typeof closeLedgerItemTransactionHistoryModal
  showLedgerItemPaymentModal: typeof showLedgerItemPaymentModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  errors: any
  ledgerItemPayments: LedgerItemPayment[]
}

class LedgerItemTransactionHistoryModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      ledgerItemPayments: [],
      errors: {},
    }

    this.onLedgerItemTransactionHistoryModalClose = this.onLedgerItemTransactionHistoryModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onActionClick = this.onActionClick.bind(this)
  }

  componentDidMount() {
    this.fetchForm().catch(console.error)
  }

  async fetchForm() {
    const { ledgerItemId } = this.props
    try {
      const ledgerItemPayments = await LedgerItemPaymentsController.getPayments(ledgerItemId)

      this.setState({
        didInitialLoad: true,
        ledgerItemPayments: ledgerItemPayments,
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  onLedgerItemTransactionHistoryModalClose() {
    if (this.props.onSync) this.props.onSync()
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onActionClick(key: string, index: number) {
    const { ledgerItemId, t } = this.props
    const { ledgerItemPayments } = this.state

    const ledgerItemPayment = ledgerItemPayments[index]

    switch (key) {
      case 'edit':


        this.props.showLedgerItemPaymentModal({
          ledgerItemId: ledgerItemId,
          ledgerItemPaymentId: ledgerItemPayment.id,
          onSubmit: (ledgerItemPayment: LedgerItemPayment) => {
            ledgerItemPayments[index] = ledgerItemPayment

            this.setState({ ledgerItemPayments: [...ledgerItemPayments] })
            if (this.props.onSync) this.props.onSync()
          }
        })
        break
      case 'delete':
        this.props.showConfirmModal({
          title: t('LedgerItemTransactionHistoryModal::Delete payment'),
          description: t('LedgerItemTransactionHistoryModal::You are about to delete this payment. Are you sure?'),
          action: { label: t('LedgerItemTransactionHistoryModal::Delete'), isDestructive: true },
          onConfirm: async () => {
            try {
              const response = await LedgerItemPaymentsController.delete(ledgerItemId, ledgerItemPayment.id)

              if (response.success) {
                ledgerItemPayments.splice(index, 1);

                this.setState({
                  ledgerItemPayments: [...ledgerItemPayments,]
                })
                if (this.props.onSync) this.props.onSync()
              }

            } catch (ex) {
              console.error(ex)
            }
          }
        })
        break
    }
  }

  render() {
    const {
      t,
      currentUser: {
        workspace: {
          setting
        }
      }
    } = this.props
    const { ledgerItemPayments, didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={t('LedgerItemTransactionHistoryModal::Transaction history')}
          onCloseClick={this.onLedgerItemTransactionHistoryModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form>
              {ledgerItemPayments.length === 0 && <CardEmptyInfo
                icon='search'
                description={t('LedgerItemTransactionHistoryModal::No payments found')}
              />}
              {ledgerItemPayments.length > 0 && <ResourceModalTable>
                <ResourceModalTableRow header={true}>
                  <ResourceModalTableRowHeader>
                    {t('LedgerItemTransactionHistoryModal::Date')}
                  </ResourceModalTableRowHeader>
                  <ResourceModalTableRowHeader>
                    {t('LedgerItemTransactionHistoryModal::Amount')}
                  </ResourceModalTableRowHeader>
                  <ResourceModalTableRowHeader>
                    {t('LedgerItemTransactionHistoryModal::Currency')}
                  </ResourceModalTableRowHeader>
                  <ResourceModalTableRowHeader />
                </ResourceModalTableRow>

                {ledgerItemPayments.map((payment, index) => (
                  <ResourceModalTableRow key={payment.id}>
                    <ResourceModalTableRowData>
                      {moment(payment.executed_at).format(setting.date_format)}
                    </ResourceModalTableRowData>
                    <ResourceModalTableRowData>
                      {NumberFormatter.formatCurrency(payment.currency, setting.number_format, payment.amount)}
                    </ResourceModalTableRowData>
                    <ResourceModalTableRowData>
                      {payment.currency}
                    </ResourceModalTableRowData>
                    <ResourceModalTableRowActions
                      actions={[
                        { key: 'edit', icon: 'edit-solid', content: t('LedgerItemTransactionHistoryModal::Edit') },
                        { key: 'delete', icon: 'trash-alt-solid', content: t('LedgerItemTransactionHistoryModal::Delete'), destructive: true },
                      ]}
                      onActionClick={(key) => this.onActionClick(key, index)}
                    />
                  </ResourceModalTableRow>
                ))}
              </ResourceModalTable>}
              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      ledgerItemTransactionHistoryModal: {
        ledgerItemId,
        onSync,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    ledgerItemId: ledgerItemId,
    onSync: onSync
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeLedgerItemTransactionHistoryModal()),
    showLedgerItemPaymentModal: (options) => dispatch(showLedgerItemPaymentModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LedgerItemTransactionHistoryModal))