import { Howl } from 'howler'

export enum Sounds {
	COMPLETE = 'complete.mp3',
	STEP_COMPLETE = 'step-complete.mp3'
}

export default class Sound {
	static play(sound: Sounds) {
		new Howl({ src: `/sounds/${sound}`}).play()
	}
}