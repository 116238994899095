import * as React from 'react'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import styled from "styled-components"
import { Style } from '../../styles'
import EmailAttachment from './EmailAttachment'
import EmailAttachments from './EmailAttachments'

const Container = styled.div`
	flex: 1;
	background: rgb(239, 243, 247);;
	padding: 32px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	padding-top: calc(32px + 56px);
	display: flex;
	flex-direction: column;
	overflow: auto;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: none;
	}
`

const Subject = styled.div`
	font-size: 16px;
`

const HR = styled.hr`
	align-self: stretch;
	margin: 16px 0;
	border: none;
	outline: none;
	border-bottom: 1px solid ${Style.color.border};
`

const PreviewContainer = styled.div`
	min-height: 400px;
	max-height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	line-height: 1.4;
	background-color: #fff;
	padding: 32px;

	table {
		width: 100%;
	}
`

const PreviewAttachments = styled.div`
	width: 100%;
	background: white;
	padding-left: 8px;
	padding-right: 8px;
	border-top: 1px solid ${Style.color.border};
`

interface IProps {
	subject: string
	body: string
	attachments?: ActiveStorage.Blob[]
	onAttachmentDeleteClick?: (index: number) => void
}

const EmailPreview = (props: IProps) => {
	const { subject, body, attachments, onAttachmentDeleteClick } = props

	return (
		<Container>
			<Subject>{subject}</Subject>

			<HR />

			<PreviewContainer className='branded-email'>
				<FroalaEditorView model={body} />
			</PreviewContainer>

			{attachments && attachments.length > 0 && <>
				<PreviewAttachments>
					<EmailAttachments>
						{attachments.map((attachment, index) => {
							return (
								<EmailAttachment
									key={index}
									file={attachment}
									onDeleteClick={() => onAttachmentDeleteClick(index)}
								/>
							)
						})}
					</EmailAttachments>
				</PreviewAttachments>
			</>}
		</Container>
	)
}

export default EmailPreview