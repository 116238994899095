import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import prettyBytes from 'pretty-bytes';
import FileHelper from '../../helpers/FileHelper';
import Icon from '../Icons/Icon';

const DeleteAction = styled.div`
	border-left: 1px solid ${Style.color.border};
	height: 100%;
	display: none;
	justify-content: center;
	align-items: center;
	padding: 0 8px;
	cursor: pointer;
`

export const EmailAttachmentContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid ${Style.color.border};
	border-radius: ${Style.variables.baseBorderRadius};
	margin: 4px 0;
	margin-right: 8px;
	width: 200px;
	max-width: 200px;
	position: relative;

	&:hover {
		${DeleteAction} {
			display: flex;
		}
	}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		${DeleteAction} {
			display: flex;
		}
	}
`

const Preview = styled.div<{ onClick: () => void }>`
	display: flex;
	justify-content: center;
	align-items: center;
  background-color: rgba(9,30,66,.04);
  border-radius: 3px;
  height: 100%;
  text-align: center;
  text-decoration: none;
	height: 40px;
  width: 40px;
	min-width: 40px;
	margin-right: 8px;

  span {
		color: #5e6c84;
		display: block;
		font-size: 14px;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
		text-decoration: none;
		width: 100%;
  }

	${props => props.onClick && css`
		cursor: pointer;
	`}
`

const Metadata = styled.div<{ onClick: () => void }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;

	${props => props.onClick && css`
		cursor: pointer;
	`}
`

const Filename = styled.div`
	color: black;
	font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Filesize = styled.div`
	color: #8791A5;
	font-size: 11px;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface IProps {
	file: ActiveStorage.Blob
	onDeleteClick: () => void
	onClick?: () => void
}

const EmailAttachment = (props: IProps) => {
	const { file, onClick, onDeleteClick } = props

	return (
		<EmailAttachmentContainer>
			<Preview onClick={onClick}>
				<span>{FileHelper.getExtension(file.filename)}</span>
			</Preview>
			<Metadata onClick={onClick}>
				<Filename>{file.filename}</Filename>
				<Filesize>{prettyBytes(file.byte_size)}</Filesize>
			</Metadata>
			<DeleteAction onClick={onDeleteClick}>
				<Icon icon='close' />
			</DeleteAction>
		</EmailAttachmentContainer>
	)
}

export default EmailAttachment