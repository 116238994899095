import styled from "styled-components";

const SidebarContent = styled.div`
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
	margin-top: -10px;
	margin-right: -10px;
	padding-top: 12px;
	padding-right: 10px;

	&::-webkit-scrollbar {
    background: white;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #7d7d7d;
    }
  }
`

export default SidebarContent