import styled from "styled-components";

const TaskPopoverHeader = styled.div`
	position: relative;
	height: 40px;
	position: relative;
	margin-bottom: 8px;
	text-align: center;
`

export default TaskPopoverHeader