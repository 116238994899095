import * as React from 'react'
import { closeSequenceStepModal, showConfirmModal, showContactModal, showEmailTemplateModal } from '../../store/modals/actions'
import TooltipError from '../Tooltips/ErrorTooltip'
import { EmailTemplatesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { EMAIL_EDITOR_CONFIG } from '../Editor/Editor'
import EditorHelper from '../../helpers/EditorHelper'
import Dropzone, { DropEvent, DropzoneRef, FileRejection } from 'react-dropzone'
import { MAX_EMAIL_ATTACHMENTS_SIZE, MAX_EMAIL_ATTACHMENT_SIZE } from '../../Constants'
import { AutomatedEmailSequenceStep, CurrentUser, EmailTemplate, MimeTypes, SequenceStep } from '../../types'
import EmailTemplatesPopover from '../EmailModal/EmailTemplatesPopover'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import EmailPreviewContainer from '../Editor/EmailPreviewContainer'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import MustacheHelper from '../../helpers/MustacheHelper'
import ModalWindow from '../Modals/Parts/ModalWindow'
import ModalMiddle from '../Modals/Parts/ModalMiddle'
import ModalHeader from '../Modals/Parts/ModalHeader'
import ModalContent from '../Modals/Parts/ModalContent'
import ModalLoader from '../Modals/Parts/ModalLoader'
import ModalFooter from '../Modals/Parts/ModalFooter'
import Button from '../Button/Button'
import ModalFooterActionIcons from '../Modals/Parts/ModalFooterActionIcons'
import ModalFooterActionIcon from '../Modals/Parts/ModalFooterAction'
import VariableList from '../Editor/components/VariableList'
import Popover from '../Popover/Popover'
import ButtonLink from '../Button/ButtonLink'
import VariableListContainer from '../Editor/components/VariableListContainer'

const EmailModalWindow = styled(ModalWindow)`
  height: 100vh;
  max-width: 400px;
  max-width: initial;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: initial;
  }

  ${ModalMiddle} {
    overflow-x: hidden;
    overflow-y: auto;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeSequenceStepModal
  showEmailTemplateModal: typeof showEmailTemplateModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = {
  step: AutomatedEmailSequenceStep
  emailIndex: number
  variables: any
  onSubmit: (step: SequenceStep) => void
  onClose: () => void
} & IDispatchToProps & IStateToProps & WithTranslation & RouteComponentProps

interface IState {
  step: AutomatedEmailSequenceStep
  errors: any
  templatePopoverActive: boolean
  preview: boolean
  showVariables: boolean
}

class SequenceStepEmailModalContent extends React.Component<IProps, IState> {
  private dropzone = React.createRef<DropzoneRef>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      step: props.step,
      errors: {},
      templatePopoverActive: false,
      preview: false,
      showVariables: false
    }

    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onSubjectChange = this.onSubjectChange.bind(this)
    this.onBodyChange = this.onBodyChange.bind(this)
    this.onTogglePreviewClick = this.onTogglePreviewClick.bind(this)
    this.onAddAttachmentClick = this.onAddAttachmentClick.bind(this)
    this.onAttachmentDrop = this.onAttachmentDrop.bind(this)
    this.onAttachmentDeleteClick = this.onAttachmentDeleteClick.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
    this.onTemplatePopoverToggle = this.onTemplatePopoverToggle.bind(this)
    this.onTemplatePopoverClose = this.onTemplatePopoverClose.bind(this)
    this.onCreateTemplateClick = this.onCreateTemplateClick.bind(this)
    this.onEditTemplateClick = this.onEditTemplateClick.bind(this)
    this.onDeleteTemplateClick = this.onDeleteTemplateClick.bind(this)
    this.onTemplateClick = this.onTemplateClick.bind(this)
    this.onVariablesToggle = this.onVariablesToggle.bind(this)
    this.onVariablesClose = this.onVariablesClose.bind(this)
    this.onVariableClick = this.onVariableClick.bind(this)
    this.onTogglePreviewClick = this.onTogglePreviewClick.bind(this)
  }

  onCloseClick() {
    this.props.onClose()
  }

  onTemplatePopoverToggle() {
    this.setState({
      templatePopoverActive: !this.state.templatePopoverActive
    })
  }

  onCreateTemplateClick() {
    this.setState({ templatePopoverActive: false }, () => {
      requestAnimationFrame(() => {
        this.props.showEmailTemplateModal({
          emailTemplate: {},
          onSubmit: () => {
            this.setState({ templatePopoverActive: true })
          }
        })
      })
    })
  }

  onEditTemplateClick(emailTemplate: EmailTemplate) {
    this.setState({ templatePopoverActive: false }, () => {
      requestAnimationFrame(() => {
        this.props.showEmailTemplateModal({
          emailTemplate: emailTemplate,
          onSubmit: () => {
            this.setState({ templatePopoverActive: true })
          }
        })
      })
    })
  }

  onDeleteTemplateClick(emailTemplate: EmailTemplate) {
    const { t } = this.props
    this.setState({ templatePopoverActive: false }, () => {

      requestAnimationFrame(() => {
        this.props.showConfirmModal({
          title: t('EmailTemplatesPopover::Delete template'),
          description: t('EmailTemplatesPopover::Are you sure you want to delete this template?'),
          action: {
            label: t('EmailTemplatesPopover::Delete'),
            isDestructive: true
          },
          onConfirm: async () => {
            try {
              await EmailTemplatesController.delete(emailTemplate.id)
              this.setState({ templatePopoverActive: true })
            } catch (ex) {
              console.error(ex)
            }
          }
        })
      })
    })
  }

  onTemplateClick(emailTemplate: EmailTemplate) {
    const { emailIndex } = this.props
    const { step } = this.state

    const email = step.emails[emailIndex]

    step.emails[emailIndex] = {
      ...email,
      subject: emailTemplate.subject,
      body: emailTemplate.body,
    }

    this.setState({
      step: {
        ...step,
        emails: [...step.emails]
      },
      templatePopoverActive: false
    })
  }

  onTemplatePopoverClose() {
    this.setState({
      templatePopoverActive: false
    })
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onSubjectChange(e) {
    e.preventDefault();
    const { emailIndex } = this.props
    const { step } = this.state

    const newSubject = e.currentTarget.value;

    const email = step.emails[emailIndex]

    step.emails[emailIndex] = {
      ...email,
      subject: newSubject,
    }

    this.setState({ step: { ...step, } })
  }

  onBodyChange(body: string) {
    const { emailIndex } = this.props
    const { step } = this.state

    const email = step.emails[emailIndex]

    step.emails[emailIndex] = {
      ...email,
      body: body,
    }

    this.setState({ step: { ...step, } })
  }

  onAddAttachmentClick() {
    if (this.dropzone.current) {
      this.dropzone.current.open()
    }
  }

  onTogglePreviewClick() {
    const { preview } = this.state
    this.setState({ preview: !preview })
  }

  onAttachmentDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    // const { t } = this.props
    // acceptedFiles.forEach(async (file: any) => {
    //   try {
    //     ActiveStorageController.upload(file, async (error, blob) => {
    //       if (error) { console.error(error) }
    //       if (blob) {
    //         this.setState({
    //           attachments: [...this.state.attachments, blob]
    //         })
    //       }
    //     })
    //   } catch (ex) {
    //     console.error(ex)
    //   }
    // })

    // // Display the file names and errors
    // fileRejections.forEach((fileRejection) => {
    //   fileRejection.errors.forEach((err) => {
    //     if (err.code === "file-too-large") Notification.notifyError(t('SequenceStepModal::File {{filename}} is too large', { filename: fileRejection.file.name }))
    //     if (err.code === "file-invalid-type") Notification.notifyError(t('SequenceStepModal::File {{filename}} is an invalid filetype', { filename: fileRejection.file.name }))
    //   });
    // });
  }

  onAttachmentDeleteClick(index: number) {
    // const { attachments } = this.state

    // attachments.splice(index, 1)

    // this.setState({
    //   attachments: [...attachments]
    // })
  }

  onFormSubmit(e?: React.FormEvent) {
    e.preventDefault()

    this.props.onSubmit(this.state.step)
  }

  onVariablesToggle() {
    this.setState({ showVariables: true })
  }

  onVariablesClose() {
    this.setState({ showVariables: false })
  }

  onVariableClick(value: string) {
    const { emailIndex } = this.props
    const { step } = this.state

    const email = step.emails[emailIndex]

    step.emails[emailIndex] = {
      ...email,
      subject: `${email.subject} ${value}`
    }

    this.setState({ step: { ...step }, showVariables: false })
  }

  render() {
    const { t, variables, emailIndex } = this.props
    const {
      errors,
      templatePopoverActive,
      preview,
      step,
      showVariables
    } = this.state

    const attachmentsSize = 0 // attachments.reduce((acc, attachment) => acc + attachment.byte_size, 0)
    const emailAttachmentLimitReached = attachmentsSize >= MAX_EMAIL_ATTACHMENTS_SIZE
    const variableOptions = EditorHelper.getVariableOptions(variables)
    const email = (step?.emails || [])[emailIndex]

    return (
      <EmailModalWindow>
        <ModalHeader
          title={t('SequenceStepModal::Edit email')}
          onCloseClick={this.onCloseClick}
        />
        <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div key='content'>
                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <div className='field-label-with-action'>
                        <label>{t('SequenceStepModal::Subject')} <span>*</span></label>
                        <Popover
                          activator={
                            <div>
                              <ButtonLink
                                onClick={this.onVariablesToggle}
                                text={t('SequenceStepModal::Personalize')}
                              />
                            </div>
                          }
                          active={showVariables}
                          onClose={this.onVariablesClose}
                          placement='bottom-end'
                        >
                          <VariableListContainer>
                            <VariableList
                              variables={variableOptions}
                              onClick={this.onVariableClick}
                            />
                          </VariableListContainer>
                        </Popover>
                      </div>

                      <input type='text'
                        value={preview ? MustacheHelper.process(email?.subject || '', variables) : (email?.subject || '')}
                        onChange={this.onSubjectChange}
                        placeholder={t('SequenceStepModal::Type a subject for your email')}
                        name='subject'
                        required
                        disabled={preview}
                      />
                    </div>
                  </div>
                </div>

                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <div className='field-label-with-action'>
                        <label>{t('SequenceStepModal::Content')} <span>*</span></label>
                      </div>
                      {!preview && <EditorContainer toolbarBottom>
                        <Editor
                          key={String(variableOptions.length)}
                          model={email?.body || ''}
                          onModelChange={this.onBodyChange}
                          config={{
                            ...EMAIL_EDITOR_CONFIG,
                            editorClass: 'user-email',
                            toolbarBottom: true,
                            heightMin: '67vh',
                            heightMax: '67vh',
                            variableOptions: variableOptions,
                            placeholderText: t('SequenceStepModal::Type your email here'),
                          }}
                        />
                      </EditorContainer>}

                      {preview && <EmailPreviewContainer style={{ minHeight: '67vh', maxHeight: '67vh' }} >
                        <FroalaEditorView model={MustacheHelper.process(email?.body || '', variables)} />
                      </EmailPreviewContainer>}
                    </div>
                  </div>
                </div>

                {/* {attachments?.length > 0 && <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SequenceStepModal::Attachments')}</label>

                      {emailAttachmentLimitReached && <Alert
                        type='warning'
                        text={t('SequenceStepModal::You have reached the average maximum size limit for most email providers. The email will be sent but we can\'t guarantee it will arrive at its destination.')}
                      />}

                      {attachments && attachments.length > 0 && <EmailAttachments>
                        {attachments?.map((attachment, index) => {
                          return (
                            <EmailAttachment
                              key={index}
                              file={attachment}
                              onDeleteClick={() => this.onAttachmentDeleteClick(index)}
                            />
                          )
                        })}
                      </EmailAttachments>}
                    </div>
                  </div>
                </div>} */}
                <Dropzone
                  ref={this.dropzone}
                  onDrop={this.onAttachmentDrop}
                  multiple={true}
                  accept={[
                    MimeTypes.JPEG,
                    MimeTypes.JPG,
                    MimeTypes.PNG,
                    MimeTypes.PDF,
                    MimeTypes.CSV,
                    MimeTypes.EXCEL
                  ]}
                  maxSize={MAX_EMAIL_ATTACHMENT_SIZE}
                >
                  {({ getInputProps, open }) => (
                    <input {...getInputProps()} />
                  )}
                </Dropzone>
              </div>
            </form>
          </ModalContent>
        </ModalMiddle>
        <ModalFooter>
          <div className='modal-footer'>
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <Button
                  type='success'
                  text={t('SequenceStepModal::Save')}
                  onClick={this.onFormSubmit}
                />
              </div>
            </div>
            <ModalFooterActionIcons>
              <ModalFooterActionIcon
                icon='eye'
                onClick={this.onTogglePreviewClick}
                tooltip={t('SequenceStepModal::Toggle preview')}
                active={preview}
              />

              <EmailTemplatesPopover
                active={templatePopoverActive}
                activator={
                  <ModalFooterActionIcon
                    icon='file-code'
                    onClick={this.onTemplatePopoverToggle}
                    tooltip={t('SequenceStepModal::Email templates')}
                  />
                }
                onCreateTemplateClick={this.onCreateTemplateClick}
                onEditTemplateClick={this.onEditTemplateClick}
                onDeleteTemplateClick={this.onDeleteTemplateClick}
                onTemplateClick={this.onTemplateClick}
                onClose={this.onTemplatePopoverClose}
              />

              {false && <ModalFooterActionIcon
                icon='attachment'
                onClick={this.onAddAttachmentClick}
                tooltip={t('SequenceStepModal::Add attachments')}
              />}
            </ModalFooterActionIcons>
          </div>
        </ModalFooter>
      </EmailModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
  } = state

  return { currentUser: currentUser }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeSequenceStepModal()),
    showEmailTemplateModal: (options) => dispatch(showEmailTemplateModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SequenceStepEmailModalContent)))