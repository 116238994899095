import * as React from 'react'
import TooltipError from '../Tooltips/ErrorTooltip'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { EMAIL_EDITOR_CONFIG } from '../Editor/Editor'
import EditorHelper from '../../helpers/EditorHelper'
import { CallSequenceStep, ManualEmailSequenceStep, SequenceStep, SequenceStepType, TaskSequenceStep } from '../../types'
import ModalWindow from '../Modals/Parts/ModalWindow'
import ModalMiddle from '../Modals/Parts/ModalMiddle'
import ModalHeader from '../Modals/Parts/ModalHeader'
import ModalContent from '../Modals/Parts/ModalContent'
import ModalFooter from '../Modals/Parts/ModalFooter'
import ModalFooterActionIcons from '../Modals/Parts/ModalFooterActionIcons'
import Button from '../Button/Button'
import ButtonLink from '../Button/ButtonLink'
import Popover from '../Popover/Popover'
import VariableList from '../Editor/components/VariableList'
import ModalFooterActionIcon from '../Modals/Parts/ModalFooterAction'
import MustacheHelper from '../../helpers/MustacheHelper'
import EmailPreviewContainer from '../Editor/EmailPreviewContainer'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import VariableListContainer from '../Editor/components/VariableListContainer'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Tooltip from '../Tooltips/Tooltip'

const SequenceStepModalWindow = styled(ModalWindow)`
  height: 100vh;
  max-width: initial;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: initial;
  }

  ${ModalMiddle} {
    overflow-x: hidden;
    overflow-y: auto;
  }
`

type IProps = {
  step: ManualEmailSequenceStep | CallSequenceStep | TaskSequenceStep
  onSubmit: (step: SequenceStep) => void
  onClose: () => void
  variables: any
} & WithTranslation

interface IState {
  step: ManualEmailSequenceStep | CallSequenceStep | TaskSequenceStep
  isSaving: boolean
  errors: any
  showVariables: boolean
  preview: boolean
}

class SequenceStepTaskModalContent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      step: props.step,
      isSaving: false,
      errors: {},
      showVariables: false,
      preview: false,
    }

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
    this.onVariablesToggle = this.onVariablesToggle.bind(this)
    this.onVariablesClose = this.onVariablesClose.bind(this)
    this.onVariableClick = this.onVariableClick.bind(this)
    this.onTogglePreviewClick = this.onTogglePreviewClick.bind(this)
    this.onPlaybookChange = this.onPlaybookChange.bind(this)
  }

  async onFormSubmit(e?: React.FormEvent) {
    e.preventDefault()

    this.props.onSubmit(this.state.step)
  }

  onCloseClick() {
    this.props.onClose()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onNameChange(e) {
    e.preventDefault();
    const { step } = this.state

    const newName = e.currentTarget.value;

    this.setState({ step: { ...step, name: newName } });
  }

  onDescriptionChange(description: string) {
    const { step } = this.state

    this.setState({ step: { ...step, description: description } });
  }

  onVariablesToggle() {
    this.setState({ showVariables: true })
  }

  onVariablesClose() {
    this.setState({ showVariables: false })
  }

  onVariableClick(value: string) {
    const { step } = this.state

    this.setState({
      step: {
        ...step,
        name: `${step.name} ${value}`,
      },
      showVariables: false
    })
  }

  onTogglePreviewClick() {
    const { preview } = this.state
    this.setState({ preview: !preview })
  }

  onPlaybookChange(playbookId?: string) {
    const { step } = this.state

    if (step.type === SequenceStepType.CALL) {
      this.setState({
        step: {
          ...step,
          playbook_id: playbookId
        }
      })
    }
  }

  render() {
    const { t, variables } = this.props
    const {
      step,
      errors,
      showVariables,
      preview,
    } = this.state

    const variableOptions = EditorHelper.getVariableOptions(variables)

    return (
      <SequenceStepModalWindow>
        <ModalHeader
          title={t('SequenceStepModal::Edit task')}
          onCloseClick={this.onCloseClick}
        />

        <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div key='content'>
                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <div className='field-label-with-action' style={{ position: 'relative' }}>
                        <label>{t('SequenceStepTaskModalContent::Task name')} <span>*</span></label>
                        <Popover
                          activator={
                            <div>
                              <ButtonLink
                                onClick={this.onVariablesToggle}
                                text={t('SequenceStepTaskModalContent::Personalize')}
                              />
                            </div>
                          }
                          active={showVariables}
                          onClose={this.onVariablesClose}
                          placement='bottom-end'
                        >
                          <VariableListContainer>
                            <VariableList
                              variables={variableOptions}
                              onClick={this.onVariableClick}
                            />
                          </VariableListContainer>
                        </Popover>
                      </div>

                      <input
                        type='text'
                        value={preview ? MustacheHelper.process(step.name, variables) : step.name}
                        onChange={this.onNameChange}
                        placeholder={t('SequenceStepTaskModalContent::Type a name for your new task')}
                        name='subject'
                        required
                        disabled={preview}
                      />
                    </div>
                  </div>

                  {step.type === SequenceStepType.CALL && <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>
                        {t('SequenceStepTaskModalContent::Playbook')}

                        <Tooltip
                          content={t('SequenceStepTaskModalContent::Automatically assign a playbook to go through when calling this contact.')}
                          containerStyle={{ marginLeft: 8 }}
                        />
                      </label>
                      <div className='form-item'>
                        <ResourceCreatablePowerSelect
                          type='playbook'
                          value={step.playbook_id}
                          onChange={this.onPlaybookChange}
                          placeholder={t('PlaybookPopover::Select playbook...')}
                          isClearable={true}
                        />
                      </div>
                    </div>
                  </div>}
                </div>

                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <div className='field-label-with-action'>
                        <label>{t('SequenceStepTaskModalContent::Description')} <span></span></label>
                      </div>
                      <EditorContainer toolbarBottom>
                        {!preview && <Editor
                          key={String(variableOptions.length)}
                          model={step.description}
                          onModelChange={this.onDescriptionChange}
                          config={{
                            ...EMAIL_EDITOR_CONFIG,
                            toolbarBottom: true,
                            heightMin: '67vh',
                            heightMax: '67vh',
                            variableOptions: variableOptions,
                            placeholderText: t('SequenceStepTaskModalContent::Add notes or a description for your task'),
                          }}
                        />}
                        {preview && <EmailPreviewContainer style={{ minHeight: '67vh', maxHeight: '67vh' }} >
                          <FroalaEditorView model={MustacheHelper.process(step.description, variables)} />
                        </EmailPreviewContainer>}
                      </EditorContainer>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ModalContent>
        </ModalMiddle>
        <ModalFooter>
          <div className='modal-footer'>
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <Button
                  type='success'
                  text={t('SequenceStepModal::Save')}
                  onClick={this.onFormSubmit}
                  disabled={step?.name?.length === 0}
                />
              </div>
            </div>
            <ModalFooterActionIcons>
              <ModalFooterActionIcon
                icon='eye'
                onClick={this.onTogglePreviewClick}
                tooltip={t('SequenceStepModal::Toggle preview')}
                active={preview}
              />
            </ModalFooterActionIcons>
          </div>
        </ModalFooter>
      </SequenceStepModalWindow>
    )
  }
}

export default withTranslation()(SequenceStepTaskModalContent)