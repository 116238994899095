import i18n from "../translations";
import { DayOfWeek, MonthOfYear, RecurrenceUnit, RecurringSchedule, Workspace } from "../types";
import moment from "../utilities/Moment";

type OptionType = { label: string; value: string };

export default class RecurringScheduleHelper {
  static getDefaultSettingsForType(
    type: RecurrenceUnit,
    workspace: Workspace
  ): RecurringSchedule {
    let defaultRecurringSchedule: RecurringSchedule = {
      recurrence_interval: 1,
      timezone: workspace.timezone,
    };

    const startDate = moment().add(1, 'day')
    const repeatAtEndOfMonth = moment(startDate).endOf('month').isSame(startDate, 'day')

    switch (type) {
      case RecurrenceUnit.DAILY:
        return {
          ...defaultRecurringSchedule,
          recurrence_unit: RecurrenceUnit.DAILY,
          start_date: startDate,
          end_date: null,
        };
      case RecurrenceUnit.WEEKLY:
        return {
          ...defaultRecurringSchedule,
          recurrence_unit: RecurrenceUnit.WEEKLY,
          repeat_on_day_of_week: [startDate.isoWeekday()],
          start_date: startDate,
          end_date: null,
        };
      case RecurrenceUnit.MONTHLY:
        return {
          ...defaultRecurringSchedule,
          recurrence_unit: RecurrenceUnit.MONTHLY,
          repeat_on_day_of_month: startDate.date(),
          start_date: startDate,
          end_date: null,
          repeat_at_end_of_month: repeatAtEndOfMonth
        };
      case RecurrenceUnit.BIMONTHLY:
        return {
          ...defaultRecurringSchedule,
          recurrence_unit: RecurrenceUnit.BIMONTHLY,
          repeat_on_day_of_month: startDate.date(),
          start_date: startDate,
          end_date: null,
          repeat_at_end_of_month: repeatAtEndOfMonth
        };
      case RecurrenceUnit.QUARTERLY:
        return {
          ...defaultRecurringSchedule,
          recurrence_unit: RecurrenceUnit.QUARTERLY,
          repeat_on_day_of_month: startDate.date(),
          start_date: startDate,
          end_date: null,
          repeat_at_end_of_month: repeatAtEndOfMonth
        };
      case RecurrenceUnit.SEMESTERLY:
        return {
          ...defaultRecurringSchedule,
          recurrence_unit: RecurrenceUnit.SEMESTERLY,
          repeat_on_day_of_month: startDate.date(),
          start_date: startDate,
          end_date: null,
          repeat_at_end_of_month: repeatAtEndOfMonth
        };
      case RecurrenceUnit.YEARLY:
        return {
          ...defaultRecurringSchedule,
          recurrence_unit: RecurrenceUnit.YEARLY,
          // Zero index add 1 for actual month
          repeat_on_month_of_year: startDate.month() + 1,
          repeat_on_day_of_month: startDate.date(),
          start_date: startDate,
          end_date: null,
          repeat_at_end_of_month: repeatAtEndOfMonth
        };
      default:
        throw Error("Unsupported ");
    }
  }

  static unitOptions(): OptionType[] {
    return [
      {
        label: i18n.t("RecurringScheduleHelper::Daily"),
        value: RecurrenceUnit.DAILY,
      },
      {
        label: i18n.t("RecurringScheduleHelper::Weekly"),
        value: RecurrenceUnit.WEEKLY,
      },
      {
        label: i18n.t("RecurringScheduleHelper::Monthly"),
        value: RecurrenceUnit.MONTHLY,
      },
      {
        label: i18n.t("RecurringScheduleHelper::Bimonthly"),
        value: RecurrenceUnit.BIMONTHLY,
      },
      {
        label: i18n.t("RecurringScheduleHelper::Quarterly"),
        value: RecurrenceUnit.QUARTERLY,
      },
      {
        label: i18n.t("RecurringScheduleHelper::Semesterly"),
        value: RecurrenceUnit.SEMESTERLY,
      },
      {
        label: i18n.t("RecurringScheduleHelper::Yearly"),
        value: RecurrenceUnit.YEARLY,
      },
    ];
  }

  static dayOfWeekOptions(): OptionType[] {
    return [
      {
        label: i18n.t("RecurringScheduleHelper::Monday"),
        value: String(DayOfWeek.MONDAY),
      },
      {
        label: i18n.t("RecurringScheduleHelper::Tuesday"),
        value: String(DayOfWeek.TUESDAY),
      },
      {
        label: i18n.t("RecurringScheduleHelper::Wednesday"),
        value: String(DayOfWeek.WEDNESDAY),
      },
      {
        label: i18n.t("RecurringScheduleHelper::Thursday"),
        value: String(DayOfWeek.THURSDAY),
      },
      {
        label: i18n.t("RecurringScheduleHelper::Friday"),
        value: String(DayOfWeek.FRIDAY),
      },
      {
        label: i18n.t("RecurringScheduleHelper::Saturday"),
        value: String(DayOfWeek.SATURDAY),
      },
      {
        label: i18n.t("RecurringScheduleHelper::Sunday"),
        value: String(DayOfWeek.SUNDAY),
      },
    ];
  }

  static dayOfMonthOptions(): OptionType[] {
    return [
      { label: i18n.t("RecurringScheduleHelper::First"), value: "1" },
      { label: i18n.t("RecurringScheduleHelper::Last"), value: "last" },
      { label: i18n.t("RecurringScheduleHelper::2nd"), value: "2" },
      { label: i18n.t("RecurringScheduleHelper::3rd"), value: "3" },
      { label: i18n.t("RecurringScheduleHelper::4th"), value: "4" },
      { label: i18n.t("RecurringScheduleHelper::5th"), value: "5" },
      { label: i18n.t("RecurringScheduleHelper::6th"), value: "6" },
      { label: i18n.t("RecurringScheduleHelper::7th"), value: "7" },
      { label: i18n.t("RecurringScheduleHelper::8th"), value: "8" },
      { label: i18n.t("RecurringScheduleHelper::9th"), value: "9" },
      { label: i18n.t("RecurringScheduleHelper::10th"), value: "10" },
      { label: i18n.t("RecurringScheduleHelper::11th"), value: "11" },
      { label: i18n.t("RecurringScheduleHelper::12th"), value: "12" },
      { label: i18n.t("RecurringScheduleHelper::13th"), value: "13" },
      { label: i18n.t("RecurringScheduleHelper::14th"), value: "14" },
      { label: i18n.t("RecurringScheduleHelper::15th"), value: "15" },
      { label: i18n.t("RecurringScheduleHelper::16th"), value: "16" },
      { label: i18n.t("RecurringScheduleHelper::17th"), value: "17" },
      { label: i18n.t("RecurringScheduleHelper::18th"), value: "18" },
      { label: i18n.t("RecurringScheduleHelper::19th"), value: "19" },
      { label: i18n.t("RecurringScheduleHelper::20th"), value: "20" },
      { label: i18n.t("RecurringScheduleHelper::21st"), value: "21" },
      { label: i18n.t("RecurringScheduleHelper::22nd"), value: "22" },
      { label: i18n.t("RecurringScheduleHelper::23rd"), value: "23" },
      { label: i18n.t("RecurringScheduleHelper::24th"), value: "24" },
      { label: i18n.t("RecurringScheduleHelper::25th"), value: "25" },
      { label: i18n.t("RecurringScheduleHelper::26th"), value: "26" },
      { label: i18n.t("RecurringScheduleHelper::27th"), value: "27" },
      { label: i18n.t("RecurringScheduleHelper::28th"), value: "28" },
      { label: i18n.t("RecurringScheduleHelper::29th"), value: "29" },
      { label: i18n.t("RecurringScheduleHelper::30th"), value: "30" },
      { label: i18n.t("RecurringScheduleHelper::31st"), value: "31" },
    ];
  }

  static monthOfYearOptions(): OptionType[] {
    return [
      {
        label: i18n.t("RecurringScheduleHelper::January"),
        value: String(MonthOfYear.JANUARY),
      },
      {
        label: i18n.t("RecurringScheduleHelper::February"),
        value: String(MonthOfYear.FEBRUARY),
      },
      {
        label: i18n.t("RecurringScheduleHelper::March"),
        value: String(MonthOfYear.MARCH),
      },
      {
        label: i18n.t("RecurringScheduleHelper::April"),
        value: String(MonthOfYear.APRIL),
      },
      {
        label: i18n.t("RecurringScheduleHelper::May"),
        value: String(MonthOfYear.MAY),
      },
      {
        label: i18n.t("RecurringScheduleHelper::June"),
        value: String(MonthOfYear.JUNE),
      },
      {
        label: i18n.t("RecurringScheduleHelper::July"),
        value: String(MonthOfYear.JULY),
      },
      {
        label: i18n.t("RecurringScheduleHelper::August"),
        value: String(MonthOfYear.AUGUST),
      },
      {
        label: i18n.t("RecurringScheduleHelper::September"),
        value: String(MonthOfYear.SEPTEMBER),
      },
      {
        label: i18n.t("RecurringScheduleHelper::October"),
        value: String(MonthOfYear.OCTOBER),
      },
      {
        label: i18n.t("RecurringScheduleHelper::November"),
        value: String(MonthOfYear.NOVEMBER),
      },
      {
        label: i18n.t("RecurringScheduleHelper::December"),
        value: String(MonthOfYear.DECEMBER),
      },
    ];
  }

  static recurrenceUnitLabel(unit: RecurrenceUnit) {
    switch (unit) {
      case RecurrenceUnit.DAILY:
        return i18n.t("RecurringScheduleHelper::Daily");
      case RecurrenceUnit.WEEKLY:
        return i18n.t("RecurringScheduleHelper::Weekly");
      case RecurrenceUnit.MONTHLY:
        return i18n.t("RecurringScheduleHelper::Monthly");
      case RecurrenceUnit.BIMONTHLY:
        return i18n.t("RecurringScheduleHelper::Bimonthly");
      case RecurrenceUnit.QUARTERLY:
        return i18n.t("RecurringScheduleHelper::Quarterly");
      case RecurrenceUnit.SEMESTERLY:
        return i18n.t("RecurringScheduleHelper::Semesterly");
      case RecurrenceUnit.YEARLY:
        return i18n.t("RecurringScheduleHelper::Yearly");
    }
  }

  static getScheduleText(schedule: RecurringSchedule): string {
    const {
      repeat_on_day_of_week,
      repeat_on_day_of_month,
      repeat_on_month_of_year,
      repeat_at_end_of_month,
    } = schedule;

    if (schedule.recurrence_unit === RecurrenceUnit.DAILY) {
      return i18n.t(
        "RecurringScheduleHelper::Repeats <b>{{recurrenceUnit}}</b>",
        {
          recurrenceUnit: this.recurrenceUnitLabel(
            schedule.recurrence_unit
          ).toLowerCase(),
        }
      );
    } else if (schedule.recurrence_unit === RecurrenceUnit.WEEKLY) {
      const weekday = this.dayOfWeekOptions().find(
        (option) => repeat_on_day_of_week.includes(Number(option.value))
      );

      return i18n.t(
        "RecurringScheduleHelper::Repeats <b>{{recurrenceUnit}}</b> every <b>{{weekday}}</b>",
        {
          recurrenceUnit: this.recurrenceUnitLabel(
            schedule.recurrence_unit
          ).toLowerCase(),
          weekday: weekday.label.toLowerCase(),
        }
      );
    } else if (
      schedule.recurrence_unit === RecurrenceUnit.MONTHLY ||
      schedule.recurrence_unit === RecurrenceUnit.BIMONTHLY ||
      schedule.recurrence_unit === RecurrenceUnit.QUARTERLY ||
      schedule.recurrence_unit === RecurrenceUnit.SEMESTERLY
    ) {
      const day = this.dayOfMonthOptions().find(
        (option) => option.value === String(repeat_on_day_of_month)
      );

      if (repeat_at_end_of_month) {
        return i18n.t(
          "RecurringScheduleHelper::Repeats <b>{{recurrenceUnit}}</b> every <b>last day of the month</b>",
          {
            recurrenceUnit: this.recurrenceUnitLabel(
              schedule.recurrence_unit
            ).toLowerCase(),
          }
        );
      }

      return i18n.t(
        "RecurringScheduleHelper::Repeats <b>{{recurrenceUnit}}</b> every <b>{{day}} day of the month</b>",
        {
          recurrenceUnit: this.recurrenceUnitLabel(
            schedule.recurrence_unit
          ).toLowerCase(),
          day: day.label.toLowerCase(),
        }
      );
    } else if (schedule.recurrence_unit === RecurrenceUnit.YEARLY) {
      const month = this.monthOfYearOptions().find(
        (option) => option.value === String(repeat_on_month_of_year)
      );
      const day = this.dayOfMonthOptions().find(
        (option) => option.value === String(repeat_on_day_of_month)
      );

      if (repeat_at_end_of_month) {
        return i18n.t(
          "RecurringScheduleHelper::Repeats <b>{{recurrenceUnit}}</b> every month <b>{{month}}</b> on the <b>last</b> day of the month</b>",
          {
            recurrenceUnit: this.recurrenceUnitLabel(
              schedule.recurrence_unit
            ).toLowerCase(),
            month: month.label.toLowerCase(),
          }
        );
      }

      return i18n.t(
        "RecurringScheduleHelper::Repeats <b>{{recurrenceUnit}}</b> every month <b>{{month}}</b> on the <b>{{day}}</b> day of the month</b>",
        {
          recurrenceUnit: this.recurrenceUnitLabel(
            schedule.recurrence_unit
          ).toLowerCase(),
          month: month.label.toLowerCase(),
          day: day.label.toLowerCase(),
        }
      );
    }
  }
}
