import * as React from 'react'
import styled from "styled-components";
import Icon from "../Icons/Icon";

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	cursor: pointer;

	svg {
		width: 24px;
		height: 24px;
		color: #42526e;
		fill: #42526e;
	}
`

interface IProps {
	onClick: () => void
}

const TaskCancelAction = (props: IProps) => {
	return (
		<Container onClick={props.onClick}>
			<Icon icon='close' />
		</Container>
	)
}

export default TaskCancelAction
