import * as React from 'react'
import { closeAttachmentsViewerModal, showConfirmModal } from '../../store/modals/actions'
import ModalWindowPart from './Parts/ModalWindow'
import { AppState } from '../../store';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import { WithTranslation, withTranslation } from 'react-i18next';
import Icon, { IconTypes } from '../Icons/Icon';
import Slider from 'react-slick';
import moment from '../../utilities/Moment';
import prettyBytes from 'pretty-bytes';
import AttachmentPreviewer from './AttachmentsViewer/AttachmentsPreviewer';
import { PreviewAttachment } from '../../types';

const ModalWindow = styled(ModalWindowPart)`
	position: relative;
	max-width: 100%;
	min-width: 100%;
  width: 100%;
	min-height: 100%;
	box-shadow: none;
	background: transparent;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      max-width: initial;
      overflow-y: auto;
      margin-top: 0;
      margin-bottom: 0;
  }
`

const ModalHeader = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
`

const ModalClose = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	width: 50px;
	height: 50px;

	i {
		transition: 150ms all;
		font-size: 25px;
		color: hsla(0,0%,100%,.6);
		margin-top: -10px;

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			margin-top: 0px;
		}
	}

	&:hover {
		i {
			color: white;
			transform: scale(1.2);
		 }
	}
`

const PreviewContainer = styled.div`
	position: absolute;
	top: -30px;
	right: ${Style.spacing.x3};
	bottom: 0;
	left: ${Style.spacing.x3};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		top: 0;
	}

	.slick-slider {
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 100%;
		height: 100%;

		&::-webkit-scrollbar {
			height: 9px;
			width: 9px;
		}

		&::-webkit-scrollbar-track-piece {
			background: transparent;
		}
	}

	.slick-slide {
		outline: none !important;
	}
`

const PreviewWrapper = styled.div`
	padding: 48px 24px 112px;
	outline: none !important;

	img, iframe {
		border-radius: 3px;
		width: 100%;
    max-width: 100%;
    position: relative;
    transition: transform .15s,opacity .4s;
	}

	iframe {
		height: 90vh;
		border: none;
	}
`

const NavigateLeft = styled.div`
	position: absolute;
	height: 100px;
	bottom: 0;
	left: 0;
	width: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: .6;
	transition: opacity 250ms ease-in-out;

	&:hover {
		opacity: 1;
	}

	i {
		color: white;
		font-size: 24px;
	}
`

const NavigateRight = styled.div`
	position: absolute;
	height: 100px;
	bottom: 0;
	right: 0;
	width: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: .6;
	transition: opacity 250ms ease-in-out;

	&:hover {
		opacity: 1;
	}

	i {
		color: white;
		font-size: 24px;
	}
`

const ModalFooter = styled.div`
	height: 100px;
	position: fixed;
	right: 0;
	left: 0;
	height: 100px;
	bottom: 0;
	z-index: 1;
	background-color: rgba(0,0,0,.7);
`

const ModalFooterWrapper = styled.div`
	width: 100%;
`

const Metadata = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100px;
	padding: 12px 10%;
	color: white;
	justify-content: center;
	align-items: center;
	text-align: center;
`

const AttachmentName = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin-bottom: ${Style.spacing.x1};
	max-width: 90%;
	overflow: hidden;
	min-height: 28px;
	white-space: nowrap;
	text-overflow: ellipsis;
`

const AttachmentMetata = styled.div`
	font-size: 14px;
	font-weight: 400;
	margin-bottom: ${Style.spacing.x1};
`

const AttachmentActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		display: none;
	}
`

const AttachmentAction = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: white;

	i, svg {
		color: white;
		fill: white;
		height: 20px;
    font-size: 16px;
    line-height: 20px;
    width: 20px;
		margin-left: ${Style.spacing.x1_5};
		margin-right: ${Style.spacing.x0_5};
	}

	span {
		text-decoration: underline;
	}
`

export interface AttachmentViewerAction {
	icon: IconTypes
	text: string
	onClick: (index: number) => void
}

interface IStateToProps {
	show: boolean
	activeIndex?: number
	attachments: PreviewAttachment[]
	actions?: AttachmentViewerAction[]
	onDelete?: () => void
}

interface IDispatchToProps {
	close: typeof closeAttachmentsViewerModal
	showConfirmModal: typeof showConfirmModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
	activeIndex: number
}

class AttachmentsViewerModal extends React.Component<IProps, IState> {
	private slider = React.createRef<Slider>()

	constructor(props: IProps) {
		super(props)

		this.state = {
			activeIndex: props.activeIndex || 0
		}

		this.onKeydown = this.onKeydown.bind(this)
		this.onCloseClick = this.onCloseClick.bind(this)
		this.onSliderPreviousClick = this.onSliderPreviousClick.bind(this)
		this.onSliderNextClick = this.onSliderNextClick.bind(this)
		this.onSlideChange = this.onSlideChange.bind(this)
	}

	componentDidMount() {
		document.addEventListener('keydown', this.onKeydown)
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeydown)
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
		if (
			// Modal active
			this.props.show &&
			// Active index
			prevProps.activeIndex !== this.props.activeIndex && this.props.activeIndex >= 0 &&
			this.slider.current
		) {
			// Get new active index
			const newActiveIndex = this.props.activeIndex

			// Manually navigate to new slide
			this.slider.current.slickGoTo(newActiveIndex)

			// Set new active index
			this.setState({ activeIndex: newActiveIndex })
		}
	}

	onKeydown(e) {
		const { attachments } = this.props
		const { activeIndex } = this.state

		switch (e.key) {
			case "ArrowLeft":
				const previousActiveIndex = activeIndex - 1

				if (previousActiveIndex >= 0) {
					this.setState({ activeIndex: previousActiveIndex }, () => {
						if (this.slider.current) this.slider.current.slickGoTo(previousActiveIndex)
					})
				}
				break;
			case "ArrowRight":
				const nextActiveIndex = activeIndex + 1

				if (nextActiveIndex <= attachments.length - 1) {
					this.setState({ activeIndex: nextActiveIndex }, () => {
						if (this.slider.current) this.slider.current.slickGoTo(nextActiveIndex)
					})
				}
				break;
		}
	}

	onCloseClick() {
		this.props.close()
	}

	onSliderPreviousClick() {
		if (this.slider.current) {
			this.slider.current.slickPrev()
		}
	}

	onSliderNextClick() {
		if (this.slider.current) {
			this.slider.current.slickNext()
		}
	}

	onSlideChange(activeIndex: number) {
		this.setState({ activeIndex: activeIndex })
	}

	render() {
		const { t, attachments, actions } = this.props
		const { activeIndex } = this.state

		const activeAttachment = attachments[activeIndex]

		return (
			<ModalWindow>
				<ModalHeader>
					<ModalClose onClick={this.onCloseClick}>
						<Icon icon='close' className='modal-close-icon' />
					</ModalClose>
				</ModalHeader>

				<PreviewContainer onClick={this.onCloseClick}>
					{attachments.length > 0 && <Slider
						ref={this.slider}
						slidesToScroll={1}
						slidesToShow={1}
						arrows={true}
						infinite={false}
						initialSlide={activeIndex}
						swipeToSlide={true}
						swipe={true}
						speed={150}
						afterChange={this.onSlideChange}
					>
						{attachments.map(attachment => {
							return (
								<PreviewWrapper key={attachment.id}>
									<AttachmentPreviewer
										filename={attachment.name}
										source={attachment.url}
										contentType={attachment.content_type}
									/>
								</PreviewWrapper>
							)
						})}
					</Slider>}
				</PreviewContainer>

				{activeAttachment && <ModalFooter>
					<ModalFooterWrapper>
						{activeIndex > 0 && <NavigateLeft onClick={this.onSliderPreviousClick}>
							<Icon icon='chevron-left' />
						</NavigateLeft>}

						<Metadata>
							<AttachmentName>{activeAttachment.name}</AttachmentName>
							{activeAttachment.created_at && <AttachmentMetata>{moment(activeAttachment.created_at).fromNow()} - {prettyBytes(activeAttachment.file_size)}</AttachmentMetata>}
							{actions && actions.length > 0 && <AttachmentActions>
								{actions.map((action, index) => {
									return (
										<AttachmentAction key={index} onClick={() => action.onClick(activeIndex)}>
											<Icon icon={action.icon} />
											<span>{action.text}</span>
										</AttachmentAction>
									)
								})}
							</AttachmentActions>}
						</Metadata>

						{activeIndex < attachments.length - 1 && <NavigateRight onClick={this.onSliderNextClick}>
							<Icon icon='chevron-right' />
						</NavigateRight>}
					</ModalFooterWrapper>
				</ModalFooter>}
			</ModalWindow >
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		modals: {
			attachmentsViewerModal: {
				show,
				activeIndex,
				attachments,
				actions,
				onDelete
			}
		},
	} = state

	return {
		show: show,
		activeIndex: activeIndex,
		attachments: attachments,
		actions: actions,
		onDelete: onDelete,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	close: closeAttachmentsViewerModal,
	showConfirmModal: showConfirmModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AttachmentsViewerModal))
