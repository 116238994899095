import * as React from 'react'
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api'
import { GOOGLE_MAPS_KEY } from '../../Constants'

interface IProps {
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  disabled?: boolean
}

const LIBRARIES = ['places']

const LocationInput = (props: IProps) => {
  const { value, onChange } = props
  const locationRef = React.useRef<HTMLInputElement>(null)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    // @ts-ignore
    libraries: LIBRARIES,
  })

  const onLocationPlaceChanged = () => {
    const newLocation = locationRef.current.value
    if (onChange) onChange(newLocation)
  }

  const onLocationChange = (e) => {
    const newLocation = e.currentTarget.value
    if (onChange) onChange(newLocation)
  }

  if (!isLoaded) {
    return (
      <input ref={locationRef}
        type='text'
        {...props}
        onChange={onLocationChange}
      />
    )
  }

  return (
    <Autocomplete
      onPlaceChanged={onLocationPlaceChanged}
    >
      <input
        ref={locationRef}
        type='text'
        {...props}
        onChange={onLocationChange}
      />
    </Autocomplete>
  )
}


export default LocationInput

