import * as React from 'react'
import { CustomFieldsController } from '../../controllers'
import { CustomField, CustomFieldType, ModelCustomFieldsType } from '../../types'
import CustomFieldInput from "./CustomFieldInput"

interface IProps {
  customFields: CustomField[]
  modelCustomFields: ModelCustomFieldsType
  onCustomFieldValueChange: (key: string, value: any) => void
  onCustomFieldsChange: (customFields: CustomField[]) => void
}

const CustomFieldModalInputs = (props: IProps) => {
  const { customFields, modelCustomFields, onCustomFieldValueChange, onCustomFieldsChange } = props

  const onAddCustomFieldOption = async (customField: CustomField, currentValue: any, inputValue: string) => {
    try {
      if ((customField.type === CustomFieldType.SINGLE_OPTION || customField.type === CustomFieldType.MULTIPLE_OPTION) && customField.config) {
        const config = customField.config
        const options = [...config.options, { label: inputValue, value: inputValue }]

        const updatedCustomField: CustomField = {
          ...customField,
          config: {
            ...config,
            options: options,
          }
        }

        const response = await CustomFieldsController.update(updatedCustomField)

        if (!response.errors) {
          const updatedCustomFields = customFields

          const customFieldIndex = customFields.findIndex(c => c.id === customField.id)

          if (customFieldIndex !== -1) {
            // Update custom fields with custom field with new option
            customFields[customFieldIndex] = updatedCustomField

            // Trigger custom fields change event
            onCustomFieldsChange(updatedCustomFields)

            switch (customField.type as CustomFieldType) {
              case CustomFieldType.SINGLE_OPTION:
                onCustomFieldValueChange(customField.name, inputValue)
                break
              case CustomFieldType.MULTIPLE_OPTION:
                const currentValueArray = Array.isArray(currentValue) ? currentValue : []

                if (currentValue) {
                  onCustomFieldValueChange(customField.name, [...currentValueArray, inputValue])
                } else {
                  onCustomFieldValueChange(customField.name, [inputValue])
                }
                break
            }
          }
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  return (
    <div className='grid'>
      {customFields.map(customField => {
        const value = modelCustomFields[customField.name]

        return (
          <div key={customField.id} className={`grid-cell with-${[CustomFieldType.TEXT, CustomFieldType.MULTIPLE_OPTION].includes(customField.type) ? 12 : 6}col`}>
            <div className='form-item'>
              <label>
                {customField.name} {customField.required && <span>*</span>}
              </label>
              <CustomFieldInput
                customField={customField}
                value={value}
                onCustomFieldValueChange={onCustomFieldValueChange}
                onCustomFieldAddOption={(inputValue: string) => onAddCustomFieldOption(customField, value, inputValue)}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CustomFieldModalInputs