import * as React from 'react'
import { closeImportExpensesModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import NumberFormatter from '../../utilities/NumberFormatter'
import SummaryContainer from '../Summary/SummaryContainer'
import SummaryItem from '../Summary/SummaryItem'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import { ExpensesController } from '../../controllers'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import { CurrentUser, Expense, ProjectStatus } from '../../types'


interface IStateToProps {
  currentUser: CurrentUser
  onSubmit?: (expenses: Expense[], description?: string) => void
  contactId?: string
  projectId?: string
}

interface IDispatchToProps {
  close: typeof closeImportExpensesModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  expenses: Expense[]
  contactId?: string
  projectIds?: string[]
  description: string
}

class ImportExpensesModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      expenses: [],
      contactId: props.contactId,
      projectIds: props.projectId ? [props.projectId] : [],
      description: '',
    }

    this.onContactChange = this.onContactChange.bind(this)
    this.onProjectChange = this.onProjectChange.bind(this)
    this.onImportExpensesModalCloseClick = this.onImportExpensesModalCloseClick.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchEntries()
  }

  async fetchEntries() {
    const { contactId, projectIds } = this.state;

    let queryData = {}

    try {
      if (contactId) queryData = { ...queryData, contact_id: contactId }
      if (projectIds && projectIds.length > 0) queryData = { ...queryData, 'project_id[in]': projectIds }

      queryData = {
        ...queryData,
      }

      const expenses = await ExpensesController.importExpenses(queryData)

      this.setState({ expenses: expenses, didInitialLoad: true });
    } catch (ex) {
      console.error(ex)
    }
  }


  getExpensesTotalCount() {
    return this.state.expenses.length;
  }

  getEntriesTotalAmount() {
    return this.state.expenses.reduce((currentValue, expense) => {
      if (expense.billable_amount) {
        return currentValue + Number(expense.billable_amount)
      }

      return currentValue + Number(expense.net_total)
    }, 0)
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { close, onSubmit } = this.props
    const { expenses, description } = this.state

    if (onSubmit) onSubmit(expenses, description)
    close()
  }

  onImportExpensesModalCloseClick() {
    this.props.close()
  }

  onContactChange(value?: string) {
    const newcontactId = value ? value : null

    this.setState({ contactId: newcontactId, projectIds: [] }, () => {
      this.fetchEntries()
    })
  }

  onProjectChange(value?: string[]) {
    this.setState({ projectIds: value }, () => {
      this.fetchEntries()
    })
  }

  onDescriptionChange(e) {
    const description = e.currentTarget.value

    this.setState({
      description: description
    })
  }

  render() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { didInitialLoad, description, contactId, projectIds } = this.state

    const totalCount = this.getExpensesTotalCount();
    const totalAmount = this.getEntriesTotalAmount();

    return (
      <ModalWindow>
        <ModalHeader
          title={t('ImportExpensesModal::Import expenses')}
          onCloseClick={this.onImportExpensesModalCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('ImportExpensesModal::Contact')}</label>
                    <ResourceCreatablePowerSelect
                      type='contact'
                      params={{ archived: false }}
                      value={contactId}
                      onChange={this.onContactChange}
                      isClearable={true}
                      isDisabled={Boolean(contactId)}
                    />
                  </div>
                </div>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('ImportExpensesModal::Project')}</label>
                    <ResourceCreatablePowerSelect
                      type='project'
                      value={projectIds}
                      onChange={this.onProjectChange}
                      isDisabled={!contactId}
                      isClearable={true}
                      isMulti={true}
                      params={{ 'contact_id': contactId, 'status[in]': [ProjectStatus.PROPOSAL, ProjectStatus.ACTIVE] }}
                      isValidNewOption={() => false}
                    />
                  </div>
                </div>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('ImportExpensesModal::Description (optional)')}</label>
                    <textarea
                      placeholder={t('ImportExpensesModal::General description (visible on invoice if none provided. Leave empty to import individual expenses with descriptions)')}
                      value={description}
                      onChange={this.onDescriptionChange}
                    />
                  </div>
                </div>
              </div>
              <div className='fields-section is-last'>
                <div className='fieldset'>
                  <SummaryContainer columnCount={2}>
                    <SummaryItem
                      title={t('ImportExpensesModal::Uninvoiced expenses')}
                      label={`${totalCount}`}
                    />
                    <SummaryItem
                      title={t('ImportExpensesModal::Invoiceable amount')}
                      label={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, totalAmount)}
                    />
                  </SummaryContainer>
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>
          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('ImportExpensesModal::Import')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      importExpensesModal: {
        onSubmit,
        contactId,
        projectId,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    onSubmit: onSubmit,
    contactId: contactId,
    projectId: projectId
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeImportExpensesModal()),
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ImportExpensesModal))