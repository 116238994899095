import * as React from 'react'
import { closeFinancialYearModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { FinancialYearController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CurrentUser, Locale, FinancialYear } from '../../types'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'

interface IStateToProps {
  financialYear: FinancialYear
  onSubmit?: (financialYear: FinancialYear) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeFinancialYearModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

export enum FinancialYearModalTab {
  DETAILS = 'details',
  RULES = 'rules',
}

interface IState {
  didInitialLoad: boolean
  activeTab: FinancialYearModalTab
  financialYear: FinancialYear | null
  errors: any
}

class FinancialYearModal extends React.Component<IProps, IState> {
  static defaultProps = {
    financialYear: {}
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: FinancialYearModalTab.DETAILS,
      financialYear: null,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.onStartDateChange = this.onStartDateChange.bind(this)
    this.onEndDateChange = this.onEndDateChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onFinancialYearModalCloseClick = this.onFinancialYearModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { financialYear: propsFinancialYear } = this.props

    try {
      const response = await FinancialYearController.getForm({ id: propsFinancialYear.id })
      const { financial_year } = response

      this.setState({
        financialYear: {
          ...financial_year,
          ...propsFinancialYear
        },
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onNameChange(e) {
    const { financialYear } = this.state;
    const name = e.currentTarget.value;

    this.setState({
      financialYear: {
        ...financialYear,
        name: name
      },
    })
  }

  onStartDateChange(value) {
    const { financialYear } = this.state;
    const startDateMoment = moment(value);


    this.setState({
      financialYear: {
        ...financialYear,
        start_date: startDateMoment.isValid() ? startDateMoment.format('YYYY-MM-DD') : null
      }
    });
  }

  onEndDateChange(value) {
    const { financialYear } = this.state;
    const endDateMoment = moment(value);


    this.setState({
      financialYear: {
        ...financialYear,
        end_date: endDateMoment.isValid() ? endDateMoment.format('YYYY-MM-DD') : null
      }
    });
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { financialYear } = this.state;
    const { close, onSubmit, t } = this.props

    try {

      if (financialYear.id) { // Do update
        const response = await FinancialYearController.update(financialYear)
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });
          Notification.notifyError(t('FinancialYearModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('FinancialYearModal::Financial year successfully updated.'))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
      else {
        const response = await FinancialYearController.create(financialYear)
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });
          Notification.notifyError(t('FinancialYearModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('FinancialYearModal::Financial year successfully created.'))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onFinancialYearModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderDetails() {
    const { t, currentUser } = this.props
    const { activeTab, financialYear } = this.state;
    const { workspace: { setting } } = currentUser

    if (activeTab !== FinancialYearModalTab.DETAILS) return null;

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('FinancialYearModal::Name')}
                <span> *</span>
              </label>
              <input
                type='text'
                name='name'
                onChange={this.onNameChange}
                value={financialYear.name}
                placeholder={t('FinancialYearModal::Name')}
                required
              />
            </div>
          </div>
        </div>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('FinancialYearModal::Start date')}
                <span> *</span>
              </label>
              <DateInput
                name='start_date'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(financialYear.start_date)}
                inputProps={{ placeholder: setting.date_format }}
                onChange={this.onStartDateChange}
                closeOnSelect
              />
            </div>
          </div>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('FinancialYearModal::End date')}
                <span> *</span>
              </label>
              <DateInput
                name='estimated_start_date'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(financialYear.end_date)}
                inputProps={{ placeholder: setting.date_format }}
                onChange={this.onEndDateChange}
                closeOnSelect
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { financialYear, t } = this.props
    const { activeTab, didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={financialYear?.id ? t('FinancialYearModal::Edit financial year') : t('FinancialYearModal::Create financial year')}
          onCloseClick={this.onFinancialYearModalCloseClick}
          navigation={<ModalNavigation>
            <ModalNavigationItem active={activeTab === FinancialYearModalTab.DETAILS} onClick={() => this.setState({ activeTab: FinancialYearModalTab.DETAILS })}>
              <Icon icon='info' />
              <span>
                {t('FinancialYearModal::Details')}
              </span>
            </ModalNavigationItem>
          </ModalNavigation>}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('FinancialYearModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      financialYearModal: {
        financialYear,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    financialYear: financialYear,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeFinancialYearModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FinancialYearModal))