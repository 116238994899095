import styled from 'styled-components'

const GroupHeading = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;

  i, svg {
    font-size: 14px;
  }
`

export default GroupHeading