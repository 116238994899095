import * as React from 'react'
import { useTranslation } from 'react-i18next'
import moment from '../../utilities/Moment'
import DateInput from '../Form/DateInput'
import PowerSelect from '../Form/PowerSelect'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverClose from './TaskPopoverClose'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'

interface IProps {
	startOn: string | Date | any;
	dueOn: string | Date | any;
	dateFormat: string
	activator: JSX.Element
	onSubmit: (form: {
		start_on: string | Date | any,
		due_on: string | Date | any,
	}) => void
}

const DatePopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator, dateFormat } = props

	const [popoverActive, setPopoverActive] = React.useState(false)
	const [startOn, setStartDate] = React.useState(props.startOn)
	const [dueOn, setDueDate] = React.useState(props.dueOn)

	const onTogglePopover = () => {
		if (!popoverActive) {
			if (props.startOn) {
				setStartDate(moment(props.startOn))
			}
			if (props.dueOn) {
				setDueDate(moment(props.dueOn))
			} else {
				// If no date is set, set on today when opening popover
				setDueDate(moment())
			}
		} else {
			// Reset form on hide
			resetForm()
		}
		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
	}

	const onStartDateChange = (value) => {
		const newStartDate = moment(value)

		if (newStartDate.isValid()) {
			setStartDate(newStartDate.format('YYYY-MM-DD'))
		} else {
			setStartDate(null)
		}
	}

	const onDueDateChange = (value) => {
		const newDueDate = moment(value)

		if (newDueDate.isValid()) {
			setDueDate(newDueDate.format('YYYY-MM-DD'))
		} else {
			setDueDate(null)
		}
	}

	const onFormSubmit = (e) => {
		e.preventDefault()

		// Submit form to parent
		props.onSubmit({
			start_on: startOn,
			due_on: dueOn,
		})

		// Reset form
		resetForm()

		// Close popover
		setPopoverActive(false)
	}

	const onRemoveClick = () => {
		// Reset form
		resetForm()

		// Submit form to parent
		props.onSubmit({
			start_on: null,
			due_on: null,
		})

		// Close popover
		setPopoverActive(false)
	}

	const resetForm = () => {
		setStartDate(null)
		setDueDate(null)
	}

	return (
		<Popover
			active={popoverActive}
			activator={<div onClick={onTogglePopover}>
				{activator}
			</div>}
			onClose={onPopoverClose}
			placement='bottom-start'
		>
			<TaskPopoverContainer>
				<TaskPopoverHeader>
					<div />
					<TaskPopoverTitle>{t('TaskModal::Dates')}</TaskPopoverTitle>
					<TaskPopoverClose onClick={onPopoverClose} />
				</TaskPopoverHeader>
				<TaskPopoverContent>
					<form onSubmit={onFormSubmit}>
						{popoverActive && <>
							<div className='form-item'>
								<label>{t('TaskModal::Start date')}</label>
								<DateInput
									name='start_date'
									initialValue={moment(startOn)}
									onChange={onStartDateChange}
									inputProps={{ placeholder: dateFormat }}
									timeFormat={false}
									closeOnSelect
								/>
							</div>
							<div className='form-item'>
								<label>{t('TaskModal::Due date')}</label>
								<DateInput
									name='due_date'
									initialValue={moment(dueOn)}
									onChange={onDueDateChange}
									inputProps={{ placeholder: dateFormat }}
									closeOnSelect
									timeFormat={false}
								/>
							</div>
						</>}
						<TaskButton
							onClick={onFormSubmit}
							style={{ marginTop: 12 }}
							success
							center
						>
							{t('TaskModal::Save')}
						</TaskButton>
						<TaskButton
							onClick={onRemoveClick}
							style={{ marginTop: 8 }}
							center
						>
							{t('TaskModal::Remove')}
						</TaskButton>
					</form>
				</TaskPopoverContent>
			</TaskPopoverContainer>
		</Popover >
	)
}

export default DatePopover