import * as React from 'react'
import { closeAddAccountantModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import AccountantController from '../../controllers/AccountantController'
import Notification from '../../utilities/Notification'
import { Accountant, CurrentUser } from '../../types'

interface IStateToProps {
  onSubmit?: (accountant: Accountant) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeAddAccountantModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  email: string
  errors: any
}

class AddAccountantModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      email: '',
      errors: {},
    }

    this.onCloseClick = this.onCloseClick.bind(this)
    this.onEmailChange = this.onEmailChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  onCloseClick() {
    this.props.close()
  }

  onEmailChange(e) {
    const email = e.currentTarget.value;

    this.setState({ email: email })
  }

  async onFormSubmit(e) {
    e.preventDefault()

    const { t } = this.props
    const { email } = this.state

    try {
      const response = await AccountantController.create(email)

      if (response.errors) {
        this.setState({ errors: response.errors });
        Notification.notifyError(t('AddTeamMember::Oops something went wrong'))
      }
      else {
        Notification.notifySuccess(t('AddTeamMember::Accountant successfully added'))

        const accountant: Accountant = response

        if (this.props.onSubmit) this.props.onSubmit(accountant)
        this.props.close()
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onErrorsDismiss() {
    this.setState({ errors: {} })
  }

  render() {
    const { t } = this.props
    const { email, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={t('AddAccountantModal::Add accountant')}
          onCloseClick={this.onCloseClick}
          navigation={<ModalNavigation>
            <ModalNavigationItem active={true} data-tab='details'>
              <Icon icon='info' />
              <span>
                {t('AddAccountantModal::Details')}
              </span>
            </ModalNavigationItem>
          </ModalNavigation>}
        />

        <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div data-tab='details'>
                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>
                        {t('AddAccountantModal::Email')}<span>*</span>
                      </label>
                      <input
                        type='email'
                        name='email'
                        onChange={this.onEmailChange}
                        value={email}
                        placeholder='accountant@company.com'
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('AddAccountantModal::Invite')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      addAccountantModal: {
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeAddAccountantModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddAccountantModal))