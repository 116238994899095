import * as React from 'react'
import Badge from '../components/Badge/Badge'
import i18n from '../translations'
import { Payment, PaymentInitiationStatus, QRCData, RemittanceInformationType } from '../types'
import OgmHelper from './OgmHelper'

export default class PaymentHelper {
  static getBadge(payment: Payment): JSX.Element {
    if (payment.payment_initiation) {
      const paymentInitiation = payment.payment_initiation

      switch (paymentInitiation.status) {
        case PaymentInitiationStatus.UNSIGNED: return <Badge type='warning' text={i18n.t('PaymentHelper::Signature required')} />
        case PaymentInitiationStatus.SIGNED: return <Badge type='success' text={i18n.t('PaymentHelper::Paid')} />
        default:
          throw Error('[PaymentHelper.getBadge] invalid status')
      }
    } else {
      return <Badge type='primary' text={i18n.t('PaymentHelper::Draft')} />
    }
  }

  static getSuggestedExecutionDateForPayments(payments: Payment[]) {
    if (payments.length > 0) {
      let suggestedExecutionDate = null

      const suggestedExecutionDates = payments
        // Get suggested execution dates
        .map(payment => payment.suggested_execution_date)
        // Remove null values
        .filter(Boolean)

      if (suggestedExecutionDates.length > 0) {
        suggestedExecutionDate = suggestedExecutionDates[0]
        let minimumExecutionDate = new Date(suggestedExecutionDate);

        suggestedExecutionDates.forEach(function (dt, index) {
          if (new Date(dt) < minimumExecutionDate) {
            suggestedExecutionDate = dt;
            minimumExecutionDate = new Date(dt);
          }
        });
      }

      return suggestedExecutionDate
    }

    return null
  }

  static quickResponseCodeData(paymenData: QRCData): string {
    const { remittanceInformation } = paymenData

    let bic = ''
    let name = paymenData.name || ''
    let iban = paymenData.iban || ''
    let currency = paymenData.currency
    let amount = (Math.round(paymenData.amount * 100) / 100).toFixed(2);
    let purpose = ''
    let creditorReference = ''
    let reference = ''
    let btoInfo = ''

    if (paymenData.remittanceInformationType === RemittanceInformationType.STRUCTURED) {
      creditorReference = remittanceInformation
    } else {
      reference = remittanceInformation
    }

    return [
      "BCD",
      "002",
      "2",
      "SCT",
      `${bic}`,
      `${name}`,
      `${iban}`,
      `${currency}${amount}`,
      `${purpose}`,
      `${creditorReference}`,
      `${reference}`,
      `${btoInfo}`
    ].join('\n')
  }

  static getPaymentReferenceMask(identifierType: RemittanceInformationType) {
    switch (identifierType) {
      case RemittanceInformationType.FREE:
      case RemittanceInformationType.UNSTRUCTURED:
        return false
      case RemittanceInformationType.STRUCTURED:
        return ['+', '+', '+', ' ', /\d/, /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/, ' ', '/', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', '+', '+', '+']
    }
  }

  static generatePaymentReference(identifierType: RemittanceInformationType, seed?: number): string {
    switch (identifierType) {
      case RemittanceInformationType.FREE:
      case RemittanceInformationType.UNSTRUCTURED:
        return Math.random().toString(36).slice(-7).toUpperCase()
      case RemittanceInformationType.STRUCTURED: return OgmHelper.generate(seed)
    }
  }

  static formatPaymentReference(identifierType: RemittanceInformationType, value: string) {
    switch (identifierType) {
      case RemittanceInformationType.FREE:
      case RemittanceInformationType.UNSTRUCTURED:
        return value
      case RemittanceInformationType.STRUCTURED: return OgmHelper.format(value)
    }
  }

  static unformatPaymentReference(identifierType: RemittanceInformationType, value: string) {
    switch (identifierType) {
      case RemittanceInformationType.FREE:
      case RemittanceInformationType.UNSTRUCTURED:
        return value
      case RemittanceInformationType.STRUCTURED: return OgmHelper.unformat(value)
    }
  }
}