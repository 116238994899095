import * as React from 'react'
import copy from 'copy-to-clipboard';
import { closeShareLinksModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import { AppState } from '../../store'
import styled from 'styled-components'
import Button from '../Button/Button'
import { Style } from '../../styles'
import Notification from '../../utilities/Notification'

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${Style.spacing.x1};

  &:last-of-type {
    margin-bottom: 0;
  }
`

interface IStateToProps {
  title?: string
  shareableLinks: ShareableLink[]
}

interface IDispatchToProps {
  close: typeof closeShareLinksModal
}

export interface ShareableLink {
  content: string
  url: string
}

type IProps = IStateToProps & IDispatchToProps

const ShareLinksModal = (props: IProps) => {
  const { t } = useTranslation()
  const { title, shareableLinks } = props

  const onCloseClick = () => {
    props.close()
  }

  const onCopyClick = (shareableLink: ShareableLink) => {
    copy(shareableLink.url)
    Notification.notifySuccess(t('ShareLinksModal::Copied to clipboard'))
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={title ? title : t('ShareLinksModal::Share links')}
        onCloseClick={onCloseClick}
      />

      <ModalMiddle>
        <ModalContent>
          {shareableLinks.map((shareableLink, index) => {
            return (
              <LinkContainer key={index}>
                <input type='text' value={shareableLink.content} readOnly disabled style={{ marginRight: Style.spacing.x1 }} />
                <Button
                  type='default'
                  icon='link'
                  text={t('ShareLinksModal::Copy')}
                  onClick={() => onCopyClick(shareableLink)}
                />
              </LinkContainer>
            )
          })}
        </ModalContent>
      </ModalMiddle>
    </ModalWindow>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    modals: {
      shareLinksModal: {
        title,
        shareableLinks,
      }
    }
  } = state

  return {
    title: title,
    shareableLinks: shareableLinks,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeShareLinksModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareLinksModal)