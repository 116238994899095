import * as React from 'react'
import { closeContactTypeModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Icon from '../Icons/Icon'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import Images from '../../images'
import { ContactType } from '../../types'

const ContactTypeItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 2px solid ${Style.color.border};
  padding: ${Style.spacing.x1};
  height: 250px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border-color: ${Style.color.grayDarkest};
  }

  i {
    font-size: 50px;
  }

  svg {
    width: 50px;
    height: 50px;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    margin-top: ${Style.spacing.x1_5};
    text-align: center;
  }

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
    margin-bottom: ${Style.spacing.x1};
	}
`

interface IStateToProps {
  onSubmit?: (contactType: ContactType) => void
}

interface IDispatchToProps {
  close: typeof closeContactTypeModal
}

type IProps = IDispatchToProps & IStateToProps

const ContactTypeModal = (props: IProps) => {
  const [didInitialLoad, setDidInitialLoad] = React.useState(false)
  const { t } = useTranslation()

  React.useEffect(() => {
    setDidInitialLoad(true)
  })

  const onContactTypeModalCloseClick = () => {
    props.close()
  }

  const onContactTypeClick = (contactType: ContactType) => {
    props.onSubmit(contactType)
    props.close()
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={t('ContactTypeModal::Select contact type')}
        onCloseClick={onContactTypeModalCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form>
            <div className='grid'>
              <div className='grid-cell with-6col'>
                <ContactTypeItem onClick={() => onContactTypeClick(ContactType.PERSON)}>
                  <Icon icon='user' />
                  <span>{t('ContactTypeModal::Person')}</span>
                </ContactTypeItem>
              </div>

              <div className='grid-cell with-6col'>
                <ContactTypeItem onClick={() => onContactTypeClick(ContactType.COMPANY)}>
                  <Icon icon='company' />
                  <span>{t('ContactTypeModal::Company')}</span>
                </ContactTypeItem>
              </div>
            </div>
            <input type='submit' style={{ display: 'none' }} />
          </form>
        </ModalContent>
      </ModalMiddle>}
    </ModalWindow>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    modals: {
      contactTypeModal: {
        onSubmit,
      }
    },
  } = state

  return {
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeContactTypeModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactTypeModal)