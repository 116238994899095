import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import Icon from '../Icons/Icon'
import Popover from '../Popover/Popover'
import { EmailTemplate } from '../../types'
import TaskPopoverContainer from '../TaskModal/TaskPopoverContainer'
import TaskPopoverHeader from '../TaskModal/TaskPopoverHeader'
import TaskPopoverContent from '../TaskModal/TaskPopoverContent'
import TaskButton from '../TaskModal/TaskButton'
import TaskPopoverTitle from '../TaskModal/TaskPopoverTitle'
import { EmailTemplatesController } from '../../controllers'
import CardEmptyInfo from '../Card/CardEmptyInfo';
import Loader from '../Loaders/Loader';
import { Style } from '../../styles';
import Utils from '../../utilities/Utils';

const ActivatorContainer = styled.div`
	min-height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Templates = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 150px;

	.card-empty-info {
		padding: 12px;

		svg {
			width: 30px;
			height: 30px;
			fill: currentColor;
			margin-bottom: 12px;
		}
	}

	.card-empty-info-icon {
    font-size: 30px;
    width: 30px;
    height: 30px;
		margin-bottom: 12px;

		svg {
			width: 30px;
			height: 30px;
			fill: currentColor;
		}
	}

	.card-empty-info-description {
		font-size: 14px;
		line-height: 22px;
	}
`

const TemplateContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 4px;
`

const Template = styled.div`
	position: relative;
	background-color: rgba(9,30,66,.04);
	border-radius: 4px;
	color: black;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-right: 4px;
	max-width: 100%;
	overflow: hidden;
	padding: 4px 6px;
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	min-height: 32px;
	padding: 6px 12px;
	position: relative;
	font-size: 14px;
	width: 100%;
	padding-right: 28px;
	display: block;
	text-overflow: ellipsis;

	&:hover {
		background-color: rgba(9,30,66,.08);
		color: #091e42;
	}
`

const TemplateAction = styled.div<{ destructive?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 32px;
	width: 32px;
	min-width: 32px;	
	padding: 6px 12px;
	border-radius: 4px;
	max-width: 100%;
	overflow: hidden;
	padding: 4px 6px;
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	transition: background-color 250ms ease-in-out;
	cursor: pointer;

	&:hover {
		background-color: rgba(9,30,66,.08);
	}

	svg, i {
		color: #42526e;
		fill: #42526e;
		font-size: 14px;
	}

	${props => props.destructive && css`
		&:hover {
			background-color: ${Style.color.brandDanger};

			svg, i {
				color: white;
				fill: white;
			}
		}
	`}
`

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
`

const TemplateLoader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	width: 100%;
`

type IProps = {
	activator: JSX.Element
	active: boolean
	onTemplateClick: (emailTemplate: EmailTemplate) => void
	onEditTemplateClick: (emailTemplate: EmailTemplate) => void
	onDeleteTemplateClick: (emailTemplate: EmailTemplate) => void
	onCreateTemplateClick: () => void
	onClose: () => void
} & WithTranslation

interface IState {
	emailTemplates: EmailTemplate[]
	currentPage: number
	totalPages: number
	didInitialLoad: boolean
	isFetching: boolean
	searchValue: string
}

class EmailTemplatesPopover extends React.Component<IProps, IState> {
	private container = React.createRef<HTMLDivElement>()

	constructor(props: IProps) {
		super(props)

		this.state = {
			emailTemplates: [],
			currentPage: 1,
			totalPages: 1,
			didInitialLoad: false,
			isFetching: false,
			searchValue: '',
		}

		this.fetchTemplates = this.fetchTemplates.bind(this)
		this.debouncedFetchTemplates = Utils.debounce(this.fetchTemplates, 250)
		this.onScrollChange = Utils.debounce(this.onScrollChange.bind(this), 50)
		this.onSearchChange = this.onSearchChange.bind(this)
		this.onTemplateClick = this.onTemplateClick.bind(this)
		this.onEditTemplateClick = this.onEditTemplateClick.bind(this)
		this.onDeleteTemplateClick = this.onDeleteTemplateClick.bind(this)
		this.onNewTemplateClick = this.onNewTemplateClick.bind(this)
		this.onClearFiltersClick = this.onClearFiltersClick.bind(this)
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if (!prevProps.active && this.props.active) this.fetchTemplates(1).catch(console.error)
	}

	async fetchTemplates(page: number) {
		const { isFetching, searchValue, emailTemplates, currentPage } = this.state

		try {
			this.setState({ isFetching: true })

			let params: any = { page: page, search: searchValue }
			const response = await EmailTemplatesController.getEmailTemplates(params)
			const { current_page, total_pages, email_templates } = response

			this.setState({
				emailTemplates: page === 1 ? [...email_templates] : [...emailTemplates, ...email_templates],
				currentPage: current_page,
				totalPages: total_pages,
				didInitialLoad: true,
				isFetching: false,
			});

		} catch (ex) {
			console.error(ex)
		}
	}
	debouncedFetchTemplates(page: number) {
		this.fetchTemplates(page)
	}

	onScrollChange(event) {
		const { totalPages, currentPage } = this.state

		var node = event.target;
		const endReached = node.scrollHeight - node.scrollTop - 200 <= node.clientHeight;

		if (endReached && totalPages > currentPage) this.debouncedFetchTemplates(currentPage + 1)
	}

	onSearchChange(e) {
		this.setState({ searchValue: e.currentTarget.value, emailTemplates: [] }, () => {
			this.debouncedFetchTemplates(1)
		})
	}

	onTemplateClick(template: EmailTemplate) {
		this.props.onTemplateClick(template)
	}

	onEditTemplateClick(template: EmailTemplate) {
		this.props.onEditTemplateClick(template)
	}

	onDeleteTemplateClick(template: EmailTemplate) {
		this.props.onDeleteTemplateClick(template)
	}

	onNewTemplateClick() {
		this.props.onCreateTemplateClick()
	}

	onClearFiltersClick() {
		this.setState({ searchValue: '' }, () => {
			this.fetchTemplates(1)
		})
	}

	render() {
		const { t, activator, active, onClose } = this.props
		const {
			searchValue,
			emailTemplates,
			isFetching,
			didInitialLoad,
		} = this.state

		const filtersActive = searchValue?.length > 0

		return (
			<Popover
				active={active}
				activator={<ActivatorContainer>
					{activator}
				</ActivatorContainer>}
				onClose={onClose}
				placement='top'
				strategy='fixed'
			>
				<TaskPopoverContainer>
					<TaskPopoverHeader>
						<TaskPopoverTitle>{t('SendEmailModal::Email Templates')}</TaskPopoverTitle>
					</TaskPopoverHeader>
					<TaskPopoverContent>
						<div className='form-item'>
							<input
								type='text'
								placeholder={t('SendEmailModal::Search templates')}
								value={searchValue}
								onChange={this.onSearchChange}
							/>
						</div>

						{!didInitialLoad && <LoaderContainer><Loader /></LoaderContainer>}
						{didInitialLoad && <>
							<Templates ref={this.container}>
								{emailTemplates?.length === 0 && !isFetching && <CardEmptyInfo
									icon={filtersActive ? 'search' : 'file-code'}
									description={filtersActive ? t('EmailTemplatesPopover::No templates found') : t('EmailTemplatesPopover::No templates have been created yet')}
									descriptionActionText={filtersActive ? t('EmailTemplatesPopover::Clear filters') : t('EmailTemplatesPopover::Add new template')}
									onDescriptionActionClick={filtersActive ? this.onClearFiltersClick : this.onNewTemplateClick}
								/>}
								{emailTemplates.map(template => (
									<TemplateContainer key={template.id} >
										<Template onClick={() => this.onTemplateClick(template)} title={template.name}>
											{template.name}
										</Template>
										<TemplateAction onClick={() => this.onEditTemplateClick(template)}>
											<Icon icon='pencil' />
										</TemplateAction>
										<TemplateAction onClick={() => this.onDeleteTemplateClick(template)} destructive>
											<Icon icon='trash' />
										</TemplateAction>
									</TemplateContainer>))}
								{isFetching && <TemplateLoader><Loader /></TemplateLoader>}
							</Templates>

							<TaskButton
								onClick={this.onNewTemplateClick}
								style={{ marginTop: 12 }}
								center
							>
								{t('SendEmailModal::Create template')}
							</TaskButton>
						</>}
					</TaskPopoverContent>
				</TaskPopoverContainer>
			</Popover>
		)
	}
}

export default withTranslation()(EmailTemplatesPopover)