import * as React from 'react'
import Notification from '../../utilities/Notification'
import ModalHeader from './Parts/ModalHeader'
import ModalLoader from './Parts/ModalLoader'
import ModalMiddle from './Parts/ModalMiddle'
import ModalWindow from './Parts/ModalWindow'
import ModalContent from './Parts/ModalContent'
import TooltipError from '../Tooltips/ErrorTooltip'
import Button from '../Button/Button'
import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { closeLedgerItemPaymentModal } from '../../store/modals/actions'
import CheckboxInput from '../Form/CheckboxInput'
import MoneyInput from '../Form/MoneyInput'
import { AppState } from '../../store'
import { CurrentUser, LedgerItem, LedgerItemPayment, LedgerItemPaymentMethod } from '../../types'
import PowerSelect from '../Form/PowerSelect'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'
import { LedgerItemPaymentsController } from '../../controllers'
import SummaryItem from '../Summary/SummaryItem'
import NumberFormatter from '../../utilities/NumberFormatter'
import SummaryContainer from '../Summary/SummaryContainer'

interface IStateToProps {
  currentUser: CurrentUser
  ledgerItemId: string
  ledgerItemPaymentId: string
  onSubmit: (ledgerItemPayment: LedgerItemPayment) => void
}

interface IDispatchToProps {
  close: typeof closeLedgerItemPaymentModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  errors: any
  isSubmitting: boolean
  ledgerItem: LedgerItem
  ledgerItemPayment: LedgerItemPayment
  currentPayments: LedgerItemPayment[]
  markFullyPaid: boolean
}

class LedgerItemPaymentModal extends React.Component<IProps, IState> {
  private amountInput = React.createRef<MoneyInput>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      isSubmitting: false,
      ledgerItem: null,
      currentPayments: [],
      ledgerItemPayment: null,
      markFullyPaid: false,
      errors: {},
    }

    this.onLedgerItemPaymentModalClose = this.onLedgerItemPaymentModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onAmountChange = this.onAmountChange.bind(this)
    this.onToggleFullyPaid = this.onToggleFullyPaid.bind(this)
    this.onPaymentMethodChange = this.onPaymentMethodChange.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.onDetailsChange = this.onDetailsChange.bind(this)

  }

  componentDidMount() {
    this.fetchForm().catch(console.error)
  }

  async fetchForm() {
    const { ledgerItemId, ledgerItemPaymentId } = this.props
    try {
      const { ledger_item, current_payments, ledger_item_payment } = await LedgerItemPaymentsController.getForm(ledgerItemId, { id: ledgerItemPaymentId })

      this.setState({
        ledgerItem: ledger_item,
        currentPayments: current_payments,
        ledgerItemPayment: ledger_item_payment,
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onFormSubmit(e) {
    e.preventDefault();

    const { close, t, onSubmit } = this.props
    const { ledgerItemPayment } = this.state

    this.setState({ isSubmitting: true })
    try {
      if (ledgerItemPayment.id) {
        const response = await LedgerItemPaymentsController.update(ledgerItemPayment.ledger_item_id, ledgerItemPayment)

        const { errors, error_full_messages } = response;

        if (errors) {
          this.setState({ errors: errors });
          Notification.notifyError(t('LedgerItemPaymentModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('LedgerItemPaymentModal::Payment successfully updated'))
          if (onSubmit) onSubmit(response)
          close()
        }
      } else {
        const response = await LedgerItemPaymentsController.create(ledgerItemPayment.ledger_item_id, ledgerItemPayment)

        const { errors, error_full_messages } = response;

        if (errors) {
          this.setState({ errors: errors });
          Notification.notifyError(t('LedgerItemPaymentModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('LedgerItemPaymentModal::Payment successfully created'))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isSubmitting: false })
    }
  }

  onLedgerItemPaymentModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onAmountChange(amount) {
    const { ledgerItemPayment } = this.state;

    this.setState({
      ledgerItemPayment: {
        ...ledgerItemPayment,
        amount: amount,
      },
    });
  }

  onToggleFullyPaid() {
    const { ledgerItemPayment, markFullyPaid } = this.state

    const { remainingAmount } = this.getBalance()
    const newMarkFullyPaid = !markFullyPaid
    const newAmount = newMarkFullyPaid ? remainingAmount : ledgerItemPayment.amount

    this.setState({
      markFullyPaid: newMarkFullyPaid,
      ledgerItemPayment: {
        ...ledgerItemPayment,
        amount: newAmount
      },
    }, () => {
      if (this.amountInput.current) this.amountInput.current.value(this.state.ledgerItemPayment.amount)
    })
  }

  onPaymentMethodChange(option) {
    const { ledgerItemPayment } = this.state

    this.setState({
      ledgerItemPayment: {
        ...ledgerItemPayment,
        payment_method: option.value
      }
    })
  }

  onDateChange(value) {
    const { ledgerItemPayment } = this.state;

    const newDate = moment(value)

    if (newDate.isValid()) {
      this.setState({
        ledgerItemPayment: {
          ...ledgerItemPayment,
          executed_at: newDate.format('YYYY-MM-DD'),
        },
      })
    }
  }

  onDetailsChange(e) {
    const { ledgerItemPayment } = this.state

    const details = e.currentTarget.value

    this.setState({
      ledgerItemPayment: {
        ...ledgerItemPayment,
        details: details
      }
    })
  }

  getBalance() {
    const { didInitialLoad, currentPayments, ledgerItem, ledgerItemPayment } = this.state
    let paymentAmount = 0
    let paidAmount = 0
    let newBalanceAmount = 0
    let remainingAmount = 0

    if (didInitialLoad) {
      paymentAmount = ledgerItemPayment.amount || 0
      paidAmount = currentPayments.reduce((amount, payment) => Number(amount) + Number(payment.amount), 0)
      remainingAmount = Number(ledgerItem.amount) - paidAmount
      newBalanceAmount = remainingAmount - paymentAmount
    }

    return {
      paymentAmount: paymentAmount,
      paidAmount: paidAmount,
      remainingAmount: remainingAmount,
      newBalanceAmount: newBalanceAmount
    }
  }

  render() {
    const {
      t,
      currentUser: {
        workspace: {
          setting
        }
      }
    } = this.props
    const {
      ledgerItem,
      ledgerItemPayment,
      markFullyPaid,
      didInitialLoad,
      isSubmitting,
      errors,
    } = this.state

    const { paymentAmount, newBalanceAmount } = this.getBalance()
    const ledgerItemPaymentMethodOptions = [
      { label: t(`LedgerItemPaymentMethod::${LedgerItemPaymentMethod.CASH}`), value: String(LedgerItemPaymentMethod.CASH) },
      { label: t(`LedgerItemPaymentMethod::${LedgerItemPaymentMethod.BANK_TRANSFER}`), value: String(LedgerItemPaymentMethod.BANK_TRANSFER) }
    ]
    const selectedLedgerItemPaymentMethod = ledgerItemPayment && ledgerItemPaymentMethodOptions.find(l => l.value === ledgerItemPayment.payment_method)



    return (
      <ModalWindow>
        <ModalHeader
          title={t('LedgerItemPaymentModal::Add payment')}
          onCloseClick={this.onLedgerItemPaymentModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('LedgerItemPaymentModal::Amount')}
                      <span>*</span></label>
                    <MoneyInput
                      ref={this.amountInput}
                      name='price'
                      currency={ledgerItemPayment.currency}
                      numberFormat={setting.number_format}
                      onBlur={this.onAmountChange}
                      value={ledgerItemPayment.amount}
                      disabled={markFullyPaid}
                    />
                  </div>
                </div>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('LedgerItemPaymentModal::Payment status')}</label>
                    <CheckboxInput
                      onChange={this.onToggleFullyPaid}
                      checked={markFullyPaid}
                      label={t('LedgerItemPaymentModal::Mark as fully paid')}
                    />
                  </div>
                </div>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('LedgerItemPaymentModal::Method')}
                      <span>*</span>
                    </label>
                    <PowerSelect
                      options={ledgerItemPaymentMethodOptions}
                      value={selectedLedgerItemPaymentMethod}
                      onChange={this.onPaymentMethodChange}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('LedgerItemPaymentModal::Date')}
                      <span>*</span>
                    </label>
                    <DateInput
                      name='executed_at'
                      dateFormat={setting.date_format}
                      timeFormat={false}
                      initialValue={moment(ledgerItemPayment.executed_at)}
                      onChange={this.onDateChange}
                      closeOnSelect
                    />
                  </div>
                </div>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('LedgerItemPaymentModal::Details')}</label>
                    <textarea
                      onChange={this.onDetailsChange}
                      value={ledgerItemPayment.details}
                      placeholder={t('LedgerItemPaymentModal::Add details...')}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <SummaryContainer columnCount={2}>
                    <SummaryItem
                      title={t('LedgerItemPaymentModal::Payment')}
                      label={NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, paymentAmount)}
                    />
                    <SummaryItem
                      title={t('ImportTimeEntriesModal::New balance')}
                      label={NumberFormatter.formatCurrency(ledgerItem.currency, setting.number_format, newBalanceAmount)}
                    />
                  </SummaryContainer>
                </div>
              </div>
              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <Button
                  type='success'
                  text={t('LedgerItemPaymentModal::Add payment')}
                  onClick={this.onFormSubmit}
                  isLoading={isSubmitting}
                />
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      ledgerItemPaymentModal: {
        ledgerItemId,
        ledgerItemPaymentId,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    ledgerItemId,
    ledgerItemPaymentId,
    onSubmit
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeLedgerItemPaymentModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LedgerItemPaymentModal))