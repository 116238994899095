import store from "../store";
import i18n from "../translations";
import {
  NavigationMenuItemPage,
  NavigationMenuItemType,
  RateType,
} from "../types";

class SettingHelper {
  static hasNavigationitem(
    navigationItemPage: NavigationMenuItemPage
  ): boolean {
    return store
      ?.getState()
      ?.authentication?.currentUser?.workspace?.setting?.default_menu?.some(
        (item) =>
          item.type === NavigationMenuItemType.PAGE &&
          item.page === navigationItemPage
      );
  }
  static getRateTypeOptions() {
    return Object.keys(RateType).map((key) => ({
      label: i18n.t(`RateType::${RateType[key]}`),
      value: RateType[key],
    }));
  }

  static getRateTypeLabel(pricingType: RateType) {
    return i18n.t(`RateType::${pricingType}`);
  }
}

export default SettingHelper;
