import * as React from 'react'
import styled from 'styled-components'
import Icon from '../Icons/Icon'

interface IProps {
	onClick: () => void
}

const Container = styled.a`
	color: #6b778c;
	fill: #6b778c;
	padding: 10px 12px 10px 12px;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	cursor: pointer;

	svg, i {
		color: #6b778c;
		fill: #6b778c;
		font-size: 20px;
	}

	&:hover {
		svg, i {
			color: #172b4d;
			fill: #172b4d;
		}
	}
`

const TaskPopoverClose = (props: IProps) => {
	return (
		<Container onClick={props.onClick}>
			<Icon icon='close' />
		</Container>
	)
}

export default TaskPopoverClose