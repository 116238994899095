import * as React from 'react'
import { components } from 'react-select'
import { SelectComponents } from 'react-select/src/components'
import styled from 'styled-components'
import Icon from '../Icons/Icon'
import GroupHeading from './GroupHeading'

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 16px;

  svg {
    margin-right: 8px;
		width: 14px;
		height: 14px;
  }
`

const ValueContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  i, svg {
    margin-right: 8px;
		width: 14px;
		height: 14px;
  }
`

const ProjectStatusSelectComponents: Partial<SelectComponents<any, any>> = {
	GroupHeading: (props, test) => {
		return (
			<GroupHeading>
				<components.GroupHeading {...props} />
			</GroupHeading>
		)
	},
	Option: (props) => {
		const { data: { label, icon, __isNew__, placeholder } } = props

		if (placeholder) return null

		if (!__isNew__) {
			return (
				<components.Option {...props}>
					<OptionContainer>
						<Icon icon={icon} />
						{label}
					</OptionContainer>
				</components.Option>
			)
		} else {
			return (
				<components.Option {...props}>
					{label}
				</components.Option>
			)
		}
	},
	SingleValue: (props) => {
		const { data: { label, icon } } = props

		return (
			<components.SingleValue {...props}>
				<ValueContainer>
					<Icon icon={icon} />
					{label}
				</ValueContainer>
			</components.SingleValue>
		)
	},
}

export default ProjectStatusSelectComponents
