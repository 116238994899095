import i18n from "../translations"
import { SpreadsheetExportType } from "../types";

class SpreadsheetHelper {
	static getOptions(): { label: string; value: string }[] {
		return [
			{ label: i18n.t(`SpreadsheetExportType::${SpreadsheetExportType.EXCEL}`), value: SpreadsheetExportType.EXCEL },
			{ label: i18n.t(`SpreadsheetExportType::${SpreadsheetExportType.CSV}`), value: SpreadsheetExportType.CSV },
		]
	}
}

export default SpreadsheetHelper