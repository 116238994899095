import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'
import TelephoneNumberInput from '../Form/TelephoneNumberInput';

interface IProps {
	activator: JSX.Element
	telephoneNumber?: string
	countryCode: string
	onSubmit: (telephoneNumber: string) => void
}

const CallPopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator, countryCode } = props
	const [popoverActive, setPopoverActive] = React.useState(false)
	const [telephoneNumber, setTelephoneNumber] = React.useState(props.telephoneNumber || null)
	const [valid, setValid] = React.useState(false)

	React.useEffect(() => {
		setTelephoneNumber(props.telephoneNumber)
	}, [popoverActive, props.telephoneNumber])

	const onTogglePopover = () => {
		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
	}

	const onTelephoneNumberChange = (telephoneNumber: string) => {
		setTelephoneNumber(telephoneNumber)
	}

	const onTelephoneNumberValidChange = (valid) => {
		setValid(valid)
	}

	const onFormSubmit = (e) => {
		if (e) e.preventDefault()
		if (!valid) return
		// Submit form
		props.onSubmit(telephoneNumber)

		// Close popover
		onPopoverClose()
	}

	const onRemoveClick = () => {
		//
		props.onSubmit(null)

		onPopoverClose()
	}

	return (
		<Popover
			active={popoverActive}
			activator={
				<div onClick={onTogglePopover}>
					{activator}
				</div>
			}
			onClose={onPopoverClose}
			placement='bottom-start'
		>
			<TaskPopoverContainer>
				<TaskPopoverHeader>
					<TaskPopoverTitle>{t('TaskModal::Call')}</TaskPopoverTitle>
				</TaskPopoverHeader>
				<TaskPopoverContent>
					<form onSubmit={onFormSubmit}>
						<TelephoneNumberInput
							onChangeNumber={onTelephoneNumberChange}
							onChangeValidity={onTelephoneNumberValidChange}
							initialValue={telephoneNumber}
							initialCountry={countryCode}
						/>

						<TaskButton
							onClick={valid ? onFormSubmit : undefined}
							style={{ marginTop: 12 }}
							success
							center
							disabled={!valid}
						>
							{t('TaskModal::Save')}
						</TaskButton>

						<TaskButton
							onClick={onRemoveClick}
							style={{ marginTop: 8 }}
							center
						>
							{t('TaskModal::Remove')}
						</TaskButton>
						<input type='submit' style={{ display: 'none' }} />
					</form>
				</TaskPopoverContent>
			</TaskPopoverContainer>
		</Popover>
	)
}

export default CallPopover 