import * as React from 'react'
import { closeTaxesModal, showConfirmModal, showTaxModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import { TaxesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Button from '../Button/Button'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import { CurrentUser, WorkspaceTax } from '../../types'
import ResourceModalTableRow from '../Resource/Modal/ResourceModalTableRow'
import ResourceModalTableRowHeader from '../Resource/Modal/ResourceModalTableRowHeader'
import ResourceModalTableRowData from '../Resource/Modal/ResourceModalTableRowData'
import ResourceModalTableRowActions from '../Resource/Modal/ResourceModalTableRowActions'
import CardEmptyInfo from '../Card/CardEmptyInfo'
import ResourceModalTable from '../Resource/Modal/ResourceModalTable'
import Tooltip from '../Tooltips/Tooltip'
import styled from 'styled-components'

const TooltipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface IStateToProps {
  currentUser: CurrentUser
  onSubmit?: (taxes: WorkspaceTax[]) => void
}

interface IDispatchToProps {
  close: typeof closeTaxesModal
  showTaxModal: typeof showTaxModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  taxes: WorkspaceTax[]
  errors: any
}

class TaxesModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      taxes: [],
      errors: {},
    }

    this.fetchTaxes = this.fetchTaxes.bind(this)
    this.onAddTaxClick = this.onAddTaxClick.bind(this)
    this.onEditTaxClick = this.onEditTaxClick.bind(this)
    this.onDeleteTaxClick = this.onDeleteTaxClick.bind(this)
    this.onTaxesModalCloseClick = this.onTaxesModalCloseClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchTaxes()
  }

  async fetchTaxes() {
    try {
      const taxes = await TaxesController.getTaxes({})

      this.setState({ didInitialLoad: true, taxes: taxes })
    } catch (ex) {
      console.error(ex)
    }
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { taxes } = this.state
    const { close, onSubmit } = this.props

    if (onSubmit) onSubmit(taxes)
    close()
  }

  onTaxesModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onAddTaxClick() {
    const { taxes } = this.state

    this.props.showTaxModal({
      tax: {},
      onSubmit: (tax) => {
        const updatedTaxes = [...taxes, tax]

        this.setState({ taxes: updatedTaxes })
        this.props.onSubmit(updatedTaxes)
      }
    })
  }

  onEditTaxClick(tax: WorkspaceTax) {
    const { taxes } = this.state

    this.props.showTaxModal({
      tax: { id: tax.id },
      onSubmit: (tax) => {
        const taxIndex = taxes.findIndex(t => t.id === tax.id);

        if (taxIndex !== -1) {
          taxes[taxIndex] = tax

          this.setState({ taxes: [...taxes] })
          this.props.onSubmit([...taxes])
        }
      }
    })
  }

  onDeleteTaxClick(tax: WorkspaceTax) {
    const { t } = this.props
    const { taxes } = this.state

    this.props.showConfirmModal({
      title: t('TaxesModal::Delete VAT rate'),
      description: t('TaxesModal::You are about to delete this VAT rate. This VAT rate will be permanently deleted. Are you sure?'),
      action: { label: t('TaxesModal::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          await TaxesController.delete(tax.id)

          const taxIndex = taxes.findIndex(t => t.id === tax.id);

          taxes.splice(taxIndex, 1);

          this.setState({ taxes: [...taxes] })
          this.props.onSubmit([...taxes])
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  render() {
    const { t } = this.props
    const { didInitialLoad, errors, taxes } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={t('TaxesModal::VAT Rates')}
          onCloseClick={this.onTaxesModalCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            {taxes.length === 0 && <CardEmptyInfo
              icon='percent'
              description={t('TaxesModal::No VAT rates found')}
              descriptionActionText={t('TaxesModal::Add VAT rate')}
              onDescriptionActionClick={this.onAddTaxClick}
            />}
            {taxes.length > 0 && <ResourceModalTable>
              <ResourceModalTableRow header={true}>
                <ResourceModalTableRowHeader>
                  {t('TaxesModal::Name')}
                </ResourceModalTableRowHeader>
                <ResourceModalTableRowHeader>
                  {t('TaxesModal::Percentage')}
                </ResourceModalTableRowHeader>
                <ResourceModalTableRowHeader style={{ width: 40 }} />
              </ResourceModalTableRow>

              {taxes.map((tax, index) => (
                <ResourceModalTableRow key={index}>
                  <ResourceModalTableRowData>
                    {tax.name}
                  </ResourceModalTableRowData>
                  <ResourceModalTableRowData>
                    {`${Number(tax.rate) * 100}%`}
                  </ResourceModalTableRowData>
                  {!tax.id && <ResourceModalTableRowData style={{ width: 40 }}>
                    <TooltipContainer>
                      <Tooltip
                        content={t('TaxesModal::Default VAT rate provided by the country you conduct business from.')}
                      />
                    </TooltipContainer>
                  </ResourceModalTableRowData>}
                  {tax.id && <ResourceModalTableRowActions
                    actions={[
                      { key: 'edit', icon: 'edit-solid', content: t('TaxesModal::Edit'), onClick: () => this.onEditTaxClick(tax) },
                      { key: 'delete', icon: 'trash-alt-solid', content: t('TaxesModal::Delete'), destructive: true, onClick: () => this.onDeleteTaxClick(tax) },
                    ]}
                  />}
                </ResourceModalTableRow>
              ))}
            </ResourceModalTable>}

            {taxes.length > 0 && <div className='form-table-footer is-right' style={{ border: 'none' }}>
              <Button type='default' icon='plus' text={t('TaxesModal::Add VAT')} onClick={this.onAddTaxClick} />
            </div>}
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('TaxesModal::Close')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      taxesModal: {
        onSubmit,
      }
    }
  } = state

  return {
    currentUser,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeTaxesModal()),
    showTaxModal: (options) => dispatch(showTaxModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TaxesModal))