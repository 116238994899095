import * as React from 'react'
import { closeImportProductsModal, showProductModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import { ContactsController, ProductsController } from '../../controllers'
import Loader from '../Loaders/Loader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import MoneyInput from '../Form/MoneyInput'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import SummaryItem from '../Summary/SummaryItem'
import SummaryContainer from '../Summary/SummaryContainer'
import ProductHelper from '../../helpers/ProductHelper'
import NumberFormatter from '../../utilities/NumberFormatter'
import styled, { css } from 'styled-components'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Style } from '../../styles'
import { CurrentUser, ImportedProduct, Product } from '../../types'

const AmountInput = styled.input<{ danger?: boolean }>`
	text-align: right;
	padding-top: 4;
	padding-bottom: 4px;

	${(props: { danger?: boolean }) => props.danger && css`
		color: ${Style.color.brandDanger} !important;
	`}
`

const EditAction = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: ${Style.color.defaultColor};
  color: white;

  i, svg {
    color: white;
    fill: white;
    font-size: 22px;
  }
`


interface IStateToProps {
  currentUser: CurrentUser
  onSubmit?: (importedProducts: ImportedProduct[]) => void
}

interface IDispatchToProps {
  showProductModal: typeof showProductModal
  close: typeof closeImportProductsModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  products: Product[],
  selectedProducts: ImportedProduct[],
  currentPage: number
  totalPages: number
  filter: string
  isLoading: boolean
  errors: any
}

class ImportProductsModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      isLoading: false,
      currentPage: 1,
      totalPages: 1,
      products: [],
      selectedProducts: [],
      filter: '',
      errors: {},
    }

    this.fetchProducts = this.fetchProducts.bind(this)
    this.onQuantityChange = this.onQuantityChange.bind(this)
    this.onEditProductClick = this.onEditProductClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onImportProductsModalCloseClick = this.onImportProductsModalCloseClick.bind(this)
    this.onAddProductClick = this.onAddProductClick.bind(this)
    this.onNewProductSubmit = this.onNewProductSubmit.bind(this)
    this.onEditProductSubmit = this.onEditProductSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)

    // Filter
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onFilterSubmit = this.onFilterSubmit.bind(this)

    this.onProductsLoadMoreClick = this.onProductsLoadMoreClick.bind(this)
  }

  componentDidMount() {
    this.fetchProducts(1)
  }

  fetchProducts(page: number, filter?: string) {
    let params = {}
    if (filter) {
      params = {
        q: filter,
        page: page
      }
    } else {
      params = { page: page }
    }

    this.setState({
      isLoading: true
    })

    ProductsController
      .getProducts(params)
      .then(response => {
        const { products: stateProducts } = this.state
        const { total_pages, current_page, per_page, products } = response

        let newProducts = []
        if (current_page > 1) {
          newProducts = [...stateProducts, ...products]
        } else {
          newProducts = products
        }

        this.setState({
          isLoading: false,
          totalPages: total_pages,
          currentPage: current_page,
          products: newProducts,
          didInitialLoad: true
        });
      })
      .catch(console.error)
  }

  onQuantityChange(product: Product, quantity: string) {
    let { selectedProducts, products } = this.state
    const productId = product.id

    const selectedProductIndex = selectedProducts.findIndex(p => p.productId === productId)

    if (selectedProductIndex === -1) {
      selectedProducts = [
        { productId: product.id, quantity: Number(quantity), product: product },
        ...selectedProducts,
      ]
    } else {
      selectedProducts[selectedProductIndex].quantity = Number(quantity)
    }

    this.setState({
      selectedProducts: [
        ...selectedProducts,
      ]
    })
  }

  onEditProductClick(product: Product) {
    this.props.showProductModal({
      product: { id: product.id },
      onSubmit: this.onEditProductSubmit
    })
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { selectedProducts } = this.state;
    const { close, onSubmit } = this.props

    if (onSubmit) onSubmit(selectedProducts)
    close()
  }

  onImportProductsModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onFilterChange(e) {
    e.preventDefault();

    const filter = e.currentTarget.value

    this.setState({
      filter: filter
    }, () => {
      if (filter === '') this.fetchProducts(1, filter)
    })
  }

  onFilterSubmit(e) {
    e.preventDefault();

    const { filter } = this.state

    this.fetchProducts(1, filter)
  }

  onAddProductClick() {
    const { showProductModal } = this.props

    showProductModal({ onSubmit: this.onNewProductSubmit })
  }

  onNewProductSubmit(product: Product) {
    const { products } = this.state

    this.setState({
      products: [
        product,
        ...products,
      ]
    })
  }

  onEditProductSubmit(product: Product) {
    const { products } = this.state

    const productIndex = products.findIndex(p => p.id === product.id)

    if (productIndex !== -1) {
      products[productIndex] = product

      this.setState({
        products: [
          ...products,
        ]
      }, () => {
        this[`price-input-${productIndex}`].value(product.price)
      })
    }
  }

  onProductsLoadMoreClick(e) {
    e.preventDefault()

    const { currentPage, filter } = this.state

    this.fetchProducts(currentPage + 1, filter)
  }

  renderProducts() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { products, selectedProducts, isLoading, currentPage, totalPages } = this.state

    if (isLoading) {
      return (
        <div className='resource-table-loader'>
          <Loader size='medium' />
        </div>
      )
    }

    const elements = []

    const productElements = products.map((product, index) => {
      const selectedProduct = selectedProducts.find(sp => sp.productId === product.id)
      const hasStock = product.stock !== null && Number(product.stock) >= 0
      const stockOverSelected = selectedProduct && selectedProduct.product.stock !== null && Number(selectedProduct.product.stock) < selectedProduct.quantity

      let quantityInputProps = {}

      if (hasStock) {
        quantityInputProps = {
          ...quantityInputProps,
          max: product.stock,
        }
      }

      return (
        <div key={`product-${product.id ? product.id : index}`} className='resource-table-item is-form'>
          <div style={{ maxWidth: '60px' }}>
            <AmountInput
              type='number'
              min={0}
              step={1}
              name='quantity'
              onChange={(e) => this.onQuantityChange(product, e.currentTarget.value)}
              required
              value={selectedProduct ? selectedProduct.quantity : 0}
              autoComplete='off'
              autoCorrect='off'
              danger={stockOverSelected}
              {...quantityInputProps}
            />
          </div>
          <div style={{ maxWidth: '60px' }}>
            <AmountInput
              type='text'
              value={hasStock ? product.stock : '∞'}
              autoComplete='off'
              autoCorrect='off'
              danger={stockOverSelected}
              disabled={true}
            />
          </div>
          <div>
            <input
              type='text'
              name='name'
              value={product.name}
              disabled={true}
              required
            />
          </div>
          <div style={{ maxWidth: '140px' }}>
            <MoneyInput
              ref={input => this[`price-input-${index}`] = input}
              name='price'
              currency={setting.default_currency}
              numberFormat={setting.number_format}
              placeholderValue={0}
              value={product.price}
              onBlur={(value) => { }}
              disabled={true}
              style={{ textAlign: 'right' }}
            />
          </div>
          <div className='is-action'>
            <EditAction
              href='javascript://'
              onClick={(e) => this.onEditProductClick(product)}
            >
              <Icon icon='pencil' />
            </EditAction>
          </div>
        </div>
      )
    })

    elements.push(productElements)

    if (totalPages > 0 && currentPage !== totalPages && isLoading) {
      elements.push(
        <div className='resource-table-loadmore' onClick={this.onProductsLoadMoreClick}>
          <a href='javascript://'>
            {t('ImportProductsModal::Show more')}
          </a>
        </div>
      )
    }

    return elements
  }

  render() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { didInitialLoad, errors, selectedProducts } = this.state

    const totalProductCount = selectedProducts.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0)
    const subtotal = selectedProducts.reduce((previousValue, currentValue) => previousValue + (ProductHelper.getSubtotal(currentValue.product) * currentValue.quantity), 0)
    const total = selectedProducts.reduce((previousValue, currentValue) => previousValue + (ProductHelper.getTotal(currentValue.product) * currentValue.quantity), 0)

    return (
      <ModalWindow>
        <ModalHeader
          title={t('ImportProductsModal::Import products')}
          onCloseClick={this.onImportProductsModalCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <div className="grid">
              <div className="grid-cell with-12col">
                <form onSubmit={this.onFilterSubmit}>
                  <div className="resource-table-filter">
                    <div className="resource-table-filter-label">{t('ImportProductsModal::Filter')}</div>
                    <div className="resource-table-filter-input">
                      <Icon icon='search' />
                      <input type="text" placeholder={t('ImportProductsModal::Search products')} name="search" onChange={this.onFilterChange} />
                    </div>

                    <a href="javascript://" className="button button-default">
                      {t('ImportProductsModal::Search')}
                    </a>
                  </div>
                  <input type="submit" style={{ display: 'none' }} />
                </form>

                <div className="resource-table is-product">
                  <div className="resource-table-header">
                    <div className="resource-table-header-item" style={{ display: 'block', maxWidth: '60px', textAlign: 'left' }}>
                      {t('ImportProductsModal::Count')}
                    </div>
                    <div className="resource-table-header-item" style={{ display: 'block', maxWidth: '60px', textAlign: 'right' }}>
                      {t('ImportProductsModal::Stock')}
                    </div>
                    <div className="resource-table-header-item">
                      {t('ImportProductsModal::Name')}
                    </div>

                    <div className="resource-table-header-item" style={{ display: 'block', maxWidth: '140px', textAlign: 'right' }}>
                      {t('ImportProductsModal::Price')}
                    </div>
                    <div className='resource-table-header-item is-action'>
                    </div>
                  </div>

                  <div className="resource-table-body">
                    <form onSubmit={this.onFormSubmit}>
                      {this.renderProducts()}
                      <input type='submit' style={{ display: 'none' }} />
                    </form>
                  </div>
                </div>

                <div className="form-table-footer is-right" style={{ border: 'none' }}>
                  <a className="button button-default" href="javascript://" onClick={this.onAddProductClick}>
                    <Icon icon='plus' />
                    {t('ImportProductsModal::Add product')}
                  </a>
                </div>

                <div className='fields-section is-last'>
                  <div className='fieldset'>
                    <SummaryContainer columnCount={3}>
                      <SummaryItem
                        title={t('ImportProductsModal::Product count')}
                        label={`${totalProductCount}`}
                      />
                      <SummaryItem
                        title={t('ImportProductsModal::Subtotal')}
                        label={`${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, subtotal)}`}
                      />
                      <SummaryItem
                        title={t('ImportProductsModal::Total (incl. VAT)')}
                        label={`${NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, total)}`}
                      />
                    </SummaryContainer>
                  </div>
                </div>
              </div>
            </div>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('ImportProductsModal::Import')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      importProductsModal: {
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showProductModal: (options) => dispatch(showProductModal(options)),
    close: () => dispatch(closeImportProductsModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImportProductsModal))