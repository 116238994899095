import * as React from 'react'
import { closeFileModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { FilesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Button from '../Button/Button';
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import { BizzeyFile, CurrentUser } from '../../types'

interface IStateToProps {
  currentUser: CurrentUser
  file?: BizzeyFile
  onSubmit?: (file: BizzeyFile) => void
}

interface IDispatchToProps {
  close: typeof closeFileModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

const enum FileModalTab {
  DETAILS = 'details',
}

interface IState {
  didInitialLoad: boolean
  file: BizzeyFile | null
  errors: any
  isSubmitting: boolean
  activeTab: FileModalTab
}

class FileModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      file: null,
      errors: {},
      isSubmitting: false,
      activeTab: FileModalTab.DETAILS,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this)
    this.onNameChange = this.onNameChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFileModalClose = this.onFileModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)

    this.renderNavigation = this.renderNavigation.bind(this)
  }

  getTitle() {
    const { t } = this.props
    const { file } = this.state

    if (file) {
      if (file.type === 'directory') {
        if (file.id) return t('FileModal::Edit folder')

        return 'Folder aanmaken'
      } else if (file.type === 'file') {
        if (file.id) return t('FileModal::Edit file')

        return t('FileModal::Create file')
      }
    }

    return file && file.id ? t('FileModal::Edit File') : t('FileModal::Create file')
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { file: propFile } = this.props

    try {
      const response = await FilesController.getForm({ id: propFile.id })
      const { file } = response

      this.setState({
        file: {
          ...file,
          ...propFile,
        },
        didInitialLoad: true
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  onNavigationItemClick(e) {
    const activeTab = e.currentTarget.getAttribute('data-tab');

    this.setState({
      activeTab: activeTab
    })
  }

  onNameChange(e) {
    const newName = e.currentTarget.value;

    const { file } = this.state;

    file.name = newName;

    this.setState({
      file
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { close, onSubmit, t } = this.props
    const { file } = this.state;

    var formData = new FormData();

    for (var key in file) {
      if (!['id'].includes(key))
        formData.append(`file[${key}]`, file[key]);
    }

    this.setState({ isSubmitting: true })

    if (file.id) { // Do update
      FilesController.update(file)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('FileModal::Oops something went wrong'))
          }
          else {
            if (response.type === 'directory') {
              Notification.notifySuccess(t('FileModal::Folder successfully updated'))
            } else {
              Notification.notifySuccess(t('FileModal::File successfully updated'))
            }
            if (onSubmit) onSubmit(response)
            close()
          }

          this.setState({ isSubmitting: false })
        })
        .catch(error => console.error(error))
    }
    else {
      FilesController.create(file)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('FileModal::Oops something went wrong'))
          }
          else {
            if (response.type === 'directory') {
              Notification.notifySuccess(t('FileModal::Folder created successfully'))
            } else {
              Notification.notifySuccess(t('FileModal::File created successfully'))
            }
            if (onSubmit) onSubmit(response)
            close()
          }

          this.setState({ isSubmitting: false })
        })
        .catch(console.error)
    }
  }

  onFileModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderNavigation(): JSX.Element {
    const { t } = this.props
    const { activeTab, file, didInitialLoad } = this.state

    return (
      <ModalNavigation>
        <ModalNavigationItem active={activeTab === FileModalTab.DETAILS} data-tab={FileModalTab.DETAILS} onClick={this.onNavigationItemClick}>
          <Icon icon='info' />
          <span>
            {t('FileModal::Details')}
          </span>
        </ModalNavigationItem>
      </ModalNavigation>
    )
  }

  renderDetails() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { file } = this.state

    return (
      <>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('FileModal::Name')}<span>*</span>
              </label>
              <input
                type='text'
                name='name'
                value={file.name}
                onChange={this.onNameChange}
                placeholder={'Naam'}
                required
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    const { file, t } = this.props
    const { didInitialLoad, errors, isSubmitting, activeTab } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={this.getTitle()}
          navigation={this.renderNavigation()}
          onCloseClick={this.onFileModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />

                <Button
                  type='success'
                  text={t('FileModal::Save')}
                  isLoading={isSubmitting}
                  onClick={this.onFormSubmit}
                />
              </div>
            </div>
          </div>

        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      fileModal: {
        file,
        onSubmit,
      }
    }
  } = state

  return {
    file: file,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeFileModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileModal))