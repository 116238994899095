import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'
import Icon from '../Icons/Icon'

const Container = styled.div`
	position: absolute;
	right: ${Style.spacing.x1};
	top: 50%;
	transform: translateY(-50%);
`

const SidebarSectionItemCheck = () => {
	return (
		<Container>
			<Icon icon='check-circle' />
		</Container>
	)
}

export default SidebarSectionItemCheck