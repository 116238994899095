import styled from 'styled-components'

export default styled.ul`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 50px;
  margin-top: 20px;
  margin-bottom: -66px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0 20px;
  overflow: auto;

  &::-webkit-scrollbar {
    background: white;
		height: 8px;
		width: 6px;
	}

	&::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border-radius: 4px;
	}
`