import * as React from 'react'
import styled from "styled-components"
import { Style } from "../../styles"
import Icon from "../Icons/Icon"

const Container = styled.div`
	position: absolute;
	right: ${Style.spacing.x1};
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 22px;
	cursor: pointer;
`

interface IProps {
	onClick: () => void
}

const SidebarClose = (props: IProps) => {
	return (
		<Container onClick={props.onClick}>
			<Icon icon='close' />
		</Container>
	)
}

export default SidebarClose