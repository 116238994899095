import * as React from 'react'
import { closeFileUploadModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import { BizzeyFile, BizzeyFileResourceType, BizzeyFileType, CurrentUser } from '../../types'
import Dropzone from '../Dropzone/Dropzone'
import { MAX_FILE_SIZE } from '../../Constants'
import { DropEvent, FileRejection } from 'react-dropzone'
import { FilesController } from '../../controllers'

interface IStateToProps {
  currentUser: CurrentUser
  resourceId: string
  resourceType: BizzeyFileResourceType
  onSubmit?: (files: BizzeyFile[]) => void
}

interface IDispatchToProps {
  close: typeof closeFileUploadModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  errors: any
  isSubmitting: boolean
}

class FileUploadModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: true,
      errors: {},
      isSubmitting: false,
    }

    this.onFileUploadModalClose = this.onFileUploadModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onFileDrop = this.onFileDrop.bind(this)
  }

  onFileUploadModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  async onFileDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    const { resourceType, resourceId } = this.props

    const files = await Promise.all(acceptedFiles.map(async (file: any) => {
      try {
        const newFile: BizzeyFile = {
          type: BizzeyFileType.FILE,
          name: file.name,
          resource_id: resourceId,
          resource_type: resourceType,
          attachment: file,
          attachment_content_type: file.type,
        }

        const responseFile = await FilesController.create(newFile)

        return responseFile

      } catch (ex) {
        console.error(ex)
      }
    }))

    this.props.onSubmit(files)
    this.props.close()
  }

  render() {
    const { t } = this.props
    const { didInitialLoad } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={t('FileUploadModal::Upload files')}
          onCloseClick={this.onFileUploadModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <Dropzone
              dropzoneProps={{
                onDrop: this.onFileDrop,
                multiple: true,
                maxSize: MAX_FILE_SIZE
              }}
            />
          </ModalContent>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      fileUploadModal: {
        resourceId,
        resourceType,
        onSubmit,
      }
    }
  } = state

  return {
    resourceId: resourceId,
    resourceType: resourceType,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeFileUploadModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileUploadModal))