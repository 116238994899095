import * as React from 'react'
import { closeCalendarEventModal, showContactModal, showConfirmModal, showProjectModal, showTimeEntryModal } from '../../store/modals/actions'
import MaskedInput from 'react-text-mask';
import ModalWindowPart from './Parts/ModalWindow'
import Notification from '../../utilities/Notification'
import { AppState } from '../../store';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Icon, { IconTypes } from '../Icons/Icon'
import { CalendarController, CalendarEventsController, TasksController } from '../../controllers';
import ModalLoader from './Parts/ModalLoader';
import { WithTranslation, withTranslation } from 'react-i18next';
import DateInput, { DateInput as DateInputClass } from '../Form/DateInput'
import Utils from '../../utilities/Utils'
import Button from '../Button/Button';
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect';
import Editor, { CALENDAR_EVENT_EDITOR_CONFIG, MINIMAL_EDITOR_CONFIG } from '../Editor/Editor';
import EditorContainer from '../Editor/EditorContainer';
import { CalendarEvent, CalendarEventAttendeeStatus, CalendarEventReminderType, CalendarEventReminderUnit, CalendarEventType, CalendarViewEvent, CalendarViewEventType, Contact, CurrentUser, CustomField, DayOfWeek, Project, ProjectStatus, RecurrenceUnit, Task } from '../../types';
import TooltipError from '../Tooltips/ErrorTooltip';
import Map from '../Map/Map';
import MapContainer from '../Map/MapContainer';
import ReactTooltip from 'react-tooltip';
import ModalActionFollowUp from './Parts/ModalActionFollowUp';
import moment from '../../utilities/Moment';
import ModalMiddle from './Parts/ModalMiddle';
import ModalHeader from './Parts/ModalHeader';
import ModalContent from './Parts/ModalContent';
import ModalFooter from './Parts/ModalFooter';
import withDragAndDrop, { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import { Calendar, EventProps, momentLocalizer } from 'react-big-calendar';
import CalendarToolbar from '../Calendar/CalendarToolbar';
import { CalendarDay, CalendarHeader, NavigateButtonLeft, NavigateButtonRight } from '../Navigation/SidebarCalendar';
import CalendarEventView from '../Calendar/CalendarEventView';
import Alert from '../Alert/Alert';
import LocationInput from '../Form/LocationInput';
import AttendeeItem from '../Calendar/AttendeeItem';
import CalendarEventHelper from '../../helpers/CalendarEventHelper';

// @ts-ignore
const DnDCalendar = withDragAndDrop(Calendar)

const ModalWindow = styled(ModalWindowPart)`
  display: flex;
  flex-direction: row;
  height: 100vh;
  max-width: initial;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: initial;
  }

  ${ModalMiddle} {
    overflow-x: hidden;
    overflow-y: auto;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 270px;
  width: 270px;
  width: 100%;
  border-left: 1px solid ${Style.color.border};

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: none;
  }

  .rbc-time-view {
    border: none;
    overflow: auto;
  }

  .rbc-time-header {
    border-top: 1px solid ${Style.color.border};
  }

  .rbc-calendar {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .rbc-time-content {
    overflow: initial;
  }

  ${CalendarHeader} {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  ${CalendarDay} {
    span {
      &:first-child {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
      }

      &:last-child {
        font-size: 14px;
        font-weight: 800;
      }
    }
  }
`

const ModalTitle = styled.div`
  margin: 4px 0 0;
  padding: 8px 0 0;
  width: 100%;

  input {
    color: rgb(23, 43, 77);
    background: transparent;
    border-radius: 3px;
    box-shadow: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin: -4px -8px;
    min-height: 24px;
    padding: 4px 8px;
    resize: none;
    border: none !important;
    border-radius: 0px !important;
    border-bottom: 2px solid transparent !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
      border-bottom: 2px solid ${Style.color.brandPrimary} !important;
    }
  }
`

const ContactProjectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    align-items: initial;
  }

  > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: ${Style.spacing.x1};

      @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        margin-right: 0;
        margin-bottom: ${Style.spacing.x1};
      }
    }

    &:last-child {
      flex: initial;
      margin-right: 0;
    }
  }
`

const EventTypes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin-right: ${Style.spacing.x1};
    margin-bottom: ${Style.spacing.x0_5};
  }
`

const EventType = styled.div<{ active: boolean }>`
  line-height: 38px;
  padding: 0 ${Style.spacing.x1};
  color: #5f6368;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e8f0fe;
    color: ${Style.color.brandPrimary};
  }

  ${props => props.active && css`
    background-color: #e8f0fe;
    color: ${Style.color.brandPrimary};
  `}
`

const AllDayContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Dates = styled.div`
  display: flex;
  flex-direction: column;
`

const DatetimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  > div:first-child {
    width: 100%;

    > div:first-child {
      margin-right: ${Style.spacing.x0_5};
    }
  }

  > div:last-child {
    width: 100%;

    > div:first-child {
      margin-right: ${Style.spacing.x0_5};
    }
  }
`

const DatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    align-items: initial;
  }

  ${DatetimeContainer} {
    &:first-child {
      margin-right: ${Style.spacing.x1};

      @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        margin-right: 0;
      }
    }
    
  }
`

const AttendeeItems = styled.div`
  margin-top: ${Style.spacing.x1};
`

const ModalSection = styled.div`
  margin: 0px 18px 18px 0px;
  min-height: 32px;
  position: relative;
  display: flex;
  flex-direction: row;
`

const ModalSectionIcon = styled.div`
  display: flex;
  width: 40px;
  height: 31px;
  justify-content: center;
  align-items: center;
  margin-right: ${Style.spacing.x1_5};

  i {
    font-size: 20px;
    color: #5f6368;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: #5f6368;
    color: #5f6368;
  }
`

const ModalSectionIconAction = styled(ModalSectionIcon)`
  width: 38px;
  height: 38px;
  border-radius: ${Style.variables.baseBorderRadius};
  cursor: pointer;

  &:hover {
    background: #f6f6f7;
  }
`

const InputWithBackground = styled.div`
	width: 100%;

  select {
    padding-right: ${Style.spacing.x3_5};
  }
`

const ModalSectionContent = styled.div`
  width: 100%;
`

const Notifications = styled.div`
  display: flex;
  flex-direction: column;
`

const NotificationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${Style.spacing.x1};

  > div:first-child {
    margin-right: ${Style.spacing.x1};
  }

  > div:nth-child(2) {
    margin-right: ${Style.spacing.x1};
    max-width: 65px;
  }

  > div:nth-child(3) {
    margin-right: ${Style.spacing.x1};
  }
`

const AddItem = styled.div`
  display: inline-block;
  color: black;
  cursor: pointer;
  padding: ${Style.spacing.x0_5} ${Style.spacing.x1};
  border-radius: 4px;

  &:hover {
    background-color: #f1f3f4;
  }
`

const ModalLoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${Style.spacing.x1};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const ItemRemove = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  cursor: pointer;
  border-radius: 50%;

  svg {
		width: 24px;
		height: 24px;
		color: #42526e;
		fill: #42526e;
	}

  &:hover {
    background-color: #f1f3f4;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  calendarEvent: CalendarEvent
  contactDisabled?: boolean
  projectDisabled?: boolean
  readOnly?: boolean
  onSubmit?: (calendarEvent: CalendarEvent) => void
  onDelete?: (calendarEventId: string) => void
}

interface IDispatchToProps {
  close: typeof closeCalendarEventModal
  showConfirmModal: typeof showConfirmModal
  showContactModal: typeof showContactModal
  showProjectModal: typeof showProjectModal
  showTimeEntryModal: typeof showTimeEntryModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  calendarEvent: CalendarEvent
  location?: string
  customFields: CustomField[]
  newAttendee: string
  errors: any
  isSubmitting: boolean
  isDeleting: boolean
  task: Task
  events: CalendarViewEvent[]
}

class CalendarEventModal extends React.Component<IProps, IState> {
  private startDateInput = React.createRef<DateInputClass>()
  private endDateInput = React.createRef<DateInputClass>()
  private startTimeInput = React.createRef<MaskedInput>()
  private endTimeInput = React.createRef<MaskedInput>()
  private debouncedFetchEvents: () => void

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      calendarEvent: null,
      location: null,
      customFields: [],
      newAttendee: '',
      errors: {},
      isSubmitting: false,
      isDeleting: false,
      task: null,
      events: []
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.fetchEvents = this.fetchEvents.bind(this)
    this.debouncedFetchEvents = Utils.debounce(this.fetchEvents, 250, false)
    this.getTypeIcon = this.getTypeIcon.bind(this)
    this.onTitleChange = this.onTitleChange.bind(this)
    this.onTypeChange = this.onTypeChange.bind(this)
    this.onContactChange = this.onContactChange.bind(this)
    this.onProjectChange = this.onProjectChange.bind(this)
    this.onAddTimeEntryClick = this.onAddTimeEntryClick.bind(this)
    this.onStartChange = this.onStartChange.bind(this)
    this.onStartTimeChange = this.onStartTimeChange.bind(this)
    this.onStartTimeBlur = this.onStartTimeBlur.bind(this)
    this.onEndChange = this.onEndChange.bind(this)
    this.onEndTimeChange = this.onEndTimeChange.bind(this)
    this.onEndTimeBlur = this.onEndTimeBlur.bind(this)
    this.onAllDayChange = this.onAllDayChange.bind(this)
    this.onJoinMeetingClick = this.onJoinMeetingClick.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onFollowUpActionChange = this.onFollowUpActionChange.bind(this)
    this.onAttendeeChange = this.onAttendeeChange.bind(this)
    this.onAttendeeEditClick = this.onAttendeeEditClick.bind(this)
    this.onAttendeeStatusChange = this.onAttendeeStatusChange.bind(this)
    this.onAttendeeRemoveClick = this.onAttendeeRemoveClick.bind(this)
    this.onLocationChange = this.onLocationChange.bind(this)
    this.onNotificationItemTypeChange = this.onNotificationItemTypeChange.bind(this)
    this.onNotificationItemIntervalChange = this.onNotificationItemIntervalChange.bind(this)
    this.onNotificationItemUnitChange = this.onNotificationItemUnitChange.bind(this)
    this.onAddNotificationItemClick = this.onAddNotificationItemClick.bind(this)
    this.onCalendarEventDeleteClick = this.onCalendarEventDeleteClick.bind(this)
    this.onCalendarEventModalCloseclick = this.onCalendarEventModalCloseclick.bind(this)
    this.onCalendarNavigateBack = this.onCalendarNavigateBack.bind(this)
    this.onCalendarNavigateForward = this.onCalendarNavigateForward.bind(this)
    this.onCalendarEventDrop = this.onCalendarEventDrop.bind(this)
    this.onCalendarEventResize = this.onCalendarEventResize.bind(this)
    this.syncCalendarInputs = this.syncCalendarInputs.bind(this)
    this.isDraggable = this.isDraggable.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentDidMount() {
    setTimeout(() => {
      this.fetchForm()
    }, 250)
  }

  componentWillUnmount() { }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  async fetchForm() {
    const { calendarEvent } = this.props

    try {
      const response = await CalendarEventsController.getForm({ id: calendarEvent.id })
      const { calendar_event: responseCalendarEvent, custom_fields, contacts } = response

      this.setState({
        calendarEvent: {
          ...responseCalendarEvent,
          ...calendarEvent,
        },
        location: responseCalendarEvent?.location || null,
        customFields: custom_fields,
        didInitialLoad: true,
      }, this.fetchEvents)
    } catch (ex) {
      console.error(ex)
    }
  }

  async fetchEvents() {
    const { calendarEvent } = this.state

    const startMoment = moment(calendarEvent.start)
    const endMoment = moment(calendarEvent.end)

    if (startMoment.isValid() && endMoment.isValid()) {
      try {
        const { events } = await CalendarController.getEvents({
          start: startMoment.startOf('day').toISOString(),
          end: endMoment.endOf('day').toISOString(),
        })

        this.setState({
          events: events.map(calendarEvent => ({
            ...calendarEvent,
            start: new Date(calendarEvent.start),
            end: new Date(calendarEvent.end),
            disabled: true
          }))
        })
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  onFormSubmit(e) {
    if (e) e.preventDefault()

    const { t, onSubmit, close } = this.props
    const { calendarEvent, task } = this.state;

    if (calendarEvent.start && calendarEvent.end) {
      this.setState({ isSubmitting: true })
      if (calendarEvent.id) {
        CalendarEventsController
          .update(calendarEvent)
          .then(response => {
            const { errors } = response;

            if (errors) {
              this.setState({ errors: errors });
              Notification.notifyError(t('CalendarEventModal::Oops something went wrong!'))
            }
            else {
              Notification.notifySuccess(t('CalendarEventModal::Calendar event successfully updated.'))

              if (task) {
                TasksController.create({
                  ...task,
                  name: calendarEvent?.contact ? `${task.name} ${calendarEvent.contact.name}` : task.name,
                  contact_id: calendarEvent?.contact_id,
                }).catch(console.error)
              }

              if (onSubmit) onSubmit(response)
              close()
            }
            this.setState({ isSubmitting: false })
          })
          .catch(error => console.error(error))
      } else {
        CalendarEventsController
          .create(calendarEvent)
          .then(response => {
            const { errors } = response;

            if (errors) {
              this.setState({ errors: errors });
              Notification.notifyError(t('CalendarEventModal::Oops something went wrong!'))
            }
            else {
              Notification.notifySuccess(t('CalendarEventModal::Calendar event successfully created.'))

              if (task) {
                TasksController.create({
                  ...task,
                  name: calendarEvent?.contact ? `${task.name} ${calendarEvent.contact.name}` : task.name,
                  contact_id: calendarEvent?.contact_id,
                }).catch(console.error)
              }

              if (onSubmit) onSubmit(response)
              close()
            }
            this.setState({ isSubmitting: false })
          })
          .catch(error => console.error(error))
      }
    }
  }

  onCalendarEventModalCloseclick() {
    this.props.close()
  }

  onTitleChange(e) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        title: e.currentTarget.value
      },
    })
  }

  onTypeChange(type: CalendarEventType) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        type: type
      },
    })
  }

  onContactChange(value?: string, contact?: Contact) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        contact_id: value,
        contact: contact,
        project_id: null,
      }
    })
  }

  onProjectChange(value?: string, project?: Project) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        project_id: value,
        project: project
      }
    })
  }

  onAddTimeEntryClick() {
    const { calendarEvent, } = this.state

    this.props.showTimeEntryModal({
      contactDisabled: Boolean(calendarEvent?.contact_id),
      projectDisabled: Boolean(calendarEvent?.project_id),
      timeEntry: {
        description: calendarEvent.title,
        contact_id: calendarEvent?.contact_id,
        project_id: calendarEvent?.project_id,
        started_at: calendarEvent.start,
        ended_at: calendarEvent.end,
      },
    })
  }

  onStartChange(value) {
    const { calendarEvent } = this.state

    const newStartDatetime = moment(value)

    if (newStartDatetime.isValid()) {
      let newEndDatetime = null

      if (calendarEvent.all_day) {
        newStartDatetime.startOf('day')
        newEndDatetime = moment(newStartDatetime).endOf('day')
      }
      else if (this.startTimeInput.current) {
        // @ts-ignore
        const timeValue = this.startTimeInput.current.inputElement.value

        if (timeValue !== '' && Utils.isValidTime(timeValue)) {
          const valueParts = timeValue.split(':').filter(v => v !== '')
          const hour = valueParts[0]
          const minutes = valueParts[1]

          newStartDatetime
            .set('hour', hour)
            .set('minute', minutes)
        }
      }

      this.setState({
        calendarEvent: {
          ...calendarEvent,
          start: newStartDatetime.toISOString(),
          end: newEndDatetime ? newEndDatetime.toISOString() : calendarEvent.end,
          recurring_schedule: calendarEvent.recurring_schedule ? {
            ...calendarEvent.recurring_schedule,
            start_date: newStartDatetime.format('YYYY-MM-DD'),
          } : null
        }
      }, this.debouncedFetchEvents)
    }
  }

  onStartTimeChange(e) {
    const { calendarEvent } = this.state
    const value = e.currentTarget.value

    // Valid
    if (value !== '' && Utils.isValidTime(value)) {
      const valueParts = value.split(':').filter(v => v !== '')
      const hour = valueParts[0]
      const minutes = valueParts[1]

      const newStartedAt = moment(calendarEvent.start)

      if (newStartedAt.isValid()) {
        newStartedAt
          .set('hour', hour)
          .set('minutes', minutes)

        this.setState({
          calendarEvent: {
            ...calendarEvent,
            start: newStartedAt.toISOString()
          }
        }, this.debouncedFetchEvents)
      }
    }
  }

  onStartTimeBlur() {
    const { calendarEvent } = this.state

    const startMoment = moment(calendarEvent.start)

    if (this.startTimeInput.current && startMoment.isValid()) {
      // @ts-ignore
      this.startTimeInput.current.inputElement.value = startMoment.format('HH:mm')
    }
  }

  onEndChange(value) {
    const { calendarEvent } = this.state

    const newEndDatetime = moment(value)

    if (newEndDatetime.isValid()) {
      if (calendarEvent.all_day) {
        newEndDatetime.endOf('day')
      }
      else if (this.endTimeInput.current) {
        // @ts-ignore
        const timeValue = this.endTimeInput.current.inputElement.value
        if (timeValue !== '' && Utils.isValidTime(timeValue)) {
          const valueParts = timeValue.split(':').filter(v => v !== '')
          const hour = valueParts[0]
          const minutes = valueParts[1]

          newEndDatetime
            .set('hour', hour)
            .set('minute', minutes)
        }
      }

      if (newEndDatetime.isValid()) {
        this.setState({
          calendarEvent: {
            ...calendarEvent,
            end: newEndDatetime.toISOString(),
          }
        }, this.debouncedFetchEvents)
      }
    }
  }

  onEndTimeChange(e) {
    const { calendarEvent } = this.state
    const value = e.currentTarget.value

    // Valid
    if (value !== '' && Utils.isValidTime(value)) {
      const valueParts = value.split(':').filter(v => v !== '')
      const hour = valueParts[0]
      const minutes = valueParts[1]

      const newEndedAt = moment(calendarEvent.end)

      if (newEndedAt.isValid()) {
        newEndedAt
          .set('hour', hour)
          .set('minutes', minutes)

        this.setState({
          calendarEvent: {
            ...calendarEvent,
            end: newEndedAt.toISOString()
          }
        }, this.debouncedFetchEvents)
      }
    }
  }

  onEndTimeBlur() {
    const { calendarEvent } = this.state

    const endMoment = moment(calendarEvent.end)

    if (this.endTimeInput.current && endMoment.isValid()) {
      // @ts-ignore
      this.endTimeInput.current.inputElement.value = endMoment.format('HH:mm')
    }
  }

  onAllDayChange() {
    const { calendarEvent } = this.state

    const nowMoment = moment()
    const newAllDay = !calendarEvent.all_day
    let start = moment(calendarEvent.start)
    let end = moment(calendarEvent.end)

    // Toggled all day on, no end date should be set
    if (newAllDay) {
      start = moment(calendarEvent.start).startOf('day')
      end = moment(calendarEvent.start).endOf('day')
    } else {
      start = moment(calendarEvent.start)
        .set('hour', nowMoment.get('hour'))
        .set('minute', nowMoment.get('minute'))
        .set('seconds', 0)

      end = moment(start)
        .set('hour', start.get('hour') + 1)
        .set('minute', start.get('minute'))
        .set('seconds', 0)
    }

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        all_day: newAllDay,
        start: start.isValid() ? start.toISOString() : null,
        end: end.isValid() ? end.toISOString() : null,
      }
    }, this.debouncedFetchEvents)
  }

  onJoinMeetingClick() {
    const { calendarEvent } = this.state
    window.open(calendarEvent.meeting_link, '_blank')
  }

  onDescriptionChange(description: string) {
    const { calendarEvent } = this.state;

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        description: description,
      }
    });
  }

  onFollowUpActionChange(task: Task) {
    this.setState({
      task: task
    })
  }

  onAttendeeChange(attendeeId: string, attendee: Contact) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        attendees: [
          ...calendarEvent.attendees,
          {
            contact: attendee,
            contact_id: attendeeId,
            status: CalendarEventAttendeeStatus.ACTION_REQUIRED,
          }
        ]
      }
    })
  }

  onAttendeeEditClick(index) {
    const { calendarEvent } = this.state

    const attendees = calendarEvent.attendees
    const attendee = attendees[index]

    this.props.showContactModal({
      contact: { id: attendee.contact_id },
      onSubmit: (contact: Contact) => {

        attendees[index].contact = contact

        this.setState({
          calendarEvent: {
            ...calendarEvent,
            attendees: [
              ...attendees,
            ]
          }
        })
      },
    })
  }

  onAttendeeStatusChange(index, status: CalendarEventAttendeeStatus) {
    const { calendarEvent } = this.state

    const attendees = calendarEvent.attendees

    attendees[index].status = status

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        attendees: [...attendees]
      }
    })
  }

  onAttendeeRemoveClick(index) {
    const { calendarEvent } = this.state

    const attendees = calendarEvent.attendees
    const attendee = attendees[index]

    if (attendee.id) {
      attendees[index]._destroy = true
    } else {
      attendees.splice(index, 1)
    }

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        attendees: [...attendees]
      }
    })
  }

  onLocationChange(location: string) {
    const { calendarEvent } = this.state

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        location: location,
      }
    })
  }

  onNotificationItemTypeChange(index, e) {
    const { calendarEvent } = this.state

    const updatedReminders = calendarEvent.reminders

    updatedReminders[index].type = e.currentTarget.value

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        reminders: [...updatedReminders]
      }
    })
  }

  onNotificationItemIntervalChange(index, e) {
    const { calendarEvent } = this.state

    const updatedReminders = calendarEvent.reminders

    updatedReminders[index].interval = e.currentTarget.value

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        reminders: [...updatedReminders]
      }
    })
  }

  onNotificationItemUnitChange(index, e) {
    const { calendarEvent } = this.state

    const updatedReminders = calendarEvent.reminders

    updatedReminders[index].interval_unit = e.currentTarget.value

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        reminders: [...updatedReminders]
      }
    })
  }

  onNotificationItemRemoveClick(index) {
    const { calendarEvent } = this.state

    const updatedReminders = calendarEvent.reminders

    updatedReminders.splice(index, 1);

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        reminders: [...updatedReminders]
      }
    })
  }

  onAddNotificationItemClick(e) {
    const { calendarEvent } = this.state
    e.preventDefault()

    this.setState({
      calendarEvent: {
        ...calendarEvent,
        reminders: [
          ...calendarEvent.reminders,
          { type: CalendarEventReminderType.NOTIFICATION, interval: 30, interval_unit: CalendarEventReminderUnit.MINUTE }
        ]
      }
    })
  }

  async onCalendarEventDeleteClick() {
    const { onDelete, close, showConfirmModal, t } = this.props
    const { calendarEvent } = this.state

    showConfirmModal({
      title: t('CalendarEventModal::Delete calendar event'),
      description: t('CalendarEventModal::You are about to delete this event. This event and all its associated will be permanently deleted. Are you sure you want to continue?'),
      action: { label: t('CalendarEventModal::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          this.setState({ isDeleting: true })
          const response = await CalendarEventsController.delete(calendarEvent.id)
          this.setState({ isDeleting: false })

          // Trigger onDelete callback
          if (onDelete) onDelete(calendarEvent.id)

          // Close modal
          close()
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  getTypeIcon(): IconTypes {
    const { calendarEvent } = this.state

    switch (calendarEvent.type) {
      case CalendarEventType.EVENT:
        return 'calendar-day'
      case CalendarEventType.APPOINTMENT:
        return 'calendar-day'
      case CalendarEventType.CALL:
        return 'phone'
      case CalendarEventType.FOCUS_TIME:
        return 'calendar-day'
      case CalendarEventType.HOLIDAY:
        return 'calendar-day'
      default:
        throw Error(`Invalid calendar event type: ${calendarEvent.type}`)
    }
  }

  onCalendarNavigateBack() {
    const { calendarEvent } = this.state

    const currentEndMoment = moment(calendarEvent.end)

    const startMoment = moment(calendarEvent.start).subtract(1, 'day')
    const endMoment = moment(startMoment)
      .set('hour', currentEndMoment.get('hour'))
      .set('minute', currentEndMoment.get('minute'))

    this.onCalendarUpdate({
      ...calendarEvent,
      start: startMoment.toISOString(),
      end: endMoment.toISOString(),
    })
  }

  onCalendarNavigateForward() {
    const { calendarEvent } = this.state

    const currentEndMoment = moment(calendarEvent.end)

    const startMoment = moment(calendarEvent.start).add(1, 'day')
    const endMoment = moment(startMoment)
      .set('hour', currentEndMoment.get('hour'))
      .set('minute', currentEndMoment.get('minute'))

    this.onCalendarUpdate({
      ...calendarEvent,
      start: startMoment.toISOString(),
      end: endMoment.toISOString(),
    })
  }

  onCalendarEventDrop(eventInteraction: EventInteractionArgs<CalendarViewEvent>) {
    const { calendarEvent } = this.state
    const { start, end, isAllDay } = eventInteraction

    this.onCalendarUpdate({
      ...calendarEvent,
      start: moment(start).toISOString(),
      end: moment(end).toISOString(),
      all_day: Boolean(isAllDay)
    })
  }

  onCalendarEventResize(eventInteraction: EventInteractionArgs<CalendarViewEvent>) {
    const { calendarEvent } = this.state
    const { start, end, isAllDay } = eventInteraction

    this.onCalendarUpdate({
      ...calendarEvent,
      start: moment(start).toISOString(),
      end: moment(end).toISOString(),
      all_day: Boolean(isAllDay)
    })
  }

  onCalendarUpdate(calendarEvent: CalendarEvent) {
    this.setState({ calendarEvent: calendarEvent }, () => {
      this.debouncedFetchEvents()
      this.syncCalendarInputs()
    })
  }

  syncCalendarInputs() {
    const { currentUser: { workspace: { setting } } } = this.props
    const { calendarEvent } = this.state

    const startMoment = moment(calendarEvent.start)
    const endMoment = moment(calendarEvent.end)

    if (this.startDateInput.current) this.startDateInput.current.input.current.value = startMoment.format(setting.date_format)
    if (this.startTimeInput.current) (this.startTimeInput.current.inputElement as HTMLInputElement).value = startMoment.format('HH:mm')
    if (this.endDateInput.current) this.endDateInput.current.input.current.value = endMoment.format(setting.date_format)
    if (this.endTimeInput.current) (this.endTimeInput.current.inputElement as HTMLInputElement).value = endMoment.format('HH:mm')
  }

  isDraggable(event: CalendarViewEvent) {
    const { calendarEvent } = this.state
    return event.resource?.id === calendarEvent.id || Boolean(event.resource?.id) === false
  }

  render() {
    const { t, currentUser: { workspace: { setting } }, contactDisabled, projectDisabled, readOnly } = this.props
    const {
      didInitialLoad,
      calendarEvent,
      location,
      newAttendee,
      errors,
      isDeleting,
      isSubmitting,
      events,
    } = this.state
    const localizer = momentLocalizer(moment)
    let startMoment = null
    let endMoment = null
    let resultEvents: CalendarViewEvent[] = [
      ...events,
    ]
      // Remove current event from events (we don't want to show it twice and modify it on the fly)
      .filter(event => event.resource.id !== calendarEvent.id)

    let eventDurationInHours = 0

    if (calendarEvent) {
      startMoment = moment(calendarEvent?.start)
      endMoment = moment(calendarEvent?.end)

      if (startMoment.isValid() && endMoment.isValid()) {
        resultEvents = [
          ...resultEvents,
          {
            resource: calendarEvent,
            type: CalendarViewEventType.CALENDAR_EVENT,
            start: new Date(calendarEvent.start),
            end: new Date(calendarEvent.end),
            title: calendarEvent.title,
            all_day: calendarEvent.all_day,
          }
        ]

        eventDurationInHours = endMoment.diff(startMoment, 'hours')
      }
    }

    const notificationTypeOptions = [
      { label: t(`CalendarEventReminderType::${CalendarEventReminderType.NOTIFICATION}`), value: CalendarEventReminderType.NOTIFICATION },
      { label: t(`CalendarEventReminderType::${CalendarEventReminderType.EMAIL}`), value: CalendarEventReminderType.EMAIL },
    ]

    const selectedAttendeeIds = calendarEvent?.attendees?.map(attendee => attendee.contact_id) || []
    const attendeeParams = { archived: false }

    if (selectedAttendeeIds.length > 0) {
      attendeeParams['id[not_in]'] = selectedAttendeeIds
    }

    return (
      <ModalWindow>
        {!didInitialLoad && <ModalLoaderWrapper><ModalLoader /></ModalLoaderWrapper>}
        {didInitialLoad && <>
          <Content>
            <ModalHeader
              title={calendarEvent?.id ? t('CalendarEventModal::Edit event') : t('CalendarEventModal::Create event')}
              onCloseClick={this.onCalendarEventModalCloseclick}
            />

            <ModalMiddle>
              <ModalContent>
                <form onSubmit={this.onFormSubmit}>
                  <ModalSection>
                    <ModalSectionIcon style={{ marginTop: 9 }}>
                      <Icon icon={this.getTypeIcon()} />
                    </ModalSectionIcon>

                    <ModalTitle>
                      <input
                        type='text'
                        value={calendarEvent.title}
                        placeholder={t('CalendarEventModal::Enter title')}
                        autoFocus={true}
                        onChange={this.onTitleChange}
                        disabled={readOnly}
                      />
                    </ModalTitle>
                  </ModalSection>

                  <ModalSection>
                    <ModalSectionIcon />

                    <EventTypes>
                      {Object.keys(CalendarEventType).map(type => (
                        <EventType
                          key={type}
                          active={String(CalendarEventType[type]) === String(calendarEvent.type)}
                          onClick={() => !readOnly ? this.onTypeChange(CalendarEventType[type]) : null}
                        >
                          {t(`CalendarEventType::${CalendarEventType[type]}`)}
                        </EventType>
                      ))}
                    </EventTypes>
                  </ModalSection>

                  <ModalSection>
                    <ModalSectionIcon>
                      <Icon icon='user' />
                    </ModalSectionIcon>

                    <ContactProjectContainer>
                      <ResourceCreatablePowerSelect
                        type='contact'
                        params={{ archived: false, }}
                        value={calendarEvent.contact_id}
                        onChange={this.onContactChange}
                        isClearable={true}
                        styles={{
                          container: (provided, state) => ({
                            ...provided,
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            overflow: 'hidden'
                          }),
                          valueContainer: (provided, state) => ({
                            ...provided,
                          }),
                          dropdownIndicator: (provided, state) => ({
                            ...provided,
                          }),
                          indicatorSeparator: (provided, state) => ({
                            ...provided,
                          }),
                        }}
                        isDisabled={Boolean(contactDisabled) || Boolean(readOnly)}
                      />

                      <ResourceCreatablePowerSelect
                        type='project'
                        value={calendarEvent.project_id}
                        onChange={this.onProjectChange}
                        isDisabled={!calendarEvent.contact_id || Boolean(projectDisabled) || Boolean(readOnly)}
                        isClearable={true}
                        params={{ 'contact_id': calendarEvent.contact_id, 'status[in]': [ProjectStatus.PROPOSAL, ProjectStatus.ACTIVE] }}
                        createParams={{ contact_id: calendarEvent.contact_id }}
                        styles={{
                          container: (provided, state) => ({
                            ...provided,
                            background: '#f1f3f4',
                          }),
                          control: (provided, state) => ({
                            ...provided,
                            background: '#f1f3f4',
                            overflow: 'hidden'
                          }),
                          valueContainer: (provided, state) => ({
                            ...provided,
                            background: '#f1f3f4'
                          }),
                          dropdownIndicator: (provided, state) => ({
                            ...provided,
                            background: '#f1f3f4'
                          }),
                          indicatorSeparator: (provided, state) => ({
                            ...provided,
                            background: '#f1f3f4'
                          }),
                        }}
                      />

                      <ModalSectionIconAction
                        onClick={this.onAddTimeEntryClick}
                        data-tip={t('CalendarEventModal::Create time entry for this event')}
                      >
                        <Icon icon='clock' />
                      </ModalSectionIconAction>
                    </ContactProjectContainer>
                  </ModalSection>

                  <ModalSection>
                    <ModalSectionIcon>
                      <Icon icon='clock' />
                    </ModalSectionIcon>

                    <ModalSectionContent>
                      <Dates>
                        <DatesContainer>
                          <DatetimeContainer>
                            <InputWithBackground style={{ marginBottom: 4 }}>
                              <DateInput
                                ref={this.startDateInput}
                                name='start'
                                dateFormat={setting.date_format}
                                timeFormat={false}
                                initialValue={moment(calendarEvent.start)}
                                inputProps={{ placeholder: setting.date_format, disabled: Boolean(readOnly) }}
                                onChange={this.onStartChange}
                                closeOnSelect
                              />
                            </InputWithBackground>

                            {!calendarEvent.all_day && <InputWithBackground style={{ maxWidth: 130, marginBottom: 4, width: 'fit-content' }}>
                              <MaskedInput
                                ref={this.startTimeInput}
                                type='text'
                                defaultValue={moment(calendarEvent.start).format('HH:mm')}
                                placeholder='00:00'
                                guide={false}
                                showMask={false}
                                mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                                onChange={this.onStartTimeChange}
                                onBlur={this.onStartTimeBlur}
                                style={{ maxWidth: 70 }}
                                disabled={Boolean(readOnly)}
                              />
                            </InputWithBackground>}
                          </DatetimeContainer>

                          <DatetimeContainer>
                            <InputWithBackground style={{ marginBottom: 4 }}>
                              <DateInput
                                ref={this.endDateInput}
                                name='end'
                                dateFormat={setting.date_format}
                                timeFormat={false}
                                initialValue={moment(calendarEvent.end)}
                                inputProps={{
                                  placeholder: setting.date_format,
                                  disabled: Boolean(readOnly),
                                }}
                                onChange={this.onEndChange}
                                closeOnSelect
                              />
                            </InputWithBackground>

                            {!calendarEvent.all_day && <InputWithBackground style={{ maxWidth: 130, marginBottom: 4, width: 'fit-content' }}>
                              <MaskedInput
                                ref={this.endTimeInput}
                                type='text'
                                defaultValue={moment(calendarEvent.end).format('HH:mm')}
                                placeholder='00:00'
                                guide={false}
                                showMask={false}
                                mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                                onChange={this.onEndTimeChange}
                                onBlur={this.onEndTimeBlur}
                                style={{ maxWidth: 70 }}
                                disabled={Boolean(readOnly)}
                              />
                            </InputWithBackground>}
                          </DatetimeContainer>
                        </DatesContainer>

                        <AllDayContainer>
                          <input type='checkbox' checked={calendarEvent.all_day} onChange={this.onAllDayChange} disabled={readOnly} />
                          {t('CalendarEventModal::All day')}
                        </AllDayContainer>

                        {!calendarEvent.all_day && eventDurationInHours > 10 && <div style={{ marginTop: 8 }}>
                          <Alert
                            type='warning'
                            text={t('CalendarEventModal::This event spans a long duration and may appear cluttered on your calendar. Would you prefer to set it as an all-day event instead?')}
                          />
                        </div>}
                      </Dates>
                    </ModalSectionContent>
                  </ModalSection>

                  {calendarEvent.meeting_link?.length > 0 && <ModalSection>
                    <ModalSectionIcon>
                      <Icon icon='video' />
                    </ModalSectionIcon>

                    <ModalSectionContent>
                      <InputWithBackground>
                        <LinkContainer>
                          <input
                            type='text'
                            value={calendarEvent.meeting_link}
                            disabled={true}
                          />
                        </LinkContainer>

                        <Button
                          type='default'
                          text={t('CalendarEventModal::Join meeting')}
                          style={{ marginTop: 8 }}
                          onClick={this.onJoinMeetingClick}
                        />
                      </InputWithBackground>
                    </ModalSectionContent>
                  </ModalSection>}

                  <ModalSection>
                    <ModalSectionIcon>
                      <Icon icon='people' />
                    </ModalSectionIcon>

                    <ModalSectionContent>
                      <InputWithBackground>
                        <ResourceCreatablePowerSelect
                          type='contact_with_email'
                          params={attendeeParams}
                          value={[]}
                          onChange={this.onAttendeeChange}
                          placeholder={t('CalendarEventModal::Add attendees')}
                          isClearable={true}
                          isDisabled={Boolean(readOnly)}
                        />
                      </InputWithBackground>

                      {calendarEvent?.attendees?.length > 0 && <AttendeeItems>
                        {calendarEvent?.attendees?.map((attendee, index) => {
                          if (attendee._destroy) return null

                          return (
                            <AttendeeItem
                              attendee={attendee}
                              onEditClick={() => this.onAttendeeEditClick(index)}
                              onStatusChange={(status) => this.onAttendeeStatusChange(index, status)}
                              onRemoveClick={() => this.onAttendeeRemoveClick(index)}
                            />
                          )
                        })}
                      </AttendeeItems>}
                    </ModalSectionContent>
                  </ModalSection>

                  <ModalSection>
                    <ModalSectionIcon>
                      <Icon icon='map-marker' />
                    </ModalSectionIcon>

                    <ModalSectionContent>
                      <InputWithBackground>
                        <LocationInput
                          value={calendarEvent.location}
                          placeholder={t('CalendarEventModal::Add location')}
                          onChange={this.onLocationChange}
                          disabled={Boolean(readOnly)}
                        />
                      </InputWithBackground>

                      {location?.length > 0 && <MapContainer style={{
                        marginTop: Style.spacing.x1,
                        borderWidth: 1,
                        borderColor: Style.color.border,
                        borderStyle: 'solid'
                      }}>
                        <Map location={location} />
                      </MapContainer>}
                    </ModalSectionContent>
                  </ModalSection>

                  <ModalSection>
                    <ModalSectionIcon>
                      <Icon icon='bell' />
                    </ModalSectionIcon>

                    <ModalSectionContent>
                      {calendarEvent.reminders && <Notifications>
                        {calendarEvent.reminders.map((notification, index) => {
                          const notificationUnitOptions = [
                            { label: t(`CalendarEventReminderUnit::${CalendarEventReminderUnit.MINUTE}`, { count: notification.interval }), value: CalendarEventReminderUnit.MINUTE },
                            { label: t(`CalendarEventReminderUnit::${CalendarEventReminderUnit.HOUR}`, { count: notification.interval }), value: CalendarEventReminderUnit.HOUR },
                            { label: t(`CalendarEventReminderUnit::${CalendarEventReminderUnit.DAY}`, { count: notification.interval }), value: CalendarEventReminderUnit.DAY },
                            { label: t(`CalendarEventReminderUnit::${CalendarEventReminderUnit.WEEK}`, { count: notification.interval }), value: CalendarEventReminderUnit.WEEK },
                          ]

                          return (
                            <NotificationItem key={index}>
                              <InputWithBackground>
                                <select value={notification.type} onChange={(e) => this.onNotificationItemTypeChange(index, e)} disabled={Boolean(readOnly)}>
                                  {notificationTypeOptions.map(option => <option key={option.value} label={option.label} value={option.value} />)}
                                </select>
                              </InputWithBackground>

                              <InputWithBackground>
                                <input
                                  type='number'
                                  value={notification.interval}
                                  min={0}
                                  onChange={(e) => this.onNotificationItemIntervalChange(index, e)}
                                  disabled={Boolean(readOnly)}
                                />
                              </InputWithBackground>

                              <InputWithBackground>
                                <select value={notification.interval_unit} onChange={(e) => this.onNotificationItemUnitChange(index, e)} disabled={Boolean(readOnly)}>
                                  {notificationUnitOptions.map(option => <option key={option.value} label={option.label} value={option.value} />)}
                                </select>
                              </InputWithBackground>

                              {!readOnly && <ItemRemove onClick={() => this.onNotificationItemRemoveClick(index)}>
                                <Icon icon='close' />
                              </ItemRemove>}
                            </NotificationItem>
                          )
                        })}

                      </Notifications>}

                      {!readOnly && <AddItem onClick={this.onAddNotificationItemClick}>
                        <span style={{ marginRight: 8 }}>
                          <Icon icon='plus' />
                        </span>
                        {t('CalendarEventModal::Add reminder')}
                      </AddItem>}
                    </ModalSectionContent>
                  </ModalSection>

                  <ModalSection>
                    <ModalSectionIcon>
                      <Icon icon='align-justify' />
                    </ModalSectionIcon>

                    <ModalSectionContent>
                      <InputWithBackground>
                        <EditorContainer>
                          <Editor
                            model={calendarEvent.description}
                            onModelChange={this.onDescriptionChange}
                            config={{
                              ...CALENDAR_EVENT_EDITOR_CONFIG,
                              placeholderText: t('CalendarEventModal::Add a more detailed description'),
                              heightMin: 120,
                              heightMax: 265,
                            }}
                          />
                        </EditorContainer>
                      </InputWithBackground>
                    </ModalSectionContent>
                  </ModalSection>

                  <input type='submit' style={{ display: 'none' }} />
                </form>
              </ModalContent>
            </ModalMiddle>

            <ModalFooter>
              <div className='modal-footer'>
                <div className='modal-footer-actions'>
                  <div key='main-action' className='popover-wrapper'>
                    <TooltipError
                      errors={errors}
                      onDismiss={this.onErrorsDismiss}
                    />
                    <Button
                      text={t('CalendarEventModal::Save')}
                      type='success'
                      onClick={this.onFormSubmit}
                      isLoading={isSubmitting}
                      disabled={Boolean(readOnly)}
                    />
                  </div>
                  <ModalActionFollowUp
                    onFollowUpActionChange={this.onFollowUpActionChange}
                  />
                </div>
                <div style={{ marginLeft: 8 }}>
                  {calendarEvent?.id && <Button
                    text={t('CalendarEventModal::Delete')}
                    type='danger'
                    onClick={this.onCalendarEventDeleteClick}
                    isLoading={isDeleting}
                    disabled={Boolean(readOnly)}
                  />}
                </div>
              </div>
            </ModalFooter>
          </Content>

          <Sidebar>
            <CalendarHeader>
              <NavigateButtonLeft onClick={this.onCalendarNavigateBack} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="m10.75 8.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></NavigateButtonLeft>
              <CalendarDay>
                <span>{startMoment.format('ddd')}</span>
                <span>{startMoment.format('MMM D')}</span>
              </CalendarDay>
              <NavigateButtonRight onClick={this.onCalendarNavigateForward} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="40" width="40" aria-hidden="true" ><path d="m10.75 8.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></NavigateButtonRight>
            </CalendarHeader>

            {/* @ts-ignore */}
            <DnDCalendar
              localizer={localizer}
              views={['day']}
              defaultView='day'
              events={resultEvents}
              getNow={() => moment().toDate()}
              startAccessor="start"
              endAccessor="end"
              selectable={true}
              onEventDrop={this.onCalendarEventDrop}
              onEventResize={this.onCalendarEventResize}
              date={startMoment.toDate()}
              formats={{
                eventTimeRangeFormat: (range, culture, localizer) => localizer.format(range.start, 'H:mm A', culture),
              }}
              draggableAccessor={this.isDraggable}
              scrollToTime={moment().toDate()}
              timeslots={4}
              step={15}
              eventPropGetter={CalendarEventHelper.eventPropGetter}
              components={{
                toolbar: (props) => <CalendarToolbar {...props} hide={true} />,
                event: (props: EventProps<CalendarViewEvent>) => <CalendarEventView {...props} view='day' />,
              }}
              // @ts-ignore
              allDayAccessor={(event => Boolean(event.all_day))}
            />
          </Sidebar>
        </>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      calendarEventModal: {
        calendarEvent,
        contactDisabled,
        projectDisabled,
        readOnly,
        onSubmit,
        onDelete,
      }
    },
    timer: {
      entry
    },
  } = state

  return {
    currentUser: currentUser,
    calendarEvent: calendarEvent,
    contactDisabled: contactDisabled,
    projectDisabled: projectDisabled,
    readOnly: readOnly,
    onSubmit: onSubmit,
    onDelete: onDelete,
  }
}

const mapDispatchToProps: IDispatchToProps = {
  close: closeCalendarEventModal,
  showConfirmModal: showConfirmModal,
  showContactModal: showContactModal,
  showProjectModal: showProjectModal,
  showTimeEntryModal: showTimeEntryModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CalendarEventModal))
