import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import randomColor from '../../utilities/randomColor'
import Button from '../Button/Button'
import Icon from '../Icons/Icon'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverBack from './TaskPopoverBack'
import TaskPopoverClose from './TaskPopoverClose'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverFormActions from './TaskPopoverFormActions'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'
import SwatchPicker from '../Swatch/SwatchPicker'
import Fuse from 'fuse.js'
import { BoardLabel } from '../../types'


const Labels = styled.div`
	display: flex;
	flex-direction: column;
`

const LabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 4px;
`

const Label = styled.div<{ boxShadowColor: string }>`
	position: relative;
	background-color: #b3bac5;
	border-radius: 4px;
	color: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-right: 4px;
	max-width: 100%;
	overflow: hidden;
	padding: 4px 6px;
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
	font-weight: 700;
	min-height: 32px;
	padding: 6px 12px;
	position: relative;
	transition: padding 85ms,margin 85ms,box-shadow 85ms;
	font-size: 14px;
	width: 100%;
	padding-right: 28px;
	display: block;
	text-overflow: ellipsis;

	&:hover {
		margin-left: 8px;
		box-shadow: -8px 0 ${props => props.boxShadowColor};
	}
`

const LabelChecked = styled.div`
	position: absolute;
	right: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	top: 50%;
	transform: translateY(-50%);

	svg, i {
		color: white;
		fill: white;
		font-size: 13px;
	}
`

const LabelEdit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 32px;
	width: 32px;
	min-width: 32px;	
	padding: 6px 12px;
	border-radius: 4px;
	max-width: 100%;
	overflow: hidden;
	padding: 4px 6px;
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	transition: background-color 250ms ease-in-out;
	cursor: pointer;

	&:hover {
		background-color: rgba(9,30,66,.08);
	}

	svg, i {
		color: #42526e;
		fill: #42526e;
		font-size: 14px;
	}
`

interface IProps {
	activator: JSX.Element
	labels: BoardLabel[]
	labelIds: string[]
	onToggleLabel: (label: BoardLabel) => void
	onSubmit: (label: BoardLabel) => void
	onDelete: (label: BoardLabel) => void
}

const LabelsPopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator, labels, labelIds } = props

	const fuse = new Fuse<BoardLabel>(labels, {
		shouldSort: false,
		threshold: 0.3,
		location: 0,
		distance: 100,
		minMatchCharLength: 1,
		keys: ['name'],
	});

	const [search, setSearch] = React.useState('')
	const [searchedLabels, setSearchedLabels] = React.useState<BoardLabel[]>([])
	const [formLabel, setFormLabel] = React.useState<BoardLabel | null>(null)
	const [popoverActive, setPopoverActive] = React.useState(false)
	let title = t('TaskModal::Labels')

	const onTogglePopover = () => {
		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
		setFormLabel(null)
		setSearchedLabels([])
	}

	const onSearchChange = (e) => {
		const searchValue = e.currentTarget.value
		const searchedLabels = fuse.search(e.currentTarget.value).map(result => result.item)

		setSearch(searchValue)
		setSearchedLabels(searchedLabels)
	}

	const onToggleLabel = (label: BoardLabel) => {
		props.onToggleLabel(label)
	}

	const onEditLabelClick = (label: BoardLabel) => {
		setFormLabel(label)
	}

	const onNewLabelClick = () => {
		setFormLabel({ name: '', color: randomColor({}) })
	}

	const onNameChange = (e) => {
		setFormLabel({ ...formLabel, name: e.currentTarget.value })
	}

	const onColorChange = (color) => {
		setFormLabel({ ...formLabel, color: color })
	}

	const onFormSubmit = (e) => {
		e.preventDefault()

		// Submit form to parent
		props.onSubmit(formLabel)

		// Reset form
		setFormLabel(null)
	}

	const onFormCancel = () => {
		setFormLabel(null)
	}

	const onDeleteClick = (label: BoardLabel) => {
		// Submit delete to parent
		props.onDelete(label)

		// Reset form
		setFormLabel(null)
	}

	const getLabelBoxShadow = (color: string, amount) => {
		return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
	}

	if (formLabel) {
		if (formLabel.id) {
			title = t('TaskModal::Change label')
		} else {
			title = t('TaskModal::Create label')
		}
	}


	return (
		<Popover
			active={popoverActive}
			activator={<div style={{ minHeight: 32 }} onClick={onTogglePopover}>
				{activator}
			</div>}
			onClose={onPopoverClose}
			placement='bottom-start'
		>
			<TaskPopoverContainer>
				<TaskPopoverHeader>
					{formLabel && <TaskPopoverBack onClick={onFormCancel} />}
					<TaskPopoverTitle>{title}</TaskPopoverTitle>
					{formLabel && <TaskPopoverClose onClick={onPopoverClose} />}
				</TaskPopoverHeader>
				<TaskPopoverContent>
					{!formLabel && <>
						<input
							type='text'
							placeholder={t('TaskModal::Search Labels')}
							style={{ marginBottom: 12 }}
							value={search}
							onChange={onSearchChange}
						/>

						<Labels>
							{search !== '' && searchedLabels.map(label => (
								<LabelContainer
									key={label.id}
								>
									<Label
										style={{ backgroundColor: label.color }}
										boxShadowColor={getLabelBoxShadow(label.color, -10)}
										onClick={() => onToggleLabel(label)}
									>
										{label.name}
										{labelIds.includes(label.id) && <LabelChecked><Icon icon='check' /></LabelChecked>}
									</Label>
									<LabelEdit onClick={() => onEditLabelClick(label)}>
										<Icon icon='pen' />
									</LabelEdit>
								</LabelContainer>))}

							{search === '' && labels.map(label => (
								<LabelContainer
									key={label.id}
								>
									<Label

										style={{ backgroundColor: label.color }}
										boxShadowColor={getLabelBoxShadow(label.color, -10)}
										onClick={() => onToggleLabel(label)}
									>
										{label.name}
										{labelIds.includes(label.id) && <LabelChecked><Icon icon='check' /></LabelChecked>}
									</Label>
									<LabelEdit onClick={() => onEditLabelClick(label)}>
										<Icon icon='pen' />
									</LabelEdit>
								</LabelContainer>))}
						</Labels>

						<TaskButton
							onClick={onNewLabelClick}
							style={{ marginTop: 12 }}
							center
						>
							{t('TaskModal::Create a new label')}
						</TaskButton>
					</>}
					{formLabel && <form onSubmit={onFormSubmit}>
						<div className='grid'>
							<div className='grid-cell with-8col'>
								<div className='form-item'>
									<label>{t('TaskModal::Name')}</label>
									<input
										type='text'
										placeholder={t('TaskModal::My task')}
										value={formLabel.name}
										onChange={onNameChange}
									/>
								</div>
							</div>

							<div className='grid-cell with-4col'>
								<div className='form-item'>
									<label>{t('TaskModal::Color')}</label>
									<SwatchPicker
										color={formLabel.color}
										onChange={onColorChange}
									/>
								</div>
							</div>
						</div>


						<TaskPopoverFormActions>
							<div>
								<Button
									type='success'
									text={formLabel.id ? t('TaskModal::Update') : t('TaskModal::Create')}
									onClick={onFormSubmit}
								/>
							</div>

							{formLabel.id && <div>
								<Button
									type='danger'
									text={t('TaskModal::Delete')}
									onClick={() => onDeleteClick(formLabel)}
								/>
							</div>}
						</TaskPopoverFormActions>
						<input type='submit' style={{ display: 'none' }} />
					</form>}
				</TaskPopoverContent>
			</TaskPopoverContainer>
		</Popover >
	)
}

export default LabelsPopover