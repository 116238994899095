import * as React from 'react'
import { closePaymentModal, showPaymentInitiationModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Button from '../Button/Button'
import { PaymentsController } from '../../controllers'
import { CurrentUser, DisplayableError, RemittanceInformationType, Payment, PaymentInitiationStatus, Account } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'
import PowerSelect from '../Form/PowerSelect'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import NumberFormatter from '../../utilities/NumberFormatter'
import MaskedInput from 'react-text-mask'
import Switch from '../Form/Switch'
import MoneyInput from '../Form/MoneyInput'
import OgmHelper from '../../helpers/OgmHelper'
import IbanHelper from '../../helpers/IbanHelper'
import ModalFooterActionIcons from './Parts/ModalFooterActionIcons'
import ModalFooterActionIcon from './Parts/ModalFooterAction'
import moment from '../../utilities/Moment'
import DateInput from '../Form/DateInput'
import Tooltip from '../Tooltips/Tooltip'
import PaymentHelper from '../../helpers/PaymentHelper'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'

interface IStateToProps {
  currentUser: CurrentUser
  payment?: Payment
  onSubmit: (payment: Payment) => void
}

interface IDispatchToProps {
  close: typeof closePaymentModal
  showPaymentInitiationModal: typeof showPaymentInitiationModal
}

type IProps = IDispatchToProps & IStateToProps

interface IState {
  didInitialLoad: boolean
  payment: Payment
  currencies: { label: string, value: string }[]
  errors: DisplayableError[]
}

const PaymentModal = (props: IProps) => {
  const { t } = useTranslation()
  const { currentUser } = props
  const { setting } = currentUser.workspace
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    payment: null,
    currencies: [],
    errors: [],
  })
  const { didInitialLoad, payment, currencies, errors } = state

  React.useEffect(() => {
    getForm().catch(console.error)
  }, [])

  const getForm = async () => {
    try {
      const { payment, currencies } = await PaymentsController.getForm({ id: props.payment ? props.payment.id : null })

      setState({
        ...state,
        payment: {
          ...payment,
          ...props.payment,
        },
        currencies: currencies,
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onNameChange = (e) => {
    const newName = e.currentTarget.value

    setState({ ...state, payment: { ...payment, creditor_name: newName } })
  }

  const onAccountNumberChange = (e) => {
    const newAccountNumber = e.currentTarget.value

    setState({
      ...state,
      payment: {
        ...payment,
        creditor_account: newAccountNumber
      }
    })
  }

  const onAmountChange = (amount: number) => {
    setState({ ...state, payment: { ...payment, amount: amount } })
  }

  const onSuggestedExecutionDate = (value) => {
    const suggestedExecutionDate = moment(value);

    setState({
      ...state, payment: {
        ...payment,
        suggested_execution_date: suggestedExecutionDate.isValid() ? suggestedExecutionDate.format('YYYY-MM-DD') : null
      }
    })
  }

  const onCurrencyChange = (currencyOption) => {
    setState({
      ...state,
      payment: {
        ...payment,
        currency: currencyOption ? currencyOption.value : null,
      }
    })
  }

  const onRemittanceInformationChange = (e) => {
    const remittanceInformation = e.currentTarget.value

    if (payment.remittance_information_type === RemittanceInformationType.STRUCTURED) {
      setState({ ...state, payment: { ...payment, remittance_information: OgmHelper.unformat(remittanceInformation) } })
    } else {
      setState({ ...state, payment: { ...payment, remittance_information: remittanceInformation } })
    }
  }

  const onStructuredCommunicationToggle = () => {
    switch (payment.remittance_information_type) {
      case RemittanceInformationType.UNSTRUCTURED:
        setState({
          ...state,
          payment: {
            ...payment,
            remittance_information: '',
            remittance_information_type: RemittanceInformationType.STRUCTURED
          }
        })
        break
      case RemittanceInformationType.STRUCTURED:
        setState({
          ...state,
          payment: {
            ...payment,
            remittance_information: '',
            remittance_information_type: RemittanceInformationType.UNSTRUCTURED
          }
        })
        break
    }
  }

  const onNotesChange = (e) => {
    const notes = e.currentTarget.value

    setState({ ...state, payment: { ...payment, notes: notes } })
  }

  const onCloseClick = () => {
    props.close()
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
      let response = null
      if (payment.id) {
        response = await PaymentsController.update(payment)
      } else {
        response = await PaymentsController.create(payment)
      }

      if (response.errors) {
        setState({ ...state, errors: response.errors })
      } else {
        props.onSubmit(response)
        props.close()
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  const onPaymentScheduledInfoClick = () => {
    props.showPaymentInitiationModal({
      paymentInitiation: { id: payment.payment_initiation.id }
    })
    props.close()
  }

  const onErrorsDismiss = () => {
    setState({ ...state, errors: [] })
  }

  const selectedCurrencyOption = currencies.find(option => option.value === payment.currency)

  return (
    <ModalWindow>
      <ModalHeader
        title={payment?.id ? t('PaymentModal::Update payment') : t('PaymentModal::Create payment')}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            <div className='grid'>
              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>{t('PaymentModal::Account holder')} <span>*</span></label>
                  <input
                    type='text'
                    placeholder={t('PaymentModal::Name')}
                    onChange={onNameChange}
                    value={payment.creditor_name}
                    disabled={Boolean(payment.payment_initiation)}
                  />
                </div>
              </div>
              <div className='grid-cell with-6col'>
                <div className={`form-item ${payment && payment.creditor_account && payment.creditor_account.length >= 16 && !IbanHelper.validate(payment.creditor_account) ? 'is-danger' : ''}`}>
                  <label>{t('PaymentModal::Account number')} <span>*</span></label>
                  <input
                    type='text'
                    placeholder='BE71 0961 2345 6769'
                    onChange={onAccountNumberChange}
                    value={payment.creditor_account}
                    disabled={Boolean(payment.payment_initiation)}
                  />
                </div>
              </div>

              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>{t('PaymentModal::Amount')} <span>*</span></label>
                  <MoneyInput
                    name='rate'
                    currency={payment?.currency || 'EUR'}
                    numberFormat={setting.number_format}
                    placeholderValue={t('PaymentModal::{{amount}}', { amount: NumberFormatter.formatCurrency(payment.currency, setting.number_format, 0) })}
                    value={payment?.amount}
                    onBlur={onAmountChange}
                    disabled={Boolean(payment.payment_initiation)}
                  />
                </div>
              </div>

              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>{t('PaymentModal::Currency')}</label>
                  <PowerSelect
                    options={currencies}
                    value={selectedCurrencyOption}
                    onChange={onCurrencyChange}
                    theme={ReactSelectTheme}
                    isDisabled={Boolean(payment.payment_initiation)}
                  />
                </div>
              </div>

              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>
                    {t('PaymentModal::Execution date (optional)')}
                    <Tooltip
                      content={t('PaymentInitiationModal::When paying in bulk the ideal execution date will be calculated based on all the payments in the bulk payment.')}
                      containerStyle={{ marginLeft: 8 }}
                    />
                  </label>
                  <DateInput
                    name='suggested_execution_date'
                    dateFormat={setting.date_format}
                    timeFormat={false}
                    initialValue={moment(payment.suggested_execution_date)}
                    inputProps={{ placeholder: setting.date_format, disabled: Boolean(payment.payment_initiation) }}
                    onChange={onSuggestedExecutionDate}
                    closeOnSelect
                  />
                </div>
              </div>

              <div className='grid-cell with-12col'>
                <div className={`form-item ${payment && payment.remittance_information.length === 12 && !OgmHelper.validate(payment.remittance_information) ? 'is-danger' : ''}`}>
                  <label>{payment?.remittance_information_type === RemittanceInformationType.STRUCTURED ? t('PaymentModal::Structured communication') : t('PaymentModal::Communication')}</label>
                  {payment?.remittance_information_type === RemittanceInformationType.STRUCTURED && <>
                    <MaskedInput
                      type='text'
                      placeholder='+++001/8094/26074+++'
                      guide={true}
                      showMask={true}
                      mask={PaymentHelper.getPaymentReferenceMask(RemittanceInformationType.STRUCTURED)}
                      onChange={onRemittanceInformationChange}
                      value={payment.remittance_information}
                      style={{ textAlign: 'center' }}
                      disabled={Boolean(payment.payment_initiation)}
                    />
                  </>}

                  {payment?.remittance_information_type === RemittanceInformationType.UNSTRUCTURED && <textarea
                    placeholder={t('PaymentModal::Communication')}
                    onChange={onRemittanceInformationChange}
                    value={payment.remittance_information}
                    disabled={Boolean(payment.payment_initiation)}
                  />}
                  {!Boolean(payment.payment_initiation) && <div style={{ marginTop: 8 }}>
                    <Switch
                      name='structured_communication'
                      label={t('PaymentModal::Structured communication')}
                      onClick={onStructuredCommunicationToggle}
                      checked={payment?.remittance_information_type === RemittanceInformationType.STRUCTURED}
                    />
                  </div>}
                </div>
              </div>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('PaymentModal::Notes')}</label>
                  <textarea
                    placeholder={t('PaymentModal::Add notes for this payment (only visible to you)')}
                    onChange={onNotesChange}
                    value={payment.notes}
                  />
                </div>
              </div>
            </div>

            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <ModalFooterActionIcons>
            {payment?.payment_initiation && <>
              <ModalFooterActionIcon
                icon='file-contract'
                active={payment.payment_initiation.status === PaymentInitiationStatus.SIGNED}
                onClick={onPaymentScheduledInfoClick}
                tooltip={payment.payment_initiation.status === PaymentInitiationStatus.SIGNED ? t('PaymentInitiationModal::Payment signed and scheduled') : t('PaymentInitiationModal::Payment needs your signature')}
              />
            </>}
          </ModalFooterActionIcons>
          <div key='main-action' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={onErrorsDismiss}
            />
            <Button
              type='success'
              text={payment?.id ? t('PaymentModal::Update payment') : t('PaymentModal::Create payment')}
              onClick={onFormSubmit}
            />
          </div>
        </div>
      </ModalMiddle>}
    </ModalWindow >
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      paymentModal: {
        payment,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    payment: payment,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closePaymentModal()),
    showPaymentInitiationModal: (options) => dispatch(showPaymentInitiationModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal)