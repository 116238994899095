import * as React from 'react'
import { useTranslation } from 'react-i18next'
import MaskedInput from 'react-text-mask'
import styled from 'styled-components'
import Switch from './Switch'
import PaymentHelper from '../../helpers/PaymentHelper'
import { RemittanceInformationType } from '../../types'
import Icon from '../Icons/Icon'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  a {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    height: 38px;

    &:hover {
      transform: none !important;
    }
  }
`

const TypeWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	line-height: 0;
  font-size: 12px;
  padding-top: 8px;
  color: #858C99;
`


interface IProps {
  value: string
  type: RemittanceInformationType
  onRemittanceInformationChange: (value: string) => void
  onRemittanceInformationTypeChange: (remittanceInformationType: RemittanceInformationType, remittanceInformation: string) => void
}

const RemittanceInformationInput = (props: IProps) => {
  const { t } = useTranslation()
  const { value, type } = props
  const referenceInput = React.useRef<MaskedInput>()

  React.useEffect(() => {
    // @ts-ignore
    if (referenceInput?.current?.inputElement?.value && type === RemittanceInformationType.UNSTRUCTURED) referenceInput.current.inputElement.value = value
  }, [type])

  const onRemittanceInformationChange = (e) => {
    const value = PaymentHelper.unformatPaymentReference(type, e.currentTarget.value)

    props.onRemittanceInformationChange(value)
  }

  const onRemittanceInformationTypeChange = () => {
    const newRemittanceInformationType = type === RemittanceInformationType.STRUCTURED ? RemittanceInformationType.UNSTRUCTURED : RemittanceInformationType.STRUCTURED

    props.onRemittanceInformationTypeChange(newRemittanceInformationType, '')
  }

  return (
    <Container>
      <Wrapper>
        <MaskedInput
          ref={referenceInput}
          type='text'
          value={value}
          guide={true}
          showMask={true}
          mask={PaymentHelper.getPaymentReferenceMask(type)}
          placeholder={(type === RemittanceInformationType.UNSTRUCTURED) ? t('RemittanceInformationInput::Remittance information') : '+++ 085 / 1927 / 54115 +++'}
          placeholderChar='_'
          onChange={onRemittanceInformationChange}
        />
      </Wrapper >
      <TypeWrapper>
        {t('RemittanceInformationInput::Structured communication')}
        <Switch
          name='identifier_type'
          onClick={onRemittanceInformationTypeChange}
          checked={type === RemittanceInformationType.STRUCTURED}
        />
      </TypeWrapper>
    </Container>
  )
}

export default RemittanceInformationInput