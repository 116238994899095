import * as React from 'react'
import { closeConfirmModal, closeCustomComponentModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalContent from './Parts/ModalContent'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import showdown from '../../utilities/showdown'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CurrentUser } from '../../types'



const CustomModalWindow = styled(ModalWindow)`
	max-width: 500px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}){
		max-width: initial;
	}
`

const Description = styled.div`
  font-size: 16px;
`

const Action = styled.div<ActionProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	min-height: 55px;
	flex: 1;
	background: #F7F6FB;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 250ms linear;

	${props => props.primary && css`
		background-color: ${Style.color.brandSuccess};
		color: #FFFF;
		font-weight: 600;

		&:hover {
			background-color: #55e200;
		}
	`}

	${props => props.destructive && css`
		background-color: ${Style.color.brandDanger};
		color: #FFFF;
		font-weight: 600;

		&:hover {
			background-color: #ff5a5a;
		}
	`}
`

const Actions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	${Action}:first-child {
		border-bottom-left-radius: ${Style.variables.baseBorderRadius};
	}

	${Action}:last-child {
		border-bottom-right-radius: ${Style.variables.baseBorderRadius};
	}
`

interface ActionProps {
  primary?: boolean
  destructive?: boolean
}

export interface ConfirmModalAction {
  label: string
  isDestructive?: boolean
}

interface IStateToProps {
  title: string
  component?: JSX.Element
}

interface IDispatchToProps {
  close: typeof closeCustomComponentModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

class CustomComponentModal extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  render() {
    const {
      title,
      component,
      t
    } = this.props

    return (
      <CustomModalWindow>
        <ModalHeader title={title} onCloseClick={close} />
        <ModalMiddle>
          <ModalContent>
            {component}
          </ModalContent>
        </ModalMiddle>
      </CustomModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    modals: {
      customComponentModal: {
        title,
        component
      }
    }
  } = state

  return {
    title: title,
    component: component
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeCustomComponentModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomComponentModal))