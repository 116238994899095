import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Popover from '../Popover/Popover'
import TaskPopoverClose from './TaskPopoverClose'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'

interface IProps {
  activator: JSX.Element
  onSubmit: (name: string) => void
}

const CustomFieldsPopover = (props: IProps) => {
  const { t } = useTranslation()
  const { activator } = props

  const [popoverActive, setPopoverActive] = React.useState(false)

  const onTogglePopover = () => {
    setPopoverActive(!popoverActive)
  }

  const onPopoverClose = () => {
    setPopoverActive(false)
  }

  return (
    <Popover
      active={popoverActive}
      activator={<div onClick={onTogglePopover}>
        {activator}
      </div>}
      onClose={onPopoverClose}
      placement='bottom-start'
    >
      <TaskPopoverContainer>
        <TaskPopoverHeader>
          <div />
          <TaskPopoverTitle>{t('TaskModal::Custom fields')}</TaskPopoverTitle>
          <TaskPopoverClose onClick={onPopoverClose} />
        </TaskPopoverHeader>
        <TaskPopoverContent>
          <h1>Insert custom field items</h1>
        </TaskPopoverContent>
      </TaskPopoverContainer>
    </Popover >
  )
}

export default CustomFieldsPopover