import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const Container = styled.div<{ center?: boolean }>`
	background: #fff;
	border: 1px solid #c4cdd5;
	/* box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15); */
	transition: box-shadow .2s;
	padding: 8px 16px;
	border-radius: 3px;

  ${props => props.center && css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`

const Title = styled.div`
	display: inline-block;
	border-bottom: 2px dotted #c4cdd5;
	font-size: 15px;
	color: #637381;
	font-weight: 500;

	a {
		color: #637381;
	}
`

const Placeholder = styled.div`
	position: relative;
	height: 28px;
	max-width: 120px;
	width: 100%;
	margin-top: 8px;
	will-change: opacity;
	animation: ${Style.animations.shimmerAnimation} .8s linear infinite alternate;

	&::before {
		content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-color: #dfe3e8;
    border-radius: 3px;
	}
`

const Label = styled.div<{ big?: boolean }>`
	margin-top: 6px;
	font-size: 20px;
	font-weight: 600;
	color: black;

  ${(props) => props.big && css`
    font-size: 24px;
    line-height: 30px;
  `}
`

const CaptionLabel = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: #637381;
`

export interface IProps {
  title: string | JSX.Element
  label?: string | JSX.Element
  labelBig?: boolean
  captionLabel?: string | JSX.Element
  isLoading?: boolean
  center?: boolean
}

export default class SummaryItem extends React.PureComponent<IProps> {
  render(): JSX.Element {
    const {
      title,
      label,
      labelBig,
      captionLabel,
      isLoading,
      center,
    } = this.props

    return (
      <Container center={center}>
        <Title>
          {title}
        </Title>

        {isLoading && <>
          <Placeholder />
          <Placeholder style={{ maxWidth: 60 }} />
        </>}

        {!isLoading && <>
          {label && <Label big={labelBig}>
            {label}
          </Label>}

          {captionLabel && <CaptionLabel>
            {captionLabel}
          </CaptionLabel>}
        </>}
      </Container>
    )
  }
}