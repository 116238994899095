import * as React from 'react'
import { closeExportProductsModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import { TimeEntriesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Moment } from 'moment';
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import { CurrentUser, TimeEntryExportType } from '../../types'

interface IStateToProps {
  currentUser: CurrentUser
  contactId?: string
  contactDisabled?: boolean
  projectId?: string
  projectDisabled?: boolean
  start?: Moment
  end?: Moment
  type?: TimeEntryExportType
  onSubmit?: () => void
}

interface IDispatchToProps {
  close: typeof closeExportProductsModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  errors: any
}

class ExportProductsModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onProductsExportModalClose = this.onProductsExportModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { start, end, contactId, projectId, type } = this.props

    try {
      const form = await TimeEntriesController.getExportForm()

      this.setState({ didInitialLoad: true, });
    } catch (ex) {
      console.error(ex)
    }
  }

  onFormSubmit(e) {
    e.preventDefault();
  }

  onProductsExportModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  render() {
    const {
      currentUser: { workspace: { setting } },
      t
    } = this.props
    const { didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={t('ProductsExportModal::Export products')}
          onCloseClick={this.onProductsExportModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit} style={{ width: '100%' }}>
              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('ProductsExportModal::Export')}
                </a>
              </div>
            </div>
          </div>

        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      timeEntryExportModal: {
        contactId,
        contactDisabled,
        projectId,
        projectDisabled,
        start,
        end,
        type,
        onSubmit,
      }
    }
  } = state

  return {
    contactId,
    contactDisabled,
    projectId,
    projectDisabled,
    start: start,
    end: end,
    type: type,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeExportProductsModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExportProductsModal))