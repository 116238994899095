import * as React from 'react'
import styled from 'styled-components'


interface IProps {
  filename: string
  source: string
  contentType: string
}

const Container = styled.div`
  height: 80vh;
  width: 100%;
  min-width: 100px;
  min-height: 100px;
  background: #f4f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.2vw;
  text-transform: uppercase;
`

const UnknownPreview = (props: IProps) => {
  const { filename } = props

  const extension = filename.split('.').pop()

  return (
    <Container>
      .{extension}
    </Container>
  )
}

export default UnknownPreview