import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:  16px;
  height: 60px;
  padding: 12px;
  border: 1px solid ${Style.color.border};
  border-radius: ${Style.variables.baseBorderRadius};
  cursor: pointer;

  &:hover {
    border-color: ${Style.color.brandPrimary};
    border-width: 2px;
  }
`

const Logo = styled.img`
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`

const Description = styled.div`
  color: #919191;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

interface IProps {
  logo: any
  title: string
  description: string
  onClick: () => void
}
const EmailIntegration = (props: IProps) => {
  const { logo, title, description, onClick } = props
  return (
    <Container onClick={onClick}>
      <Logo src={logo} />
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Content>
    </Container>
  )
}

export default EmailIntegration