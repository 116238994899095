import * as React from 'react'
import { closeExpenseCategoryModal, showConfirmModal, showExpenseCategoryModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Notification from '../../utilities/Notification'
import { ExpenseCategoriesController, ExpensesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Button from '../Button/Button';
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import ModalWindowPart from './Parts/ModalWindow'
import SwatchPicker from '../Swatch/SwatchPicker'
import PowerSelect from '../Form/PowerSelect'
import randomColor from '../../utilities/randomColor'
import styled from 'styled-components'
import { Style } from '../../styles'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import { CurrentUser, ExpenseCategory } from '../../types'

const ModalWindow = styled(ModalWindowPart)`
  min-width: 420px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    min-width: initial;
  }
`

const DeleteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const DeleteLink = styled.a`
  justify-self: center;
  color: ${Style.color.brandDanger};
`

interface IStateToProps {
  currentUser: CurrentUser
  category?: ExpenseCategory
  onSubmit?: (category: ExpenseCategory, deleted?: boolean) => void
}

interface IDispatchToProps {
  showConfirmModal: typeof showConfirmModal
  close: typeof closeExpenseCategoryModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  category: ExpenseCategory | null
  categories: ExpenseCategory[]
  errors: any
  isSubmitting: boolean
}

class ExpenseCategoryModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      category: null,
      categories: [],
      errors: {},
      isSubmitting: false,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNameChange = this.onNameChange.bind(this);
    this.onColorChange = this.onColorChange.bind(this)
    this.onParentCategoryChange = this.onParentCategoryChange.bind(this)
    this.onCategoryDeleteClick = this.onCategoryDeleteClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onExpenseCategoryModalClose = this.onExpenseCategoryModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  fetchForm() {
    const { category: propCategory } = this.props

    ExpenseCategoriesController
      .getForm(propCategory.id)
      .then(response => {
        const { category, categories } = response

        this.setState({
          category: {
            ...category,
            ...propCategory,
          },
          categories: categories,
          didInitialLoad: true
        })
      })
      .catch(console.error)
  }

  onNameChange(e) {
    const { category } = this.state;

    const newName = e.currentTarget.value;

    this.setState({
      category: {
        ...category,
        name: newName
      }
    });
  }

  onColorChange(color: string) {
    const { category } = this.state;

    this.setState({
      category: {
        ...category,
        color: color,
      }
    });
  }

  onParentCategoryChange(option) {
    const { category, categories } = this.state;

    let color = category.color

    if (option) {
      const parentCategory = categories.find(c => c.id === option.value)

      if (parentCategory) {
        color = parentCategory.color
      }
    } else {
      color = randomColor()
    }

    this.setState({
      category: {
        ...category,
        parent_id: option ? option.value : null,
        color: color,
      },
    });
  }

  async onCategoryDeleteClick() {
    const { t, onSubmit, close } = this.props
    const { category } = this.state

    requestAnimationFrame(() => {
      this.props.showConfirmModal({
        title: t('ExpenseCategoryModal::Delete expense category'),
        description: t('ExpenseCategoryModal::You are about to delete this expense category. When deleting an expense category you are also deleting all associated subcategories. Are you sure?'),
        action: { label: t('ExpenseCategoryModal::Delete'), isDestructive: true },
        onConfirm: async () => {
          this.setState({ isSubmitting: true })
          try {
            const response = await ExpenseCategoriesController.delete(category.id)
            const { errors } = response

            if (errors) {
              this.setState({
                errors: errors
              });
              Notification.notifyError(t('ExpenseCategoryModal::Oops something went wrong'))
            }
            else {
              Notification.notifySuccess(t('ExpenseCategoryModal::Category successfully deleted'))
              if (onSubmit) onSubmit(response, true)
              close()
            }
          } catch (ex) {
            console.error(ex)
          } finally {
            this.setState({ isSubmitting: true })
          }
        }
      })
    })
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { category } = this.state;
    const { close, onSubmit, t } = this.props

    this.setState({ isSubmitting: true })

    if (category.id) { // Do update
      ExpenseCategoriesController
        .update(category)
        .then(response => {
          const { errors, error_full_messages } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('ExpenseCategoryModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('ExpenseCategoryModal::Expense category successfully updated'))
            if (onSubmit) onSubmit(response)
            close()
          }

          this.setState({ isSubmitting: false })
        })
        .catch(error => console.error(error))
    }
    else {
      ExpenseCategoriesController
        .create(category)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('ExpenseCategoryModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('ExpenseCategoryModal::Expense category successfully created'))
            if (onSubmit) onSubmit(response)
            close()
          }

          this.setState({ isSubmitting: false })
        })
        .catch(console.error)
    }
  }

  onExpenseCategoryModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderForm() {
    const { t } = this.props
    const { category, categories, didInitialLoad } = this.state

    if (!didInitialLoad) return null

    const categoryOptions = categories.map(category => ({ label: category.name, value: category.id }))
    const selectedCategoryOption = categoryOptions.find(option => option.value === category.parent_id)

    return (
      <>
        <div className='grid'>
          <div className={`grid-cell with-${category.parent_id ? 12 : 9}col`}>
            <div className='form-item'>
              <label>
                {t('ExpenseCategoryModal::Name')}<span>*</span>
              </label>
              <input
                type='text'
                name='name'
                value={category.name}
                onChange={this.onNameChange}
                placeholder={t('ExpenseModal::Name')}
                autoComplete='off'
                required
              />
            </div>
          </div>

          {category.parent_id === null && <div className='grid-cell with-3col'>
            <div className='form-item'>
              <label>{t('ExpenseCategoryModal::Color')}</label>
              <SwatchPicker
                color={category.color}
                onChange={this.onColorChange}
              />
            </div>
          </div>}
        </div>

        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ExpenseCategoryModal::Parent category (optional)')}</label>
              <PowerSelect
                options={categoryOptions}
                value={selectedCategoryOption}
                onChange={this.onParentCategoryChange}
                noOptionsMessage={(value) => t('ExpenseCategoryModal::No category found.')}
                isClearable={true}
                theme={ReactSelectTheme}
              />
            </div>
          </div>
        </div>

        {category.id && <div className='grid'>
          <div className='grid-cell with-12col'>
            <DeleteContainer>
              <DeleteLink href='javascript://' onClick={this.onCategoryDeleteClick}>
                {t('ExpenseCategoryModal::Delete category')}
              </DeleteLink>
            </DeleteContainer>
          </div>
        </div>}
      </>
    )
  }

  render() {
    const { category: { id }, t } = this.props
    const { category, didInitialLoad, errors, isSubmitting } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={id ? t('ExpenseCategoryModal::Edit category') : t('ExpenseCategoryModal::Create category')}
          onCloseClick={this.onExpenseCategoryModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderForm()}

              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />

                <Button
                  type='success'
                  text={t('ExpenseCategoryModal::Save')}
                  isLoading={isSubmitting}
                  onClick={this.onFormSubmit}
                />
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      expenseCategoryModal: {
        category,
        onSubmit,
      }
    }
  } = state

  return {
    category: category,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    close: () => dispatch(closeExpenseCategoryModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExpenseCategoryModal))