import * as React from 'react'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'
import { Style } from '../../../styles'

const TableContainer = styled.div`
  overflow-x: auto;
  overscroll-behavior-x: contain;
	border: 1px solid ${Style.color.border};
	border-radius: ${Style.variables.baseBorderRadius};
	max-height: 250px;
	min-height: 250px;
`

const Table = styled.table`
	position: relative;
	flex: 1;
	max-height: 100%;
	background: white;
	width: 100%;
	border-collapse: collapse;

	input {
		box-shadow: none !important;
	}
`

interface IProps {
	loading?: boolean
	children: React.ReactNode
	onEndReached?: () => void
}


const ResourceModalTable = (props: IProps) => {
	const { onEndReached } = props
	const container = React.useRef<HTMLDivElement>()

	const debouncedScrollChange = useDebouncedCallback(
		(event) => onScrollChange(event), 50
	);

	const onScrollChange = (event) => {
		var node = event.target;
		const endReached = node.scrollHeight - node.scrollTop <= node.clientHeight;

		if (endReached && onEndReached) onEndReached()
	}

	React.useEffect(() => {
		if (container.current) container.current.addEventListener('scroll', debouncedScrollChange)

		return () => {
			if (container.current) container.current.removeEventListener('scroll', debouncedScrollChange)
		}
	}, [])

	return (
		<TableContainer ref={container}>
			<Table>
				{props.children}
			</Table>
		</TableContainer>
	)
}

export default ResourceModalTable