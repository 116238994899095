import * as React from 'react'
import ReactDropzone, { DropzoneProps } from 'react-dropzone'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import Link from '../Forms/Link'
import Icon from '../Icons/Icon'
import prettyBytes from 'pretty-bytes';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #f4f4f8;
	border: 2px dashed ${Style.color.border};
	border-radius: 4px;
	display: flex;
	margin: 18px;
	padding: 12px;
	text-align: center;
	height: 100%;
	cursor: pointer;
`

const IconContainer = styled.div`
	svg {
		width: 40px;
	}
`

const Description = styled.div`
	${Link} {
		color: black;
		font-weight: 500;
		text-decoration: underline !important;
	}
`

const FilesizeLimit = styled.div`
	font-size: 14px;

`


interface IProps {
	dropzoneProps: DropzoneProps
}

const Dropzone = (props: IProps) => {
	const { t } = useTranslation()

	const { dropzoneProps } = props
	return (
		<ReactDropzone {...dropzoneProps} >
			{({ getRootProps, getInputProps }) => (
				<Container {...getRootProps()}>
					<input {...getInputProps()} />

					<IconContainer>
						<Icon icon='cloud-arrow-up' />
					</IconContainer>

					<Description>
						<Trans t={t}>
							<Link {...getRootProps()}>Click to upload</Link> or drag and drop.
						</Trans>
					</Description>

					{dropzoneProps?.maxSize > 0 && <FilesizeLimit>
						{t('Dropzone::Maximum file size {{size}}.', { size: prettyBytes(dropzoneProps.maxSize) })}
					</FilesizeLimit>}
				</Container>)
			}
		</ReactDropzone>
	)
}

export default Dropzone