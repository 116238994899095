import * as React from 'react'
import NumberFormatter from '../../utilities/NumberFormatter';

interface IProps {
	name: string
	currency: string
	numberFormat: string
	value: string | number
	onBlur: (value: string | number) => void
	placeholderValue?: string | number
	disabled?: boolean
	style?: any
	max?: number
	optional?: boolean
}

export default class MoneyInput extends React.PureComponent<IProps> {
	static defaultProps = {
		style: {}
	}

	private input = React.createRef<HTMLInputElement>()


	constructor(props) {
		super(props);

		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);
		this.getFormattedValue = this.getFormattedValue.bind(this);
		this.unformatValue = this.unformatValue.bind(this);
		this.focus = this.focus.bind(this);
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any): void {
		if (
			this.props.numberFormat !== prevProps.numberFormat ||
			this.props.currency !== prevProps.currency
		) {
			const { currency, numberFormat, value } = this.props;
			this.input.current.value = NumberFormatter.formatCurrency(currency, numberFormat, value);
		}
	}

	onBlur(e) {
		const { max, optional } = this.props
		let currentValue = this.unformatValue(e.currentTarget.value);

		if (optional) currentValue = /\d/.test(e.currentTarget.value) ? this.unformatValue(e.currentTarget.value) : null;

		if (max && currentValue >= max) {
			this.props.onBlur(max);
			this.input.current.value = this.getFormattedValue(max);
		} else {
			this.props.onBlur(currentValue);
			this.input.current.value = currentValue !== null ? this.getFormattedValue(currentValue) : '';
		}
	}

	onChange(e) {
		const { max, optional } = this.props
		let currentValue = this.unformatValue(e.currentTarget.value);

		if (optional) currentValue = /\d/.test(e.currentTarget.value) ? this.unformatValue(e.currentTarget.value) : null;

		if (max && currentValue >= max) {
			this.props.onBlur(max);
			this.value(max)
		} else {
			this.props.onBlur(currentValue);
		}
	}

	getFormattedValue(value) {
		const { currency, numberFormat } = this.props;
		return NumberFormatter.formatCurrency(currency, numberFormat, value)
	}

	unformatValue(value) {
		const { numberFormat } = this.props;
		return NumberFormatter.unformatCurrency(numberFormat, value)
	}

	// Proxy method to actual input
	focus() {
		this.input.current.focus();
	}

	value(value) {
		const { currency, numberFormat, optional } = this.props;

		let textValue = NumberFormatter.formatCurrency(currency, numberFormat, value);

		if (optional) textValue = /\d/.test(value) ? NumberFormatter.formatCurrency(currency, numberFormat, value) : ''


		this.input.current.value = textValue
	}

	render() {
		const {
			name,
			value,
			currency,
			numberFormat,
			placeholderValue,
			disabled,
			style,
		} = this.props;

		return (
			<input
				ref={this.input}
				name={name}
				type='text'
				defaultValue={value ? NumberFormatter.formatCurrency(currency, numberFormat, value) : ''}
				onBlur={this.onBlur}
				onChange={this.onChange}
				placeholder={NumberFormatter.formatCurrency(currency, numberFormat, placeholderValue)}
				disabled={disabled}
				style={style}
				autoComplete='off'
			/>
		)
	}
}