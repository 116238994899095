import i18n from '../translations'
import { CalendarEventType, CalendarViewEvent } from '../types'

export default class CalendarEventHelper {
  static getTypeLabel(type: CalendarEventType): string {
    return i18n.t(`CalendarEventType::${type}`)
  }

  static eventPropGetter(event: CalendarViewEvent, start: Date, end: Date, isSelected: boolean): { className?: string | undefined; style?: React.CSSProperties } {
    return {
      className: `event-${event.type} ${event.disabled ? 'disabled' : ''}`
    }
  }
}