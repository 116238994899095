import * as React from 'react'
import Badge from "../components/Badge/Badge"
import i18n from "../translations"
import { MimeTypes, Product } from '../types'

export default class ProductHelper {
	static getProfitMargin(product: Product) {
		const price = Number(product.price)
		const purchasePrice = Number(product.purchase_price)
		const grossProfit = price - purchasePrice
		const grossProfitByRevenue = grossProfit / price

		return Math.round((grossProfitByRevenue * 100 + Number.EPSILON) * 100) / 100
	}

	static getProfit(product: Product) {
		return Math.round((Number(product.price) - Number(product.purchase_price)) * 100) / 100
	}

	static getSubtotal(product: Product) {
		return Number(product.price)
	}

	static getTaxRateTotal(product: Product) {
		return Number(product.price) * (Number(product.tax_rate) * 100) / 100
	}

	static getTotal(product: Product) {
		return this.getSubtotal(product) + this.getTaxRateTotal(product)
	}

	static getDropzoneMimeTypes(): MimeTypes[] {
		return [
			MimeTypes.CSV,
			MimeTypes.EXCEL
		]
	}
}