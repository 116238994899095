import * as React from 'react'
import { closeBulkProjectStatusUpdateModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ProjectHelper from '../../helpers/ProjectHelper'
import PowerSelect from '../Form/PowerSelect'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import ProjectStatusSelectComponents from '../Powerselect/ProjectStatusSelectComponents'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import { CurrentUser, ProjectStatus } from '../../types'

interface IStateToProps {
  excludeStatuses?: ProjectStatus[]
  onSubmit?: (status: ProjectStatus) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeBulkProjectStatusUpdateModal,
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  statuses: ProjectStatus[]
  selectedStatus: ProjectStatus
  errors: any
}

class BulkProjectStatusUpdateModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      statuses: [],
      selectedStatus: ProjectStatus.ACTIVE,
      errors: {},
    }

    this.onProjectStatusChange = this.onProjectStatusChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.setState({ didInitialLoad: true })
  }

  onProjectStatusChange(option) {
    this.setState({ selectedStatus: option.value, });
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { close, onSubmit, t } = this.props
    const { selectedStatus } = this.state

    if (onSubmit) onSubmit(selectedStatus)
    close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  render() {
    const { close, t, excludeStatuses } = this.props
    const { didInitialLoad, selectedStatus, errors } = this.state

    const statuses = ProjectHelper.getStatuses()
    const statusOptions = ProjectHelper.getStatusOptions(excludeStatuses)
    const selectedStatusOption = selectedStatus ? ProjectHelper.getStatusOption(statuses, selectedStatus) : null

    return (
      <ModalWindow>
        <ModalHeader
          title={t('BulkProjectStatusUpdateModal::Bulk update')}
          onCloseClick={close}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div data-tab='details'>
                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('BulkProjectStatusUpdateModal::Status')}</label>
                      <PowerSelect
                        options={statusOptions}
                        value={selectedStatusOption}
                        onChange={this.onProjectStatusChange}
                        components={ProjectStatusSelectComponents}
                        theme={ReactSelectTheme}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('BulkProjectStatusUpdateModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      bulkProjectStatusUpdateModal: {
        excludeStatuses,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    excludeStatuses: excludeStatuses,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeBulkProjectStatusUpdateModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BulkProjectStatusUpdateModal))