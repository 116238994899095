import * as React from 'react'
import { closeSendLedgerItemModal, showContactModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import { ActiveStorageController, InvoicesController, SettingsController, TasksController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Notification from '../../utilities/Notification'
import MustacheHelper from '../../helpers/MustacheHelper'
import { updateSettings } from '../../store/authentication/actions'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import EmailPreview from '../EmailPreview/EmailPreview'
import ModalWindowWithPreview from './Parts/ModalWindowWithPreview'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { EMAIL_EDITOR_CONFIG } from '../Editor/Editor'
import MobilityHelper from '../../helpers/MobilityHelper'
import EditorHelper from '../../helpers/EditorHelper'
import Dropzone, { DropEvent, DropzoneRef, FileRejection } from 'react-dropzone'
import { MAX_EMAIL_ATTACHMENTS_SIZE, MAX_EMAIL_ATTACHMENT_SIZE } from '../../Constants'
import EmailAttachment from '../EmailPreview/EmailAttachment'
import EmailAttachments from '../EmailPreview/EmailAttachments'
import ReactTooltip from 'react-tooltip'
import Alert from '../Alert/Alert'
import ModalFooterActionIcons from './Parts/ModalFooterActionIcons'
import ModalFooterActionIcon from './Parts/ModalFooterAction'
import { Contact, CurrentUser, LedgerItem, LedgerItemType, Locale, MimeTypes, SendLedgerItemEmailParams, Settings, Task } from '../../types'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Icon from '../Icons/Icon'
import ModalActionFollowUp from './Parts/ModalActionFollowUp'
import ButtonWithActions from '../Button/ButtonWithActions'

const LedgerItemModalMiddle = styled(ModalMiddle)`
  height: calc(100% - 56px);
`

const LedgerItemModalContent = styled(ModalContent)`
  overflow-y: auto;
`

const Content = styled.div`
  flex: 1;
  overflow: hidden;
`

const EmailOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const EmailOptionMainContainer = styled.div`
  flex: 1;
  width: 100%;
  margin-right: 8px;

  &:last-child {
    margin-right: 0px;
  }
`

const EmailOptionLink = styled.a`
  color: ${Style.color.brandPrimary};
  margin-right: 4px;
  cursor: pointer;

  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    text-decoration: underline;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  id: string
  reminder: boolean
  onSubmit?: (ledgerItem: LedgerItem) => void
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  close: typeof closeSendLedgerItemModal
  showContactModal: typeof showContactModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  type: LedgerItemType | null
  locale: Locale,
  contactId: string | null
  to: Contact[]
  cc: Contact[]
  ccActive: boolean
  bcc: Contact[]
  bccActive: boolean
  subject: string | null
  body: string | null
  attachLedgerItem: boolean
  attachments: ActiveStorage.Blob[]
  variables: object
  isSending: boolean
  emailIntegrationEnabled?: boolean
  errors: any
  task: Task
}

class SendLedgerItemModal extends React.Component<IProps, IState> {
  private dropzone = React.createRef<DropzoneRef>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      type: null,
      locale: null,
      contactId: null,
      subject: '',
      to: [],
      cc: [],
      ccActive: false,
      bcc: [],
      bccActive: false,
      body: '',
      attachLedgerItem: true,
      attachments: [],
      variables: {},
      isSending: false,
      emailIntegrationEnabled: false,
      errors: {},
      task: null
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onFollowUpActionChange = this.onFollowUpActionChange.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onDefaultSubjectClick = this.onDefaultSubjectClick.bind(this)
    this.onDefaultBodyClick = this.onDefaultBodyClick.bind(this)
    this.onToChange = this.onToChange.bind(this)
    this.onToggleCcActive = this.onToggleCcActive.bind(this)
    this.onCcChange = this.onCcChange.bind(this)
    this.onBcChange = this.onBcChange.bind(this)
    this.onToggleBccActive = this.onToggleBccActive.bind(this)
    this.onSubjectChange = this.onSubjectChange.bind(this)
    this.onBodyChange = this.onBodyChange.bind(this)
    this.onToggleAttachLedgerItem = this.onToggleAttachLedgerItem.bind(this)
    this.onAddAttachmentClick = this.onAddAttachmentClick.bind(this)
    this.onAttachmentDrop = this.onAttachmentDrop.bind(this)
    this.onAttachmentDeleteClick = this.onAttachmentDeleteClick.bind(this)
    this.onSendLedgerItemCloseModalClick = this.onSendLedgerItemCloseModalClick.bind(this)
    this.onSendTestEmailClick = this.onSendTestEmailClick.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  async fetchForm() {
    const { id, reminder } = this.props

    try {
      const response = await InvoicesController.getEmailForm({ id: id, reminder: reminder })

      const {
        type,
        locale,
        contact_id,
        default_email_contact,
        subject,
        body,
        variables,
        email_integration_enabled,
      } = response

      this.setState({
        didInitialLoad: true,
        type: type,
        to: default_email_contact ? [default_email_contact] : [],
        locale: locale,
        contactId: contact_id,
        subject: subject,
        body: body,
        variables: variables,
        emailIntegrationEnabled: email_integration_enabled
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  async onFormSubmit(e?: React.FormEvent, testEmail: boolean = false) {
    e?.preventDefault();

    const { id, onSubmit, close, t, reminder } = this.props

    const { to, cc, bcc, subject, body, attachLedgerItem, attachments, task } = this.state;

    try {
      let response = null

      this.setState({ isSending: true })
      const SendLedgerItemEmailParams: SendLedgerItemEmailParams = {
        ledger_item_id: id,
        to: to?.map(contact => contact.id) || [],
        cc: cc?.map(contact => contact.id) || [],
        bcc: bcc?.map(contact => contact.id) || [],
        subject: subject,
        body: body,
        attach_ledger_item: attachLedgerItem,
        // @ts-ignore
        attachment_ids: attachments?.map(attachment => attachment.id) || [],
        test_email: testEmail,
      }

      if (reminder) {
        response = await InvoicesController.sendEmailReminder(SendLedgerItemEmailParams)
      } else {
        response = await InvoicesController.sendEmail(SendLedgerItemEmailParams)
      }

      const { errors } = response;

      if (errors) {
        this.setState({
          errors: errors
        });
        Notification.notifyError(t('SendLedgerItemModal::Oops something went wrong'))
      }
      else {
        const ledgerItem: LedgerItem = response

        if (reminder) {
          Notification.notifySuccess(t('SendLedgerItemModal::Invoice reminder successfully send'))
        } else {
          switch (ledgerItem.type) {
            case LedgerItemType.QUOTATION:
              Notification.notifySuccess(t('SendLedgerItemModal::Quotation successfully send'))
              break
            case LedgerItemType.INVOICE:
              Notification.notifySuccess(t('SendLedgerItemModal::Invoice successfully send'))
              break
            case LedgerItemType.CREDIT_NOTE:
              Notification.notifySuccess(t('SendLedgerItemModal::Credit note successfully send'))
              break
          }
        }

        if (task && !testEmail && to?.length > 0) {
          const contact = to[0]

          TasksController.create({
            ...task,
            name: `${task.name} ${contact.name}`,
            contact_id: contact.id,
          }).catch(console.error)
        }

        if (!testEmail) {
          if (onSubmit) onSubmit(ledgerItem)
          close()
        }
      }

    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isSending: false })
    }
  }

  onFollowUpActionChange(task: Task) {
    this.setState({
      task: task
    })
  }

  onSendLedgerItemCloseModalClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  async onDefaultSubjectClick(e) {
    e.preventDefault();

    const { currentUser: { workspace: { setting } }, updateSettings, t, reminder } = this.props
    const { type, subject, locale } = this.state;

    try {
      let translationProperty = null

      if (reminder) {
        translationProperty = 'invoice_reminder_email_subject'
      } else {
        translationProperty = `${type}_email_subject`
      }
      const updatedTranslations = MobilityHelper.updateTranslation(locale, setting.translations, translationProperty, subject)
      const updatedSettings = await SettingsController.update({ ...setting, translations: updatedTranslations })

      Notification.notifySuccess(t('SendLedgerItemModal::Default subject saved'));
      updateSettings(updatedSettings)
    } catch (ex) {
      console.error(ex)
    }
  }

  async onDefaultBodyClick(e) {
    e.preventDefault();

    const { currentUser: { workspace: { setting } }, updateSettings, t, reminder } = this.props
    const { type, body, locale } = this.state;

    try {
      let translationProperty = null
      if (reminder) {
        translationProperty = 'invoice_reminder_email_body'
      } else {
        translationProperty = `${type}_email_body`
      }

      const updatedTranslations = MobilityHelper.updateTranslation(locale, setting.translations, translationProperty, body)
      const updatedSetting = await SettingsController.update({ ...setting, translations: updatedTranslations })

      Notification.notifySuccess(t('SendLedgerItemModal::Default body saved'));
      updateSettings(updatedSetting)
    } catch (ex) {
      console.error(ex)
    }
  }

  onToChange(value: string[], contacts: Contact[]) {
    this.setState({
      to: contacts ? contacts : []
    });
  }

  onCcChange(value: string[], contacts: Contact[]) {
    this.setState({
      cc: contacts ? contacts : []
    });
  }

  onBcChange(value: string[], contacts: Contact[]) {
    this.setState({
      bcc: contacts ? contacts : []
    });
  }

  onToggleCcActive() {
    const { ccActive } = this.state

    this.setState({
      ccActive: !ccActive
    })
  }

  onToggleBccActive() {
    const { bccActive } = this.state

    this.setState({
      bccActive: !bccActive
    })
  }

  onSubjectChange(e) {
    e.preventDefault();

    const newSubject = e.currentTarget.value;

    this.setState({
      subject: newSubject
    });
  }

  onBodyChange(body: string) {
    this.setState({ body: body })
  }

  onToggleAttachLedgerItem() {
    const { attachLedgerItem } = this.state

    this.setState({ attachLedgerItem: !attachLedgerItem })
  }

  onAddAttachmentClick() {
    if (this.dropzone.current) {
      this.dropzone.current.open()
    }
  }

  onAttachmentDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    const { t } = this.props
    acceptedFiles.forEach(async (file: any) => {
      try {
        ActiveStorageController.upload(file, async (error, blob) => {
          if (error) { console.error(error) }
          if (blob) {
            this.setState({
              attachments: [
                ...this.state.attachments,
                blob
              ]
            })
          }
        })
      } catch (ex) {
        console.error(ex)
      }
    })

    // Display the file names and errors
    fileRejections.forEach((fileRejection) => {
      fileRejection.errors.forEach((err) => {
        if (err.code === "file-too-large") Notification.notifyError(t('SendLedgerItemModal::File {{filename}} is too large', { filename: fileRejection.file.name }))
        if (err.code === "file-invalid-type") Notification.notifyError(t('SendLedgerItemModal::File {{filename}} is an invalid filetype', { filename: fileRejection.file.name }))
      });
    });
  }

  onAttachmentDeleteClick(index: number) {
    const { attachments } = this.state

    attachments.splice(index, 1)

    this.setState({
      attachments: [...attachments]
    })
  }

  isValidOption(inputValue: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(inputValue).toLowerCase());
  }


  getLedgerItemAttachmentTooltip() {
    const { t } = this.props
    const { type } = this.state

    switch (type) {
      case LedgerItemType.ORDER_FORM:
        return t('SendLedgerItemModal::Attach order form as attachment')
      case LedgerItemType.DELIVERY_NOTE:
        return t('SendLedgerItemModal::Attach delivery note as attachment')
      case LedgerItemType.QUOTATION:
        return t('SendLedgerItemModal::Attach quote as attachment')
      case LedgerItemType.INVOICE:
        return t('SendLedgerItemModal::Attach invoice as attachment')
      case LedgerItemType.CREDIT_NOTE:
        return t('SendLedgerItemModal::Attach credit note as attachment')
      default: ''
    }
  }

  onSendTestEmailClick() {
    this.onFormSubmit(null, true)
  }

  render() {
    const { t, currentUser, reminder } = this.props
    const {
      didInitialLoad,
      errors,
      type,
      to,
      cc,
      ccActive,
      bcc,
      bccActive,
      subject,
      body,
      variables,
      attachLedgerItem,
      attachments,
      emailIntegrationEnabled,
      isSending
    } = this.state

    const attachmentsSize = attachments.reduce((acc, attachment) => acc + attachment.byte_size, 0)
    const emailAttachmentLimitReached = attachmentsSize >= MAX_EMAIL_ATTACHMENTS_SIZE
    const toIds = to?.map(contact => contact.id) || []
    const ccIds = cc?.map(contact => contact.id) || []
    const bccIds = bcc?.map(contact => contact.id) || []
    const emailContacts = [...to, ...cc, ...bcc]
    const sendDisabled = emailContacts.some(contact => contact.emails.length === 0) || to?.length === 0
    let tooltipReason = null

    if (to.length === 0) {
      tooltipReason = t('SendLedgerItemModal::Please select at least one contact.')
    } else if (emailContacts.some(contact => contact.emails.length === 0)) {
      tooltipReason = t('SendLedgerItemModal::Please make sure all selected contacts have an email address configured.')
    }

    return (
      <ModalWindowWithPreview>
        <Content>
          <ModalHeader
            title={reminder ? t('SendLedgerItemModal::Send reminder') : t('SendLedgerItemModal::Email')}
            onCloseClick={this.onSendLedgerItemCloseModalClick}
          />

          {!didInitialLoad && <ModalLoader />}
          {didInitialLoad && <LedgerItemModalMiddle>
            <LedgerItemModalContent>
              <form onSubmit={this.onFormSubmit}>
                <div key='content'>
                  <div className='grid'>
                    {tooltipReason && <div className='grid-cell with-12col' style={{ marginBottom: 8 }}>
                      <Alert type='warning' text={<>
                        <span style={{ marginRight: 4 }}>
                          <Icon icon='exclamation-triangle' />
                        </span>
                        {tooltipReason}
                      </>} />
                    </div>}

                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>{t('SendLedgerItemModal::Send to')} <span>*</span></label>
                        <EmailOptionsContainer>
                          <EmailOptionMainContainer>
                            <ResourceCreatablePowerSelect
                              type='contact_with_email'
                              params={{ archived: false }}
                              value={toIds}
                              onChange={this.onToChange}
                              placeholder={t('LedgerItemEditor::Select contacts...')}
                              isClearable={true}
                              isMulti
                            />
                          </EmailOptionMainContainer>
                          {!ccActive && <EmailOptionLink onClick={this.onToggleCcActive}>
                            {t('SendLedgerItemModal::CC')}
                          </EmailOptionLink>}
                          {!bccActive && <EmailOptionLink onClick={this.onToggleBccActive}>
                            {t('SendLedgerItemModal::BCC')}
                          </EmailOptionLink>}
                        </EmailOptionsContainer>
                      </div>
                    </div>
                  </div>

                  {ccActive && <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>{t('SendLedgerItemModal::CC')}</label>

                        <ResourceCreatablePowerSelect
                          type='contact_with_email'
                          params={{ archived: false }}
                          value={ccIds}
                          onChange={this.onCcChange}
                          placeholder={t('LedgerItemEditor::Select contacts...')}
                          isClearable={true}
                          isMulti
                        />
                      </div>
                    </div>
                  </div>}

                  {bccActive && <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>{t('SendLedgerItemModal::BCC')}</label>

                        <ResourceCreatablePowerSelect
                          type='contact_with_email'
                          params={{ archived: false }}
                          value={bccIds}
                          onChange={this.onBcChange}
                          placeholder={t('LedgerItemEditor::Select contacts...')}
                          isClearable={true}
                          isMulti
                        />
                      </div>
                    </div>
                  </div>}

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <div className='field-label-with-action'>
                          <label>{t('SendLedgerItemModal::Subject')} <span>*</span></label>
                          <a href='javascript://' onClick={this.onDefaultSubjectClick}>
                            {t('SendLedgerItemModal::Save as default')}
                          </a>
                        </div>

                        <input type='text'
                          value={subject}
                          onChange={this.onSubjectChange}
                          name='subject'
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <div className='field-label-with-action'>
                          <label>{t('SendLedgerItemModal::Content')} <span>*</span></label>
                          <a href='javascript://' onClick={this.onDefaultBodyClick}>
                            {t('SendLedgerItemModal::Save as default')}
                          </a>
                        </div>
                        <EditorContainer>
                          <Editor
                            model={body}
                            onModelChange={this.onBodyChange}
                            config={{
                              ...EMAIL_EDITOR_CONFIG,
                              editorClass: 'branded-email',
                              placeholderText: t('SendLedgerItemModal::Type here...'),
                              heightMin: 260,
                              heightMax: 260,
                              variableOptions: EditorHelper.getLedgerItemVariableOptions(variables),
                            }}
                          />
                        </EditorContainer>
                      </div>
                    </div>
                  </div>

                  {emailIntegrationEnabled && attachments && attachments.length > 0 && <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>{t('SendLedgerItemModal::Attachments')}</label>

                        {emailAttachmentLimitReached && <Alert
                          type='warning'
                          text={t('SendLedgerItemModal::You have reached the average maximum size limit for most email providers. The email will be sent but we can\'t guarantee it will arrive at its destination.')}
                        />}

                        {attachments && attachments.length > 0 && <EmailAttachments>
                          {attachments?.map((attachment, index) => {
                            return (
                              <EmailAttachment
                                key={index}
                                file={attachment}
                                onDeleteClick={() => this.onAttachmentDeleteClick(index)}
                              />
                            )
                          })}
                        </EmailAttachments>}
                      </div>
                    </div>
                  </div>}
                  <Dropzone
                    ref={this.dropzone}
                    onDrop={this.onAttachmentDrop}
                    multiple={true}
                    accept={[
                      MimeTypes.JPEG,
                      MimeTypes.JPG,
                      MimeTypes.PNG,
                      MimeTypes.PDF,
                      MimeTypes.CSV,
                      MimeTypes.EXCEL
                    ]}
                    maxSize={MAX_EMAIL_ATTACHMENT_SIZE}
                  >
                    {({ getInputProps, open }) => (
                      <input {...getInputProps()} />
                    )}
                  </Dropzone>
                </div>
              </form>
            </LedgerItemModalContent>

            <div className='modal-footer'>
              <div className='modal-footer-actions'>
                <div key='main-action' className='popover-wrapper'>
                  <TooltipError
                    errors={errors}
                    onDismiss={this.onErrorsDismiss}
                  />
                  <ButtonWithActions
                    buttonProps={
                      {
                        type: 'success',
                        text: t('SendLedgerItemModal::Send'),
                        onClick: this.onFormSubmit,
                        disabled: sendDisabled,
                        tooltip: tooltipReason,
                        isLoading: isSending,
                      }
                    }
                    actions={[
                      { key: 'test-email', icon: 'email', content: t('SendLedgerItemModal::Send test email to myself'), onClick: this.onSendTestEmailClick },
                    ]}
                  />
                </div>

                <ModalActionFollowUp
                  onFollowUpActionChange={this.onFollowUpActionChange}
                />
              </div>
              <ModalFooterActionIcons>
                <ModalFooterActionIcon
                  icon='invoice'
                  active={attachLedgerItem}
                  onClick={this.onToggleAttachLedgerItem}
                  tooltip={this.getLedgerItemAttachmentTooltip()}
                />

                <ModalFooterActionIcon
                  icon='attachment'
                  disabled={!emailIntegrationEnabled}
                  onClick={this.onAddAttachmentClick}
                  tooltip={t('SendLedgerItemModal::Add attachments')}
                  disabledTooltip={t('SendLedgerItemModal::Configure an email integration or SMTP server to enable attachments')}
                />
              </ModalFooterActionIcons>
            </div>
          </LedgerItemModalMiddle>}
        </Content>

        <EmailPreview
          subject={MustacheHelper.process(this.state.subject, variables)}
          body={MustacheHelper.process(this.state.body, variables)}
          attachments={attachments}
          onAttachmentDeleteClick={this.onAttachmentDeleteClick}
        />
      </ModalWindowWithPreview >
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      sendLedgerItemModal: {
        id,
        reminder,
        onSubmit,
      }
    }
  } = state

  return {
    id: id,
    reminder: reminder,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    close: () => dispatch(closeSendLedgerItemModal()),
    showContactModal: (options) => dispatch(showContactModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SendLedgerItemModal))