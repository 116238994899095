import React from "react"
import styled from "styled-components"
import ActionList, { IActionListItem } from "../../ActionList/ActionList"
import Popover from "../../Popover/Popover"
import ResourceModalTableRowData from "./ResourceModalTableRowData"

const Container = styled.div`
	display: flex;
  justify-content: center;
	align-items: center;
`

const Wrapper = styled.div`
	position: relative;
	pointer-events: auto;
	display: flex;
	flex: 0 0 22px;
	justify-content: center;
	align-items: center;

	svg {
		position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
		max-height: 100%;
		fill: #637381;
		width: 20px;
		height: 20px;
	}

	&:hover {
		svg {
			fill: #212b36;
		}
	}
`

const ActionButton = styled.div`
	position: relative;
	border-radius: 3px;
	line-height: 1;
	color: #212b36;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	transition-property: background,border,box-shadow;
	transition-duration: .2s;
	transition-timing-function: cubic-bezier(.64,0,.35,1);
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
	border: none;
	height: 24px;
	height: 24px;
`

interface IProps {
  actions?: IActionListItem[]
  onActionClick?: (key: string) => void
}


const ResourceModalTableRowActions = (props: IProps) => {
  const { actions, onActionClick } = props
  const [popoverActive, setPopoverActive] = React.useState(false)

  const onPopoverClick = () => {
    setPopoverActive(!popoverActive)
  }

  const onPopoverClose = () => {
    setPopoverActive(false)
  }

  if (actions.length === 0) return <ResourceModalTableRowData />

  return (
    <ResourceModalTableRowData style={{
      padding: 5,
      minWidth: 42,
      maxWidth: 42,
      width: 42,
    }}>
      <Container>
        <Wrapper>
          <Popover
            active={popoverActive}
            activator={
              <ActionButton onClick={onPopoverClick}>
                <svg viewBox='0 0 20 20' focusable='false' aria-hidden='true'><path d='M6 10a2 2 0 1 1-4.001-.001A2 2 0 0 1 6 10zm6 0a2 2 0 1 1-4.001-.001A2 2 0 0 1 12 10zm6 0a2 2 0 1 1-4.001-.001A2 2 0 0 1 18 10z' fillRule='evenodd'></path></svg>
              </ActionButton>
            }
            onClose={onPopoverClose}
            placement='bottom-end'
            strategy='fixed'
          >
            <ActionList
              actions={actions}
              onClick={(key: string) => {
                onPopoverClose()
                if (onActionClick) onActionClick(key)
              }}
              hasIndicator
            />
          </Popover>
        </Wrapper>
      </Container>
    </ResourceModalTableRowData>
  )
}

export default ResourceModalTableRowActions