import * as React from 'react'
import { closeTwoFactorAuthenticationModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Button from '../Button/Button'
import { CurrentUser, DisplayableError, Sequence } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'
import styled from 'styled-components'
import { Style } from '../../styles'
import MaskedInput from 'react-text-mask'
import { UserController } from '../../controllers'
import ResourceTableRowDataLink from '../Resource/ResourceTableRowDataLink'
import copy from 'copy-to-clipboard'
import Notification from '../../utilities/Notification'

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${Style.spacing.x1};

  img {
    max-width: 200px;
    max-height: 200px;
    width: 100%;
  }

  .form-item {
    margin-top: ${Style.spacing.x1};
  }

  label {
    text-align: center;
  }

  input {
    max-width: 200px;
    text-align: center;
  }
`

const BackupCodes = styled.div`
  background-color: #F8FAFC;
  padding: 20px;
  columns: 2 auto;
  margin: auto;
  text-align: center;
  width: 300px;
  padding: 20px 0 20px 0;
  border-radius: 6px;
  border: 1px solid #CDD7E0;

  p {
    font-weight: 500;
  }
`

const BackupActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${Style.spacing.x1};
  margin-top: ${Style.spacing.x1};

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  onSubmit: () => void
}

interface IDispatchToProps {
  close: typeof closeTwoFactorAuthenticationModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  errors: DisplayableError[]
  isSubmitting: boolean
  otpEnabled: boolean
  otpSecret: string
  qrCode: string
  code: string
  password: string
  backupCodes: string[]
}

class TwoFactorAuthenticationModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      errors: [],
      isSubmitting: false,
      otpEnabled: false,
      otpSecret: null,
      qrCode: null,
      code: '',
      password: '',
      backupCodes: [],
    }

    this.onCloseClick = this.onCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onDisableOtpFormSubmit = this.onDisableOtpFormSubmit.bind(this)
    this.onCodeChange = this.onCodeChange.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
    this.onCopyBackupCodesClick = this.onCopyBackupCodesClick.bind(this)
    this.onPrintBackupCodesClick = this.onPrintBackupCodesClick.bind(this)
  }

  componentDidMount(): void {
    this.fetchForm()
  }

  async fetchForm() {
    try {
      const form = await UserController.otpForm()

      this.setState({
        didInitialLoad: true,
        otpEnabled: form.otp_enabled,
        otpSecret: form.otp_secret,
        qrCode: form.qr_code,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onCloseClick() {
    const { onSubmit, close } = this.props

    if (onSubmit) onSubmit()
    close()
  }

  onErrorsDismiss() {
    this.setState({ errors: [] })
  }

  async onFormSubmit(e) {
    e.preventDefault()
    const { otpSecret, code } = this.state

    try {
      const response = await UserController.otpConfigure(otpSecret, code)

      if (response.errors) {
        this.setState({ errors: response.errors })
        return
      }

      const { backup_codes } = response
      this.setState({ backupCodes: backup_codes, })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onDisableOtpFormSubmit(e) {
    const { onSubmit } = this.props
    const { password } = this.state
    e.preventDefault()

    try {
      const response = await UserController.otpDisable(password)

      if (response.errors) {
        this.setState({ errors: response.errors })
        return
      }

      if (onSubmit) onSubmit()
      this.props.close()
    } catch (ex) {
      console.error(ex)
    }
  }

  onCodeChange(e) {
    const code = e.currentTarget.value

    this.setState({ code: code.match(/\d+/g)?.join('') || '' })
  }

  onPasswordChange(e) {
    this.setState({ password: e.currentTarget.value })
  }

  onCopyBackupCodesClick() {
    const { t } = this.props
    const { backupCodes } = this.state

    copy(backupCodes.map(code => `${code}`).join('\n'))

    Notification.notifySuccess(t('ShareLinksModal::Copied to clipboard'))
  }

  onPrintBackupCodesClick() {
    const { backupCodes } = this.state
    var newWindow = window.open();

    const backupCodesHtml = backupCodes.map(code => `<li>${code}</li>`).join('');
    const htmlContent = `<h1>Backup Codes</h1> <ul>${backupCodesHtml}</ul> `;

    newWindow.document.write(htmlContent)
    newWindow.print()
  }

  renderContent() {
    const { t } = this.props
    const { otpEnabled, errors, code, password, qrCode, backupCodes } = this.state

    if (otpEnabled) {
      return (
        <form onSubmit={this.onDisableOtpFormSubmit}>
          <p dangerouslySetInnerHTML={{ __html: t('TwoFactorAuthenticationModal::<b>Warning: This will make your account less secure</b><br />Two-factor authentication helps protect your account by making it harder for hackers to impersonate you. If you disable it, there will be one less layer of protection. We don\'t recommend disabling two-factor authentication.') }} />

          <div className='form-item' style={{ marginTop: 16 }}>
            <label>{t('TwoFactorAuthenticationModal::Confirm password')}</label>
            <input
              type='password'
              value={password}
              onChange={this.onPasswordChange}
            />
          </div>
        </form>
      )
    } else {

      return (
        <>
          {backupCodes.length === 0 && <>
            <form onSubmit={this.onFormSubmit}>
              <p>{t('TwoFactorAuthenticationModal::To enable two-factor authentication you\'ll need an authenticator app on your phone. We recommend Google Authenticator. Please make sure that\'s installed before continuing.')}</p>
              <br />
              <p>{t('TwoFactorAuthenticationModal::Open your authenticator app, select the option to add a new site, and scan the barcode below.')}</p>

              <QRCodeContainer>
                <img src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`} />

                <div className='form-item'>
                  <label>{t('TwoFactorAuthenticationModal::Enter code')}</label>
                  <MaskedInput
                    type='text'
                    value={code}
                    guide={true}
                    showMask={true}
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                    onChange={this.onCodeChange}
                  />
                </div>
              </QRCodeContainer>

              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </>}

          {backupCodes.length > 0 && <>
            <p>{t('TwoFactorAuthenticationModal::If you lose access to your two-factor authentication method, you can use these backup codes to log in. You should keep these in a secure place such as a password manager.')}</p>

            <BackupCodes>
              {backupCodes.map((code, index) => (<p key={code}>{code}</p>))}
            </BackupCodes>

            <BackupActions>
              <ResourceTableRowDataLink onClick={this.onCopyBackupCodesClick}>
                {t('TwoFactorAuthenticationModal::Copy codes to clipboard')}
              </ResourceTableRowDataLink>

              <ResourceTableRowDataLink onClick={this.onPrintBackupCodesClick}>
                {t('TwoFactorAuthenticationModal::Print codes')}
              </ResourceTableRowDataLink>
            </BackupActions>
          </>}
        </>
      )
    }
  }

  renderFooter() {
    const { t } = this.props
    const { otpEnabled, errors, code, password, isSubmitting, backupCodes } = this.state

    if (otpEnabled) {
      return (
        <>
          <div />
          <div key='main-action' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={this.onErrorsDismiss}
            />
            <Button
              type='danger'
              text={this.props.t('TwoFactorAuthenticationModal::Disable two-factor authentication')}
              onClick={this.onDisableOtpFormSubmit}
              disabled={password?.length === 0}
              isLoading={isSubmitting}
            />
          </div>
        </>
      )
    } else if (backupCodes.length > 0) {
      return (
        <>
          <div />
          <div key='main-action' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={this.onErrorsDismiss}
            />
            <Button
              type='success'
              text={t('TwoFactorAuthenticationModal::Finish setup')}
              onClick={this.onCloseClick}
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div />
          <div key='main-action' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={this.onErrorsDismiss}
            />
            <Button
              type='success'
              text={t('TwoFactorAuthenticationModal::Continue')}
              onClick={this.onFormSubmit}
              disabled={code?.length !== 6}
              isLoading={isSubmitting}
            />
          </div>
        </>
      )
    }
  }

  render() {
    const { t } = this.props
    const { didInitialLoad, code } = this.state
    return (
      <ModalWindow>
        <ModalHeader
          title={t('TwoFactorAuthenticationModal::Two-factor authentication')}
          onCloseClick={this.onCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            {this.renderContent()}
          </ModalContent>

          <div className='modal-footer'>
            {this.renderFooter()}
          </div>
        </ModalMiddle>}
      </ModalWindow >
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      twoFactorAuthenticationModal: {
        onSubmit
      }
    }
  } = state

  return {
    currentUser: currentUser,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeTwoFactorAuthenticationModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TwoFactorAuthenticationModal))