import * as React from 'react'
import { closeTaxModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { TaxesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CurrentUser, Locale, VATLiability, WorkspaceTax } from '../../types'
import PercentInput from '../Form/PercentInput'
import PowerSelect from '../Form/PowerSelect'
import LocaleHelper from '../../helpers/LocaleHelper'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import Tooltip from '../Tooltips/Tooltip'
import MobilityHelper from '../../helpers/MobilityHelper'
import VATHelper from '../../helpers/VatHelper'

interface IStateToProps {
  tax: WorkspaceTax
  onSubmit?: (tax: WorkspaceTax) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeTaxModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

export enum TaxModalTab {
  DETAILS = 'details',
  RULES = 'rules',
}

interface IState {
  didInitialLoad: boolean
  activeTab: TaxModalTab
  tax: WorkspaceTax | null
  errors: any
  activeTranslationsLocale: Locale
}

class TaxModal extends React.Component<IProps, IState> {
  static defaultProps = {
    tax: {}
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: TaxModalTab.DETAILS,
      tax: null,
      errors: {},
      activeTranslationsLocale: props.currentUser.locale,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onTaxNameChange = this.onTaxNameChange.bind(this)
    this.onTaxCodeChange = this.onTaxCodeChange.bind(this)
    this.onTaxRateChange = this.onTaxRateChange.bind(this)
    this.onTaxVATLiabilityChange = this.onTaxVATLiabilityChange.bind(this)
    this.onTaxRateLocaleChange = this.onTaxRateLocaleChange.bind(this)
    this.onTaxRateInvoiceTextChange = this.onTaxRateInvoiceTextChange.bind(this)
    this.onTaxRateCreditNoteTextChange = this.onTaxRateCreditNoteTextChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onTaxModalCloseClick = this.onTaxModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { tax: propsTax } = this.props

    try {
      const response = await TaxesController.getForm({ id: propsTax.id })
      const { tax } = response

      this.setState({ tax: { ...tax, ...propsTax }, didInitialLoad: true, })
    } catch (ex) {
      console.error(ex)
    }
  }

  onTaxNameChange(e) {
    const { tax } = this.state;
    const name = e.currentTarget.value;

    this.setState({
      tax: {
        ...tax,
        name: name
      },
    })
  }

  onTaxCodeChange(e) {
    const { tax } = this.state;
    const code = e.currentTarget.value;

    this.setState({
      tax: {
        ...tax,
        code: code
      },
    })
  }

  onTaxRateChange(rate) {
    const { tax } = this.state;

    this.setState({
      tax: {
        ...tax,
        rate: rate
      },
    })
  }

  onTaxVATLiabilityChange(options) {
    const { tax } = this.state;

    this.setState({
      tax: {
        ...tax,
        vat_liabilities: options?.length > 0 ? options.map((option) => option.value) : []
      },
    })
  }

  onTaxRateLocaleChange(options) {
    this.setState({ activeTranslationsLocale: options.value })
  }

  onTaxRateInvoiceTextChange(e) {
    const { tax, activeTranslationsLocale } = this.state

    this.setState({
      tax: {
        ...tax,
        translations: MobilityHelper.updateTranslation(
          activeTranslationsLocale,
          tax.translations,
          'invoice_rule',
          e.currentTarget.value
        )
      }
    })
  }

  onTaxRateCreditNoteTextChange(e) {
    const { tax, activeTranslationsLocale } = this.state

    this.setState({
      tax: {
        ...tax,
        translations: MobilityHelper.updateTranslation(
          activeTranslationsLocale,
          tax.translations,
          'credit_note_rule',
          e.currentTarget.value
        )
      }
    })
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { tax } = this.state;
    const { close, onSubmit, t } = this.props

    try {

      if (tax.id) { // Do update
        const response = await TaxesController.update(tax)
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });
          Notification.notifyError(t('TaxModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('TaxModal::Tax rate successfully updated.'))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
      else {
        const response = await TaxesController.create(tax)
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });
          Notification.notifyError(t('TaxModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('TaxModal::Tax rate successfully created.'))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onTaxModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderDetails() {
    const { t } = this.props
    const { activeTab, tax, activeTranslationsLocale } = this.state;

    if (activeTab !== TaxModalTab.DETAILS) return null;

    const vatLiabilityOptions = VATHelper.getVATLiabilityOptions()
    const selectedVatLiabilityOptions = vatLiabilityOptions.filter(option => tax?.vat_liabilities?.includes(option.value as VATLiability))
    const localeOptions = LocaleHelper.getLocaleOptions()
    const selectedLocaleOption = localeOptions.find(option => option.value === activeTranslationsLocale)

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-4col'>
            <div className='form-item'>
              <label>
                {t('TaxModal::Name')}
                <span> *</span>
              </label>
              <input
                type='text'
                name='name'
                onChange={this.onTaxNameChange}
                value={tax.name}
                placeholder={t('TaxModal::Name')}
                required
              />
            </div>
          </div>
          <div className='grid-cell with-4col'>
            <div className='form-item'>
              <label>
                {t('TaxModal::Code')}
                <span> *</span>
              </label>
              <input
                type='text'
                name='name'
                onChange={this.onTaxCodeChange}
                value={tax.code}
                placeholder={t('TaxModal::Code')}
                required
              />
            </div>
          </div>
          <div className='grid-cell with-4col'>
            <div className='form-item'>
              <label>
                {t('TaxModal::Percentage')}
                <span> *</span>
              </label>
              <PercentInput
                name='rate'
                defaultValue={tax?.rate || ''}
                placeholder={String(t('TaxModal::0%'))}
                onChange={this.onTaxRateChange}
                onBlur={this.onTaxRateChange}
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('TaxModal::Applicable VAT liabilities (optional)')}

                <Tooltip
                  content={t('TaxModal::The selected VAT liabilities will determine which VAT rate applies on documents which match the liability of the contact.')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              {/* @ts-ignore */}
              <PowerSelect
                options={vatLiabilityOptions}
                value={selectedVatLiabilityOptions}
                onChange={this.onTaxVATLiabilityChange}
                noOptionsMessage={(value) => String(t('TaxModal::No VAT liability found'))}
                theme={ReactSelectTheme}
                // @ts-ignore
                isMulti
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderRules() {
    const { t } = this.props
    const { activeTab, tax, activeTranslationsLocale } = this.state;

    if (activeTab !== TaxModalTab.RULES) return null;

    const localeOptions = LocaleHelper.getLocaleOptions()
    const selectedLocaleOption = localeOptions.find(option => option.value === activeTranslationsLocale)

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('TaxModal::Language')}
              </label>
              <PowerSelect
                options={localeOptions}
                value={selectedLocaleOption}
                onChange={this.onTaxRateLocaleChange}
                noOptionsMessage={(value) => t('TaxModal::No language found.')}
                theme={ReactSelectTheme}
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('TaxModal::Invoice VAT rules')}
                <Tooltip
                  content={t('TaxModal::Default text to be included on every invoice that uses this VAT rate.')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>

              <textarea
                value={MobilityHelper.getTranslation(activeTranslationsLocale, tax.translations, 'invoice_rule') || ''}
                onChange={this.onTaxRateInvoiceTextChange}
                placeholder={t('TaxModal::Default text to be included on every invoice that uses this VAT rate.')}
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('TaxModal::Creditnote VAT rules')}
                <Tooltip
                  content={t('TaxModal::Default text to be included on every credit note that uses this VAT rate.')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>
              <textarea
                value={MobilityHelper.getTranslation(activeTranslationsLocale, tax.translations, 'credit_note_rule') || ''}
                onChange={this.onTaxRateCreditNoteTextChange}
                placeholder={t('TaxModal::Default text to be included on every credit note that uses this VAT rate.')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { tax, t } = this.props
    const { activeTab, didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={tax?.id ? t('TaxModal::Edit VAT rate') : t('TaxModal::Create VAT rate')}
          onCloseClick={this.onTaxModalCloseClick}
          navigation={<ModalNavigation>
            <ModalNavigationItem active={activeTab === TaxModalTab.DETAILS} onClick={() => this.setState({ activeTab: TaxModalTab.DETAILS })}>
              <Icon icon='info' />
              <span>
                {t('TaxModal::Details')}
              </span>
            </ModalNavigationItem>
            <ModalNavigationItem active={activeTab === TaxModalTab.RULES} onClick={() => this.setState({ activeTab: TaxModalTab.RULES })}>
              <Icon icon='scale-balanced' />
              <span>
                {t('TaxModal::Rules')}
              </span>
            </ModalNavigationItem>
          </ModalNavigation>}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              {this.renderRules()}
              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('TaxModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      taxModal: {
        tax,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    tax: tax,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeTaxModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TaxModal))