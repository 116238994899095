import * as React from 'react'
import XMLEmbedPreview from './XMLEmbedPreview'
import UnknownPreview from './UnknownPreview'

interface IProps {
	filename: string
	source: string
	contentType: string
}

const AttachmentPreviewer = (props: IProps) => {
	const { filename, source, contentType } = props

	if (source) {
		switch (contentType) {
			case 'application/pdf':
				return <iframe src={source} />
			case 'image/jpeg':
			case 'image/png':
			case 'image/jpg':
			case 'image/svg+xml':
				return <img src={source} />
			case 'application/vnd.ms-excel':
			case 'application/msword':
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			case 'application/vnd.ms-powerpoint':
			case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
				return <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(source)}`} />
			case 'text/xml':
			case 'application/xml':
				return (
					<XMLEmbedPreview
						filename={filename}
						source={source}
						contentType={contentType}
					/>
				)
			case 'audio/mpeg':
			case 'video/mpeg':
			case 'video/mp4':
			case 'video/webm':
			case 'video/x-msvideo':
			case 'text/plain':
			case 'text/css':
			case 'text/html':
			case 'application/x-httpd-php':
			case 'application/vnd.apple.keynote':
			case 'application/vnd.apple.pages':
			case 'application/vnd.apple.numbers':
			case 'image/psd':
			case 'application/psd':
			case 'application/photoshop':
			case 'application/x-photoshop':
			case 'image/vnd.adobe.photoshop':
			case 'image/tiff':
			case 'application/dxf':
			case 'application/postscript':
			case 'font/ttf':
			case 'application/zip':
			case 'application/vnd.rar':
			default:
				return (
					<UnknownPreview
						filename={filename}
						source={source}
						contentType={contentType}
					/>
				)
		}
	}
}

export default AttachmentPreviewer