import i18n from "../translations";
import { CallDirection, CallOutcome } from "../types";

export default class CallHelper {
  static getOutcomeLabel(outcome: CallOutcome): string {
    return i18n.t(`CallOutcome::${outcome}`)
  }

  static getOutcomeOptions(): { label: string, value: string }[] {
    return Object.keys(CallOutcome).map((key: string) => {
      return {
        label: this.getOutcomeLabel(CallOutcome[key]),
        value: CallOutcome[key]
      }
    })
  }

  static getCallDirectionLabel(direction: CallDirection): string {
    return i18n.t(`CallDirection::${direction}`)
  }

  static getDirectionOptions(): { label: string, value: string }[] {
    return Object.keys(CallDirection).map((key: string) => {
      return {
        label: this.getCallDirectionLabel(CallDirection[key]),
        value: CallDirection[key]
      }
    })
  }
}