import * as React from 'react'
import { closeLedgerItemClaimModal, closeMobileAppModal } from '../../store/modals/actions'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ModalWindow from './Parts/ModalWindow'
import ModalHeader from './Parts/ModalHeader'
import ModalLoader from './Parts/ModalLoader'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { InvoicesController } from '../../controllers'
import { DisplayableError, IntegrationType, LedgerItem, UnpaidClaim } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'
import showdown from '../../utilities/showdown'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router'
import Button from '../Button/Button'
import BalanceList from '../Balance/BalanceList'
import BalanceListContent from '../Balance/BalanceListContent'
import BalanceListItem from '../Balance/BalanceListItem'
import NumberFormatter from '../../utilities/NumberFormatter'
import Images from '../../images'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'

interface IStateToProps {
	ledgerItemId: string
	onSubmit?: () => void
}

const Description = styled.div`
  font-size: 16px;
`

interface IDispatchToProps {
	close: typeof closeLedgerItemClaimModal
}

type IProps = IDispatchToProps & IStateToProps & RouteComponentProps<any>

interface IState {
	didInitialLoad: boolean
	ledgerItem: LedgerItem
	integrationEnabled: boolean
	claimed: boolean
	claim: UnpaidClaim
	errors: DisplayableError[]
	processing: boolean
}

const LedgerItemClaimModal = (props: IProps) => {
	const { t } = useTranslation()
	const currentUser = useSelector((state: AppState) => state.authentication.currentUser)
	const { workspace: { setting } } = currentUser
	const { close, history } = props

	const [state, setState] = React.useState<IState>({
		didInitialLoad: false,
		ledgerItem: null,
		integrationEnabled: false,
		claimed: false,
		claim: null,
		errors: [],
		processing: false,
	})
	const {
		didInitialLoad,
		ledgerItem,
		integrationEnabled,
		claimed,
		claim,
		errors,
		processing
	} = state

	React.useEffect(() => {
		fetchForm().catch(console.error)
	}, [ledgerItem && ledgerItem.claim_id])

	const fetchForm = async () => {
		try {
			const claimForm = await InvoicesController.claimForm(props.ledgerItemId)

			setState({
				...state,
				ledgerItem: claimForm.ledgerItem,
				claim: claimForm.claim,
				claimed: claimForm.claimed,
				integrationEnabled: claimForm.integration_enabled,
				didInitialLoad: true,
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	const onSubmitClaim = async () => {
		setState({ ...state, processing: true })

		const response = await InvoicesController.startClaim(props.ledgerItemId)

		if (response.claim_id) {
			setState({ ...state, ledgerItem: response, processing: false })
		} else {
			setState({ ...state, errors: response.errors, processing: false })
		}
	}

	const onEnableIntegration = () => {
		close()
		history.push(RouteHelper.process(ERoute.PATH_INTEGRATION, { type: IntegrationType.UNPAID }))
	}
	const onPayAdvanceClick = () => {
		window.location.assign(claim.payment_url)
	}

	const onModalCloseClick = () => {
		close()
	}

	const onErrorsDismiss = () => {
		setState({ ...state, errors: [] })
	}

	const getTitle = () => {
		if (didInitialLoad) {
			if (!integrationEnabled) {
				return t('LedgerItemClaimModal::Enable integration to recover your invoice automatically')
			} else if (claimed && claim) {
				switch (Number(claim.claim_status)) {
					case 5:
						return t('LedgerItemClaimModal::We\'re processing your claim')
					case 6:
						return t('LedgerItemClaimModal::Claim processed successfully')
					default:
						return t('LedgerItemClaimModal::Payment required')
				}
			}
		}

		return t('LedgerItemClaimModal::Recover your unpaid invoice')
	}

	const renderModalContent = () => {
		if (!integrationEnabled) {
			return (
				<Description
					dangerouslySetInnerHTML={{
						__html: showdown.makeHtml(t('LedgerItemClaimModal::Enable our Unpaid integration to get your invoice paid automatically through an official baillif.'))
					}}
				/>
			)
		} else if (claimed && claim) {
			if (Number(claim.claim_status) === 5) {
				return (
					<img src={Images.WORKING} style={{ maxHeight: 335 }} />
				)
			} else if (Number(claim.claim_status) === 6) {
				return (
					<img src={Images.PIGGY_BANK} style={{ maxHeight: 335 }} />
				)
			} else {
				const {
					calculations: {
						table: {
							amount_due,
							amount_due_no_interest,
							bailiff_pay: bailiffPay
						}
					}
				} = claim

				const invoiceTotal = Number(amount_due_no_interest)
				const interest = Number(amount_due) - Number(invoiceTotal)
				const totalToBePaid = invoiceTotal + Number(bailiffPay) + interest

				return (
					<>
						<BalanceList>
							<BalanceListContent>
								<BalanceListItem
									label={t("LedgerItemClaimModal::Invoice total")}
									value={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, invoiceTotal)}
								/>
								<BalanceListItem
									label={t("LedgerItemClaimModal::Interest")}
									value={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, interest)}
								/>
								<BalanceListItem
									label={t("LedgerItemClaimModal::Advance charges")}
									value={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, bailiffPay)}
								/>
								<BalanceListItem
									label={t("LedgerItemClaimModal::Total sum to be paid by your customer")}
									value={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, totalToBePaid)}
									labelStyles={{ fontWeight: 'bold', color: 'black' }}
									valueStyles={{ fontWeight: 'bold', color: 'black' }}
								/>
							</BalanceListContent>

							<Description
								style={{ marginTop: 14 }}
								dangerouslySetInnerHTML={{
									__html: showdown.makeHtml(t('LedgerItemClaimModal::The upfront costs of: <b>{{advance}}</b> will be recovered through the counterparty.<br /><br />After payment of the advance, the claim will be processed.', { advance: NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, bailiffPay) }))
								}}
							/>
						</BalanceList>
					</>
				)
			}
		} else {
			return (
				<Description
					dangerouslySetInnerHTML={{
						__html: showdown.makeHtml(t('LedgerItemClaimModal::Unpaid and undisputed B2B invoices can be recovered through Unpaid. Unpaid engages a bailiff to recover the outstanding amount of the invoice from your customer. <br /><br />When starting your application through Unpaid, you only pay a deposit which you get back upon the successful completion of your application.'))
					}}
				/>
			)
		}

		return null
	}

	const renderModalFooter = () => {
		if (!integrationEnabled) {
			return (
				<div className='modal-footer'>
					<div />
					<div className='modal-footer-actions'>
						<div key='main-action' className='popover-wrapper'>
							<TooltipError errors={errors} onDismiss={onErrorsDismiss} />
							<Button
								text={t('LedgerItemClaimModal::Enable integration')}
								onClick={onEnableIntegration}
								type='success'
								isLoading={processing}
							/>
						</div>
					</div>
				</div>
			)
		}

		if (claimed && claim && Number(claim.claim_status) >= 5) return null

		return (
			<div className='modal-footer'>
				<div />
				<div className='modal-footer-actions'>
					{!claimed && <>
						<div key='main-action' className='popover-wrapper'>
							<TooltipError errors={errors} onDismiss={onErrorsDismiss} />
							<Button
								text={t('LedgerItemClaimModal::Submit claim')}
								onClick={onSubmitClaim}
								type='success'
								isLoading={processing}
							/>
						</div>
					</>}

					{claimed && <>
						<div key='main-action' className='popover-wrapper'>
							<TooltipError errors={errors} onDismiss={onErrorsDismiss} />
							<Button
								text={t('LedgerItemClaimModal::Pay advance')}
								onClick={onPayAdvanceClick}
								type='success'
								isLoading={processing}
							/>
						</div>
					</>}
				</div>
			</div>
		)
	}

	return (
		<ModalWindow>
			<ModalHeader
				title={getTitle()}
				onCloseClick={onModalCloseClick}
			/>
			{!didInitialLoad && <ModalLoader />}
			{didInitialLoad && <ModalMiddle>
				<ModalContent>
					{renderModalContent()}
				</ModalContent>
				{renderModalFooter()}
			</ModalMiddle>}
		</ModalWindow>
	)
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		modals: {
			ledgerItemClaimModal: {
				ledgerItemId,
				onSubmit,
			}
		}
	} = state

	return {
		ledgerItemId: ledgerItemId,
		onSubmit: onSubmit,
	}
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
	return {
		close: () => dispatch(closeLedgerItemClaimModal()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LedgerItemClaimModal))