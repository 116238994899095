import * as React from 'react'
import { saveAs } from 'file-saver'
import mustache from 'mustache'
import { closeImportTimeEntriesModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import TooltipError from '../Tooltips/ErrorTooltip'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import moment from '../../utilities/Moment'
import { TimeEntriesController } from '../../controllers'
import { Moment } from 'moment'
import DateInput from '../Form/DateInput'
import TimeFormatter from '../../utilities/TimeFormatter'
import NumberFormatter from '../../utilities/NumberFormatter'
import PowerSelect from '../Form/PowerSelect'
import SummaryContainer from '../Summary/SummaryContainer'
import SummaryItem from '../Summary/SummaryItem'
import ModalContent from './Parts/ModalContent'
import Switch from '../Form/Switch'
import { WithTranslation, withTranslation } from 'react-i18next'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import LocalStorage, { LocalStorageKey } from '../../LocalStorage'
import { CurrentUser, GroupedTimeEntries, ImportTimeEntryGroupByOption, ProjectStatus, SpreadsheetExportType, TimeEntry, TimeEntryExportType } from '../../types'
import Editor, { VARIABLE_EDITOR_CONFIG } from '../Editor/Editor'
import EditorHelper from '../../helpers/EditorHelper'
import TimeEntryHelper from '../../helpers/TimeEntryHelper'

interface IStateToProps {
  currentUser: CurrentUser
  onSubmit?: (groupedTimeEntries: GroupedTimeEntries[], travelDistance?: number) => void
  contactId?: string
  projectId?: string
}

interface IDispatchToProps {
  close: typeof closeImportTimeEntriesModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  timeEntries: TimeEntry[],
  contactId?: string
  projectIds?: string[]
  groupBy: ImportTimeEntryGroupByOption
  description: string
  from: Moment | null
  to: Moment | null
  generateExport: boolean
  chargeRelocationFee: boolean
  errors: any
}

class ImportTimeEntriesModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      timeEntries: [],
      contactId: props.contactId,
      projectIds: props.projectId ? [props.projectId] : [],
      groupBy: LocalStorage.get(LocalStorageKey.IMPORT_ENTRIES_GROUP_BY, ImportTimeEntryGroupByOption.CUSTOM_DESCRIPTION),
      description: LocalStorage.get(LocalStorageKey.IMPORT_ENTRIES_CUSTOM_DESCRIPTION, ''),
      from: moment().startOf('month'),
      to: moment().endOf('month'),
      generateExport: false,
      chargeRelocationFee: true,
      errors: {},
    }

    this.onImportTimeEntriesModalCloseClick = this.onImportTimeEntriesModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)

    // Form Handlers
    this.onContactChange = this.onContactChange.bind(this)
    this.onProjectChange = this.onProjectChange.bind(this)
    this.onGroupByChange = this.onGroupByChange.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onFromChange = this.onFromChange.bind(this);
    this.onToChange = this.onToChange.bind(this);
    this.onToggleChargeRelocationFee = this.onToggleChargeRelocationFee.bind(this);
    this.onGenerateExportClick = this.onGenerateExportClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchEntries().catch(console.error)
  }

  async fetchEntries() {
    const { from, to, contactId, projectIds } = this.state;

    let queryData = {}

    if (contactId) queryData = { ...queryData, contact_id: contactId }
    if (projectIds && projectIds.length > 0) queryData = { ...queryData, 'project_id[in]': projectIds }

    if (from && to) {
      const start = moment(from).format('YYYY-MM-DD');
      const end = moment(to).format('YYYY-MM-DD');
      queryData = { ...queryData, range: `${start}..${end}` }
    }

    queryData = {
      ...queryData,
      billable: true,
      billed: false,
      includes: ''
    }

    try {
      const timeEntries = await TimeEntriesController.importEntries(queryData)

      this.setState({
        timeEntries: timeEntries,
        from: from,
        to: to,
        didInitialLoad: true
      });

    } catch (ex) {
      console.error(ex)
    }
  }

  getEntriesTotalCount() {
    const { timeEntries } = this.state

    return timeEntries.length;
  }

  getEntriesTotalDuration() {
    const { timeEntries } = this.state

    return timeEntries.reduce((currentValue, entry) => (entry.duration + currentValue), 0);
  }

  getEntriesTotalAmount() {
    const { timeEntries } = this.state

    return timeEntries.reduce((currentValue, entry) => (Number(entry.amount) + currentValue), 0)
  }

  onContactChange(value?: string) {
    const newcontactId = value ? value : null

    this.setState({ contactId: newcontactId, projectIds: [] }, () => {
      this.fetchEntries()
    })
  }

  onProjectChange(value?: string[]) {
    this.setState({ projectIds: value }, () => {
      this.fetchEntries()
    })
  }

  onGroupByChange(option) {
    const { description } = this.state
    const groupByValue = option ? option.value : null

    LocalStorage.set(LocalStorageKey.IMPORT_ENTRIES_GROUP_BY, groupByValue)

    this.setState({
      groupBy: groupByValue,
      description: option.value !== 'total' ? '' : description
    }, () => {
      this.fetchEntries()
    })
  }

  onDescriptionChange(description: string) {
    const { groupBy } = this.state

    this.setState({
      description: description
    })

    if (groupBy === ImportTimeEntryGroupByOption.CUSTOM_DESCRIPTION) {
      LocalStorage.set(LocalStorageKey.IMPORT_ENTRIES_CUSTOM_DESCRIPTION, description)
    }
  }

  onFromChange(from) {
    const newFrom = moment(from)

    if (newFrom.isValid()) {
      this.setState({
        from: from,
      }, () => {
        this.fetchEntries().catch(console.error)
      });
    }
  }

  onToChange(to) {
    const newTo = moment(to)

    if (newTo.isValid()) {
      this.setState({
        to
      }, () => {
        this.fetchEntries().catch(console.error)
      });
    }
  }

  onToggleChargeRelocationFee(e) {
    e.preventDefault()

    const { chargeRelocationFee } = this.state

    this.setState({ chargeRelocationFee: !chargeRelocationFee })
  }

  onGenerateExportClick(e) {
    e.preventDefault()

    const { generateExport } = this.state

    this.setState({ generateExport: !generateExport })
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { close, onSubmit } = this.props
    const { generateExport, chargeRelocationFee } = this.state

    try {
      const groupedEntries = this.getGroupedEntries()
      const travelDistance = chargeRelocationFee ? this.getTravelDistance() : null

      if (onSubmit) onSubmit(groupedEntries, travelDistance)
      if (generateExport) await this.generateExport()
    } catch (ex) {
      console.error(ex)
    } finally {
      close()
    }
  }

  getCustomDescription(timeEntry: TimeEntry) {
    const { description } = this.state
    const htmlStripper = document.createElement("div")

    htmlStripper.innerHTML = description
      // Replace first occurence of <p> with nothing
      .replace('<p>', '')
      // Replace all occurences of <br> and <p> with a new line
      .replace(/\<br>|<p>/g, '\n')

    return mustache.render(htmlStripper.innerText, TimeEntryHelper.getVariables(timeEntry), undefined, { escape: (text) => text })
  }

  getGroupedEntries(): GroupedTimeEntries[] {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { timeEntries, groupBy } = this.state

    const groupedTimeEntries: GroupedTimeEntries[] = []

    switch (groupBy) {
      case ImportTimeEntryGroupByOption.CUSTOM_DESCRIPTION:
        timeEntries
          .forEach((timeEntry, index) => {
            const timeEntryDescription = this.getCustomDescription(timeEntry)

            const groupedTimeEntryIndex = groupedTimeEntries
              .findIndex((groupedTimeEntry) => {
                return (
                  groupedTimeEntry.description === timeEntryDescription &&
                  groupedTimeEntry.rate === timeEntry.rate &&
                  groupedTimeEntry.fixed_rate === timeEntry.fixed_rate
                )
              })

            if (groupedTimeEntryIndex !== -1) {
              groupedTimeEntries[groupedTimeEntryIndex].entries.push(timeEntry)
            } else {
              groupedTimeEntries.push({
                description: timeEntryDescription,
                rate: timeEntry.rate,
                entries: [timeEntry],
                fixed_rate: timeEntry.fixed_rate,
              })
            }
          })

        return groupedTimeEntries
      case ImportTimeEntryGroupByOption.DESCRIPTION:
        timeEntries
          .forEach((timeEntry, index) => {
            const groupedTimeEntryIndex = groupedTimeEntries.findIndex((groupedTimeEntry) => {
              return (
                groupedTimeEntry.description === timeEntry.description &&
                groupedTimeEntry.rate === timeEntry.rate &&
                groupedTimeEntry.fixed_rate === timeEntry.fixed_rate
              )
            })

            if (groupedTimeEntryIndex !== -1) {
              groupedTimeEntries[groupedTimeEntryIndex].entries.push(timeEntry)
            } else {
              groupedTimeEntries.push({
                description: timeEntry.description,
                rate: timeEntry.rate,
                entries: [timeEntry],
                fixed_rate: timeEntry.fixed_rate,
              })
            }
          })
        return groupedTimeEntries
      case ImportTimeEntryGroupByOption.DATE:
        timeEntries
          .forEach((timeEntry, index) => {
            const startedAtMoment = moment(timeEntry.started_at)
            const timeEntryDescription = startedAtMoment.format(setting.date_format)

            const groupedTimeEntryIndex = groupedTimeEntries.findIndex((groupedTimeEntry) => {
              return (
                groupedTimeEntry.description === timeEntryDescription &&
                groupedTimeEntry.rate === timeEntry.rate &&
                groupedTimeEntry.fixed_rate === timeEntry.fixed_rate
              )
            })

            if (groupedTimeEntryIndex !== -1) {
              groupedTimeEntries[groupedTimeEntryIndex].entries.push(timeEntry)
            } else {
              groupedTimeEntries.push({
                description: timeEntryDescription,
                rate: timeEntry.rate,
                entries: [timeEntry],
                fixed_rate: timeEntry.fixed_rate
              })
            }
          })

        return groupedTimeEntries
      case ImportTimeEntryGroupByOption.DATE_HOUR_DESCRIPTION:
        timeEntries
          .forEach((timeEntry, index) => {
            const description = `${moment(timeEntry.started_at).format(setting.date_format)} - ${timeEntry.description} | ${TimeFormatter.durationFormat(timeEntry.duration, setting.time_format)} | ${moment(timeEntry.started_at).format('HH:mm')} - ${moment(timeEntry.ended_at).format('HH:mm')}`

            groupedTimeEntries.push({
              description: description,
              rate: timeEntry.rate,
              entries: [timeEntry],
              fixed_rate: timeEntry.fixed_rate,
            })
          })
        return groupedTimeEntries
      case ImportTimeEntryGroupByOption.WORK_TYPE:
        timeEntries
          .forEach((timeEntry, index) => {
            const timeEntryDescription = timeEntry?.work_type?.name || t('ImportTimeEntriesModal::No work type')

            const groupedTimeEntryIndex = groupedTimeEntries
              .findIndex((groupedTimeEntry) => (groupedTimeEntry.description === timeEntryDescription))

            if (groupedTimeEntryIndex !== -1) {
              groupedTimeEntries[groupedTimeEntryIndex].entries.push(timeEntry)
            } else {
              groupedTimeEntries.push({
                description: timeEntryDescription,
                rate: timeEntry.rate,
                entries: [timeEntry],
                fixed_rate: timeEntry.fixed_rate,
              })
            }
          })
        return groupedTimeEntries
      default: throw new Error(`Unsupported group by options ${groupBy}`)
    }
  }

  getTravelDistance(): number {
    const { timeEntries } = this.state

    return timeEntries
      .reduce((travelDistance, timeEntry) => (travelDistance += Number(timeEntry.travel_distance)), 0)
  }

  async generateExport() {
    const { contactId, projectIds, from, to } = this.state

    try {
      let range = `${moment(from).format('DD-MM-YYYY')}..${moment(to).format('DD-MM-YYYY')}`

      let queryData = {}
      if (contactId) queryData = { ...queryData, contact_id: contactId }
      if (projectIds && projectIds.length > 0) queryData = { ...queryData, 'project_id[in]': projectIds }
      if (range) queryData = { ...queryData, range: range }

      queryData = {
        ...queryData,
        type: TimeEntryExportType.BILLABLE,
        spreadsheet_type: LocalStorage.get(LocalStorageKey.PREFERRED_EXPORT_FORMAT, SpreadsheetExportType.CSV)
      }

      // Get blob result
      const result: any = await TimeEntriesController.export(queryData)

      // Blog destructering
      const { blob, filename } = result

      // Use save as library
      saveAs(blob, filename)
    } catch (ex) {
      console.error(ex)
    }
  }

  onImportTimeEntriesModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  render() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const {
      didInitialLoad,
      contactId,
      projectIds,
      groupBy,
      description,
      from,
      to,
      generateExport,
      chargeRelocationFee,
      errors,
      timeEntries
    } = this.state

    const totalCount = this.getEntriesTotalCount();
    const totalDuration = this.getEntriesTotalDuration();
    const totalAmount = this.getEntriesTotalAmount();

    let groupByOptions = [
      { label: t('ImportTimeEntriesModal::Custom description'), value: String(ImportTimeEntryGroupByOption.CUSTOM_DESCRIPTION) },
      { label: t('ImportTimeEntriesModal::Work type'), value: String(ImportTimeEntryGroupByOption.WORK_TYPE) },
      { label: t('ImportTimeEntriesModal::Description'), value: String(ImportTimeEntryGroupByOption.DESCRIPTION) },
      { label: t('ImportTimeEntriesModal::Date'), value: String(ImportTimeEntryGroupByOption.DATE) },
      { label: t('ImportTimeEntriesModal::Date - Description - Duration'), value: String(ImportTimeEntryGroupByOption.DATE_HOUR_DESCRIPTION) },
    ]

    const selectedGroupByOption = groupByOptions.find(option => option.value === groupBy)
    const travelDistance = this.getTravelDistance()
    const editorVariables = timeEntries[0] ? EditorHelper.getVariableOptions(TimeEntryHelper.getVariables(timeEntries[0])) : []

    return (
      <ModalWindow>
        <ModalHeader
          title={t('ImportTimeEntriesModal::Import time entries')}
          onCloseClick={this.onImportTimeEntriesModalCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('ImportTimeEntriesModal::Contact')}</label>
                    <ResourceCreatablePowerSelect
                      type='contact'
                      value={contactId}
                      params={{ archived: false }}
                      onChange={this.onContactChange}
                      isClearable={true}
                      isDisabled={Boolean(this.props.contactId)}
                    />
                  </div>
                </div>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('ImportTimeEntriesModal::Project')}</label>
                    <ResourceCreatablePowerSelect
                      type='project'
                      value={projectIds}
                      onChange={this.onProjectChange}
                      isDisabled={!contactId}
                      isClearable={true}
                      isMulti={true}
                      params={{ 'contact_id': contactId, 'status[in]': [ProjectStatus.PROPOSAL, ProjectStatus.ACTIVE] }}
                      isValidNewOption={() => false}
                    />
                  </div>
                </div>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('ImportTimeEntriesModal::Group by')}</label>
                    <PowerSelect
                      options={groupByOptions}
                      value={selectedGroupByOption}
                      onChange={this.onGroupByChange}
                      noOptionsMessage={() => t('ImportTimeEntriesModal::No options found')}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>
                <div className='grid-cell with-12col'>
                  {groupBy === ImportTimeEntryGroupByOption.CUSTOM_DESCRIPTION && <>
                    <div className='form-item'>
                      <label>{t('ImportTimeEntriesModal::Description')}</label>
                      <Editor
                        key={String(editorVariables.length)}
                        model={description}
                        onModelChange={this.onDescriptionChange}
                        config={{
                          ...VARIABLE_EDITOR_CONFIG,
                          heightMin: 120,
                          heightMax: 125,
                          placeholderText: t('ImportTimeEntriesModal::General description (visible on invoice)'),
                          variableOptions: editorVariables,
                        }}
                      />
                    </div>
                  </>}
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('ImportTimeEntriesModal::Start')}</label>
                    <DateInput
                      name='from'
                      dateFormat={setting.date_format}
                      initialValue={from}
                      timeFormat={false}
                      inputProps={{ placeholder: setting.date_format }}
                      onChange={this.onFromChange}
                      closeOnSelect
                    />
                  </div>
                </div>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('ImportTimeEntriesModal::End')}</label>
                    <DateInput
                      name='to'
                      dateFormat={setting.date_format}
                      inputProps={{ placeholder: setting.date_format }}
                      initialValue={to}
                      timeFormat={false}
                      onChange={this.onToChange}
                      closeOnSelect
                    />
                  </div>
                </div>

                {travelDistance > 0 && <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <Switch
                      name='generate_export'
                      label={t('ImportTimeEntriesModal::Charge relocation fee')}
                      checked={chargeRelocationFee}
                      onClick={this.onToggleChargeRelocationFee}
                    />
                  </div>
                </div>}
              </div>

              <div className='fields-section is-last'>
                <div className='fieldset'>
                  <SummaryContainer columnCount={3}>
                    <SummaryItem
                      title={t('ImportTimeEntriesModal::Uninvoiced entries')}
                      label={`${totalCount}`}
                    />
                    <SummaryItem
                      title={t('ImportTimeEntriesModal::Duration')}
                      label={`${TimeFormatter.durationFormat(totalDuration, setting.time_format)}`}
                    />
                    <SummaryItem
                      title={t('ImportTimeEntriesModal::Total amount')}
                      label={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, totalAmount)}
                    />
                  </SummaryContainer>
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>
          <div className='modal-footer'>
            <div>
              <Switch
                name='generate_export'
                label={t('ImportTimeEntriesModal::Generate CSV export')}
                checked={generateExport}
                onClick={this.onGenerateExportClick}
              />
            </div>
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('ImportTimeEntriesModal::Import')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      importTimeEntriesModal: {
        onSubmit,
        contactId,
        projectId,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    onSubmit: onSubmit,
    contactId: contactId,
    projectId: projectId
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeImportTimeEntriesModal()),
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ImportTimeEntriesModal))