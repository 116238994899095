import styled, { css } from 'styled-components'
import { Style } from '../../styles'

const TaskButton = styled.div<{ success?: boolean, destructive?: boolean, fitContent?: boolean, center?: boolean, colorPermanent?: boolean, disabled?: boolean }>`
    position: relative;
    border-radius: 3px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 20px;
    margin-bottom: 8px;
    padding: 6px 12px;
    text-decoration: none;
    cursor: pointer;
    background-color: rgba(9,30,66,.04);
    box-shadow: none;
    border: none;
    color: #172b4d;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: 6px;

    &:hover {
        background-color: rgba(9,30,66,.08);
        color: #091e42;
    }
    i {
        font-size: 14px;
        color: rgb(66, 82, 110);
    }
    svg {
        width: 14px;
        height: 14px;
        min-width: 14px;
        fill: rgb(66, 82, 110);
    }

    ${(props) => props.fitContent && css`
      width: fit-content;
    `}

    ${(props) => props.success && css`
        background-color: ${Style.color.brandSuccess};
        color: white;

        i, svg {
            color: white;
            fill: white;
        }

        &:hover {
            background-color: ${Style.color.brandSuccess};
            color: white;
            i, svg {
                color: white;
                fill: white;
            }
        }
    `}

    ${(props) => props.destructive && css`
        ${props.colorPermanent && css`
            background-color: ${Style.color.brandDanger};
            color: white;
        `}
        &:hover {
            background-color: ${Style.color.brandDanger};
            color: white;
            i, svg {
                color: white;
                fill: white;
            }
        }
    `}

    ${(props) => props.disabled && css`
        background-color: rgba(9,30,66,.04);
        color: #172b4d;
        cursor: not-allowed;

        &:hover {
            background-color: rgba(9,30,66,.04);
            color: #172b4d;

            i, svg {
                color: rgb(66, 82, 110);
                fill: rgb(66, 82, 110);
            }
        }
    `}

    ${(props) => props.center && css`
        justify-content: center;
    `}

    span {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export default TaskButton