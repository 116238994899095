import * as React from 'react'
import { closeMoveFilesModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import { FilesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Button from '../Button/Button';
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { BizzeyFile, CurrentUser } from '../../types'

interface IStateToProps {
  currentUser: CurrentUser
  baseResourcePath: string
  fileIds: string[]
  onSubmit?: (file: BizzeyFile) => void
}

interface IDispatchToProps {
  close: typeof closeMoveFilesModal
}

type IProps = IDispatchToProps & IStateToProps

const enum MoveFilesModalTab {
  DETAILS = 'details',
}

interface IState {
  didInitialLoad: boolean
  file: BizzeyFile | null
  errors: any
  isSubmitting: boolean
  activeTab: MoveFilesModalTab
}

class MoveFilesModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      file: null,
      errors: {},
      isSubmitting: false,
      activeTab: MoveFilesModalTab.DETAILS,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this)
    this.onNameChange = this.onNameChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onMoveFilesModalClose = this.onMoveFilesModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { fileIds } = this.props

    try {
      const response = await FilesController.getTreeView({ 'ids': fileIds })

      this.setState({ didInitialLoad: true })
    } catch (ex) {
      console.error(ex)
    }
  }

  onNavigationItemClick(e) {
    const activeTab = e.currentTarget.getAttribute('data-tab');

    this.setState({
      activeTab: activeTab
    })
  }

  onNameChange(e) {
    const newName = e.currentTarget.value;

    const { file } = this.state;

    file.name = newName;

    this.setState({
      file
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
  }

  onMoveFilesModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderNavigation(): JSX.Element {
    const { activeTab } = this.state

    return (
      <ModalNavigation>
        <ModalNavigationItem active={activeTab === MoveFilesModalTab.DETAILS} data-tab={MoveFilesModalTab.DETAILS} onClick={this.onNavigationItemClick}>
          <Icon icon='info' />
          <span>
            Details
          </span>
        </ModalNavigationItem>
      </ModalNavigation>
    )
  }

  render() {
    const { fileIds } = this.props
    const { didInitialLoad, errors, isSubmitting, activeTab } = this.state

    const data = {
      name: 'root',
      toggled: true,
      children: [
        {
          name: 'parent',
          children: [
            { name: 'child1' },
            { name: 'child2' }
          ]
        },
        {
          name: 'loading parent',
          loading: true,
          children: []
        },
        {
          name: 'parent',
          children: [
            {
              name: 'nested parent',
              children: [
                { name: 'nested child 1' },
                { name: 'nested child 2' }
              ]
            }
          ]
        }
      ]
    };

    return (
      <ModalWindow>
        <ModalHeader
          title={'Bestanden verplaatsen'}
          onCloseClick={this.onMoveFilesModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                </div>
              </div>
              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />

                <Button
                  type='success'
                  text='Opslaan'
                  isLoading={isSubmitting}
                  onClick={this.onFormSubmit}
                />
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      moveFilesModal: {
        fileIds,
        baseResourcePath,
        onSubmit,
      }
    }
  } = state

  return {
    fileIds: fileIds,
    onSubmit: onSubmit,
    currentUser: currentUser,
    baseResourcePath: baseResourcePath,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeMoveFilesModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveFilesModal)