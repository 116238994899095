export default class OgmHelper {
	static generate(start?: number | string): string {
    let ogm = start ? start.toString().padStart(10, '0') : this.generateRandomOgm();

    const modulo: number = Number(ogm) % 97;

    if (modulo === 0) {
      ogm += '97';
    } else {
      ogm += modulo.toString().padStart(2, '0');
    }

    return ogm;
  }

  static format(ogm: string): string {
    return `+++ ${ogm.toString().substr(0, 3)} / ${ogm.toString().substr(3, 4)} / ${ogm.toString().substr(7)} +++`;
  }

  static unformat(ogm: string): string {
    return ogm.replace(/[^0-9]/g, '')
  }

  static validate(ogm: string): boolean {
    const parsedOgm = ogm.replace(/[^0-9]/g, '')

    return this.generate(parsedOgm.substr(0, 10)) === parsedOgm;
  }

  static generateRandomOgm(): string {
    return Array.from(Array(10).keys()).map(() => this.generateRandomNumber()).join('');
  }

  static generateRandomNumber(min: number = 0, max: number = 9): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}