import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Icon from '../Icons/Icon'
import { CalendarEventAttendee, CalendarEventAttendeeStatus } from '../../types'
import Avatar from '../Avatar/Avatar'
import Utils from '../../utilities/Utils'
import { useTranslation } from 'react-i18next'
import Popover from '../Popover/Popover'

const ItemAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  cursor: pointer;
  border-radius: 50%;

  svg {
		width: 16px;
		height: 16px;
		color: #42526e;
		fill: #42526e;
	}

  &:hover {
    background-color: #f1f3f4;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  padding: ${Style.spacing.x1} ${Style.spacing.x0_5};
  margin-bottom: ${Style.spacing.x0_5};
  border-radius: ${Style.variables.baseBorderRadius};
  cursor: pointer;

  &:hover {
    background: #f1f3f4;
  }

  span {
    flex: 1;
  }

  ${ItemAction} {
    margin-left: ${Style.spacing.x0_25};
  }
`

const AttendeeItemAvatar = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${Style.spacing.x1_5};
`

const AttendeeItemAvatarStatus = styled.div<{ status: CalendarEventAttendeeStatus }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -3px;
  bottom: -3px;
  background: white;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid ${Style.color.border};

  svg {
    width: 6px;
    height: 6px;
    fill: currentColor;
  }

  i {
    font-size: 6px;
    color: currentColor;
  }

  ${props => props.status === CalendarEventAttendeeStatus.ACCEPTED && css`
    background: ${Style.color.brandSuccess};
    color: white;
    border-color: ${Style.color.brandSuccess};

    svg {
      fill: currentColor;
    }

    i {
      color: currentColor;
    }
  `}

  ${props => props.status === CalendarEventAttendeeStatus.DECLINED && css`
    background: ${Style.color.brandDanger};
    color: white;
    border-color: ${Style.color.brandDanger};

    svg {
      fill: currentColor;
    }

    i {
      color: currentColor;
    }
  `}

  ${props => props.status === CalendarEventAttendeeStatus.TENTATIVE && css`
    background: #edeff0;
    color: #616E7C;
    border-color: #edeff0;
  `}

  ${props => CalendarEventAttendeeStatus.ACTION_REQUIRED === props.status && css`
    background: ${Style.color.brandWarning};
    color: white;
    border-color: white;

    svg {
      fill: currentColor;
    }

    i {
      color: currentColor;
    }
  `}
`

const AttendeeStatuses = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Style.color.border};
  overflow: hidden;
  border-radius: 10px;
  min-width: 450px;
  background: white;
  box-shadow: 0 2px 8px 1px #d6dde1;
  margin: 8px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    min-width: initial;
    max-width: 95vw;
    width: 100%;
    min-width: 280px;
    flex-direction: column;
  }
`

const AttendeeStatusIcon = styled.div<{ status: CalendarEventAttendeeStatus }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${Style.color.border};
  margin-right: 8px;

  svg {
    width: 12px;
    height: 12px;
    fill: currentColor;
  }

  i {
    font-size: 12px;
    color: currentColor;
  }

  ${props => props.status === CalendarEventAttendeeStatus.ACCEPTED && css`
    background: ${Style.color.brandSuccess};
    color: white;
    border-color: ${Style.color.brandSuccess};

    svg {
      fill: currentColor;
    }

    i {
      color: currentColor;
    }
  `}

  ${props => props.status === CalendarEventAttendeeStatus.DECLINED && css`
    background: ${Style.color.brandDanger};
    color: white;
    border-color: ${Style.color.brandDanger};

    svg {
      fill: currentColor;
    }

    i {
      color: currentColor;
    }
  `}

  ${props => props.status === CalendarEventAttendeeStatus.TENTATIVE && css`
    background: #edeff0;
    color: #616E7C;
    border-color: ${Style.color.border};
  `}

  ${props => CalendarEventAttendeeStatus.ACTION_REQUIRED === props.status && css`
    background: ${Style.color.brandWarning};
    color: white;
    border-color: ${Style.color.border};

    svg {
      fill: currentColor;
    }

    i {
      color: currentColor;
    }
  `}
`

const AttendeeStatus = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  min-height: 54px;
  font-size: 15px;
  border-right: 1px solid ${Style.color.border};
  border-collapse: collapse;
  flex: 1;
  font-weight: 500;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: rgba(9,30,66,.08);
    color: #091e42;
  }

  ${props => props.active && css`
    background-color: rgba(9,30,66,.08);
    color: #091e42;
  `}

  &:last-child {
    border-right: none
  }

  ${AttendeeStatusIcon} {
    display: none;

    ${props => props.active && css`
      display: flex;
    `}
  }
`

const ActivatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 100%;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const EmailIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${Style.spacing.x1};

  svg {
    width: 16px;
    height: 16px;
    fill: ${Style.color.brandWarning};
  }
`

interface IProps {
  attendee: CalendarEventAttendee
  onEditClick: () => void
  onRemoveClick: () => void
  onStatusChange: (status: CalendarEventAttendeeStatus) => void
}

interface IState {
  popoverActive: boolean
}

const AttendeeItem = (props: IProps) => {
  const { attendee } = props
  const { t } = useTranslation()
  const name = attendee.contact?.name
  const email = attendee.contact?.emails[0]?.value
  const [state, setState] = React.useState<IState>({ popoverActive: false })
  const { popoverActive } = state

  const onPopoverClick = () => {
    setState({ popoverActive: true })
  }

  const onPopoverClose = () => {
    setState({ ...state, popoverActive: false })
  }

  const onStatusChange = (status: CalendarEventAttendeeStatus) => {
    onPopoverClose()
    props.onStatusChange(status)
  }

  const onEditClick = () => {
    setTimeout(() => {
      onPopoverClose()
    }, 50)

    props.onEditClick()
  }

  const onRemoveClick = () => {
    setTimeout(() => {
      onPopoverClose()
    }, 50)

    props.onRemoveClick()
  }

  return (
    <>
      <Popover
        active={popoverActive}
        activator={
          <Container>
            <ActivatorContainer onClick={onPopoverClick}>
              <AttendeeItemAvatar>
                <Avatar
                  width={28}
                  rounded
                  name={Utils.getInitials(name).toUpperCase()}
                />

                <AttendeeItemAvatarStatus status={attendee.status}>
                  {attendee.status === CalendarEventAttendeeStatus.ACCEPTED && <Icon icon='check' />}
                  {attendee.status === CalendarEventAttendeeStatus.DECLINED && <Icon icon='close' />}
                  {[CalendarEventAttendeeStatus.TENTATIVE, CalendarEventAttendeeStatus.ACTION_REQUIRED].includes(attendee.status) && <Icon icon='question-mark' />}
                </AttendeeItemAvatarStatus>
              </AttendeeItemAvatar>

              <span>
                {name}

                {attendee?.contact?.emails?.length === 0 && <EmailIndicator data-tip={t('AttendeeItem::Calendar invite cannot be sent as no email address is associated with this contact.')}>
                  <Icon icon='envelope' />
                </EmailIndicator>}
              </span>
            </ActivatorContainer>

            <ItemAction onClick={onEditClick}>
              <Icon icon='edit-solid' />
            </ItemAction>

            <ItemAction onClick={onRemoveClick}>
              <Icon icon='close' />
            </ItemAction>
          </Container>
        }
        onClose={onPopoverClose}
        placement='top'
      >
        <AttendeeStatuses>
          <AttendeeStatus active={attendee.status === CalendarEventAttendeeStatus.ACCEPTED} onClick={() => onStatusChange(CalendarEventAttendeeStatus.ACCEPTED)}>
            <AttendeeStatusIcon status={CalendarEventAttendeeStatus.ACCEPTED}>
              <Icon icon='check' />
            </AttendeeStatusIcon>
            {t('AttendeeItem::Attending')}
          </AttendeeStatus>
          <AttendeeStatus active={[CalendarEventAttendeeStatus.TENTATIVE, CalendarEventAttendeeStatus.ACTION_REQUIRED].includes(attendee.status)} onClick={() => onStatusChange(CalendarEventAttendeeStatus.TENTATIVE)}>
            <AttendeeStatusIcon status={CalendarEventAttendeeStatus.TENTATIVE}>
              <Icon icon='question-mark' />
            </AttendeeStatusIcon>
            {t('AttendeeItem::Maybe')}
          </AttendeeStatus>
          <AttendeeStatus active={attendee.status === CalendarEventAttendeeStatus.DECLINED} onClick={() => onStatusChange(CalendarEventAttendeeStatus.DECLINED)}>
            <AttendeeStatusIcon status={CalendarEventAttendeeStatus.DECLINED}>
              <Icon icon='close' />
            </AttendeeStatusIcon>
            {t('AttendeeItem::Not attending')}
          </AttendeeStatus>
        </AttendeeStatuses>
      </Popover>
    </>

  )

}

export default AttendeeItem