import * as React from 'react'
import { closeSendContractModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import { ContractsController, SettingsController, TasksController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Notification from '../../utilities/Notification'
import MustacheHelper from '../../helpers/MustacheHelper'
import { updateSettings } from '../../store/authentication/actions'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import EmailPreview from '../EmailPreview/EmailPreview'
import ModalWindowWithPreview from './Parts/ModalWindowWithPreview'
import Editor, { EMAIL_EDITOR_CONFIG } from '../Editor/Editor'
import EditorHelper from '../../helpers/EditorHelper'
import EditorContainer from '../Editor/EditorContainer'
import MobilityHelper from '../../helpers/MobilityHelper'
import { Contact, CurrentUser, Locale, SendContractEmailParams, Settings, Task } from '../../types'
import Alert from '../Alert/Alert'
import Icon from '../Icons/Icon'
import ModalActionFollowUp from './Parts/ModalActionFollowUp'
import ButtonWithActions from '../Button/ButtonWithActions'

const ContractModalMiddle = styled(ModalMiddle)`
  height: calc(100% - 56px);
`

const ContractModalContent = styled(ModalContent)`
  overflow-y: auto;
`

const Content = styled.div`
  flex: 1;
`

const EmailOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const EmailOptionMainContainer = styled.div`
  flex: 1;
  width: 100%;
  margin-right: 8px;

  &:last-child {
    margin-right: 0px;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  id: string
  onSubmit?: () => void
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  close: typeof closeSendContractModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  locale?: Locale,
  contacts: string[]
  contactId?: string
  to: Contact[]
  subject: string | null
  body: string | null
  variables: any[]
  errors: any
  isSending: boolean
  task: Task
}

class SendContractModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      contacts: [],
      locale: null,
      contactId: null,
      subject: '',
      to: [],
      body: '',
      variables: [],
      errors: {},
      isSending: false,
      task: null,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onSendTestEmailClick = this.onSendTestEmailClick.bind(this)
    this.onFollowUpActionChange = this.onFollowUpActionChange.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onDefaultSubjectClick = this.onDefaultSubjectClick.bind(this)
    this.onDefaultBodyClick = this.onDefaultBodyClick.bind(this)
    this.onSubjectChange = this.onSubjectChange.bind(this)
    this.onBodyChange = this.onBodyChange.bind(this)
    this.onSendLedgerItemCloseModalClick = this.onSendLedgerItemCloseModalClick.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { id } = this.props

    try {
      const response = await ContractsController.getEmailForm({ id: id })

      const {
        locale,
        contact_id,
        signees,
        subject,
        body,
        variables
      } = response

      this.setState({
        didInitialLoad: true,
        contacts: [],
        locale: locale,
        contactId: contact_id,
        to: signees,
        subject: subject,
        body: body,
        variables: variables
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  async onFormSubmit(e?: React.FormEvent, testEmail: boolean = false) {
    e?.preventDefault();

    const { id, onSubmit, close, t } = this.props
    const { to, subject, body, task, variables } = this.state;

    try {
      this.setState({ isSending: true })
      const emailParams: SendContractEmailParams = {
        to: to.map(signee => signee.id),
        subject: subject,
        body: body,
        test_email: testEmail
      }

      const response = await ContractsController.send(id, emailParams)

      const { errors } = response;

      if (errors) {
        this.setState({ errors: errors });
        Notification.notifyError(t('SendContractModal::Oops something went wrong'))
      }
      else {
        Notification.notifySuccess(t('SendContractModal::Contract successfully send'))

        if (task && !testEmail && to?.length > 0) {
          to.forEach((contact) => {
            TasksController.create({
              ...task,
              name: `${task.name} ${contact.name} | ${variables['contract']?.name}`,
              contact_id: contact.id,
            }).catch(console.error)
          })
        }
      }

      if (!testEmail) {
        if (onSubmit) onSubmit()
        close()
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isSending: false })
    }
  }

  onFollowUpActionChange(task: Task) {
    this.setState({
      task: task
    })
  }

  onSendLedgerItemCloseModalClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  async onDefaultSubjectClick(e) {
    e.preventDefault();

    const { currentUser: { workspace: { setting } }, updateSettings, t } = this.props
    const { locale, subject } = this.state;

    try {
      const updatedTranslations = MobilityHelper.updateTranslation(locale, setting.translations, 'contract_email_subject', subject)
      const updatedSetting = await SettingsController.update({ ...setting, translations: updatedTranslations })

      Notification.notifySuccess(t('SendContractModal::Default subject saved'));
      updateSettings(updatedSetting)
    } catch (ex) {
      console.error(ex)
    }
  }

  async onDefaultBodyClick(e) {
    e.preventDefault();

    const { currentUser: { workspace: { setting } }, updateSettings, t } = this.props
    const { locale, body } = this.state;

    try {
      const updatedTranslations = MobilityHelper.updateTranslation(locale, setting.translations, 'contract_email_body', body)
      const updatedSetting = await SettingsController.update({ ...setting, translations: updatedTranslations })

      Notification.notifySuccess(t('SendContractModal::Default body saved'));
      updateSettings(updatedSetting)
    } catch (ex) {
      console.error(ex)
    }
  }

  onSubjectChange(e) {
    e.preventDefault();

    const newSubject = e.currentTarget.value;

    this.setState({
      subject: newSubject
    });
  }

  onBodyChange(body: string) {
    this.setState({ body: body, })
  }

  isValidOption(inputValue: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(inputValue).toLowerCase());
  }

  onSendTestEmailClick() {
    this.onFormSubmit(null, true)
  }

  render() {
    const { t } = this.props
    const {
      didInitialLoad,
      errors,
      to,
      subject,
      body,
      variables,
      isSending
    } = this.state

    const toIds = to.map(contact => contact.id)
    const emailContacts = [...to]
    const sendDisabled = emailContacts?.some(contact => contact.emails.length === 0) || to?.length === 0

    return (
      <ModalWindowWithPreview>
        <Content>
          <ModalHeader
            title={t('SendContractModal::Send contract')}
            onCloseClick={this.onSendLedgerItemCloseModalClick}
          />

          {!didInitialLoad && <ModalLoader />}
          {didInitialLoad && <ContractModalMiddle>
            <ContractModalContent>
              <form onSubmit={this.onFormSubmit}>
                <div key='content'>
                  <div className='grid'>
                    {sendDisabled && <div className='grid-cell with-12col' style={{ marginBottom: 8 }}>
                      <Alert type='warning' text={<>
                        <span style={{ marginRight: 4 }}>
                          <Icon icon='exclamation-triangle' />
                        </span>
                        {t('SendContractModal::Please make sure all selected contacts have an email address configured.')}
                      </>}
                      />
                    </div>}
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <label>{t('SendContractModal::Send to')} <span>*</span></label>
                        <EmailOptionsContainer>
                          <EmailOptionMainContainer>
                            <ResourceCreatablePowerSelect
                              type='contact_with_email'
                              params={{ archived: false }}
                              value={toIds}
                              isDisabled={true}
                              isClearable={false}
                              isMulti={true}
                            />
                          </EmailOptionMainContainer>
                        </EmailOptionsContainer>
                      </div>
                    </div>
                  </div>

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <div className='field-label-with-action'>
                          <label>{t('SendContractModal::Subject')} <span>*</span></label>
                          <a href='javascript://' onClick={this.onDefaultSubjectClick}>
                            {t('SendContractModal::Save as default')}
                          </a>
                        </div>

                        <input type='text'
                          value={subject}
                          onChange={this.onSubjectChange}
                          name='subject'
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className='grid'>
                    <div className='grid-cell with-12col'>
                      <div className='form-item'>
                        <div className='field-label-with-action'>
                          <label>{t('SendContractModal::Content')} <span>*</span></label>
                          <a href='javascript://' onClick={this.onDefaultBodyClick}>
                            {t('SendContractModal::Save as default')}
                          </a>
                        </div>
                        <EditorContainer>
                          <Editor
                            model={body}
                            onModelChange={this.onBodyChange}
                            config={{
                              ...EMAIL_EDITOR_CONFIG,
                              editorClass: 'branded-email',
                              heightMin: 240,
                              heightMax: 280,
                              variableOptions: EditorHelper.getVariableOptions(variables),
                            }}
                          />
                        </EditorContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ContractModalContent>

            <div className='modal-footer'>
              <div>
                <div className='modal-footer-actions'>
                  <div key='main-action' className='popover-wrapper'>
                    <ButtonWithActions
                      buttonProps={
                        {
                          type: 'success',
                          text: t('SendContractModal::Send'),
                          onClick: this.onFormSubmit,
                          disabled: sendDisabled,
                          isLoading: isSending,
                        }
                      }
                      actions={[
                        { key: 'test-email', icon: 'email', content: t('SendContractModal::Send test email to myself'), onClick: this.onSendTestEmailClick },
                      ]}
                    />
                    <TooltipError
                      errors={errors}
                      onDismiss={this.onErrorsDismiss}
                    />
                  </div>

                  <ModalActionFollowUp
                    onFollowUpActionChange={this.onFollowUpActionChange}
                  />
                </div>
              </div>
              <div />

            </div>
          </ContractModalMiddle>}
        </Content>

        <EmailPreview
          subject={MustacheHelper.process(subject, variables)}
          body={MustacheHelper.process(body, variables)}
        />
      </ModalWindowWithPreview>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      sendContractModal: {
        id,
        onSubmit,
      }
    }
  } = state

  return {
    id: id,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    close: () => dispatch(closeSendContractModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SendContractModal))