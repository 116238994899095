import * as React from 'react'
import { closeItemBlockSettingModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Switch from '../Form/Switch'
import { useDebouncedCallback } from 'use-debounce'
import { ItemBlock } from '../../types'

interface IStateToProps {
  itemBlock: ItemBlock
  onSubmit: (itemBlock: ItemBlock) => void
}

interface IDispatchToProps {
  close: typeof closeItemBlockSettingModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

const ItemBlockSettingModal = (props: IProps) => {
  const { t } = useTranslation()
  const [itemBlock, setItemBlock] = React.useState<ItemBlock>(props.itemBlock)
  const [didInitialLoad, setDidInitialLoad] = React.useState(false)
  const debouncedQuantityAdjustmentChange = useDebouncedCallback(
    () => setItemBlock({ ...itemBlock, quantity_adjustment_allowed: !itemBlock.quantity_adjustment_allowed })
    , 50
  );

  React.useEffect(() => {
    setTimeout(() => setDidInitialLoad(true), 500)
  }, [])

  const onCloseClick = () => {
    props.close()
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    props.onSubmit(itemBlock)
    props.close()
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={t('ItemBlockSettingModal::Item settings')}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            <Switch
              name='quantity_adjustment_allowed'
              label={t('ItemBlockSettingModal::Allow contact to adjust quantity')}
              onClick={debouncedQuantityAdjustmentChange}
              checked={itemBlock.quantity_adjustment_allowed}
            />
            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <div />
          <div className='modal-footer-actions'>
            <div key='main-action' className='popover-wrapper'>
              <a href='javascript://' className='button button-success' onClick={onFormSubmit}>
                {t('ItemBlockSettingModal::Save')}
              </a>
            </div>
          </div>
        </div>
      </ModalMiddle>}
    </ModalWindow>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    modals: {
      itemBlockSettingModal: {
        itemBlock,
        onSubmit,
      }
    }
  } = state

  return {
    itemBlock: itemBlock,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeItemBlockSettingModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ItemBlockSettingModal))