import styled from 'styled-components'
import { Style } from '../../styles'

const Link = styled.div`
  display: inline-flex;
  text-align: center;
  cursor: pointer;
  color: ${Style.color.brandPrimary};
  background: none;

  &:hover {
    text-decoration: underline;
  }
`

export default Link