import styled from 'styled-components';

const TaskPopoverContainer = styled.div`
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 8px 16px -4px rgba(9,30,66,.25), 0 0 0 1px rgba(9,30,66,.08);
    width: 304px;
    margin: 8px 0;
`

export default TaskPopoverContainer