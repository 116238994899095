import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AppState } from '../../store'
import { CustomField, CustomFieldType, Settings } from '../../types'
import moment from '../../utilities/Moment'
import NumberFormatter from '../../utilities/NumberFormatter'
import CheckboxInput from '../Form/CheckboxInput'
import CreatablePowerSelect from '../Form/CreatablePowerSelect'
import DateInput from '../Form/DateInput'
import MoneyInput from '../Form/MoneyInput'
import ReactSelectTheme from '../Form/ReactSelectTheme'

interface IStateToProps {
  setting: Settings
}

type IProps = {
  customField: CustomField
  value?: any
  onCustomFieldValueChange?: (key: string, value: any) => void
  onCustomFieldAddOption: (inputValue: string) => void
} & IStateToProps

const CustomFieldInput = (props: IProps) => {
  const { t } = useTranslation()
  const { customField, value, onCustomFieldValueChange, onCustomFieldAddOption, setting } = props
  const { name } = customField

  switch (customField.type) {
    case CustomFieldType.STRING:
      return <input
        type='text'
        placeholder={customField.name}
        onChange={(e) => {
          const value = e.currentTarget.value

          if (onCustomFieldValueChange) onCustomFieldValueChange(name, value)
        }}
        value={value}
        required={customField.required}
      />
    case CustomFieldType.TEXT:
      return (
        <textarea
          onChange={(e) => {
            const value = e.currentTarget.value

            if (onCustomFieldValueChange) onCustomFieldValueChange(name, value)
          }}
          placeholder={customField.name}
          value={value}
          required={customField.required}
        />
      )
    case CustomFieldType.SINGLE_OPTION:
      const singleOptions = customField.config.options || []
      const allowSingleOptionCreation = Boolean(customField.config.allowOptionCreation)
      let selectedSingleOption = null

      if (value) {
        const valueOption = singleOptions.find(o => o.value === value)

        if (valueOption) {
          selectedSingleOption = valueOption
        }
      }

      return (
        <CreatablePowerSelect
          options={singleOptions}
          value={selectedSingleOption}
          placeholder={customField.name}
          isClearable={true}
          isValidNewOption={(inputValue) => inputValue.length > 0 && allowSingleOptionCreation}
          formatCreateLabel={(inputValue) => t('CustomFieldInput::Create option "{{input}}"', { input: inputValue })}
          theme={ReactSelectTheme}
          onChange={(option) => {
            const value = option ? option.value : []

            if (onCustomFieldValueChange) onCustomFieldValueChange(name, value)
          }}
          onCreateOption={onCustomFieldAddOption}
        />
      )
    case CustomFieldType.MULTIPLE_OPTION:
      const multipleOptions = customField.config.options || []
      const allowMultipleOptionCreation = Boolean(customField.config.allowOptionCreation)
      let selectedMultipleOption = null

      if (value) {
        const valueOption = multipleOptions.filter(o => value.includes(o.value))

        if (valueOption) {
          selectedMultipleOption = valueOption
        }
      }

      return (
        <CreatablePowerSelect
          options={multipleOptions}
          value={selectedMultipleOption}
          // @ts-ignore
          isMulti={true}
          placeholder={customField.name}
          isValidNewOption={(inputValue) => inputValue.length > 0 && allowMultipleOptionCreation}
          formatCreateLabel={(inputValue) => t('CustomFieldInput::Create option "{{input}}"', { input: inputValue })}
          theme={ReactSelectTheme}
          onChange={(selectedOptions) => {
            const value = selectedOptions ? selectedOptions.map(option => option.value) : []

            if (onCustomFieldValueChange) onCustomFieldValueChange(name, value)
          }}
          onCreateOption={onCustomFieldAddOption}
        />
      )
    case CustomFieldType.DATE:
      return (
        <DateInput
          name={customField.name}
          dateFormat={setting.date_format}
          timeFormat={false}
          initialValue={value ? moment(value) : null}
          inputProps={{ placeholder: setting.date_format }}
          onChange={(newValue) => {
            if (newValue === '' && onCustomFieldValueChange) {
              onCustomFieldValueChange(name, null)
            } else {
              const newDate = moment(newValue)

              if (newDate.isValid() && onCustomFieldValueChange) {
                onCustomFieldValueChange(name, newDate.format('YYYY-MM-DD'))
              }
            }
          }}
          closeOnSelect
        />
      )
    case CustomFieldType.MONEY:
      return (
        <MoneyInput
          name={customField.name}
          currency={setting.default_currency}
          numberFormat={setting.number_format}
          placeholderValue={NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, 50)}
          value={value}
          onBlur={(rate) => {
            if (onCustomFieldValueChange) onCustomFieldValueChange(name, rate)
          }}
        />
      )
    case CustomFieldType.NUMBER:
      return (
        <input
          name={customField.name}
          type='number'
          value={value}
          onChange={(e) => {
            const value = e.currentTarget.value

            if (onCustomFieldValueChange) onCustomFieldValueChange(name, value)
          }}
          placeholder='10'
          required={customField.required}
        />
      )
    case CustomFieldType.BOOLEAN:
      return (
        <CheckboxInput
          checked={value}
          label={customField.name}
          onChange={(checked) => {
            if (onCustomFieldValueChange) onCustomFieldValueChange(name, checked)
          }}
        />
      )
    case CustomFieldType.EMAIL:
      return (
        <input
          type='email'
          placeholder={customField.name}
          value={value}
          onChange={(e) => {
            const value = e.currentTarget.value

            if (onCustomFieldValueChange) onCustomFieldValueChange(name, value)
          }}
          required={customField.required}
        />
      )
    case CustomFieldType.PHONE_NUMBER:
      return (
        <input
          type='tel'
          placeholder='+3247546574'
          value={value}
          onChange={(e) => {
            const value = e.currentTarget.value

            if (onCustomFieldValueChange) onCustomFieldValueChange(name, value)
          }}
          required={customField.required}
        />
      )
    case CustomFieldType.URL:
      return (
        <input
          type='text'
          placeholder='https://www.website.com'
          value={value}
          onChange={(e) => {
            const value = e.currentTarget.value

            if (onCustomFieldValueChange) onCustomFieldValueChange(name, value)
          }}
          required={customField.required}
        />
      )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser: {
        workspace: {
          setting
        }
      }
    }
  } = state

  return {
    setting: setting,
  }
}

export default connect(mapStateToProps)(CustomFieldInput)