import * as React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { InvoicesController } from '../../controllers'
import LedgerItemHelper from '../../helpers/LedgerItemHelper'
import ExportHelper from '../../helpers/ExportHelper'
import { closeExportLedgerItemsModal } from '../../store/modals/actions'
import { BlobResponse, CurrentUser, ExportTimePeriod, LedgerItemExportType } from '../../types'
import Notification from '../../utilities/Notification'
import Button from '../Button/Button'
import PowerSelect from '../Form/PowerSelect'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import TooltipError from '../Tooltips/ErrorTooltip'
import ModalContent from './Parts/ModalContent'
import ModalHeader from './Parts/ModalHeader'
import ModalLoader from './Parts/ModalLoader'
import ModalMiddle from './Parts/ModalMiddle'
import ModalWindow from './Parts/ModalWindow'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'
import { AppState } from '../../store'


interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeExportLedgerItemsModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  exportType: LedgerItemExportType
  exportPeriodType: ExportTimePeriod
  start: string
  end: string
  errors: any
  isSubmitting: boolean
}

class ExportInvoicesModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { start, end } = ExportHelper.getPeriodRange(ExportTimePeriod.ALL)

    this.state = {
      didInitialLoad: false,
      exportType: LedgerItemExportType.EXCEL,
      exportPeriodType: ExportTimePeriod.ALL,
      start: start,
      end: end,
      errors: {},
      isSubmitting: false,
    }

    this.onExportTypeSelect = this.onExportTypeSelect.bind(this)
    this.onExportPeriodTypeSelect = this.onExportPeriodTypeSelect.bind(this)
    this.onStartDateChange = this.onStartDateChange.bind(this)
    this.onEndDateChange = this.onEndDateChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInvoiceExportModalClose = this.onInvoiceExportModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    requestAnimationFrame(() => this.setState({ didInitialLoad: true }))
  }

  onExportTypeSelect(option) {
    this.setState({ exportType: option.value })
  }

  onExportPeriodTypeSelect(option) {
    const exportPeriodType = option.value

    const { start, end } = ExportHelper.getPeriodRange(exportPeriodType)

    this.setState({
      exportPeriodType: option.value,
      start: start,
      end: end
    })
  }

  onStartDateChange(value) {
    const newStartDate = moment(value)

    this.setState({
      start: newStartDate.isValid() ? newStartDate.format('YYYY-MM-DD') : null
    })
  }

  onEndDateChange(value) {
    const newEndDate = moment(value)

    this.setState({
      end: newEndDate.isValid() ? newEndDate.format('YYYY-MM-DD') : null
    })
  }

  async onFormSubmit(e) {
    e.preventDefault();

    const { close, t } = this.props
    const { exportType, start, end } = this.state

    this.setState({ isSubmitting: true })

    try {
      const response = await InvoicesController.exportLedgerItems({
        type: exportType,
        start: start,
        end: end
      })

      // @ts-ignore
      if (response.success) {
        Notification.notifySuccess(t('ExportLedgerItemsModal::You\'ll receive the archive with ledger items for the selected period via mail.'))
      } else {
        // Blog destructering
        const { blob, filename } = response as BlobResponse

        // Use save as library
        saveAs(blob, filename)
      }

      close()
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isSubmitting: false })
    }
  }

  onInvoiceExportModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  render() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const {
      didInitialLoad,
      errors,
      exportType,
      exportPeriodType,
      start,
      end,
      isSubmitting,
    } = this.state

    const exportTypeOptions = LedgerItemHelper.getLedgerItemExportTypeOptions()
    const selectedExportTypeOption = exportTypeOptions.find(option => option.value === exportType)
    const exportPeriodOptions = ExportHelper.getPeriodOptions()
    const selectedExportPeriodType = exportPeriodOptions.find(option => option.value === exportPeriodType)

    return (
      <ModalWindow>
        <ModalHeader
          title={t('ExportLedgerItemsModal::Export invoices')}
          onCloseClick={this.onInvoiceExportModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('ExportLedgerItemsModal::Type')}
                    </label>

                    <PowerSelect
                      options={exportTypeOptions}
                      value={selectedExportTypeOption}
                      onChange={this.onExportTypeSelect}
                      noOptionsMessage={() => t('ExportLedgerItemsModal::No option found')}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('ExportLedgerItemsModal::Period')}
                    </label>

                    <PowerSelect
                      options={exportPeriodOptions}
                      value={selectedExportPeriodType}
                      onChange={this.onExportPeriodTypeSelect}
                      noOptionsMessage={() => t('ExportLedgerItemsModal::No option found')}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>

                {exportPeriodType === ExportTimePeriod.CUSTOM && <>
                  <div className='grid-cell with-6col'>
                    <div className='form-item'>
                      <label>
                        {t('ExportLedgerItemsModal::Start date')}
                      </label>

                      <DateInput
                        name='start_date'
                        dateFormat={setting.date_format}
                        timeFormat={false}
                        value={moment(start)}
                        inputProps={{ placeholder: setting.date_format }}
                        onChange={this.onStartDateChange}
                        closeOnSelect
                      />
                    </div>
                  </div>

                  <div className='grid-cell with-6col'>
                    <div className='form-item'>
                      <label>
                        {t('ExportLedgerItemsModal::End date')}
                      </label>
                      <DateInput
                        name='end_date'
                        dateFormat={setting.date_format}
                        timeFormat={false}
                        value={moment(end)}
                        inputProps={{ placeholder: setting.date_format }}
                        onChange={this.onEndDateChange}
                        closeOnSelect
                      />
                    </div>
                  </div>
                </>}
              </div>

              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <Button
                  type='success'
                  text={t('ExportLedgerItemsModal::Export')}
                  onClick={this.onFormSubmit}
                  disabled={!start || !end}
                  isLoading={isSubmitting}
                />
              </div>
            </div>
          </div>

        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeExportLedgerItemsModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExportInvoicesModal))