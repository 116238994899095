import * as React from 'react'
import { closePeppolParticipantModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import Icon from '../Icons/Icon'
import { PeppolController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import styled, { css } from 'styled-components'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Style } from '../../styles'
import { CurrentUser, PeppolParticipant } from '../../types'
import ResourceModalTable from '../Resource/Modal/ResourceModalTable'
import ResourceModalTableRow from '../Resource/Modal/ResourceModalTableRow'
import ResourceModalTableRowHeader from '../Resource/Modal/ResourceModalTableRowHeader'
import ResourceModalTableRowData from '../Resource/Modal/ResourceModalTableRowData'
import PageLoader from '../Page/PageLoader'
import Button from '../Button/Button'

const AmountInput = styled.input<{ danger?: boolean }>`
	text-align: right;
	padding-top: 4;
	padding-bottom: 4px;

	${(props: { danger?: boolean }) => props.danger && css`
		color: ${Style.color.brandDanger} !important;
	`}
`

const EditAction = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: ${Style.color.defaultColor};
  color: white;

  i, svg {
    color: white;
    fill: white;
    font-size: 22px;
  }
`


interface IStateToProps {
  currentUser: CurrentUser
  query?: string
  onSubmit?: (participant: PeppolParticipant) => void
}

interface IDispatchToProps {
  close: typeof closePeppolParticipantModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  peppolParticipants: PeppolParticipant[],
  currentPage: number
  totalPages: number
  query: string
  isFetching: boolean
  errors: any
}

class PeppolParticipantModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      isFetching: false,
      currentPage: 1,
      totalPages: 1,
      peppolParticipants: [],
      query: '',
      errors: {},
    }

    this.fetchPeppolParticipants = this.fetchPeppolParticipants.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onSearchSubmit = this.onSearchSubmit.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onPeppolParticipantModalCloseClick = this.onPeppolParticipantModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.setState({ didInitialLoad: true })

    if (this.props.query) {
      this.fetchPeppolParticipants(1, this.props.query)
    }
  }

  async fetchPeppolParticipants(page: number, query?: string) {
    try {
      this.setState({ isFetching: true })
      const result = await PeppolController.search({ q: query, resultPageCount: 1000 })

      this.setState({
        didInitialLoad: true,
        isFetching: false,
        peppolParticipants: result.matches,
        query: query
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onFormSubmit(participant: PeppolParticipant) {
    const { close, onSubmit } = this.props

    if (onSubmit) onSubmit(participant)
    close()
  }

  onPeppolParticipantModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({ errors: {} })
  }

  onSearchChange(e) {
    e.preventDefault();

    const q = e.currentTarget.value

    this.setState({ query: q })
  }

  onSearchSubmit(e) {
    e.preventDefault();

    const { query: q } = this.state

    this.fetchPeppolParticipants(1, q)
  }

  render() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { peppolParticipants, didInitialLoad, errors, isFetching, query } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={t('PeppolParticipantModal::Search peppol')}
          onCloseClick={this.onPeppolParticipantModalCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <div className="grid">
              <div className="grid-cell with-12col">
                <form onSubmit={this.onSearchSubmit}>
                  <div className="resource-table-filter">
                    <div className="resource-table-filter-label">{t('PeppolParticipantModal::Peppol')}</div>
                    <div className="resource-table-filter-input">
                      <Icon icon='search' />
                      <input
                        type="text"
                        placeholder={t('PeppolParticipantModal::Search peppol participant')}
                        name="search"
                        onChange={this.onSearchChange}
                        value={query}
                      />
                    </div>

                    <a href="javascript://" className="button button-default" onClick={this.onSearchSubmit}>
                      {t('PeppolParticipantModal::Search')}
                    </a>
                  </div>
                  <input type="submit" style={{ display: 'none' }} />
                </form>

                <ResourceModalTable>
                  <ResourceModalTableRow header={true}>
                    <ResourceModalTableRowHeader>
                      {t('TaxesModal::Name')}
                    </ResourceModalTableRowHeader>
                    <ResourceModalTableRowHeader style={{ width: 40 }}>
                      {t('TaxesModal::Country')}
                    </ResourceModalTableRowHeader>
                    <ResourceModalTableRowHeader>
                      {t('TaxesModal::Peppol ID')}
                    </ResourceModalTableRowHeader>
                    <ResourceModalTableRowHeader>
                      {t('TaxesModal::Action')}
                    </ResourceModalTableRowHeader>
                  </ResourceModalTableRow>

                  {isFetching &&
                    <ResourceModalTableRow>
                      <ResourceModalTableRowData colSpan={4}>
                        <PageLoader />
                      </ResourceModalTableRowData>
                    </ResourceModalTableRow>}
                  {!isFetching && peppolParticipants.map((participant, index) => {
                    const entity = participant?.entities[0]
                    const name = entity?.name[0].name
                    const country = entity?.countryCode
                    const peppolID = participant?.participantID?.value

                    return (
                      <ResourceModalTableRow key={index}>
                        <ResourceModalTableRowData style={{
                          maxWidth: 200,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}>
                          {name}
                        </ResourceModalTableRowData>
                        <ResourceModalTableRowData>
                          {country}
                        </ResourceModalTableRowData>
                        <ResourceModalTableRowData>
                          {peppolID}
                        </ResourceModalTableRowData>
                        <ResourceModalTableRowData>
                          <Button
                            type='default'
                            icon='link'
                            text={t('PeppolParticipantModal::Link')}
                            onClick={() => this.onFormSubmit(participant)}
                            style={{ width: '100%' }}
                          />
                        </ResourceModalTableRowData>
                      </ResourceModalTableRow>
                    )
                  })}
                </ResourceModalTable>
              </div>
            </div>
          </ModalContent>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      peppolParticipantModal: {
        show,
        query,
        onSubmit
      }
    }
  } = state

  return {
    currentUser: currentUser,
    query: query,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closePeppolParticipantModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PeppolParticipantModal))