import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import FixedSidebarContainer from '../Sidebar/FixedSidebarContainer'
import SidebarDivider from '../Sidebar/SidebarDivider'
import SidebarHeader from '../Sidebar/SidebarHeader'
import SidebarClose from '../Sidebar/SidebarClose'
import SidebarContent from '../Sidebar/SidebarContent'
import SidebarSection from '../Sidebar/SidebarSection'
import SidebarSectionItem from '../Sidebar/SidebarSectionItem'
import SidebarSectionItemCheck from '../Sidebar/SidebarSectionItemCheck'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'

const Caption = styled.div`
	font-size: 14px;
`

export enum DealStatusFilter {
	OPEN = 'open',
	WON = 'won',
	LOST = 'lost',
	ALL = 'all'
}

export const enum DealBoardFilterDueState {
	// t('DealSidebarFilter::due_next_day')
	DUE_NEXT_DAY = 'due_next_day',
	// t('DealSidebarFilter::due_next_week')
	DUE_NEXT_WEEK = 'due_next_week',
	// t('DealSidebarFilter::due_next_month')
	DUE_NEXT_MONTH = 'due_next_month',
	// t('DealSidebarFilter::overdue')
	OVERDUE = 'overdue',
	// t('DealSidebarFilter::no_due_date')
	NO_DUE_DATE = 'no_due_date',
}

export interface IDealBoardFilter {
	searchValue: string
	status: DealStatusFilter
	dueState: DealBoardFilterDueState | null
	assigneeIds?: string[]
}


interface IProps {
	active: boolean
	filter: IDealBoardFilter
	onFilterChange: (filter: IDealBoardFilter) => void
	onCloseClick: () => void
}

const DealSidebarFilter = (props: IProps) => {
	const { active, filter } = props
	const { t } = useTranslation()
	const container = React.useRef<HTMLDivElement>()
	const input = React.useRef<HTMLInputElement>()
	const { searchValue } = filter;

	const dueCompletionStateOptions: DealBoardFilterDueState[] = [
		DealBoardFilterDueState.DUE_NEXT_DAY,
		DealBoardFilterDueState.DUE_NEXT_WEEK,
		DealBoardFilterDueState.DUE_NEXT_MONTH,
		DealBoardFilterDueState.OVERDUE,
		DealBoardFilterDueState.NO_DUE_DATE,
	]

	React.useEffect(() => {
		document.addEventListener('mousedown', onOutsideClick);

		// Temporarily disable because on mobile it opens keyboard which annoying
		if (active && input.current) input.current.select()

		return () => {
			document.removeEventListener('mousedown', onOutsideClick);
		}
	}, [active])

	const onOutsideClick = (e) => {
		if (active && container && !container.current.contains(e.target)) {
			props.onCloseClick()
		}
	}

	const onCloseClick = () => {
		props.onCloseClick()
	}

	const onSearchChange = (e) => {
		props.onFilterChange({ ...filter, searchValue: e.currentTarget.value })
	}

	const onAssigneesChange = (assignees) => {
		props.onFilterChange({ ...filter, assigneeIds: assignees })
	}

	const onDueCompletionStateToggle = (newDueCompletionState: DealBoardFilterDueState) => {
		let currentDueCompletionState = filter.dueState

		props.onFilterChange({
			...filter,
			dueState: newDueCompletionState !== currentDueCompletionState ? newDueCompletionState : null
		})
	}

	const clearFilters = () => {
		props.onFilterChange({
			searchValue: '',
			status: DealStatusFilter.OPEN,
			dueState: null,
		})
	}

	return (
		<FixedSidebarContainer ref={container} active={active}>
			<SidebarHeader>
				{t('DealSidebarFilter::Search')}

				<SidebarClose onClick={onCloseClick} />
			</SidebarHeader>

			<SidebarDivider />

			<SidebarContent>
				<input
					ref={input}
					type='text'
					value={searchValue}
					onChange={onSearchChange}
					style={{ marginBottom: Style.spacing.x1 }}
					placeholder={t('DealSidebarFilter::Search...')}
				/>

				<Caption>{t('DealSidebarFilter::Search by name, contact, project, assignee or due time')}</Caption>

				<SidebarDivider />

				<SidebarSection>
					<ResourceCreatablePowerSelect
						type='user'
						value={filter.assigneeIds}
						isClearable={true}
						isValidNewOption={() => false}
						isMulti={true}
						onChange={onAssigneesChange}
						placeholder={t('DealSidebarFilter::Select assignees')}
					/>
				</SidebarSection>

				<SidebarDivider />

				<SidebarSection>
					{dueCompletionStateOptions.map(dueCompleteState => {
						return (
							<SidebarSectionItem key={dueCompleteState} onClick={() => onDueCompletionStateToggle(dueCompleteState)}>
								{t(`DealSidebarFilter::${dueCompleteState}`)}
								{filter.dueState === dueCompleteState && <SidebarSectionItemCheck />}
							</SidebarSectionItem>
						)
					})}
				</SidebarSection>

				<SidebarDivider />

				<SidebarSectionItem onClick={clearFilters}>
					{t('DealSidebarFilter::Clear filters')}
				</SidebarSectionItem>
			</SidebarContent>
		</FixedSidebarContainer >
	)
}

export default DealSidebarFilter