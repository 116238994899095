import * as React from "react";
import styled, { css } from "styled-components";
import { Style } from "../../styles";

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0;
  margin-left: 0;
`;

const Item = styled.div<{ active?: boolean, color?: string, backgroundColor?: string, showColorOnHover?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  height: 38px;
  min-width: 38px;
  background: white;
  border: 1px solid ${Style.color.border};
  cursor: pointer;
  flex: 1;
  font-weight: 500;
  border-left: 0;
  color: black;

  svg {
    width: 16px;
  }

  i {
    color: currentColor;
  }

  svg:not(:only-child),
  i:not(:only-child) {
    margin-right: 8px;
  }

  &:hover {
    background: #f6f6f7;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left: 1px solid ${Style.color.border};
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    ${props => css`
      ${props.showColorOnHover && css`
        ${props.color && css`
          color: ${props.color};
        `}

        ${props.backgroundColor && css`
          background-color: ${props.backgroundColor};
        `}
      `}
    `}
  }

  ${(props) =>
    props.active && css`
      background: #64788f;
      color: white;
      fill: white;
      font-weight: 500;

      ${props.color && css`
        color: ${props.color};
      `}

      ${props.backgroundColor && css`
        background-color: ${props.backgroundColor};
      `}

      &:hover {
        background: #64788f;
        color: white;
        fill: white;

        ${props.color && css`
          color: ${props.color};
        `}

        ${props.backgroundColor && css`
          background-color: ${props.backgroundColor};
        `}
      }
`}
`;

export interface ButtonGroupItem {
  element: JSX.Element | string;
  active?: boolean;
  onClick?: () => void;
  color?: string
  backgroundColor?: string
  showColorOnHover?: boolean
  visible?: boolean
}

interface IProps {
  items: ButtonGroupItem[];
}

const ButtonGroup = (props: IProps) => {
  const { items } = props;

  return (
    <Container>
      {items
        .filter(item => item.visible !== false)
        .map((item, index) => {
          const { element, active, onClick, color, backgroundColor, showColorOnHover } = item;
          return (
            <Item
              key={index}
              active={active}
              onClick={onClick}
              color={color}
              backgroundColor={backgroundColor}
              showColorOnHover={showColorOnHover}
            >
              {element}
            </Item>
          );
        })}
    </Container>
  );
};

export default ButtonGroup;
