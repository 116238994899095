import { PDFDocumentProxy } from 'pdfjs-dist';
import * as React from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import styled, { css } from 'styled-components';
import { Style } from '../../styles';
import LedgerItemPreviewPlaceholder from './LedgerItemPreviewPlaceholder'

const Container = styled.div<{ withBorder?: boolean, withBorderRadius?: boolean }>`
	width: 100%;
	height: 100%;
	
	.react-pdf__Page {
		&:not(:last-child) {
			margin-bottom: 24px;
		}

		${props => props.withBorder && css`
			border: 1px solid ${Style.color.border};
		`}

		${props => props.withBorderRadius && css`
			border-radius: ${Style.variables.baseBorderRadius};
		`}
  }
`

interface IProps {
	file?: string
	currentPage?: number
	width?: number
	height?: number
	allPages?: boolean
	withBorder?: boolean
	withBorderRadius?: boolean
	onLoadSuccess?: (pdf: PDFDocumentProxy) => void
}

interface IState {
	pageCount: number
}

export default class DocumentPreview extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			pageCount: 0,
		}

		this.onLoadSuccess = this.onLoadSuccess.bind(this)
	}
	private document = React.createRef<Document>()

	reload() {
		if (this.document.current) {
			// @ts-ignore
			this.document.current.loadDocument()
		}
	}

	onLoadSuccess(pdf: PDFDocumentProxy) {
		const { onLoadSuccess } = this.props
		this.setState({ pageCount: pdf.numPages })

		if (onLoadSuccess) onLoadSuccess(pdf)
	}

	render() {
		const {
			file,
			allPages,
			withBorder,
			withBorderRadius,
			currentPage,
			width,
		} = this.props
		const { pageCount } = this.state

		return (
			<Container withBorder={withBorder} withBorderRadius={withBorderRadius}>
				<Document
					ref={this.document}
					file={file}
					onLoadSuccess={this.onLoadSuccess}
					loading={LedgerItemPreviewPlaceholder}
					noData={LedgerItemPreviewPlaceholder}
					error={LedgerItemPreviewPlaceholder}
				>
					{!allPages && <Page
						pageNumber={currentPage}
						width={width}
						loading={LedgerItemPreviewPlaceholder}
						renderAnnotationLayer={false}
						renderTextLayer={false}
						renderInteractiveForms={false}
					/>}

					{allPages && [...Array(pageCount)].map((_, index) => {
						return (
							<Page
								key={`page_${index + 1}`}
								pageNumber={index + 1}
								width={width}
								loading={LedgerItemPreviewPlaceholder}
								renderAnnotationLayer={false}
								renderTextLayer={false}
								renderInteractiveForms={false}
							/>
						)
					})}
				</Document>
			</Container>
		)
	}
}