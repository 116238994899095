import styled, { css } from "styled-components"
import { Style } from "../../styles"

const FixedSidebarContainer = styled.div<{ active: boolean }>`
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	right: -400px;
	bottom: 0;
	box-shadow: 0 12px 24px -6px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);
	transition-property: all;
	transition-duration: .1s;
	transition-timing-function: ease-in;
	width: 400px;
	background-color: white;
	z-index: 6;
	padding: 12px;

	${props => props.active && css`
		right: 0;

		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			width: 100%;
		}
	`}
`

export default FixedSidebarContainer