import * as React from 'react'
import styled from "styled-components"
import { Style } from "../../styles"
import { useDropzone } from 'react-dropzone'
import { MimeTypes } from '../../types'

interface IProps { }

const ContactAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  min-width: 65px;
  min-height: 65px;
  border: 1px dashed #E3E3E3;
  border-radius: 50%;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill:  #E3E3E3;
  }
`

const ContactAvatar = (props: IProps) => {
  const onDrop = (acceptedFiles) => {
    // onChange(acceptedFiles)
  }
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDrop,
    multiple: false,
    accept: [
      MimeTypes.JPG,
      MimeTypes.JPEG,
      MimeTypes.PNG,
      MimeTypes.SVG
    ],
  })

  const onOpenFileDialogClick = () => {
    open()
  }

  const onDeleteClick = () => {
    // onDelete()
  }

  return (
    <ContactAvatarContainer {...getRootProps} onClick={onOpenFileDialogClick} >
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="25" width="25" aria-hidden="true"><path d="m4.75 16 2.746-3.493c.779-.99 3.004-2 5.004.993m.991-3.987c-5.995 5.487.007-.009.01-.013a2 2 0 0 1 3.085-.06L19 12.25m-12.25 7h10.5a2 2 0 0 0 2-2V6.75a2 2 0 0 0-2-2H6.75a2 2 0 0 0-2 2v10.5a2 2 0 0 0 2 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
      <input {...getInputProps()} />
    </ContactAvatarContainer>
  )
}

export { ContactAvatarContainer }
export default ContactAvatar