import * as React from 'react'
import { ContactsController, ExpensesController } from '../../controllers'
import { saveAs } from 'file-saver'
import Notification from '../../utilities/Notification'
import PowerSelect from '../Form/PowerSelect'
import ModalHeader from './Parts/ModalHeader'
import ModalLoader from './Parts/ModalLoader'
import ModalMiddle from './Parts/ModalMiddle'
import ModalWindow from './Parts/ModalWindow'
import ModalContent from './Parts/ModalContent'
import TooltipError from '../Tooltips/ErrorTooltip'
import Button from '../Button/Button'
import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { closeExportContactsModal } from '../../store/modals/actions'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import { BlobResponse, CurrentUser, ExpenseExportType, ExportTimePeriod, SpreadsheetExportType } from '../../types'
import ExportHelper from '../../helpers/ExportHelper'
import moment from '../../utilities/Moment'
import ExpenseHelper from '../../helpers/ExpenseHelper'
import DateInput from '../Form/DateInput'
import { AppState } from '../../store'
import SpreadsheetHelper from '../../helpers/SpreadsheetHelper'

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeExportContactsModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  exportType: SpreadsheetExportType
  isSubmitting: boolean
}

class ExportContactsModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      exportType: SpreadsheetExportType.EXCEL,
      isSubmitting: false,
    }

    this.onExportTypeSelect = this.onExportTypeSelect.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onModalClose = this.onModalClose.bind(this)
  }

  componentDidMount() {
    requestAnimationFrame(() => { this.setState({ didInitialLoad: true }) })
  }

  onExportTypeSelect(option) {
    this.setState({ exportType: option.value })
  }

  async onFormSubmit(e) {
    e.preventDefault();

    const { close, t } = this.props
    const { exportType } = this.state

    this.setState({ isSubmitting: true })
    try {
      const response = await ContactsController.export({ export_type: exportType })

      // Blog destructering
      const { blob, filename } = response as BlobResponse

      // Use save as library
      saveAs(blob, filename)

      close()
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isSubmitting: false })
    }
  }

  onModalClose() {
    this.props.close()
  }

  render() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const {
      didInitialLoad,
      exportType,
      isSubmitting,
    } = this.state

    const exportTypeOptions = SpreadsheetHelper.getOptions()
    const selectedExportTypeOption = exportTypeOptions.find(option => option.value === exportType)

    return (
      <ModalWindow>
        <ModalHeader
          title={t('ExportContactsModal::Export contacts')}
          onCloseClick={this.onModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>
                      {t('ExportContactsModal::Export to')}
                    </label>

                    <PowerSelect
                      options={exportTypeOptions}
                      value={selectedExportTypeOption}
                      onChange={this.onExportTypeSelect}
                      noOptionsMessage={() => t('ExportContactsModal::No option found')}
                      isClearable={false}
                      theme={ReactSelectTheme}
                    />
                  </div>
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <Button
                  type='success'
                  text={t('ExportContactsModal::Export')}
                  onClick={this.onFormSubmit}
                  isLoading={isSubmitting}
                />
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeExportContactsModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExportContactsModal))