import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import styled, { css } from 'styled-components'
import { Style } from '../../../styles'
import Icon, { IconTypes } from '../../Icons/Icon'

const Container = styled.div<{ active?: boolean, disabled?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
  cursor: pointer;
  color: dimgrey;
  margin: 0 8px;

 svg {
	 fill: dimgrey;
	 color: dimgrey;
	 min-width: 20px;
	 height: 15px;
 }

 ${props => props.active && css`
 		color: ${Style.color.brandSuccess};

		 svg {
			 fill: ${Style.color.brandSuccess};
			 color: ${Style.color.brandSuccess};
		 }
 `}

	${props => props.disabled && css`
		cursor: not-allowed;
		color: dimgrey;
		opacity: .2;
	`}
`

interface IProps {
	icon: IconTypes
	onClick?: () => void
	tooltip?: string
	active?: boolean
	disabled?: boolean
	disabledTooltip?: string
}

const ModalFooterActionIcon = (props: IProps) => {
	const { icon, tooltip, active, disabled, disabledTooltip, onClick } = props

	React.useEffect(() => {
		ReactTooltip.rebuild();
	}, [])

	return (
		<Container
			onClick={disabled ? undefined : onClick}
			active={active}
			disabled={disabled}
			data-tip={disabled ? disabledTooltip : tooltip}
			data-place='top'
			data-class='z-9999'
		>
			<Icon icon={icon} />
		</Container>
	)
}

export default ModalFooterActionIcon