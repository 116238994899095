import { v4 as uuid } from 'uuid';
import Fuse from 'fuse.js'
import { BoardFilterDueCompletionState, BoardFilterMatchLabelsAndMembers, IBoardFilter } from "../components/Board/BoardSidebarFilter";
import moment from "../utilities/Moment";
import { BoardList, IPositionable, Task } from "../types";

export default class BoardHelper {
  static POSITION_STEP_INDEX = 16384

  static generateTemporaryUuid() {
    return `tmp_${uuid()}`
  }

  static getCreatePosition(resources: IPositionable[]): number {
    if (resources.length === 0) return this.POSITION_STEP_INDEX

    return resources[resources.length - 1].position + this.POSITION_STEP_INDEX
  }

  static getTasksFromFilter(tasks: Task[], filter: IBoardFilter) {
    let filteredTasks = [...tasks]

    const fuse = new Fuse(filteredTasks, {
      shouldSort: true,
      threshold: 0.5,
      location: 0,
      distance: 100,
      minMatchCharLength: 1,
      keys: [
        'name',
        'description',
        'attachments.name',
        'attachments.attachment_file_name',
      ]
    })

    const { searchValue, assigneeIds, labelIds, dueOrCompletionState, matchLabelsAndMembers } = filter;

    // Search filteredTasks based on searchValue
    if (searchValue.length > 0) {
      filteredTasks = fuse.search(searchValue).map(result => result.item)
    }

    if (assigneeIds.length > 0) {
      filteredTasks = filteredTasks.filter(task => task.assignee_ids.some((value) => assigneeIds?.includes(value)))
    }

    if (labelIds.length > 0) {
      if (matchLabelsAndMembers === BoardFilterMatchLabelsAndMembers.ANY) {
        filteredTasks = filteredTasks.filter(task => labelIds.some(labelId => task.label_ids.includes(labelId)))
      } else if (matchLabelsAndMembers === BoardFilterMatchLabelsAndMembers.ALL) {
        filteredTasks = filteredTasks.filter(task => {
          // If length doesn't match unable to compare and automatically false
          if (task.label_ids.length !== labelIds.length) return false

          // Match every label id with label ids of the task
          return labelIds.every(labelId => task.label_ids.includes(labelId))
        })
      }
    }

    if (dueOrCompletionState) {
      const nowMoment = moment()

      switch (dueOrCompletionState) {
        case BoardFilterDueCompletionState.DUE_NEXT_DAY:
          const dueNextDayMoment = moment(nowMoment).endOf('day').add(1, 'day')
          filteredTasks = filteredTasks.filter(task => task.due_on && moment(task.due_on).isBetween(nowMoment, dueNextDayMoment))
          break
        case BoardFilterDueCompletionState.DUE_NEXT_WEEK:
          const dueNextWeekMoment = moment(nowMoment).endOf('day').add(7, 'day')
          filteredTasks = filteredTasks.filter(task => task.due_on && moment(task.due_on).isBetween(nowMoment, dueNextWeekMoment))
          break
        case BoardFilterDueCompletionState.DUE_NEXT_MONTH:
          const dueNextMonthMoment = moment(nowMoment).endOf('day').add(1, 'month')
          filteredTasks = filteredTasks.filter(task => task.due_on && moment(task.due_on).isBetween(nowMoment, dueNextMonthMoment))
          break
        case BoardFilterDueCompletionState.OVERDUE:
          filteredTasks = filteredTasks.filter(task => task.due_on && moment(task.due_on).isBefore(nowMoment))
          break
        case BoardFilterDueCompletionState.NO_DUE_DATE:
          filteredTasks = filteredTasks.filter(task => !task.due_on)
          break

        // Not supported yet
        // case BoardFilterDueCompletionState.DUE_MARKED_COMPLETE:
        //   break
        // case BoardFilterDueCompletionState.NOT_MARKED_AS_COMPLETE:
        //   break
      }
    }

    return filteredTasks
  }

  static getTasksFromList(list: BoardList, tasks: Task[], filter?: IBoardFilter): Task[] {
    let listTasks = tasks
      .filter(task => task.list_id === list.id)
      .sort((t1, t2) => t1.position - t2.position)

    // If no filters provided return all tasks from the list
    if (!filter) return listTasks

    return this.getTasksFromFilter(listTasks, filter)
  }

  static boardEmail(boardId: string) {
    return `${boardId}@board.bizzey.com`
  }
}