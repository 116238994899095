import * as React from 'react'
import { closePaymentDetailsModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import TooltipError from '../Tooltips/ErrorTooltip'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import Button from '../Button/Button';
import ModalContent from './Parts/ModalContent'
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementStyle, StripeIbanElementChangeEvent, StripeIbanElementOptions } from '@stripe/stripe-js'
import { WorkspaceController } from '../../controllers'
import { CardElement, IbanElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import Images from '../../images'
import Utils from '../../utilities/Utils'
import DataLayerHelper, { DataLayerEventType } from '../../helpers/DataLayerHelper'
import { DisplayableError, PaymentMethodType, Workspace } from '../../types'
import Alert from '../Alert/Alert'

const PaymentMethodContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 2px solid ${Style.color.border};
  padding: ${Style.spacing.x1};
  height: 250px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: ${Style.color.brandPrimary};
  }

  img, svg {
    max-height: 80px;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    margin-top: ${Style.spacing.x1_5};
    text-align: center;
  }

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
    margin-bottom: ${Style.spacing.x1};
	}
`

const MandateAcceptance = styled.div`
  font-size: 12px;
`

interface IStateToProps {
  stripePublicKey: string
  onSubmit?: (workspace: Workspace) => void
}

interface IDispatchToProps {
  close: typeof closePaymentDetailsModal
}

type IProps = IDispatchToProps & IStateToProps

const PaymentDetailsModal = (props: IProps) => {
  const [paymentMethodType, setPaymentMethodType] = React.useState<PaymentMethodType | null>(null)
  const [cardBrand, setCardBrand] = React.useState<'visa' | 'mastercard' | 'amex' | 'discover' | 'diners' | 'jcb' | 'unionpay' | 'unknown' | null>(null)
  const [setupIntentContactSecret, setSetupIntentContactSecret] = React.useState<string | null>(null)
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [didInitialLoad, setDidInitialLoad] = React.useState(false)
  const [isFetching, setIsFetching] = React.useState(false)
  const [stripeError, setStripeError] = React.useState<any>(null)
  const [errors, setErrors] = React.useState<DisplayableError[]>([])
  const { t } = useTranslation()
  const stripe = useStripe();
  const elements = useElements();

  const STRIPE_ELEMENT_STYLES: StripeElementStyle = {
    base: {
      color: '#32325D',
      fontWeight: '500',
      fontFamily: 'Inter UI, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',

      '::placeholder': {
        color: '#CFD7DF'
      }
    },
    invalid: {
      color: '#E25950'
    },
  }


  const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
    style: STRIPE_ELEMENT_STYLES,
    hidePostalCode: true,
  }

  const IBAN_ELEMENT_OPTIONS: StripeIbanElementOptions = {
    supportedCountries: ['SEPA'],
    placeholderCountry: 'BE',
    style: STRIPE_ELEMENT_STYLES,
  };


  React.useEffect(() => {
    setDidInitialLoad(true)
  })

  React.useEffect(() => {
    if (paymentMethodType !== null) {
      fetchCardForm(paymentMethodType)
    }
  }, [paymentMethodType])

  const fetchCardForm = async (type: PaymentMethodType) => {
    try {
      setDidInitialLoad(false)

      const response = await WorkspaceController.getCardForm(type)

      setSetupIntentContactSecret(response.setup_intent_secret)
    } catch (ex) {
      console.error(ex)
    } finally {
      setDidInitialLoad(true)
    }
  }

  const onPaymentMethodTypeClick = (type: PaymentMethodType) => {
    setPaymentMethodType(type)
  }

  const onNameChange = (e) => {
    setName(e.currentTarget.value)
  }

  const onEmailChange = (e) => {
    setEmail(e.currentTarget.value)
  }

  const onFormSubmit = async (e) => {
    if (e) e.preventDefault()
    const { close, onSubmit } = props

    if (
      paymentMethodType === PaymentMethodType.SEPA &&
      (name === '' || email === '' || !Utils.isValidEmail(email))
    ) {
      const newErrors: DisplayableError[] = []

      if (name === '') {
        newErrors.push({
          message: t('PaymentDetailsModal::is required'),
          field: t('PaymentDetailsModal::Name')
        })
      }

      if (email === '') {
        newErrors.push({
          message: t('PaymentDetailsModal::is required'),
          field: t('PaymentDetailsModal::Email')
        })
      }

      if (!Utils.isValidEmail(email)) {
        newErrors.push({
          message: t('PaymentDetailsModal::is invalid'),
          field: t('PaymentDetailsModal::Email')
        })
      }

      setErrors(newErrors)
      return
    }

    try {
      let result = null

      setIsFetching(true)

      switch (paymentMethodType) {
        case PaymentMethodType.CREDIT_CARD:
          const cardElement = elements.getElement(CardElement);
          result = await stripe.confirmCardSetup(setupIntentContactSecret, {
            payment_method: {
              card: cardElement,
            },
          })
          break
        case PaymentMethodType.SEPA:
          const ibanElement = elements.getElement(IbanElement);
          result = await stripe.confirmSepaDebitSetup(setupIntentContactSecret, {
            payment_method: {
              billing_details: {
                name: name,
                email: email,
              },
              sepa_debit: ibanElement,
            },
          })
          break
      }

      if (result.error) {
        Notification.notifyError(result.error.message)
        setStripeError(result.error.message)
      } else {
        const { id } = result.setupIntent

        const workspace = await WorkspaceController.updateCard(id)

        const updatedWorkspace: Workspace = workspace

        Notification.notifySuccess(t('PaymentDetailsModal::Payment details successfully updated'))

        if (onSubmit) onSubmit(updatedWorkspace)
        close()
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      setIsFetching(false)
    }
  }

  const onPaymentDetailsModalCloseClick = () => {
    props.close()
  }

  const onErrorsDismiss = () => {
    setErrors([])
  }

  const onIbanChange = (event: StripeIbanElementChangeEvent) => {

  }

  const onCardChange = (event: StripeCardElementChangeEvent) => {
    if (cardBrand !== 'unknown' && event.empty) {
      setCardBrand(null)
    }
    else if (event.brand && event.brand !== 'unknown' && cardBrand !== event.brand) {
      setCardBrand(event.brand)
    }
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={t('PaymentDetailsModal::Payment details')}
        onCloseClick={onPaymentDetailsModalCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            {paymentMethodType === null && <>
              <div className='grid'>
                <div className='grid-cell with-6col'>
                  <PaymentMethodContainer onClick={() => onPaymentMethodTypeClick(PaymentMethodType.SEPA)}>
                    <img src={Images.PRICING_SEPA} />
                    <span>{t('PaymentDetailsModal::SEPA direct debit')}</span>
                  </PaymentMethodContainer>
                </div>

                <div className='grid-cell with-6col'>
                  <PaymentMethodContainer onClick={() => onPaymentMethodTypeClick(PaymentMethodType.CREDIT_CARD)}>
                    <Icon icon='mastercard' />
                    <span>{t('PaymentDetailsModal::Creditcard')}</span>
                  </PaymentMethodContainer>
                </div>
              </div>
            </>}
            {paymentMethodType === PaymentMethodType.SEPA && <>
              <div className='form-item'>
                <label>{t('PaymentDetailsModal::Name')}<span>*</span></label>
                <input
                  type='text'
                  name='name'
                  value={name}
                  onChange={onNameChange}
                  placeholder={t('PaymentDetailsModal::Name')}
                />
              </div>
              <div className='form-item'>
                <label>{t('PaymentDetailsModal::Email')}<span>*</span></label>
                <input
                  type='email'
                  name='email'
                  value={email}
                  onChange={onEmailChange}
                  placeholder={t('PaymentDetailsModal::Email')}
                />
              </div>
              <div className='form-item'>
                <label>{t('PaymentDetailsModal::IBAN')}</label>
                <IbanElement
                  id="iban-element"
                  options={IBAN_ELEMENT_OPTIONS}
                  onChange={onIbanChange}
                />
              </div>

              <MandateAcceptance>
                {t('PaymentDetailsModal::By providing your payment information and confirming this payment, you authorise (A) Bizzey and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur.')}
              </MandateAcceptance>
            </>}
            {paymentMethodType === PaymentMethodType.CREDIT_CARD && <>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <ul className='payment-card-list'>
                    <li className={cardBrand === 'visa' ? 'is-active' : ''}>
                      <Icon icon='visa' />
                    </li>
                    <li className={cardBrand === 'mastercard' ? 'is-active' : ''}>
                      <Icon icon='mastercard' />
                    </li>
                    <li className={cardBrand === 'amex' ? 'is-active' : ''}>
                      <Icon icon='american-express' />
                    </li>
                    <li className={cardBrand === 'discover' ? 'is-active' : ''}>
                      <Icon icon='discover' />
                    </li>
                    <li className={cardBrand === 'jcb' ? 'is-active' : ''}>
                      <Icon icon='jcb' />
                    </li>
                  </ul>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('PaymentDetailsModal::Card')}</label>
                    <CardElement
                      id="card-element"
                      options={CARD_ELEMENT_OPTIONS}
                      onChange={onCardChange}
                    />
                  </div>

                  {stripeError && stripeError.message && <Alert type='danger' text={stripeError.message} />}
                </div>
              </div>
            </>}
            <input type='submit' style={{ display: 'none' }} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <div className='payment-modal-security-text'>
            <Icon icon='lock' />
            {t('PaymentDetailsModal::Secured by')} <a href='https://stripe.com' target='_blank'>{'Stripe'}</a>
          </div>
          <div className='modal-footer-actions'>
            {paymentMethodType && <div key='main-action' className='popover-wrapper'>
              <TooltipError
                errors={errors}
                onDismiss={onErrorsDismiss}
              />

              <Button
                type='success'
                text={t('PaymentDetailsModal::Save')}
                onClick={onFormSubmit}
                isLoading={isFetching}
              />
            </div>}
          </div>
        </div>
      </ModalMiddle>}
    </ModalWindow>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    modals: {
      paymentDetailsModal: {
        onSubmit,
      }
    },
    config: {
      stripePublicKey
    }
  } = state

  return {
    stripePublicKey: stripePublicKey,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closePaymentDetailsModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetailsModal)