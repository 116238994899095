import { DropResult } from 'react-beautiful-dnd'
import i18n from '../translations'
import { CustomFieldConfig, CustomFieldModel, CustomFieldType } from '../types'

export default class CustomFieldHelper {
	static POSITION_STEP_INDEX = 16384

	static TYPES: CustomFieldType[] = [
		CustomFieldType.STRING,
		CustomFieldType.TEXT,
		CustomFieldType.SINGLE_OPTION,
		CustomFieldType.MULTIPLE_OPTION,
		CustomFieldType.DATE,
		CustomFieldType.MONEY,
		CustomFieldType.NUMBER,
		CustomFieldType.BOOLEAN,
		CustomFieldType.EMAIL,
		CustomFieldType.PHONE_NUMBER,
		CustomFieldType.URL,
	]

	static MODELS: CustomFieldModel[] = [
		CustomFieldModel.CLIENT,
		CustomFieldModel.SUPPLIER,
		CustomFieldModel.PROJECT,
		CustomFieldModel.TIME_ENTRY,
		CustomFieldModel.INVOICE,
		CustomFieldModel.EXPENSE,
		CustomFieldModel.PRODUCT,
		CustomFieldModel.TASK,
		CustomFieldModel.CALENDAR_EVENT,
	]

	static getTypeOptions(): { label: string, value: string }[] {
		return this.TYPES.map(type => ({
			label: i18n.t(`CustomFieldType::${type}`),
			value: String(type)
		}))
	}

	static getModelOptions(): { label: string, value: string }[] {
		return this.MODELS.map(model => ({
			label: i18n.t(`CustomFieldModel::${model}`),
			value: String(model)
		}))
	}

	static getTypeInitialConfig(type: CustomFieldType): CustomFieldConfig {
		switch (type) {
			case CustomFieldType.SINGLE_OPTION:
			case CustomFieldType.MULTIPLE_OPTION:
				return { options: [] }
			default: return {}
		}
	}
}