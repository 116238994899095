import styled, { css } from 'styled-components'
import { Style } from '../../../styles'
import ResourceModalTableRowHeader from './ResourceModalTableRowHeader'

const ResourceModalTableRow = styled.tr<{ header?: boolean }>`
	min-height: 30px;
	border-bottom: 1px solid ${Style.color.border};

	${props => props.header && css`
		${ResourceModalTableRowHeader} {
			font-weight: 400;
			color: #5886c4;
			background-color: #e6ebf1;
		}
	`}
`

export default ResourceModalTableRow