import styled from 'styled-components'
import { Style } from '../../styles'

const TaskSectionTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
  margin: ${Style.spacing.x1} 0 ${Style.spacing.x1} ${Style.spacing.x5};
  font-size: 18px;
  font-weight: 600;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      margin-left: ${Style.spacing.x4};
  }
`

export default TaskSectionTitle