import * as React from 'react'
import { closePaymentQRModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import { CurrentUser, QRCData, RemittanceInformationType } from '../../types'
import MoneyInput from '../Form/MoneyInput'
import QRCode from '../QR/QRCode'
import styled from 'styled-components'
import Alert from '../Alert/Alert'
import Icon from '../Icons/Icon'
import PaymentHelper from '../../helpers/PaymentHelper'
import Switch from '../Form/Switch'
import MaskedInput from 'react-text-mask'
import ModalWindowClass from './Parts/ModalWindow'
import { Style } from '../../styles'
import Button from '../Button/Button'
import IbanHelper from '../../helpers/IbanHelper'

const ModalWindow = styled(ModalWindowClass)`
  min-width: 780px;
  max-width: 780px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: 100%;
    min-width: 100%;
  }
`

const CanvasContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  canvas {
    border: 2px dotted ${Style.color.border};
    border-radius: 5px;
  }
`

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
    align-items: initial;
  }

`
const ModalForm = styled.div`
  flex: 1;
`
const ModalCanvas = styled.div`
  flex: 1;
`

const SubmitContainer = styled.div`
  width: 100%;

  > a {
    width: 100%;
    text-align: center;
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  margin-bottom: ${Style.spacing.x2};

  span {
    color: rgb(174, 163, 184);
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  name?: string;
  iban?: string;
  remittanceInformation?: string;
  remittanceInformationType?: RemittanceInformationType;
  amount?: number;
  currency?: string
  onSubmit?: (paid: boolean) => void
}

interface IDispatchToProps {
  close: typeof closePaymentQRModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  errors: any
  name: string,
  iban: string,
  remittanceInformation: string,
  remittanceInformationType: RemittanceInformationType,
  amount: number,
  currency: string
  isSubmitting: boolean
}

class PaymentQRModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: true,
      errors: {},
      name: props.name,
      iban: props.iban,
      remittanceInformation: props.remittanceInformation,
      remittanceInformationType: props.remittanceInformationType,
      amount: props.amount,
      currency: props.currency,
      isSubmitting: false,
    }

    this.getQuickResponseCodeData = this.getQuickResponseCodeData.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.onIbanChange = this.onIbanChange.bind(this)
    this.onRemittanceInformationChange = this.onRemittanceInformationChange.bind(this)
    this.onToggleRemittanceInformationType = this.onToggleRemittanceInformationType.bind(this)
    this.onAmountChange = this.onAmountChange.bind(this)
    this.onMarkAsPaidClick = this.onMarkAsPaidClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  getQuickResponseCodeData(): QRCData {
    const { name, amount, currency, iban, remittanceInformation, remittanceInformationType } = this.state

    return {
      name: name,
      iban: iban,
      amount: amount,
      currency: currency,
      remittanceInformation: remittanceInformation,
      remittanceInformationType: remittanceInformationType
    }
  }

  onCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onNameChange(e) {
    this.setState({ name: e.target.value })
  }

  onIbanChange(e) {
    this.setState({ iban: e.target.value })
  }

  onRemittanceInformationChange(e) {
    this.setState({ remittanceInformation: e.target.value })
  }

  onToggleRemittanceInformationType() {
    const { remittanceInformationType } = this.state

    this.setState({
      remittanceInformation: '',
      remittanceInformationType: remittanceInformationType === RemittanceInformationType.STRUCTURED ? RemittanceInformationType.UNSTRUCTURED : RemittanceInformationType.STRUCTURED
    })
  }

  onAmountChange(amount: number) {
    this.setState({ amount: amount })
  }

  onMarkAsPaidClick() {
    const { close, onSubmit } = this.props

    close()

    if (onSubmit) onSubmit(true)
  }

  render() {
    const { t, currentUser } = this.props
    const { name, iban, remittanceInformation, remittanceInformationType, amount, currency, didInitialLoad } = this.state
    const { workspace: { setting: { number_format } } } = currentUser

    return (
      <ModalWindow>
        <ModalHeader
          title={t('PaymentQRModal::Scan this QR code with your mobile bank app to prepare the payment.')}
          onCloseClick={this.onCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <ModalContentContainer>
              <Description>
                {t('PaymentQRModal::Standard Europe QR codes are currently supported by Belfius, KBC, ING, and Bunq')}
                <span>{t('PaymentQRModal::- Note that the Bancontact/Payconiq app does not support European QR codes')}</span>
              </Description>

              <ModalContentWrapper>
                <ModalForm>
                  <form>
                    <div className='form-item'>
                      <label>{t('PaymentQRModal::Recipient name')}</label>
                      <input
                        type='text'
                        value={name}
                        placeholder={t('PaymentQRModal::Recipient name')}
                        onChange={this.onNameChange}
                      />
                    </div>
                    <div className={`form-item ${iban?.length >= 16 && !IbanHelper.validate(iban) ? 'is-danger' : ''}`}>
                      <label>{t('PaymentQRModal::Recipient IBAN')}</label>
                      <input
                        type='text'
                        value={iban}
                        placeholder={t('PaymentQRModal::Recipient IBAN')}
                        onChange={this.onIbanChange}
                      />
                    </div>
                    <div className='form-item'>
                      <label>{t('PaymentQRModal::Remittance information')}</label>
                      {remittanceInformationType === RemittanceInformationType.STRUCTURED && <>
                        <MaskedInput
                          type='text'
                          placeholder='+++001/8094/26074+++'
                          guide={true}
                          showMask={true}
                          mask={PaymentHelper.getPaymentReferenceMask(RemittanceInformationType.STRUCTURED)}
                          onChange={this.onRemittanceInformationChange}
                          value={remittanceInformation}
                          style={{ textAlign: 'center' }}
                        />
                      </>}

                      {remittanceInformationType === RemittanceInformationType.UNSTRUCTURED && <textarea
                        placeholder={t('PaymentModal::Remittance information')}
                        onChange={this.onRemittanceInformationChange}
                      />}
                      <div style={{ marginTop: 8 }}>
                        <Switch
                          name='structured_communication'
                          label={t('PaymentModal::Structured communication')}
                          onClick={this.onToggleRemittanceInformationType}
                          checked={remittanceInformationType === RemittanceInformationType.STRUCTURED}
                        />
                      </div>
                    </div>
                    <div className='form-item'>
                      <label>{t('PaymentQRModal::Amount')}</label>
                      <MoneyInput
                        currency={currency}
                        value={amount}
                        placeholderValue={t('PaymentQRModal::Amount')}
                        name='amount'
                        numberFormat={number_format}
                        onBlur={this.onAmountChange}
                      />
                    </div>

                    <SubmitContainer>
                      <Button
                        type='default'
                        text={t('PaymentQRModal::Mark as paid')}
                        onClick={this.onMarkAsPaidClick}
                      />
                    </SubmitContainer>
                  </form>
                </ModalForm>

                <ModalCanvas>
                  <div className='form-item'>
                    <Alert
                      type='warning'
                      text={
                        <span>
                          <span style={{ marginRight: 4 }}>
                            <Icon icon='exclamation-triangle' />
                          </span>
                          {t('PaymentQRModal::Verify that the payment details are correct.')}
                        </span>
                      }
                    />
                  </div>
                  <CanvasContainer>
                    <QRCode
                      data={PaymentHelper.quickResponseCodeData(this.getQuickResponseCodeData())}
                      width={256}
                    />
                  </CanvasContainer>
                </ModalCanvas>
              </ModalContentWrapper>
            </ModalContentContainer>
          </ModalContent>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      paymentQRModal: {
        name,
        iban,
        remittanceInformation,
        remittanceInformationType,
        amount,
        currency,
        onSubmit,
      }
    }
  } = state

  return {
    name: name,
    iban: iban,
    remittanceInformation: remittanceInformation,
    remittanceInformationType: remittanceInformationType,
    amount: amount,
    currency: currency,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closePaymentQRModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PaymentQRModal))