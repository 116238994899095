import styled from "styled-components";

const ContactDocumentContainer = styled.div`
	max-width: 800px;
	margin: 0 auto;

	.react-pdf__Document {
		margin-bottom: 60px;
	}
`

export default ContactDocumentContainer