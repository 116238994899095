import { TasksController } from "../controllers";
import i18n from "../translations";
import { Task, User } from "../types";
import moment from "../utilities/Moment";

class TaskHelper {
  static getAssigneeName(assignee: User): string {
    if (assignee) {
      if (assignee?.name) {
        return assignee.name;
      } else {
        return assignee.email;
      }
    } else {
      return "-";
    }
  }

  static getFollowUpActionDates() {
    return [
      {
        content: i18n.t("ModalActionFollowUp::Today"),
        date: moment().format("YYYY-MM-DD"),
      },
      {
        content: i18n.t("ModalActionFollowUp::Tomorrow"),
        date: moment().add(1, "day").format("YYYY-MM-DD"),
      },
      {
        content: i18n.t("ModalActionFollowUp::In 2 days ({{date}})", {
          date: moment().add(2, "days").format("dddd"),
        }),
        date: moment().add(2, "days").format("YYYY-MM-DD"),
      },
      {
        content: i18n.t("ModalActionFollowUp::In 3 days ({{date}})", {
          date: moment().add(3, "days").format("dddd"),
        }),
        date: moment().add(3, "days").format("YYYY-MM-DD"),
      },
      {
        content: i18n.t("ModalActionFollowUp::In 1 week ({{date}})", {
          date: moment().add(1, "week").format("D MMM"),
        }),
        date: moment().add(1, "week").format("YYYY-MM-DD"),
      },
      {
        content: i18n.t("ModalActionFollowUp::In 2 weeks ({{date}})", {
          date: moment().add(2, "weeks").format("D MMM"),
        }),
        date: moment().add(2, "weeks").format("YYYY-MM-DD"),
      },
      {
        content: i18n.t("ModalActionFollowUp::In 1 month ({{date}})", {
          date: moment().add(1, "month").format("D MMM"),
        }),
        date: moment().add(1, "month").format("YYYY-MM-DD"),
      },
      {
        content: i18n.t("ModalActionFollowUp::In 3 months ({{date}})", {
          date: moment().add(3, "months").format("D MMM"),
        }),
        date: moment().add(3, "months").format("YYYY-MM-DD"),
      },
      {
        content: i18n.t("ModalActionFollowUp::In 6 months ({{date}})", {
          date: moment().add(6, "months").format("D MMM"),
        }),
        date: moment().add(6, "months").format("YYYY-MM-DD"),
      },
      {
        content: i18n.t("ModalActionFollowUp::Custom date"),
        date: "custom",
      },
    ];
  }
}

export default TaskHelper;
