
import * as React from 'react'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import BoardHelper from '../../helpers/BoardHelper'
import DroppableHelper from '../../helpers/DroppableHelper'
import { Style } from '../../styles'
import { TaskChecklist, TaskChecklistItem, TimeEntry } from '../../types'
import Button from '../Button/Button'
import Icon from '../Icons/Icon'
import AddTaskChecklistItem from './AddTaskChecklistItem'
import ChecklistItem from './ChecklistItem'
import TaskActions from './TaskActions'
import TaskButton from './TaskButton'
import TaskCancelAction from './TaskCancelAction'
import Container from './TaskSection'
import TaskSectionContent from './TaskSectionContent'
import TitleIcon from './TaskSectionIcon'
import TaskSectionTitle from './TaskSectionTitle'

const Title = styled(TaskSectionTitle)`
	cursor: pointer;
`

const TitleName = styled.div`
	width: 100%;
`

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: ${Style.spacing.x0_5};
`

const ProgressContainer = styled.div`
	position: relative;
	margin-bottom: 6px;
`

const ProgressPercentage = styled.div`
	color: #5e6c84;
	font-size: 11px;
	line-height: 10px;
	position: absolute;
	left: 0;
	top: -1px;
	text-align: center;
	width: 32px;
`

const ProgressBar = styled.div`
	background: rgba(9,30,66,.08);
	border-radius: 4px;
	clear: both;
	height: 8px;
	margin: 0 0 0 40px;
	overflow: hidden;
	position: relative;
`

const ProgressBarCurrent = styled.div`
	background: ${Style.color.brandSuccess};
	bottom: 0;
	left: 0;
	position: absolute;
	top: 0;
	transition-property: max-width;
	transition-duration: 250ms;
	transition-timing-function: ease-in;
	width: 100%;
`

const ChecklistItems = styled.div`
	margin-bottom: ${Style.spacing.x2};
`

const FormActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
`

interface IProps {
	index: number
	checklist: TaskChecklist
	activeTimeEntry?: TimeEntry
	onNameFormSubmit: (checklist: TaskChecklist, name: string) => void
	onDeleteClick: (checklist: TaskChecklist) => void
	onAddChecklistItem: (item: TaskChecklistItem) => void
	onUpdateChecklistItem: (index: number, item: TaskChecklistItem) => void
	onChecklistItemDeleteClick: (index: number, item: TaskChecklistItem) => void
	onChecklistItemStartTimerClick: (item: TaskChecklistItem) => void
}

const TaskChecklistSection = (props: IProps) => {
	const { t } = useTranslation()
	const { index, checklist, activeTimeEntry } = props
	const { items } = checklist

	const nameInput = React.useRef<HTMLInputElement>(null)
	const nameFormContainer = React.useRef<HTMLDivElement>()
	const [checklistNameFormActive, setChecklistNameFormActive] = React.useState(false)
	const [formChecklistName, setFormChecklistName] = React.useState('')
	const itemsCount = items.length
	const itemsCompletedCount = items.filter(item => item.completed).length
	const completedPercentage = itemsCount > 0 ? Math.max(0, Math.round((itemsCompletedCount / itemsCount * 100))) : 0

	React.useEffect(() => {
		if (checklistNameFormActive) {
			document.addEventListener('mousedown', onOutsideNameContainerClick);
		} else {
			document.removeEventListener('mousedown', onOutsideNameContainerClick);
		}

		return () => {
			document.removeEventListener('mousedown', onOutsideNameContainerClick);
		}
	}, [checklistNameFormActive])

	const onToggleFormName = () => {
		if (!checklistNameFormActive) {
			setFormChecklistName(checklist.name)

			setTimeout(() => {
				if (nameInput && nameInput.current) {
					nameInput.current.select()
				}
			}, 50)
		}
		setChecklistNameFormActive(!checklistNameFormActive)
	}

	const onDeleteClick = () => {
		props.onDeleteClick(checklist)
	}

	const onOutsideNameContainerClick = (e) => {
		if (checklistNameFormActive) {
			if (nameFormContainer && !nameFormContainer.current.contains(e.target)) {
				// Reset form checklist name
				setFormChecklistName('')

				// Reset form view
				setChecklistNameFormActive(false)
			}
		}
	}

	const onNameChange = (e) => {
		setFormChecklistName(e.currentTarget.value)
	}

	const onNameFormSubmit = () => {
		// Submit form
		props.onNameFormSubmit(checklist, formChecklistName)

		// Reset form checklist name
		setFormChecklistName('')

		// Reset form view
		setChecklistNameFormActive(false)
	}

	const onNameFormCancelClick = () => {
		// Reset form checklist name
		setFormChecklistName('')

		// Reset form view
		setChecklistNameFormActive(false)
	}

	const onChecklistItemFormSubmit = (item: TaskChecklistItem) => {
		props.onAddChecklistItem({
			...item,
			position: BoardHelper.getCreatePosition(checklist.items)
		})
	}

	const onChecklistItemUpdateFormSubmit = (index: number, item: TaskChecklistItem) => {
		props.onUpdateChecklistItem(index, item)
	}

	const onChecklistStartTimerClick = (item: TaskChecklistItem) => {
		props.onChecklistItemStartTimerClick(item)
	}

	const onChecklistItemDeleteClick = (index: number, item: TaskChecklistItem) => {
		props.onChecklistItemDeleteClick(index, item)
	}

	const onChecklistItemDragEnd = (dropResult: DropResult, provided: ResponderProvided) => {
		const { destination, source, draggableId } = dropResult

		if (!destination) return

		if (
			destination.droppableId !== source.droppableId &&
			destination.index !== source.index
		) {
			return
		}

		// Calculate new position
		const newPosition = DroppableHelper.getNewPositionBasedOnDropResult(checklist.items, dropResult)

		// Fetch checklist item from items
		const checklistItem = checklist.items[source.index]

		// Request update
		props.onUpdateChecklistItem(source.index, {
			...checklistItem,
			position: newPosition
		})
	}

	return (
		<Container>
			<Title>
				<TitleIcon>
					<Icon icon='tasks' />
				</TitleIcon>

				{!checklistNameFormActive && <TitleContainer>
					<TitleName onClick={onToggleFormName}>{checklist.name}</TitleName>
					<TaskButton
						destructive={true}
						onClick={onDeleteClick}
						style={{ marginLeft: 8, marginBottom: 0, fontSize: 15, overflow: 'inherit' }}
					>
						{t('TaskChecklist::Delete')}
					</TaskButton>
				</TitleContainer>}
				{checklistNameFormActive && <TitleName ref={nameFormContainer}>
					<form onSubmit={onNameFormSubmit}>
						<input
							ref={nameInput}
							type='text'
							value={formChecklistName}
							onChange={onNameChange}
							autoFocus={true}
							placeholder={t('TaskChecklist::Enter a name')}
							style={{ marginBottom: 8 }}
						/>
						<TaskActions style={{ marginBottom: 8 }}>
							<Button
								type='success'
								text={t('TaskChecklist::Save')}
								onClick={onNameFormSubmit}
							/>

							<TaskCancelAction onClick={onNameFormCancelClick} />
						</TaskActions>
						<input type='submit' style={{ display: 'none' }} />
					</form>
				</TitleName>}
			</Title>

			<TaskSectionContent>
				<ProgressContainer>
					<ProgressPercentage>
						{`${completedPercentage}%`}
					</ProgressPercentage>
					<ProgressBar>
						<ProgressBarCurrent style={{ maxWidth: `${completedPercentage}%` }} />
					</ProgressBar>
				</ProgressContainer>

				<DragDropContext
					onDragEnd={onChecklistItemDragEnd}
				>
					<Droppable droppableId={`checklist-${checklist}`}>
						{(provided) => {
							return (
								<ChecklistItems
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{items.map((item, index) => (
										<ChecklistItem
											key={index}
											index={index}
											item={item}
											onSubmit={(item) => onChecklistItemUpdateFormSubmit(index, item)}
											onStartTimerClick={onChecklistStartTimerClick}
											onDeleteClick={(item) => onChecklistItemDeleteClick(index, item)}
											timerEnabled={Boolean(activeTimeEntry)}
										/>
									))}

									{provided.placeholder}
								</ChecklistItems>
							)
						}}
					</Droppable>
				</DragDropContext>

				<FormActions>
					<AddTaskChecklistItem
						onSubmit={onChecklistItemFormSubmit}
					/>
				</FormActions>
			</TaskSectionContent>
		</Container >
	)
}

export default TaskChecklistSection