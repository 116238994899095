import * as React from 'react'
import Icon from "../components/Icons/Icon";
import i18n from "../translations";
import { Contact, ContactAddress, ContactAddressType, ContactEmail, ContactEmailType, ContactTelephoneNumber, ContactTelephoneNumberType, ContactType, ContactWebsite, ContactableType } from "../types";

export default class ContactHelper {
  static POSITION_STEP_INDEX = 16384

  static getContactTypeIcon(contact: Contact): JSX.Element {
    return (
      <Icon icon={contact.type === 'person' ? 'user' : 'company'} />
    )
  }

  static getGroupedContactEmails(contacts: Contact[]): {
    label: string,
    value: string,
    options: [
      {
        label: string,
        value: string,
      }
    ]
  }[] {
    let groupedContacts = [];

    contacts.forEach((contact) => {
      let label = contact.name
      let value = contact.name

      const groupedContactIndex = groupedContacts.findIndex((groupedContact: any) => groupedContact.value === value)

      const options = contact.emails.map((email) => {
        return { label: email.value, value: email.value }
      })

      if (groupedContactIndex === -1) {
        groupedContacts.push({
          label: label,
          value: value,
          options: options
        })
      } else {
        groupedContacts[groupedContactIndex].options = [...groupedContacts[groupedContactIndex].options, ...options]
      }
    })

    return groupedContacts;
  }

  static getContactLabelType(type: ContactType): string {
    return i18n.t(`ContactType::${type}`)
  }

  static getEmailTypeLabel(type: ContactEmailType): string {
    return i18n.t(`ContactEmailType::${type}`)
  }

  static getEmailTypeOptions(): { label: string, value: string }[] {
    return Object.keys(ContactEmailType).map((key: string) => {
      return {
        label: this.getEmailTypeLabel(ContactEmailType[key]),
        value: ContactEmailType[key]
      }
    })
  }

  static getPhonenumberTypeLabel(type: ContactTelephoneNumberType): string {
    return i18n.t(`ContactTelephoneNumberType::${type}`)
  }

  static getPhonenumberTypeOptions(): { label: string, value: string }[] {
    return Object.keys(ContactTelephoneNumberType).map((key: string) => {
      return {
        label: this.getPhonenumberTypeLabel(ContactTelephoneNumberType[key]),
        value: ContactTelephoneNumberType[key]
      }
    })
  }

  static getAddressTypeLabel(type: ContactAddressType): string {
    return i18n.t(`ContactAddressType::${type}`)
  }

  static getAddressTypeOptions(): { label: string, value: string }[] {
    return Object.keys(ContactAddressType).map((key: string) => {
      return {
        label: this.getAddressTypeLabel(ContactAddressType[key] as ContactAddressType),
        value: ContactAddressType[key]
      }
    })
  }

  static getPrimaryEmail(contact: Contact): ContactEmail | null {
    if (contact.emails.length === 0) return null

    return contact.emails[0]
  }

  static getPrimaryTelephonenumber(contact: Contact): ContactTelephoneNumber | null {
    if (contact.telephone_numbers.length === 0) return null

    return contact.telephone_numbers[0]
  }

  static getPrimaryWebsite(contact: Contact): ContactWebsite | null {
    if (contact.websites.length === 0) return null

    return contact.websites[0]
  }

  static getFullAddress(address: ContactAddress): string {
    if (!address) return ''

    return `${address.street} ${address.street_number}, ${address.zip} ${address.city}`
  }
}