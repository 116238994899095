import styled, { css } from 'styled-components'
import { Style } from '../../../styles'

const ResourceModalTableRowHeader = styled.th<{
	sticky?: boolean
	stickyTop?: number | string
	stickyRight?: number | string
	stickyBottom?: number | string
	stickyLeft?: number | string
}>`
	padding: 5px 10px;
	font-weight: 400;
	text-align: left;
	white-space: nowrap;
	border-bottom: 1px solid ${Style.color.border};
	font-size: 14px;

	&:not(:first-child) {
		border-left: 1px dotted ${Style.color.border};
	}

	${props => props.sticky && css`
		position: sticky;
		z-index: 1;
	`}

	${props => props.stickyTop && css`
		top: ${props.stickyTop};
	`}

	${props => props.stickyRight && css`
		right: ${props.stickyRight};
	`}

	${props => props.stickyBottom && css`
		bottom: ${props.stickyBottom};
	`}

	${props => props.stickyLeft && css`
		left: ${props.stickyLeft};
	`}
`

export default ResourceModalTableRowHeader