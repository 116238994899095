import styled from 'styled-components'

const SidebarHeader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	line-height: 20px;
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	transition: margin .12s ease-in;
	white-space: nowrap;
	font-weight: bold;
	font-size: 16px;
`

export default SidebarHeader