import * as React from 'react'
import { closeProjectStatusUpdateModal, showContactModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import Notification from '../../utilities/Notification'
import { ProjectsController } from '../../controllers'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ProjectHelper from '../../helpers/ProjectHelper'
import PowerSelect from '../Form/PowerSelect'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import ProjectStatusSelectComponents from '../Powerselect/ProjectStatusSelectComponents'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import { CurrentUser, Project, ProjectStatus } from '../../types'

interface IStateToProps {
  project: Project
  onSubmit?: (project: Project) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeProjectStatusUpdateModal,
  showContactModal: typeof showContactModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  project: Project | null
  statuses: ProjectStatus[]
  errors: any
}

class ProjectStatusUpdateModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      project: null,
      statuses: [],
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onProjectStatusChange = this.onProjectStatusChange.bind(this)
    this.onEstimatedStartOnChange = this.onEstimatedStartOnChange.bind(this)
    this.onEstimatedFinishOnChange = this.onEstimatedFinishOnChange.bind(this)
    this.onStartedOnChange = this.onStartedOnChange.bind(this)
    this.onFinishedOnChange = this.onFinishedOnChange.bind(this)
    this.onCancelledOnChange = this.onCancelledOnChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  fetchForm() {
    const { project } = this.props

    ProjectsController
      .getForm(project.id)
      .then(response => {
        const { statuses, project: responseProject } = response

        this.setState({
          statuses: statuses,
          project: {
            ...responseProject,
            ...project,
          },
          didInitialLoad: true,
        })
      })
      .catch(console.error)
  }

  onProjectStatusChange(option) {
    const { project } = this.state;

    switch (option.value) {
      case ProjectStatus.PROPOSAL:
        project.start_date = null
        project.end_date = null
        break
      case ProjectStatus.ACTIVE:
        project.end_date = null
        project.cancel_date = null
        break
      case ProjectStatus.COMPLETED:
        project.cancel_date = null
        break
    }

    this.setState({
      project: {
        ...project,
        status: option.value,
      }
    });
  }

  onEstimatedStartOnChange(value) {
    const { project } = this.state;
    const estimatedStartOn = moment(value);


    this.setState({
      project: {
        ...project,
        estimated_start_date: estimatedStartOn.isValid() ? estimatedStartOn.format('YYYY-MM-DD') : null
      }
    });
  }

  onEstimatedFinishOnChange(value) {
    const { project } = this.state;
    const estimatedFinishOn = moment(value);

    this.setState({
      project: {
        ...project,
        estimated_end_date: estimatedFinishOn.isValid() ? estimatedFinishOn.format('YYYY-MM-DD') : null,
      }
    });
  }

  onStartedOnChange(value) {
    const { project } = this.state;
    const startedOn = moment(value);

    this.setState({
      project: {
        ...project,
        start_date: startedOn.isValid() ? startedOn.format('YYYY-MM-DD') : null,
      }
    });
  }

  onFinishedOnChange(value) {
    const { project } = this.state;
    const finishedOn = moment(value);

    this.setState({
      project: {
        ...project,
        end_date: finishedOn.isValid() ? finishedOn.format('YYYY-MM-DD') : null
      }
    });
  }

  onCancelledOnChange(value) {
    const { project } = this.state;
    const cancelledOn = moment(value);

    this.setState({
      project: {
        ...project,
        cancel_date: cancelledOn.isValid() ? cancelledOn.format('YYYY-MM-DD') : null
      }
    });
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { project } = this.state;
    const { close, onSubmit, t } = this.props

    if (project.id) {
      ProjectsController
        .update(project)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('ProjectStatusUpdateModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('ProjectStatusUpdateModal::Project successfully updated'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(error => console.error(error))
    }
    else {
      ProjectsController
        .create(project)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('ProjectStatusUpdateModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('ProjectStatusUpdateModal::Project successfully created'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(console.error)
    }
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderDetails() {
    const { project, statuses } = this.state;
    const { currentUser: { workspace: { setting } }, t } = this.props

    const statusOptions = ProjectHelper.getStatusOptions()
    const selectedStatusOption = project ? ProjectHelper.getStatusOption(statuses, project.status) : null

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ProjectStatusUpdateModal::Status')}</label>
              <PowerSelect
                options={statusOptions}
                value={selectedStatusOption}
                onChange={this.onProjectStatusChange}
                components={ProjectStatusSelectComponents}
                theme={ReactSelectTheme}
              />
            </div>
          </div>
        </div>

        {project.status === ProjectStatus.PROPOSAL && <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ProjectStatusUpdateModal::Estimated start on')}</label>
              <DateInput
                name='estimated_start_date'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(project.estimated_start_date)}
                inputProps={{ placeholder: setting.date_format }}
                onChange={this.onEstimatedStartOnChange}
                closeOnSelect
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ProjectStatusUpdateModal::Estimated finish on')}</label>
              <DateInput
                name='estimated_end_on'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(project.estimated_end_date)}
                inputProps={{ placeholder: setting.date_format }}
                onChange={this.onEstimatedFinishOnChange}
                closeOnSelect
              />
            </div>
          </div>
        </div>}

        {[ProjectStatus.ACTIVE, ProjectStatus.COMPLETED].includes(project.status) && <div className='grid'>
          <div className={`grid-cell with-${project.status === ProjectStatus.ACTIVE ? 12 : 6}col`}>
            <div className='form-item'>
              <label>{t('ProjectStatusUpdateModal::Start on')}</label>
              <DateInput
                name='start_date'
                dateFormat={setting.date_format}
                inputProps={{ placeholder: setting.date_format }}
                timeFormat={false}
                initialValue={moment(project.start_date)}
                onChange={this.onStartedOnChange}
                closeOnSelect
              />
            </div>
          </div>
          {project.status === ProjectStatus.COMPLETED && <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('ProjectStatusUpdateModal::Finished on')}</label>
              <DateInput
                name='end_date'
                dateFormat={setting.date_format}
                timeFormat={false}
                inputProps={{ placeholder: setting.date_format }}
                initialValue={moment(project.end_date)}
                onChange={this.onFinishedOnChange}
                closeOnSelect
              />
            </div>
          </div>}
        </div>}

        {project.status === ProjectStatus.CANCELLED && <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ProjectStatusUpdateModal::Cancelled on')}</label>
              <DateInput
                name='cancel_date'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(project.cancel_date)}
                inputProps={{ placeholder: setting.date_format }}
                onChange={this.onCancelledOnChange}
                closeOnSelect
              />
            </div>
          </div>
        </div>}
      </div>
    );
  }

  render() {
    const { project, close, t } = this.props
    const { didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={project.id ? t('ProjectStatusUpdateModal::Edit project') : t('ProjectStatusUpdateModal::Create project')}
          onCloseClick={close}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('ProjectStatusUpdateModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      projectStatusUpdateModal: {
        project,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    project: project,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeProjectStatusUpdateModal()),
    showContactModal: (options) => dispatch(showContactModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectStatusUpdateModal))