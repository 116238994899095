import styled, { css } from "styled-components"
import { Style } from "../../../styles"

const ResourceModalTableRowData = styled.td<{
	textAlign?: 'left' | 'right' | 'center'
	verticalAlign?: 'auto' | 'baseline' | 'bottom' | 'top'
}>`
	text-align: ${(props) => props.textAlign || 'left'};
	white-space: nowrap;
	padding: 5px 10px;
	height: 49px;
	min-height: 49px;
	max-height: 49px;
	vertical-align: ${(props) => props.verticalAlign};
	border-right: 1px dotted ${Style.color.border};
	font-size: 14px;

	&:last-child {
		border-right: none;
	}

	${props => props.onClick && css`
		cursor: pointer;
	`}
`

export default ResourceModalTableRowData