import * as React from 'react'
import qrcode from 'qrcode'
import { useEffect } from "react"

interface IProps {
  data: string
  width: number
}

const QRCode = (props: IProps) => {
  const { data, width } = props
  const canvas = React.createRef<HTMLCanvasElement>()

  useEffect(() => {
    qrcode.toCanvas(canvas.current, data, { width: width }, (error) => {
      if (error) console.error(error)
    })
  }, [data, width])

  return (
    <canvas ref={canvas}></canvas>
  )
}

export default QRCode