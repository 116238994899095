import * as React from 'react'
import store from "../store";
import Badge from '../components/Badge/Badge'
import i18n from '../translations'
import { Expense, ExpenseExportType, MimeTypes, Payment } from '../types';
import moment from '../utilities/Moment';
export default class ExpenseHelper {
  static getExpenseMailboxEmail() {
    const { authentication: { currentUser: { workspace } } } = store.getState()
    return `${workspace.id}@expense.bizzey.com`
  }

  static getColor(expense: Expense) {
    if (expense.category) {
      return expense.category.color
    } else {
      return '#9b9b9b'
    }
  }

  static getBillableAmount(expense: Expense) {
    if (expense.markup === 0) {
      return null
    } else if (expense.net_total && Number(expense?.markup) > 0) {
      return Number(expense.net_total) + (Number(expense.net_total) * Number(expense.markup))
    }

    return null
  }

  static getBillableBadge(expense: Expense): JSX.Element | null {
    if (expense.billable) {
      if (expense.billed) {
        return (
          <Badge
            text={i18n.t('ExpenseHelper::Billed')}
            type='success'
          />
        )
      } else {
        return (
          <Badge
            text={i18n.t('ExpenseHelper::Unbilled')}
            type='warning'
          />
        )
      }
    }

    return <span>-</span>
  }

  static getExpenseDropzoneMimeTypes(): MimeTypes[] {
    return [
      MimeTypes.JPG,
      MimeTypes.JPEG,
      MimeTypes.PNG,
      MimeTypes.PDF,
      MimeTypes.TEXT_XML,
      MimeTypes.APPLICATION_XML,
    ]
  }

  static getExpenseExportTypeOptions() {
    return Object.keys(ExpenseExportType).map(key => (
      { label: i18n.t(`ExpenseExportType::${ExpenseExportType[key]}`), value: ExpenseExportType[key] }
    ))
  }

  static getStatusBadge(expense: Expense) {
    if (expense.paid_on) {
      return <Badge text={i18n.t('ExpenseHelper::Paid')} type='success' />
    } else if (expense.due_on && moment(expense.due_on).isSameOrBefore(moment(), 'day') && !expense.paid_on) {
      return <Badge text={i18n.t('ExpenseHelper::Overdue')} type='danger' />
    } else if (expense.due_on) {
      return <Badge text={i18n.t('ExpenseHelper::Outstanding')} type='grey' />
    } else {
      return '-'
    }
  }

  static buildPaymentFromExpense(expense: Expense): Payment {
    return {
      creditor_name: expense?.supplier?.name || '',
      creditor_account: expense?.supplier?.account_numbers?.length > 0 ? expense.supplier.account_numbers[0].value : '',
      amount: expense.amount,
      remittance_information: expense.remittance_information,
      remittance_information_type: expense.remittance_information_type,
      suggested_execution_date: expense.due_on,
      currency: expense.currency,
    }
  }
}