import * as React from 'react'
import styled from "styled-components";
import Icon, { IconTypes } from "../Icons/Icon";

const Container = styled.div`
  display: flex;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #595959;

  i, svg {
    margin-right: 4px;
  }
`

const Text = styled.div`
  text-decoration: underline solid;
`

interface IProps {
  icon?: IconTypes
  text: string
  onClick?: () => void
}

const ButtonLink = (props: IProps) => {
  const { icon, text, onClick } = props
  return (
    <Container onClick={onClick}>
      {icon && <Icon icon={icon} />}
      <Text>{text}</Text>

    </Container>
  )
}

export default ButtonLink