import * as React from 'react'
import { closeCustomFieldModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Notification from '../../utilities/Notification'
import { CustomFieldsController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import CheckboxInput from '../Form/CheckboxInput'
import PowerSelect from '../Form/PowerSelect'
import CustomFieldHelper from '../../helpers/CustomFieldHelper'
import CreatablePowerSelect from '../Form/CreatablePowerSelect'
import { CurrentUser, CustomField, CustomFieldModel, CustomFieldType } from '../../types'

interface IStateToProps {
  customField: CustomField
  modelDisabled?: boolean
  typeDisabled?: boolean
  onSubmit?: (customField: CustomField) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeCustomFieldModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  customField: CustomField | null
  errors: any
}

class CustomFieldModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      customField: null,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.onModelChange = this.onModelChange.bind(this)
    this.onTypeChange = this.onTypeChange.bind(this)
    this.onRequiredChange = this.onRequiredChange.bind(this)
    this.onOptionsChange = this.onOptionsChange.bind(this)
    this.onOptionCreate = this.onOptionCreate.bind(this)
    this.onOptionCreationAllowedChange = this.onOptionCreationAllowedChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onModalCloseClick = this.onModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  fetchForm() {
    const { customField: propsCustomField } = this.props

    CustomFieldsController
      .getForm({ id: propsCustomField.id, model: propsCustomField.model })
      .then(response => {
        const { custom_field } = response

        this.setState({
          customField: {
            ...custom_field,
            ...propsCustomField,
          },
          didInitialLoad: true,
        })
      })
      .catch(console.error)
  }

  onNameChange(e) {
    const { customField } = this.state
    const name = e.currentTarget.value

    this.setState({
      customField: {
        ...customField,
        name: name
      }
    })
  }

  onModelChange(option) {
    const { customField } = this.state

    const updatedCustomField: CustomField = {
      ...customField,
      model: option.value,
    }

    // Only optional fields are allowed with given types
    if ([CustomFieldModel.TIME_ENTRY, CustomFieldModel.INVOICE, CustomFieldModel.EXPENSE].includes(option.value as CustomFieldModel)) {
      updatedCustomField.required = false
    }

    this.setState({
      customField: {
        ...updatedCustomField,
      }
    })
  }

  onTypeChange(option) {
    const { customField } = this.state

    const type = option.value
    const config = CustomFieldHelper.getTypeInitialConfig(type)

    this.setState({
      customField: {
        ...customField,
        type: type,
        config: config,
      }
    })
  }

  onRequiredChange(required: boolean) {
    const { customField } = this.state

    this.setState({
      customField: {
        ...customField,
        required: required,
      }
    })
  }

  onOptionsChange(options) {
    const { customField } = this.state

    if (
      customField.type === CustomFieldType.SINGLE_OPTION ||
      customField.type === CustomFieldType.MULTIPLE_OPTION
    ) {
      this.setState({
        customField: {
          ...customField,
          config: {
            ...customField.config,
            options: options
          }
        }
      })
    }
  }

  onOptionCreate(input) {
    const { customField } = this.state

    if (
      customField.type === CustomFieldType.SINGLE_OPTION ||
      customField.type === CustomFieldType.MULTIPLE_OPTION
    ) {
      this.setState({
        customField: {
          ...customField,
          config: {
            ...customField.config,
            options: [
              ...customField.config.options,
              { label: input, value: input },
            ]
          }
        }
      })
    }
  }

  onOptionCreationAllowedChange(optionCreationAllowed: boolean) {
    const { customField } = this.state

    if (
      customField.type === CustomFieldType.SINGLE_OPTION ||
      customField.type === CustomFieldType.MULTIPLE_OPTION
    ) {
      this.setState({
        customField: {
          ...customField,
          config: {
            ...customField.config,
            allowOptionCreation: optionCreationAllowed
          }
        }
      })
    }
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { customField } = this.state;
    const { close, onSubmit, t } = this.props

    if (customField.id) { // Do update
      CustomFieldsController
        .update(customField)
        .then(response => {
          const { errors, error_full_messages } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('CustomFieldModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('CustomFieldModal::Custom field successfully updated.'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(error => console.error(error))
    }
    else {
      CustomFieldsController
        .create(customField)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('CustomFieldModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('CustomFieldModal::Custom field successfully created.'))
            if (onSubmit) onSubmit(response)
            close()
          }
        })
        .catch(console.error)
    }
  }

  onModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderDetails() {
    const { t, modelDisabled, typeDisabled } = this.props
    const { customField } = this.state

    const modelOptions = CustomFieldHelper.getModelOptions()
    const selectedModelOption = modelOptions.find(option => option.value === customField.model)

    const typeOptions = CustomFieldHelper.getTypeOptions()
    const selectedTypeOption = typeOptions.find(option => option.value === customField.type)

    return (
      <form onSubmit={this.onFormSubmit}>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('CustomFieldModal::Name')}<span>*</span>
              </label>
              <input
                type='text'
                name='name'
                onChange={this.onNameChange}
                value={customField.name}
                placeholder={t('CustomFieldModal::Field name')}
                required
              />
            </div>
          </div>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('CustomFieldModal::For')}
              </label>
              <PowerSelect
                options={modelOptions}
                value={selectedModelOption}
                onChange={this.onModelChange}
                theme={ReactSelectTheme}
                isDisabled={modelDisabled}
              />
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('CustomFieldModal::Type')}</label>
              <PowerSelect
                options={typeOptions}
                value={selectedTypeOption}
                onChange={this.onTypeChange}
                theme={ReactSelectTheme}
                isDisabled={typeDisabled}
              />
            </div>
          </div>
          {![
            CustomFieldModel.TIME_ENTRY,
            CustomFieldModel.INVOICE,
            CustomFieldModel.EXPENSE,
            CustomFieldModel.TASK,
          ].includes(customField.model as CustomFieldModel) && <div className='grid-cell with-6col'>
              <div className='form-item'>
                <label>
                  {t('CustomFieldModal::Required')}
                </label>
                <CheckboxInput
                  onChange={this.onRequiredChange}
                  checked={customField.required}
                  label={t('CustomFieldModal::Required')}
                />
              </div>
            </div>}

          {this.renderTypeOptions()}
        </div>

        <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
      </form>
    )
  }

  renderTypeOptions() {
    const { t } = this.props
    const { customField } = this.state

    if (customField.type === CustomFieldType.SINGLE_OPTION || customField.type === CustomFieldType.MULTIPLE_OPTION) {
      const fieldOptions = customField.config.options
      const allowOptionCreation = Boolean(customField.config.allowOptionCreation)

      return (
        <>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('CustomFieldModal::Options')}
              </label>
              <CreatablePowerSelect
                options={fieldOptions}
                value={fieldOptions}
                onChange={this.onOptionsChange}
                onCreateOption={this.onOptionCreate}
                formatCreateLabel={(input) => t('CustomFieldModal::Add {{input}}', { input: input })}
                theme={ReactSelectTheme}
                // @ts-ignore
                isMulti={true}
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('CustomFieldModal::Option creation')}
              </label>
              <CheckboxInput
                onChange={this.onOptionCreationAllowedChange}
                checked={allowOptionCreation}
                label={t('CustomFieldModal::Allow')}
              />
            </div>
          </div>
        </>
      )
    }

    return null
  }

  render() {
    const { t, customField: customFieldProps } = this.props
    const { didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={customFieldProps.id ? t('CustomFieldModal::Edit custom field') : t('CustomFieldModal::Create custom field')}
          onCloseClick={this.onModalCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            {this.renderDetails()}
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('CustomFieldModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      customFieldModal: {
        customField,
        modelDisabled,
        typeDisabled,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    customField: customField,
    modelDisabled: modelDisabled,
    typeDisabled: typeDisabled,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeCustomFieldModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomFieldModal))