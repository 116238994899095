import * as React from 'react'
import styled, { css } from 'styled-components'

const SummaryContainer = styled.div`
	display: grid;
	align-items: stretch;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	width: 100%;

	${(props: { columnCount?: number }) => props.columnCount && css`
		grid-template-columns: repeat(${props.columnCount}, 1fr);
	`}

	@media screen and (max-width: 850px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media screen and (max-width: 500px) {
		grid-template-columns: 1fr;
	}
`

export default SummaryContainer