import * as React from 'react'
import { Contact, Project } from '../types';
import store from '../store';

export default class RateHelper {
  static getRateForContact(type: 'hourly' | 'day', contact: Contact) {
    switch (type) {
      case 'hourly':
        return contact.hourly_rate || store.getState().authentication.currentUser.workspace.setting.default_hourly_rate
      case 'day':
        return contact.day_rate || store.getState().authentication.currentUser.workspace.setting.default_day_rate
    }
  }

  static getRateForProject(type: 'hourly' | 'day', project: Project) {
    switch (type) {
      case 'hourly':
        return project.hourly_rate || project?.contact?.hourly_rate || store.getState().authentication.currentUser.workspace.setting.default_hourly_rate
      case 'day':
        return project.day_rate || project?.contact?.day_rate || store.getState().authentication.currentUser.workspace.setting.default_day_rate
    }
  }
}