import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'

interface IProps {
	activator: JSX.Element
	location?: string
	onSubmit: (location: string) => void
}

const LocationPopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator } = props

	const [popoverActive, setPopoverActive] = React.useState(false)
	const [location, setLocation] = React.useState(null)

	React.useEffect(() => {
		setLocation(props.location)
	}, [popoverActive, props.location])

	const onTogglePopover = () => {
		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
	}

	const onLocationChange = (e) => {
		setLocation(e.currentTarget.value)
	}

	const onFormSubmit = (e) => {
		if (e) e.preventDefault()
		// Submit form
		props.onSubmit(location)

		// Close popover
		onPopoverClose()
	}

	const onRemoveClick = () => {
		//
		props.onSubmit(null)

		onPopoverClose()
	}

	return (
		<Popover
			active={popoverActive}
			activator={
				<div onClick={onTogglePopover}>
					{activator}
				</div>
			}
			onClose={onPopoverClose}
			placement='bottom-start'
		>
			<TaskPopoverContainer>
				<TaskPopoverHeader>
					<TaskPopoverTitle>{t('TaskModal::Location')}</TaskPopoverTitle>
				</TaskPopoverHeader>
				<TaskPopoverContent>
					<form onSubmit={onFormSubmit}>
						<input
							type='text'
							value={location}
							onChange={onLocationChange}
							placeholder={t('TaskModal::Location')}
						/>

						<TaskButton
							onClick={onFormSubmit}
							style={{ marginTop: 12 }}
							success
							center
						>
							{t('TaskModal::Save')}
						</TaskButton>

						<TaskButton
							onClick={onRemoveClick}
							style={{ marginTop: 8 }}
							center
						>
							{t('TaskModal::Remove')}
						</TaskButton>
						<input type='submit' style={{ display: 'none' }} />
					</form>
				</TaskPopoverContent>
			</TaskPopoverContainer>
		</Popover>
	)
}

export default LocationPopover 