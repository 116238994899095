import styled from "styled-components"
import { Style } from "../../styles"

const SidebarContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	border-left: 1px solid ${Style.color.border};
	width: 412px;
	background-color: white;
	z-index: 6;
	padding: 12px;
	cursor: auto;
  pointer-events: all;
`

export default SidebarContainer