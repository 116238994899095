import * as React from 'react'
import { closeTransactionModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { TransactionsController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { NOTES_EDITOR_CONFIG } from '../Editor/Editor'
import { Transaction, CurrentUser, ReferralSource } from '../../types'
import MoneyInput from '../Form/MoneyInput'
import NumberFormatter from '../../utilities/NumberFormatter'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'

interface IStateToProps {
  transaction: Transaction
  activeTab?: TransactionModalTab
  onSubmit?: (transaction: Transaction) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeTransactionModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

export type TransactionModalTab = 'details' | 'notes'

interface IState {
  didInitialLoad: boolean
  activeTab: TransactionModalTab
  transaction: Transaction | null
  errors: any
}

class TransactionModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: 'details',
      transaction: null,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this)
    this.onTransactionNotesChange = this.onTransactionNotesChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onTransactionModalCloseClick = this.onTransactionModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { transaction: propsTransaction, activeTab: propActiveTab } = this.props

    try {
      const transaction = await TransactionsController.getTransaction(propsTransaction.id)
      const { activeTab } = this.state

      this.setState({
        transaction: transaction,
        didInitialLoad: true,
        activeTab: propActiveTab ? propActiveTab : activeTab,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onNavigationItemClick(e) {
    const activeTab = e.currentTarget.getAttribute('data-tab');

    this.setState({
      activeTab: activeTab
    });
  }

  onTransactionNotesChange(notes: string) {
    const { transaction } = this.state;

    this.setState({
      transaction: {
        ...transaction,
        notes: notes,
      }
    });
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { transaction } = this.state;
    const { close, onSubmit, t } = this.props

    if (transaction.id) { // Do update
      try {
        const response = await TransactionsController.update(transaction)
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });
          Notification.notifyError(t('TransactionModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('TransactionModal::Transaction successfully updated.'))
          if (onSubmit) onSubmit(response)
          close()
        }
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  onTransactionModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderNavigation(): JSX.Element {
    const { t } = this.props
    const { activeTab } = this.state

    return (
      <ModalNavigation>
        <ModalNavigationItem active={activeTab === 'details'} data-tab='details' onClick={this.onNavigationItemClick}>
          <Icon icon='info' />
          <span>
            {t('TransactionModal::Details')}
          </span>
        </ModalNavigationItem>
        <ModalNavigationItem active={activeTab === 'notes'} data-tab='notes' onClick={this.onNavigationItemClick}>
          <Icon icon='pencil' />
          <span>
            {t('TransactionModal::Notes')}
          </span>
        </ModalNavigationItem>
      </ModalNavigation>
    )
  }

  renderDetails() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const { activeTab, transaction } = this.state;

    if (activeTab !== 'details') return null;

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('TransactionModal::Account holder')} <span>*</span></label>
              <input
                type='text'
                placeholder={t('TransactionModal::Account name')}
                value={transaction.counterpart_name}
                disabled={true}
              />
            </div>
          </div>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('TransactionModal::Account number')} <span>*</span></label>
              <input
                type='text'
                placeholder='BE71 0961 2345 6769'
                value={transaction.counterpart_account_number}
                disabled={true}
              />
            </div>
          </div>


          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>{t('TransactionModal::Amount')} <span>*</span></label>
              <MoneyInput
                name='rate'
                currency={transaction.currency}
                numberFormat={setting.number_format}
                placeholderValue={t('TransactionModal::{{amount}}', { amount: NumberFormatter.formatCurrency(transaction.currency, setting.number_format, transaction.amount) })}
                value={transaction?.amount}
                onBlur={() => { }}
                disabled={true}
              />
            </div>
          </div>

          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('TransactionModal::Execution time')}
              </label>
              <DateInput
                name='execution_time'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(transaction.execution_time)}
                inputProps={{ placeholder: setting.date_format, disabled: true }}
                onChange={() => { }}
                closeOnSelect
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('TransactionModal::Remittance information')}</label>
              <textarea disabled>{transaction.remittance_information}</textarea>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNotes() {
    const { t } = this.props
    const { activeTab, transaction } = this.state;

    if (activeTab !== 'notes') return null;

    return (
      <div data-tab='notes'>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item is-last'>
              <EditorContainer>
                <Editor
                  model={transaction.notes}
                  onModelChange={this.onTransactionNotesChange}
                  config={{
                    ...NOTES_EDITOR_CONFIG,
                    placeholderText: t('TransactionModal::Add notes (only visible for you)'),
                    heightMin: 120,
                    heightMax: 265
                  }}
                />
              </EditorContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { transaction, t } = this.props
    const { didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={transaction?.id ? t('TransactionModal::Edit transaction') : t('TransactionModal::Create transaction')}
          onCloseClick={this.onTransactionModalCloseClick}
          navigation={this.renderNavigation()}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              {this.renderNotes()}

              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('TransactionModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      transactionModal: {
        transaction,
        activeTab,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    activeTab: activeTab,
    transaction: transaction,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeTransactionModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TransactionModal))