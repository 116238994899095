import * as React from 'react'
import { closeSendEmailModal, showConfirmModal, showContactModal, showEmailTemplateModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import { ActiveStorageController, ContactsController, EmailsController, EmailTemplatesController, InvoicesController, TasksController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Notification from '../../utilities/Notification'
import { updateSettings } from '../../store/authentication/actions'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { EMAIL_EDITOR_CONFIG } from '../Editor/Editor'
import EditorHelper from '../../helpers/EditorHelper'
import Dropzone, { DropEvent, DropzoneRef, FileRejection } from 'react-dropzone'
import { MAX_EMAIL_ATTACHMENTS_SIZE, MAX_EMAIL_ATTACHMENT_SIZE } from '../../Constants'
import EmailAttachment from '../EmailPreview/EmailAttachment'
import EmailAttachments from '../EmailPreview/EmailAttachments'
import ReactTooltip from 'react-tooltip'
import Alert from '../Alert/Alert'
import ModalFooterActionIcons from './Parts/ModalFooterActionIcons'
import ModalFooterActionIcon from './Parts/ModalFooterAction'
import { ActiveStorageBlob, Contact, CurrentUser, EmailTemplate, IntegrationType, LedgerItem, LedgerItemType, Locale, MimeTypes, SendEmailParams, Settings, Task } from '../../types'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Button from '../Button/Button'
import Icon from '../Icons/Icon'
import ModalActionFollowUp from './Parts/ModalActionFollowUp'
import ModalWindow from './Parts/ModalWindow'
import ModalFooter from './Parts/ModalFooter'
import EmailTemplatesPopover from '../EmailModal/EmailTemplatesPopover'
import EmailIntegrations from '../EmailModal/EmailIntegrations'
import EmailIntegration from '../EmailModal/EmailIntegration'
import Images from '../../images'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'
import EmailPreviewContainer from '../Editor/EmailPreviewContainer'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import MustacheHelper from '../../helpers/MustacheHelper'
import ButtonWithActions from '../Button/ButtonWithActions'

const SendEmailModalWindow = styled(ModalWindow)`
  height: 100vh;
  max-width: 400px;
  max-width: initial;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: initial;
  }

  ${ModalMiddle} {
    overflow-x: hidden;
    overflow-y: auto;
  }
`

const EmailOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const EmailOptionMainContainer = styled.div`
  flex: 1;
  width: 100%;
  margin-right: 8px;

  &:last-child {
    margin-right: 0px;
  }
`

const EmailOptionLink = styled.a`
  color: ${Style.color.brandPrimary};
  margin-right: 4px;
  cursor: pointer;

  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    text-decoration: underline;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  email: SendEmailParams
  onSubmit?: () => void
}

interface IDispatchToProps {
  updateSettings: typeof updateSettings
  close: typeof closeSendEmailModal
  showContactModal: typeof showContactModal
  showEmailTemplateModal: typeof showEmailTemplateModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation & RouteComponentProps

interface IState {
  didInitialLoad: boolean
  locale: Locale,
  contactId: string | null
  to: Contact[]
  cc: Contact[]
  ccActive: boolean
  bcc: Contact[]
  bccActive: boolean
  subject: string | null
  body: string | null
  attachments: ActiveStorageBlob[]
  variables: object
  isSending: boolean
  emailIntegrationEnabled?: boolean
  errors: any
  task: Task
  templatePopoverActive: boolean
  preview: boolean
}

class SendEmailModal extends React.Component<IProps, IState> {
  private dropzone = React.createRef<DropzoneRef>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      locale: null,
      contactId: null,
      subject: '',
      to: [],
      cc: [],
      ccActive: false,
      bcc: [],
      bccActive: false,
      body: '',
      attachments: [],
      variables: {},
      isSending: false,
      emailIntegrationEnabled: false,
      errors: {},
      task: null,
      templatePopoverActive: false,
      preview: false
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onSendTestEmailClick = this.onSendTestEmailClick.bind(this)
    this.onFollowUpActionChange = this.onFollowUpActionChange.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onToChange = this.onToChange.bind(this)
    this.onToggleCcActive = this.onToggleCcActive.bind(this)
    this.onCcChange = this.onCcChange.bind(this)
    this.onBcChange = this.onBcChange.bind(this)
    this.onToggleBccActive = this.onToggleBccActive.bind(this)
    this.onSubjectChange = this.onSubjectChange.bind(this)
    this.onBodyChange = this.onBodyChange.bind(this)
    this.onTogglePreviewClick = this.onTogglePreviewClick.bind(this)
    this.onAddAttachmentClick = this.onAddAttachmentClick.bind(this)
    this.onAttachmentDrop = this.onAttachmentDrop.bind(this)
    this.onAttachmentDeleteClick = this.onAttachmentDeleteClick.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
    this.onTemplatePopoverToggle = this.onTemplatePopoverToggle.bind(this)
    this.onTemplatePopoverClose = this.onTemplatePopoverClose.bind(this)
    this.onCreateTemplateClick = this.onCreateTemplateClick.bind(this)
    this.onEditTemplateClick = this.onEditTemplateClick.bind(this)
    this.onDeleteTemplateClick = this.onDeleteTemplateClick.bind(this)
    this.onTemplateClick = this.onTemplateClick.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  async fetchForm() {
    const { email } = this.props
    const { to: toState, cc: ccState, bcc: bccState, subject: subjectState, body: bodyState, didInitialLoad } = this.state

    let to = toState || []
    let cc = ccState || []
    let bcc = bccState || []

    if (!didInitialLoad) {
      if (email?.to) {
        const { contacts } = await ContactsController.getContacts({ 'id[in]': email.to })
        to = contacts
      }
      if (email?.cc) {
        const { contacts } = await ContactsController.getContacts({ 'id[in]': email.cc })
        cc = contacts
      }
      if (email?.bcc) {
        const { contacts } = await ContactsController.getContacts({ 'id[in]': email.bcc })
        bcc = contacts
      }
    }

    try {
      const response = await EmailsController.getEmailForm(to?.length > 0 ? to[0].id : null)

      const { variables, email_integration_enabled } = response

      this.setState({
        didInitialLoad: true,
        variables: variables,
        to: to,
        cc: cc,
        ccActive: cc.length > 0,
        bcc: bcc,
        bccActive: bcc.length > 0,
        subject: subjectState || email?.subject || "",
        body: bodyState || email?.body || "",
        emailIntegrationEnabled: email_integration_enabled
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  async onFormSubmit(e?: React.FormEvent, test_email: boolean = false) {
    e?.preventDefault();

    const { onSubmit, close, t } = this.props
    const { to, cc, bcc, subject, body, attachments, task } = this.state;

    try {
      let response = null

      this.setState({ isSending: true })

      const sendEmailParams: SendEmailParams = {
        to: to?.map(contact => contact.id) || [],
        cc: cc?.map(contact => contact.id) || [],
        bcc: bcc?.map(contact => contact.id) || [],
        subject: subject,
        body: body,
        attachment_ids: attachments?.map(attachment => attachment.id) || [],
        test_email: test_email
      }

      response = await EmailsController.sendEmail(sendEmailParams)

      const { errors } = response;

      if (errors) {
        this.setState({ errors: errors });
        Notification.notifyError(t('SendEmailModal::Oops something went wrong'))
      }
      else {
        if (task && to?.length > 0) {
          const contact = to[0]

          TasksController.create({
            ...task,
            name: `${task.name} ${contact.name}`,
            contact_id: contact.id,
          }).catch(console.error)
        }

        if (onSubmit) onSubmit()
        close()
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isSending: false })
    }
  }

  onSendTestEmailClick() {
    this.onFormSubmit(null, true)
  }

  onFollowUpActionChange(task: Task) {
    this.setState({
      task: task
    })
  }

  onCloseClick() {
    this.props.close()
  }

  onTemplatePopoverToggle() {
    this.setState({
      templatePopoverActive: !this.state.templatePopoverActive
    })
  }

  onCreateTemplateClick() {
    this.setState({ templatePopoverActive: false }, () => {
      requestAnimationFrame(() => {
        this.props.showEmailTemplateModal({
          emailTemplate: {},
          onSubmit: () => {
            this.setState({ templatePopoverActive: true })
          }
        })
      })
    })
  }

  onEditTemplateClick(emailTemplate: EmailTemplate) {
    this.setState({ templatePopoverActive: false }, () => {
      requestAnimationFrame(() => {
        this.props.showEmailTemplateModal({
          emailTemplate: emailTemplate,
          onSubmit: () => {
            this.setState({ templatePopoverActive: true })
          }
        })
      })
    })
  }

  onDeleteTemplateClick(emailTemplate: EmailTemplate) {
    const { t } = this.props
    this.setState({ templatePopoverActive: false }, () => {

      requestAnimationFrame(() => {
        this.props.showConfirmModal({
          title: t('EmailTemplatesPopover::Delete template'),
          description: t('EmailTemplatesPopover::Are you sure you want to delete this template?'),
          action: {
            label: t('EmailTemplatesPopover::Delete'),
            isDestructive: true
          },
          onConfirm: async () => {
            try {
              await EmailTemplatesController.delete(emailTemplate.id)
              this.setState({ templatePopoverActive: true })
            } catch (ex) {
              console.error(ex)
            }
          }
        })
      })
    })
  }

  onTemplateClick(emailTemplate: EmailTemplate) {
    const { attachments } = this.state

    this.setState({
      subject: emailTemplate.subject,
      body: emailTemplate.body,
      attachments: [
        ...attachments,
        ...emailTemplate.attachments
      ],
      templatePopoverActive: false
    })
  }

  onTemplatePopoverClose() {
    this.setState({
      templatePopoverActive: false
    })
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onToChange(value: string[], contacts: Contact[]) {
    this.setState({ to: contacts ? contacts : [] }, this.fetchForm);
  }

  onCcChange(value: string[], contacts: Contact[]) {
    this.setState({ cc: contacts ? contacts : [] });
  }

  onBcChange(value: string[], contacts: Contact[]) {
    this.setState({ bcc: contacts ? contacts : [] });
  }

  onToggleCcActive() {
    const { ccActive } = this.state

    this.setState({ ccActive: !ccActive })
  }

  onToggleBccActive() {
    const { bccActive } = this.state

    this.setState({ bccActive: !bccActive })
  }

  onSubjectChange(e) {
    e.preventDefault();

    const newSubject = e.currentTarget.value;

    this.setState({ subject: newSubject });
  }

  onBodyChange(body: string) {
    this.setState({ body: body })
  }

  onAddAttachmentClick() {
    if (this.dropzone.current) {
      this.dropzone.current.open()
    }
  }

  onTogglePreviewClick() {
    const { preview } = this.state
    this.setState({ preview: !preview })
  }

  onAttachmentDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    const { t } = this.props
    acceptedFiles.forEach(async (file: any) => {
      try {
        ActiveStorageController.upload(file, async (error, blob) => {
          if (error) { console.error(error) }
          if (blob) {
            this.setState({
              attachments: [...this.state.attachments, blob]
            })
          }
        })
      } catch (ex) {
        console.error(ex)
      }
    })

    // Display the file names and errors
    fileRejections.forEach((fileRejection) => {
      fileRejection.errors.forEach((err) => {
        if (err.code === "file-too-large") Notification.notifyError(t('SendEmailModal::File {{filename}} is too large', { filename: fileRejection.file.name }))
        if (err.code === "file-invalid-type") Notification.notifyError(t('SendEmailModal::File {{filename}} is an invalid filetype', { filename: fileRejection.file.name }))
      });
    });
  }

  onAttachmentDeleteClick(index: number) {
    const { attachments } = this.state

    attachments.splice(index, 1)

    this.setState({
      attachments: [...attachments]
    })
  }

  isValidOption(inputValue: string) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(inputValue).toLowerCase());
  }

  render() {
    const { t, currentUser } = this.props
    const {
      didInitialLoad,
      errors,
      to,
      cc,
      ccActive,
      bcc,
      bccActive,
      subject,
      body,
      variables,
      attachments,
      emailIntegrationEnabled,
      isSending,
      templatePopoverActive,
      preview
    } = this.state

    const attachmentsSize = attachments.reduce((acc, attachment) => acc + attachment.byte_size, 0)
    const emailAttachmentLimitReached = attachmentsSize >= MAX_EMAIL_ATTACHMENTS_SIZE
    const toIds = to?.map(contact => contact.id) || []
    const ccIds = cc?.map(contact => contact.id) || []
    const bccIds = bcc?.map(contact => contact.id) || []
    const emailContacts = [...to, ...cc, ...bcc]
    const sendDisabled = !emailIntegrationEnabled || (emailContacts.some(contact => contact.emails.length === 0) || to?.length === 0 || subject === '' || body === '')
    let tooltipReason = null
    const variableOptions = EditorHelper.getVariableOptions(variables)

    if (!emailIntegrationEnabled) {
      tooltipReason = t('SendEmailModal::Please configure an email integration or SMTP server to allow sending of emails.')
    } else if (to.length === 0) {
      tooltipReason = t('SendEmailModal::Please select at least one contact.')
    } else if (emailContacts.some(contact => contact.emails.length === 0)) {
      tooltipReason = t('SendEmailModal::Please make sure all selected contacts have an email address configured.')
    }

    return (
      <SendEmailModalWindow>
        <ModalHeader
          title={t('SendEmailModal::Send email')}
          onCloseClick={this.onCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            {tooltipReason && <div style={{ marginBottom: 16 }}>
              <Alert type='warning' text={<>
                <span style={{ marginRight: 4 }}>
                  <Icon icon='exclamation-triangle' />
                </span>
                {tooltipReason}
              </>} />
            </div>}
            {!emailIntegrationEnabled && <EmailIntegrations>
              <EmailIntegration
                logo={Images.GOOGLE_EMAIL_INTEGRATION_LOGO}
                title={t('SendEmailModal::Google')}
                description={t('SendEmailModal::Gmail / GSuite')}
                onClick={() => {
                  this.props.close()
                  this.props.history.push(RouteHelper.process(ERoute.PATH_INTEGRATION, { type: IntegrationType.GMAIL }))
                }}
              />
              <EmailIntegration
                logo={Images.OUTLOOK_EMAIL_INTEGRATION_LOGO}
                title={t('SendEmailModal::Outlook')}
                description={t('SendEmailModal::Hotmail, Live, MSN')}
                onClick={() => {
                  this.props.close()
                  this.props.history.push(RouteHelper.process(ERoute.PATH_INTEGRATION, { type: IntegrationType.OUTLOOK }))
                }}
              />
              <EmailIntegration
                logo={Images.SMTP_EMAIL_INTEGRATION_LOGO}
                title={t('SendEmailModal::Other')}
                description={t('SendEmailModal::Any provider with SMTP')}
                onClick={() => {
                  this.props.close()
                  this.props.history.push(ERoute.PATH_SETTINGS_SMTP)
                }}
              />
            </EmailIntegrations>}

            {emailIntegrationEnabled && <form onSubmit={this.onFormSubmit}>
              <div key='content'>
                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SendEmailModal::Send to')} <span>*</span></label>
                      <EmailOptionsContainer>
                        <EmailOptionMainContainer>
                          <ResourceCreatablePowerSelect
                            type='contact_with_email'
                            params={{ archived: false }}
                            value={toIds}
                            onChange={this.onToChange}
                            placeholder={t('LedgerItemEditor::Select contacts...')}
                            isClearable={true}
                            isMulti
                          />
                        </EmailOptionMainContainer>
                        {!ccActive && <EmailOptionLink onClick={this.onToggleCcActive}>
                          {t('SendEmailModal::CC')}
                        </EmailOptionLink>}
                        {!bccActive && <EmailOptionLink onClick={this.onToggleBccActive}>
                          {t('SendEmailModal::BCC')}
                        </EmailOptionLink>}
                      </EmailOptionsContainer>
                    </div>
                  </div>
                </div>

                {ccActive && <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SendEmailModal::CC')}</label>

                      <ResourceCreatablePowerSelect
                        type='contact_with_email'
                        params={{ archived: false }}
                        value={ccIds}
                        onChange={this.onCcChange}
                        placeholder={t('LedgerItemEditor::Select contacts...')}
                        isClearable={true}
                        isMulti
                      />
                    </div>
                  </div>
                </div>}

                {bccActive && <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SendEmailModal::BCC')}</label>

                      <ResourceCreatablePowerSelect
                        type='contact_with_email'
                        params={{ archived: false }}
                        value={bccIds}
                        onChange={this.onBcChange}
                        placeholder={t('LedgerItemEditor::Select contacts...')}
                        isClearable={true}
                        isMulti
                      />
                    </div>
                  </div>
                </div>}

                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <div className='field-label-with-action'>
                        <label>{t('SendEmailModal::Subject')} <span>*</span></label>
                      </div>

                      <input type='text'
                        value={preview ? MustacheHelper.process(subject, variables) : subject}
                        onChange={this.onSubjectChange}
                        placeholder={t('SendEmailModal::Type a subject for your email')}
                        name='subject'
                        required
                        disabled={preview}
                      />
                    </div>
                  </div>
                </div>

                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <div className='field-label-with-action'>
                        <label>{t('SendEmailModal::Content')} <span>*</span></label>
                      </div>
                      {!preview && <EditorContainer toolbarBottom>
                        <Editor
                          key={String(variableOptions.length)}
                          model={body}
                          onModelChange={this.onBodyChange}
                          config={{
                            ...EMAIL_EDITOR_CONFIG,
                            editorClass: 'user-email',
                            toolbarBottom: true,
                            heightMin: '53vh',
                            heightMax: '53vh',
                            variableOptions: variableOptions,
                            placeholderText: t('SendEmailModal::Type your email here'),
                          }}
                        />
                      </EditorContainer>}

                      {preview && <EmailPreviewContainer style={{ minHeight: '53vh', maxHeight: '53vh' }} >
                        <FroalaEditorView model={MustacheHelper.process(body, variables)} />
                      </EmailPreviewContainer>}
                    </div>
                  </div>
                </div>

                {emailIntegrationEnabled && attachments?.length > 0 && <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('SendEmailModal::Attachments')}</label>

                      {emailAttachmentLimitReached && <Alert
                        type='warning'
                        text={t('SendEmailModal::You have reached the average maximum size limit for most email providers. The email will be sent but we can\'t guarantee it will arrive at its destination.')}
                      />}

                      {attachments && attachments.length > 0 && <EmailAttachments>
                        {attachments?.map((attachment, index) => {
                          return (
                            <EmailAttachment
                              key={index}
                              file={attachment}
                              onDeleteClick={() => this.onAttachmentDeleteClick(index)}
                            />
                          )
                        })}
                      </EmailAttachments>}
                    </div>
                  </div>
                </div>}
                <Dropzone
                  ref={this.dropzone}
                  onDrop={this.onAttachmentDrop}
                  multiple={true}
                  accept={[
                    MimeTypes.JPEG,
                    MimeTypes.JPG,
                    MimeTypes.PNG,
                    MimeTypes.PDF,
                    MimeTypes.CSV,
                    MimeTypes.EXCEL
                  ]}
                  maxSize={MAX_EMAIL_ATTACHMENT_SIZE}
                >
                  {({ getInputProps, open }) => (
                    <input {...getInputProps()} />
                  )}
                </Dropzone>
              </div>
            </form>}
          </ModalContent>
        </ModalMiddle>}
        <ModalFooter>
          <div className='modal-footer'>
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <ButtonWithActions
                  buttonProps={
                    {
                      type: 'success',
                      text: t('SendLedgerItemModal::Send'),
                      onClick: this.onFormSubmit,
                      disabled: sendDisabled,
                      tooltip: tooltipReason,
                      isLoading: isSending,
                    }
                  }
                  actions={[
                    { key: 'test-email', icon: 'email', content: t('SendLedgerItemModal::Send test email to myself'), onClick: this.onSendTestEmailClick },
                  ]}
                />
              </div>

              <ModalActionFollowUp
                onFollowUpActionChange={this.onFollowUpActionChange}
              />
            </div>
            <ModalFooterActionIcons>
              <ModalFooterActionIcon
                icon='eye'
                onClick={this.onTogglePreviewClick}
                tooltip={t('SendEmailModal::Toggle preview')}
                active={preview}
              />

              <EmailTemplatesPopover
                active={templatePopoverActive}
                activator={
                  <ModalFooterActionIcon
                    icon='file-code'
                    onClick={this.onTemplatePopoverToggle}
                    disabled={!emailIntegrationEnabled}
                    tooltip={t('SendEmailModal::Email templates')}
                    disabledTooltip={t('SendEmailModal::Configure an email integration or SMTP server to enable attachments')}
                  />
                }
                onCreateTemplateClick={this.onCreateTemplateClick}
                onEditTemplateClick={this.onEditTemplateClick}
                onDeleteTemplateClick={this.onDeleteTemplateClick}
                onTemplateClick={this.onTemplateClick}
                onClose={this.onTemplatePopoverClose}
              />

              <ModalFooterActionIcon
                icon='attachment'
                disabled={!emailIntegrationEnabled}
                onClick={this.onAddAttachmentClick}
                tooltip={t('SendEmailModal::Add attachments')}
                disabledTooltip={t('SendEmailModal::Configure an email integration or SMTP server to enable attachments')}
              />
            </ModalFooterActionIcons>
          </div>
        </ModalFooter>
      </SendEmailModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      sendEmailModal: {
        email,
        onSubmit,
      }
    }
  } = state

  return {
    email: email,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    close: () => dispatch(closeSendEmailModal()),
    showContactModal: (options) => dispatch(showContactModal(options)),
    showEmailTemplateModal: (options) => dispatch(showEmailTemplateModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SendEmailModal)))