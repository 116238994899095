import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

export default class BalanceList extends React.PureComponent {
	render() {
		return (
			<Container>
				{this.props.children}
			</Container>
		)
	}
}