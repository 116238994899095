import { TimeEntry } from '../../types'
import { TimerActionTypes, TIMER_START, TIMER_PAUSE, TIMER_STOP, TIMER_RESET } from './types'

export function startTimer(entry: TimeEntry): TimerActionTypes {
	return {
		type: TIMER_START,
		entry: entry
	}
}

export function pauseTimer(entry: TimeEntry): TimerActionTypes {
	return {
		type: TIMER_PAUSE,
		entry: entry
	}
}

export function stopTimer(): TimerActionTypes {
	return {
		type: TIMER_STOP
	}
}

export function resetTimer(): TimerActionTypes {
	return {
		type: TIMER_RESET
	}
}