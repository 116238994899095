import * as React from 'react'

export interface ISelectOption {
	key?: string
	label: string
	value: string | number
}

interface IProps {
	name: string
	value: string | number
	options: ISelectOption[]
	onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
	allowEmpty?: boolean
	required?: boolean
	disabled?: boolean
}

export default class Select extends React.PureComponent<IProps> {
	static defaultProps = {
		value: '',
		required: false,
		allowEmpty: false,
		disabled: false
	}
	render() {
		const { options, value, allowEmpty, onChange, required, disabled } = this.props

		return (
			<div className={`select-wrapper ${disabled ? 'is-disabled' : ''}`} >
				<select value={value || ''} onChange={onChange} required={required} disabled={disabled}>
					{allowEmpty && <option key="-" value=''>-</option>}
					{options.map(option => {
						return (
							<option key={option.key || option.value} value={option.value}>{option.label}</option>
						);
					})}
				</select>
			</div>
		)
	}
}
