import styled from 'styled-components'
import { Style } from '../../styles'

const EmailPreviewContainer = styled.div`
  border: 1px solid #cdd2d4;
  border-radius: ${Style.variables.baseBorderRadius};
  padding: 6px 7px;
  background: #edeff0;
`

export default EmailPreviewContainer