import * as React from 'react'
import { closeContactModal, closeBoardModal, showContactModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { BoardsController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { showProjectModal } from '../../store/modals/actions'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import SwatchPicker from '../Swatch/SwatchPicker'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import { Board, BoardTemplate, CurrentUser, CustomField, ProjectStatus } from '../../types'

interface IStateToProps {
	currentUser: CurrentUser
	board?: Board
	contactDisabled?: boolean,
	projectDisabled?: boolean,
	onSubmit?: (board: Board) => void
}

interface IDispatchToProps {
	close: typeof closeContactModal
	showContactModal: typeof showContactModal
	showProjectModal: typeof showProjectModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

export enum BoardModalTab {
	DETAILS = 'details',
}

interface IState {
	didInitialLoad: boolean
	activeTab: BoardModalTab,
	board: Board | null
	// boardTemplates: BoardTemplate[]
	customFields: CustomField[]
	errors: any
	selectedTemplateId: string
}

class BoardModal extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			didInitialLoad: false,
			activeTab: BoardModalTab.DETAILS,
			board: null,
			customFields: [],
			// boardTemplates: [],
			errors: {},
			selectedTemplateId: null,
		}

		this.fetchForm = this.fetchForm.bind(this)
		this.onNavigationItemClick = this.onNavigationItemClick.bind(this);
		this.onBoardModalCloseClick = this.onBoardModalCloseClick.bind(this)
		this.onErrorsDismiss = this.onErrorsDismiss.bind(this)

		this.onNameChange = this.onNameChange.bind(this)
		this.onColorChange = this.onColorChange.bind(this)
		this.onTemplateChange = this.onTemplateChange.bind(this)
		this.onContactChange = this.onContactChange.bind(this);
		this.onProjectChange = this.onProjectChange.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this)
	}

	componentDidMount() {
		this.fetchForm()
	}

	fetchForm() {
		const { board } = this.props

		BoardsController
			.getForm(board.id)
			.then(response => {
				const { board: responseBoard, contacts, custom_fields, board_templates } = response;

				requestAnimationFrame(() => {
					this.setState({
						didInitialLoad: true,
						board: {
							...responseBoard,
							...board
						},
						customFields: custom_fields,
						// boardTemplates: board_templates
					});
				})
			})
			.catch(console.error)
	}

	onFormSubmit(e) {
		e.preventDefault();
		const { board, selectedTemplateId } = this.state;
		const { close, onSubmit, t } = this.props

		if (board.id) {
			BoardsController
				.update(board)
				.then(response => {
					const { errors, error_full_messages } = response;

					if (errors) {
						this.setState({
							errors: errors
						});
						Notification.notifyError(t('BoardModal::Oops something went wrong'))
					}
					else {
						Notification.notifySuccess(t('BoardModal::Board successfully updated'))
						if (onSubmit) onSubmit(response)
						close()
					}
				})
				.catch(error => console.error(error))
		}
		else {
			BoardsController
				.create(board, selectedTemplateId)
				.then(response => {
					const { errors } = response;

					if (errors) {
						this.setState({
							errors: errors
						});
						Notification.notifyError(t('BoardModal::Oops something went wrong'))
					}
					else {
						Notification.notifySuccess(t('BoardModal::Board successfully created'))
						if (onSubmit) onSubmit(response)
						close()
					}
				})
				.catch(console.error)
		}
	}

	onNavigationItemClick(e) {
		const activeTab = e.currentTarget.getAttribute('data-tab');

		this.setState({
			activeTab: activeTab
		});
	}

	onBoardModalCloseClick() {
		this.props.close()
	}

	onErrorsDismiss() {
		this.setState({
			errors: {}
		})
	}

	onNameChange(e) {
		const { board } = this.state

		this.setState({
			board: {
				...board,
				name: e.currentTarget.value
			}
		})
	}

	onColorChange(color: string) {
		const { board } = this.state

		this.setState({
			board: {
				...board,
				color: color,
			}
		})
	}

	onTemplateChange(option) {
		this.setState({
			selectedTemplateId: option ? option.value : null
		})
	}

	onContactChange(value?: string) {
		const { board } = this.state;

		this.setState({
			board: {
				...board,
				contact_id: value,
				project_id: null,
			}
		})
	}

	onProjectChange(value?: string) {
		const { board } = this.state;

		this.setState({
			board: {
				...board,
				project_id: value
			}
		});
	}

	renderNavigation(): JSX.Element {
		const { t } = this.props
		const { activeTab, customFields } = this.state

		return (
			<ModalNavigation>
				<ModalNavigationItem active={activeTab === BoardModalTab.DETAILS} data-tab={BoardModalTab.DETAILS} onClick={this.onNavigationItemClick}>
					<Icon icon='info' />
					<span>{t('BoardModal::Details')}</span>
				</ModalNavigationItem>
			</ModalNavigation>
		)
	}

	renderDetails() {
		const { contactDisabled, projectDisabled, t } = this.props;
		const { activeTab, board, selectedTemplateId: selectedTemplate } = this.state;

		if (activeTab !== 'details') return null;

		const projectSelectDisabled = !Boolean(board.contact_id) || projectDisabled

		// const templateOptions = boardTemplates.map(template => ({ label: template.name, value: template.id }))
		// const selectedTemplateOption = templateOptions && templateOptions.find(templateOption => templateOption.value === selectedTemplate)

		return (
			<div data-tab={BoardModalTab.DETAILS}>
				<div className='grid'>
					<div className='grid-cell with-6col'>
						<div className='form-item'>
							<label>
								{t('BoardModal::Name')}<span>*</span>
							</label>
							<input
								type='text'
								name='name'
								onChange={this.onNameChange}
								value={board.name}
								placeholder={t('BoardModal::Name')}
								required
							/>
						</div>
					</div>

					<div className='grid-cell with-1col'>
						<div className='form-item'>
							<label>{t('BoardModal::Color')}</label>
							<SwatchPicker
								color={board.color}
								onChange={this.onColorChange}
							/>
						</div>
					</div>
				</div>

				<div className='grid'>
					<div className='grid-cell with-6col'>
						<div className='form-item'>
							<label>
								{t('BoardModal::Contact')}
							</label>

							<ResourceCreatablePowerSelect
								type='contact'
								params={{ archived: false }}
								value={board.contact_id}
								onChange={this.onContactChange}
								isDisabled={contactDisabled}
								isClearable={true}
							/>
						</div>
					</div>

					<div className='grid-cell with-6col'>
						<div className='form-item'>
							<label>{t('BoardModal::Project')}</label>

							<ResourceCreatablePowerSelect
								type='project'
								value={board.project_id}
								onChange={this.onProjectChange}
								isDisabled={projectSelectDisabled}
								isClearable={true}
								params={{ 'contact_id': board.contact_id, 'status[in]': [ProjectStatus.PROPOSAL, ProjectStatus.ACTIVE] }}
								createParams={{ contact_id: board.contact_id }}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { board: propsBoard, t, currentUser: { workspace: { setting } } } = this.props
		const { board: stateBoard, didInitialLoad, errors } = this.state

		let title = t('BoardModal::Board')

		if (propsBoard) {
			title = propsBoard.id ? t('BoardModal::Edit board') : t('BoardModal::Create board')
		}

		return (
			<ModalWindow>
				<ModalHeader
					title={title}
					onCloseClick={this.onBoardModalCloseClick}
					navigation={this.renderNavigation()}
				/>

				{!didInitialLoad && <ModalLoader />}
				{didInitialLoad && <ModalMiddle>
					<ModalContent>
						<form onSubmit={this.onFormSubmit}>
							{this.renderDetails()}

							<input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
						</form>
					</ModalContent>

					<div className='modal-footer'>
						<div />
						<div className='modal-footer-actions'>
							<div key='main-action' className='popover-wrapper'>
								<TooltipError
									errors={errors}
									onDismiss={this.onErrorsDismiss}
								/>
								<a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
									{t('BoardModal::Save')}
								</a>
							</div>
						</div>
					</div>
				</ModalMiddle>}
			</ModalWindow>
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser
		},
		modals: {
			boardModal: {
				board,
				contactDisabled,
				projectDisabled,
				onSubmit,
			}
		}
	} = state

	return {
		currentUser: currentUser,
		board: board,
		contactDisabled: contactDisabled,
		projectDisabled: projectDisabled,
		onSubmit: onSubmit,
	}
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
	return {
		close: () => dispatch(closeBoardModal()),
		showContactModal: (options) => dispatch(showContactModal(options)),
		showProjectModal: (options) => dispatch(showProjectModal(options)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BoardModal))