import * as React from 'react'
import { closeItemsBlockSettingModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import PowerSelect from '../Form/PowerSelect'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import ModalLoader from './Parts/ModalLoader'
import Switch from '../Form/Switch'
import { useDebouncedCallback } from 'use-debounce'
import { ItemsBlock, ItemsSelectionRequirementType, ItemsSelectionType, ItemsView } from '../../types'

interface IStateToProps {
  itemsBlock: ItemsBlock
  onSubmit: (itemsBlock: ItemsBlock) => void
}

interface IDispatchToProps {
  close: typeof closeItemsBlockSettingModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

const ItemsBlockSettingModal = (props: IProps) => {
  const { t } = useTranslation()
  const [itemsBlock, setItemsBlock] = React.useState<ItemsBlock>(props.itemsBlock)
  const [didInitialLoad, setDidInitialLoad] = React.useState(false)
  const debouncedContactEditableChange = useDebouncedCallback(
    () => setItemsBlock({ ...itemsBlock, contact_selection_enabled: !itemsBlock.contact_selection_enabled }), 50
  );

  React.useEffect(() => {
    setTimeout(() => setDidInitialLoad(true), 500)
  }, [])

  const onCloseClick = () => {
    props.close()
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    props.onSubmit(itemsBlock)
    props.close()
  }

  const onViewChange = (option) => {
    setItemsBlock({ ...itemsBlock, view: option.value })
  }

  const onSelectionTypeChange = (option) => {
    setItemsBlock({ ...itemsBlock, selection_type: option.value })
  }

  const onSelectionRequirementChange = (option) => {
    setItemsBlock({ ...itemsBlock, selection_requirement_type: option.value })
  }

  const viewOptions = [
    { label: t('ItemsBlockSettingModal::Table view'), value: ItemsView.TABLE },
    { label: t('ItemsBlockSettingModal::Card view'), value: ItemsView.CARD },
  ]
  const selectedViewOption = viewOptions.find(option => option.value === itemsBlock.view)

  const selectionTypeOptions = [
    { label: t('ItemsBlockSettingModal::Single selection only'), value: ItemsSelectionType.SINGLE },
    { label: t('ItemsBlockSettingModal::Multiple selection allowed'), value: ItemsSelectionType.MULTI },
  ]
  const selectedSelectionTypeOption = selectionTypeOptions.find(option => option.value === itemsBlock.selection_type)
  const selectionRequirementOptions = [
    { label: t('ItemsBlockSettingModal::Selecting items is required'), value: ItemsSelectionRequirementType.REQUIRED },
    { label: t('ItemsBlockSettingModal::Selecting items is optional'), value: ItemsSelectionRequirementType.OPTIONAL },
  ]
  const selectedSelectionRequirementOption = selectionRequirementOptions.find(option => option.value === itemsBlock.selection_requirement_type)

  return (
    <ModalWindow>
      <ModalHeader
        title={t('ItemsBlockSettingModal::Item settings')}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            <div className='form-item'>
              <label>{t('ItemsBlockSettingModal::View mode')}</label>
              <PowerSelect
                options={viewOptions}
                value={selectedViewOption}
                onChange={onViewChange}
                theme={ReactSelectTheme}
              />
            </div>
            <div className='form-item'>
              <Switch
                name='contact_selection_enabled'
                label={t('ItemsBlockSettingModal::Allow contacts to select or unselect items')}
                onClick={debouncedContactEditableChange}
                checked={itemsBlock.contact_selection_enabled}
              />
            </div>
            {itemsBlock.contact_selection_enabled && <>
              <div className='form-item'>
                <label>{t('ItemsBlockSettingModal::Selection type')}</label>
                <PowerSelect
                  options={selectionTypeOptions}
                  value={selectedSelectionTypeOption}
                  onChange={onSelectionTypeChange}
                  theme={ReactSelectTheme}
                />
              </div>

              <div className='form-item'>
                <label>{t('ItemsBlockSettingModal::Selection requirement')}</label>
                <PowerSelect
                  options={selectionRequirementOptions}
                  value={selectedSelectionRequirementOption}
                  onChange={onSelectionRequirementChange}
                  theme={ReactSelectTheme}
                />
              </div>
            </>}
            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <div />
          <div className='modal-footer-actions'>
            <div key='main-action' className='popover-wrapper'>
              <a href='javascript://' className='button button-success' onClick={onFormSubmit}>
                {t('ItemsBlockSettingModal::Save')}
              </a>
            </div>
          </div>
        </div>
      </ModalMiddle>}
    </ModalWindow>
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    modals: {
      itemsBlockSettingModal: {
        itemsBlock,
        onSubmit,
      }
    }
  } = state

  return {
    itemsBlock: itemsBlock,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeItemsBlockSettingModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ItemsBlockSettingModal))