import * as React from 'react'
import styled from "styled-components"
import { Style } from '../../styles'

const SidebarSectionItem = styled.a`
	display: flex;
	flex-direction: row;
	border-radius: 3px;
	display: block;
	min-height: 38px;
	line-height: 30px;
	overflow: hidden;
	position: relative;
	text-overflow: ellipsis;
	text-decoration: none;
	white-space: nowrap;
	padding: ${Style.spacing.x0_5};
	align-items: center;
	border-radius: 4px;
	cursor: pointer;
	
	&:hover {
		background-color: rgba(9,30,66,.04);
	}
`

export default SidebarSectionItem