import styled from "styled-components";
import { Style } from "../../../../styles";

const OptionInputContainer = styled.div`
	display: flex;
	flex-direction: row;

	> :last-child {
		margin-left: ${Style.spacing.x1};
	}

	&:not(:last-child) {
		margin-bottom: ${Style.spacing.x1};
	}
`

export default OptionInputContainer