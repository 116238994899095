import * as React from 'react'
import styled from 'styled-components'
import Icon, { IconTypes } from '../Icons/Icon'
import { Style } from '../../styles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${Style.color.border};
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #F7F7F7;
  border-radius: 50px;
  border: 1px solid #E3E3E3;
  margin-right: ${Style.spacing.x1};

  svg {
    width: 20px;
    height: 20px;
  }

  i {
    font-size: 20px;
  }
`

const Action = styled.div`
  border: 1px solid #E3E3E3;
  background: #F7F7F7;
  color: black;
  display: flex;
  width: max-content;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
  border-radius: 100px;
  border-width: 1px;
  border-style: solid;
  padding: 5px 15px;
  margin-bottom: 5px;
  text-decoration: none;
  position: relative;
  z-index: 2;
  cursor: pointer;
`

interface IProps {
  icon: IconTypes
  title: string
  action?: {
    label: string,
    onClick: () => void
  }
}

const FlyoutHeader = (props: IProps) => {
  const {
    icon,
    title,
    action
  } = props

  return (
    <Container>
      <Title>
        <IconContainer>
          <Icon icon={icon} />
        </IconContainer>
        {title}
      </Title>

      {action && <Action onClick={action.onClick}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="16" width="16"><path d="M4.75 14.75v4.5h4.5M19.25 9.25v-4.5h-4.5M5 19l5.25-5.25M19 5l-5.25 5.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
        {action.label}
      </Action>}
    </Container>
  )
}

export default FlyoutHeader