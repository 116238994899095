import styled from 'styled-components'
import { Style } from '../../../styles'
import ModalWindow from './ModalWindow'

const ModalWindowWithPreview = styled(ModalWindow)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 1145px;
  min-height: 500px;
  max-height: 700px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    min-width: initial;
    max-height: initial;
    min-height: initial;
    max-width: initial;
  }
`

export default ModalWindowWithPreview