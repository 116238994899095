import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import { TaskChecklistItem } from '../../types'
import Button from '../Button/Button'
import AutogrowTextarea from '../Form/AutogrowTextArea'
import Icon from '../Icons/Icon'
import TaskButton from './TaskButton'
import TaskCancelAction from './TaskCancelAction'

const Container = styled.div<{ editing: boolean }>`
	width: 100%;

	input {
		width: 100%;
		margin-bottom: ${Style.spacing.x1};
	}

	form {
		margin: 0 ${Style.spacing.x1};
		width: 100%;
	}
`

const FormActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

interface IProps {
	onSubmit: (item: TaskChecklistItem) => void
}

const AddTaskChecklistItem = (props: IProps) => {
	const { t } = useTranslation()
	const container = React.useRef<HTMLDivElement>(null)
	const textarea = React.useRef<AutogrowTextarea>(null)
	const [editing, setEditing] = React.useState(false)
	const [name, setName] = React.useState('')

	React.useEffect(() => {
		document.addEventListener('mousedown', onOutsideContainerClick)

		return () => {
			document.removeEventListener('mousedown', onOutsideContainerClick)
		}
	}, [])

	const reset = () => {
		setName('')
		setEditing(false)
	}

	const onOutsideContainerClick = (e) => {
		if (container.current && !container.current.contains(e.target)) {
			// If name has been entered submit
			if (name.length > 0) props.onSubmit({ name: name })

			reset()
		}
	}

	const onActionClick = () => {
		setEditing(true)
	}

	const onNameChange = (e) => {
		setName(e.currentTarget.value)
	}

	const onTextAreaKeyPress = (e) => {
		if (e.which === 13 && !e.shiftKey) {
			e.preventDefault();

			onFormSubmit(e)
		}
	}

	const onFormSubmit = (e) => {
		e.preventDefault()

		if (name !== '') {
			props.onSubmit({ name: name })

			// Only reset name because user may want to create another one
			setName('')

			if (textarea && textarea.current) {
				textarea.current.focus()
			}
		}
	}


	const onCancelClick = () => {
		reset()
	}

	return (
		<Container ref={container} editing={editing}>
			{editing === false && <TaskButton style={{ width: 'fit-content' }} onClick={onActionClick}>
				<Icon icon='plus' />
				{t('AddTaskChecklistItem::Add item')}
			</TaskButton>}

			{editing === true && <form onSubmit={onFormSubmit}>
				<AutogrowTextarea
					ref={textarea}
					minRows={2}
					maxRows={2}
					placeholder={t('AddTaskChecklistItem::Add an item')}
					value={name}
					onChange={onNameChange}
					onKeyPress={onTextAreaKeyPress}
					autoFocus
				/>

				<FormActions>
					<Button
						type='success'
						text={t('AddTaskChecklistItem::Add')}
						onClick={onFormSubmit}
					/>

					<TaskCancelAction onClick={onCancelClick} />
				</FormActions>
			</form>}
		</Container>
	)
}

export default AddTaskChecklistItem