import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverClose from './TaskPopoverClose'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'

interface IProps {
  activator: JSX.Element
  onSubmit: (name: string) => void
}

const ChecklistPopover = (props: IProps) => {
  const { t } = useTranslation()
  const { activator } = props


  const input = React.useRef<HTMLInputElement>(null)
  const [popoverActive, setPopoverActive] = React.useState(false)
  const [name, setName] = React.useState('')

  const onTogglePopover = () => {
    if (!popoverActive) {
      // set default
      setName('Checklist')

      setTimeout(() => {
        if (input && input.current) {
          input.current.select()
        }
      }, 50)
    } else {
      // Reset form on hide
      resetForm()
    }
    setPopoverActive(!popoverActive)
  }

  const onPopoverClose = () => {
    setPopoverActive(false)
  }

  const onNameChange = (e) => {
    const name = e.currentTarget.value

    setName(name)
  }

  const onFormSubmit = (e) => {
    e.preventDefault()

    if (name.length > 0) {
      // Submit form to parent
      props.onSubmit(name)

      // Reset form
      resetForm()

      // Close popover
      setPopoverActive(false)
    }
  }

  const resetForm = () => {
    setName('')
  }

  return (
    <Popover
      active={popoverActive}
      activator={<div onClick={onTogglePopover}>
        {activator}
      </div>}
      onClose={onPopoverClose}
      placement='bottom-start'
    >
      <TaskPopoverContainer>
        <TaskPopoverHeader>
          <div />
          <TaskPopoverTitle>{t('TaskModal::Add checklist')}</TaskPopoverTitle>
          <TaskPopoverClose onClick={onPopoverClose} />
        </TaskPopoverHeader>
        <TaskPopoverContent>
          <form onSubmit={onFormSubmit}>
            {popoverActive && <>
              <div className='form-item'>
                <label>{t('TaskModal::Name')}</label>
                <input
                  ref={input}
                  type='text'
                  name='name'
                  placeholder={t('TaskModal::Name')}
                  value={name}
                  onChange={onNameChange}
                />
              </div>
            </>}
            <TaskButton
              onClick={onFormSubmit}
              style={{ marginTop: 12 }}
              success
              center
            >
              {t('TaskModal::Add checklist')}
            </TaskButton>
          </form>
        </TaskPopoverContent>
      </TaskPopoverContainer>
    </Popover >
  )
}

export default ChecklistPopover