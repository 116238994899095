import * as React from 'react'
import { useActionCable } from '../providers/ActionCableProvider'

interface IProps {
	channel: string | ActionCable.ChannelNameWithParams
	children: React.ReactNode
	onInitialized?(): void
	onConnected?(): void
	onDisconnected?: () => void
	onRejected?: () => void
	onReceived?: (obj: any) => void
	subscriptionHandler?: (subscription: ActionCable.Channel) => void
}

const ActionCableConsumer = (props: IProps) => {
	const {
		channel,
		onInitialized,
		onConnected,
		onDisconnected,
		onRejected,
		onReceived,
		subscriptionHandler,
		children
	} = props
	const { cable } = useActionCable()

	const channelId = typeof channel === 'string' ? channel : channel.channel

	React.useEffect(() => {
		const subscription = cable.subscriptions.create(channel, {
			initialized: onInitialized,
			connected: onConnected,
			disconnected: onDisconnected,
			rejected: onRejected,
			received: onReceived,
		})

		if (subscriptionHandler) subscriptionHandler(subscription)

		return () => {
			subscription.unsubscribe()

			if (subscriptionHandler) subscriptionHandler(null)
		}
	}, [channelId])

	return (<>{children}</>)
}

export default ActionCableConsumer