import * as React from 'react'
import { closeDirectionsModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import styled from 'styled-components'
import { GoogleMap, useJsApiLoader, Autocomplete, DirectionsRenderer } from '@react-google-maps/api'
import { GOOGLE_MAPS_KEY } from '../../Constants'
import Loader from '../Loaders/Loader'
import Button from '../Button/Button'
import SummaryItem from '../Summary/SummaryItem'
import { DirectionsController } from '../../controllers'
import { DirectionsModalResult } from '../../store/modals/types'
import LocationHelper from '../../helpers/LocationHelper'
import ModalFooterActionIcons from './Parts/ModalFooterActionIcons'
import ModalFooterActionIcon from './Parts/ModalFooterAction'

const MapsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 350px;
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  width: calc(100% + 40px);
`

const MapsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const DEFAULT_CENTER = { lat: 51.2092, lng: 3.2248 }

interface IStateToProps {
  origin?: string
  destination?: string
  onSubmit: (result: DirectionsModalResult) => void
}

interface IDispatchToProps {
  close: typeof closeDirectionsModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  map: google.maps.Map | null
  center: google.maps.LatLngLiteral
  directionsResponse: google.maps.DirectionsResult
  origin: string
  destination: string
  multiplier: boolean
}

const DirectionsModal = (props: IProps) => {
  const originRef = React.useRef<HTMLInputElement>(null)
  const destinationRef = React.useRef<HTMLInputElement>(null)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    libraries: ['places']
  })
  const { t } = useTranslation()
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    map: null,
    center: DEFAULT_CENTER,
    directionsResponse: null,
    origin: props.origin ? props.origin : '',
    destination: props.destination ? props.destination : '',
    multiplier: true,
  })
  const {
    didInitialLoad,
    map,
    center,
    directionsResponse,
    origin,
    destination,
    multiplier,
  } = state

  let route: google.maps.DirectionsLeg | null = null
  let distanceValue = 0

  if (directionsResponse && directionsResponse.routes[0] && directionsResponse.routes[0].legs[0]) {
    route = directionsResponse.routes[0].legs[0]
    distanceValue = (route.distance.value / 1000 * (multiplier ? 2 : 1))
  }

  React.useEffect(() => {
    setTimeout(() => setState({ ...state, didInitialLoad: true }), 500)
    getForm().catch(console.error)
  }, [])

  const getForm = async () => {
    try {
      const form = await DirectionsController.getForm()
    } catch (ex) {
      console.error(ex)
    }
  }

  const onCloseClick = () => {
    props.close()
  }

  const onOriginPlaceChanged = () => {
    setState({
      ...state,
      origin: originRef.current.value,
      directionsResponse: null,
    })
  }

  const onOriginChange = (e) => {
    setState({
      ...state,
      origin: e.currentTarget.value,
      directionsResponse: null,
    })
  }

  const onDestinationPlaceChanged = () => {
    setState({
      ...state,
      destination: destinationRef.current.value,
      directionsResponse: null,
    })
  }

  const onDestinationChange = (e) => {
    setState({
      ...state,
      destination: e.currentTarget.value,
      directionsResponse: null,
    })
  }

  const onCalculateRouteClick = async () => {
    if (originRef.current.value === '' || destinationRef.current.value === '') return

    try {
      const directionsService = new google.maps.DirectionsService()
      const directionsResponse = await directionsService.route({
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING
      })

      setState({
        ...state,
        directionsResponse: directionsResponse
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onToggleMultiplierClick = () => {
    setState({
      ...state,
      multiplier: !multiplier
    })
  }

  const onGetLocationClick = async () => {
    try {
      const result = await LocationHelper.getLocation()

      if (result) {
        const geocoder = new google.maps.Geocoder();
        const response = await geocoder.geocode({ location: { lat: result.latitude, lng: result.longitude } })

        if (response.results && response.results[0]) {
          setState({
            ...state,
            center: { lat: result.latitude, lng: result.longitude },
            origin: response.results[0].formatted_address,
            directionsResponse: null,
          })
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const onMapLoad = (map) => {
    setState({ ...state, map: map })
    if (origin && destination) onCalculateRouteClick()
  }

  const onFormSubmit = (e) => {
    e.preventDefault()

    if (route) {
      props.onSubmit({ distance: distanceValue })
      props.close()
    }
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={t('DirectionsModal::Calculate distance')}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && isLoaded && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            <div className='grid'>
              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>{t('DirectionsModal::Starting point')}</label>
                  <Autocomplete
                    onPlaceChanged={onOriginPlaceChanged}
                  >
                    <input
                      ref={originRef}
                      type='text'
                      placeholder={t('DirectionsModal::Starting point')}
                      value={origin}
                      onChange={onOriginChange}
                    />
                  </Autocomplete>
                </div>
              </div>
              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>{t('DirectionsModal::Destination')}</label>
                  <Autocomplete
                    onPlaceChanged={onDestinationPlaceChanged}
                  >
                    <input
                      ref={destinationRef}
                      type='text'
                      placeholder={t('DirectionsModal::Destination')}
                      value={destination}
                      onChange={onDestinationChange}
                    />
                  </Autocomplete>
                </div>
              </div>
              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <SummaryItem
                    title={t('ImportTimeEntriesModal::Distance')}
                    label={route ? route.distance.text : '-'}
                  />
                </div>
              </div>
              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <SummaryItem
                    title={t('ImportTimeEntriesModal::Duration')}
                    label={route ? route.duration.text : '-'}
                  />
                </div>
              </div>
              {Boolean(origin) && Boolean(destination) && !directionsResponse && <div className='grid-cell with-12col'>
                <div className='form-item' style={{ textAlign: 'center' }}>
                  <Button
                    type='default'
                    text='Calculate'
                    onClick={onCalculateRouteClick}
                  />
                </div>
              </div>}
            </div>

            <MapsContainer>
              {!isLoaded && <Loader />}
              {isLoaded && <MapsWrapper>
                <GoogleMap
                  center={center}
                  zoom={10}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  onLoad={onMapLoad}
                  options={{
                    controlSize: 26,
                    mapTypeControl: false,
                    streetViewControl: false,
                  }}
                >
                  {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                </GoogleMap>
              </MapsWrapper>}
            </MapsContainer>

            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <ModalFooterActionIcons>
            <ModalFooterActionIcon
              icon='track'
              onClick={onGetLocationClick}
              tooltip={t('DirectionsModal::Use your location')}
            />
          </ModalFooterActionIcons>
          {route && <div className='modal-footer-actions'>
            <ModalFooterActionIcon
              icon='arrow-right-arrow-left'
              active={multiplier}
              onClick={onToggleMultiplierClick}
              tooltip={t('DirectionsModal::Back and forth')}
            />
            <a href='javascript://' className='button button-success' onClick={onFormSubmit}>
              {t('DirectionsModal::Confirm')}
            </a>
          </div>}
        </div>
      </ModalMiddle>}
    </ModalWindow >
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    modals: {
      directionsModal: {
        origin,
        destination,
        onSubmit,
      }
    }
  } = state

  return {
    origin: origin,
    destination: destination,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeDirectionsModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DirectionsModal))