import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import { TaskChecklistItem } from '../../types'
import Button from '../Button/Button'
import Checkbox from '../Checkbox/Checkbox'
import AutogrowTextarea from '../Form/AutogrowTextArea'
import Icon from '../Icons/Icon'
import ResourceCheckbox from '../Resource/ResourceCheckbox'
import TaskCancelAction from './TaskCancelAction'

const Container = styled.div``

const ItemViewActions = styled.div`
	display: none;
	flex-direction: row;
	align-items: center;
	margin: 0 ${Style.spacing.x0_5};
`

const ItemViewTimerAction = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 1;
	padding: 6px;

	i {
		color: ${Style.color.brandSuccess};
		font-size: 12px;
	}
`

const ItemViewDeleteAction = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 1;
	padding: 6px;

	i {
		color: #82828c;
		font-size: 12px;
	}
`

const Wrapper = styled.div<{ editing: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	min-height: 36px;
	padding: ${Style.spacing.x1} ${Style.spacing.x1};
	border-radius: 4px;

	&:hover {
		background-color: rgba(9,30,66,.04);

		${ItemViewActions} {
			display: flex;
		}
	}

	input {
		width: 100%;
		margin-bottom: ${Style.spacing.x1};
	}

	form {
		margin: 0 ${Style.spacing.x1};
		width: 100%;
	}
`

const CheckboxContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3px;
	margin-right: 8px;
	cursor: pointer;
`

const ItemViewContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	cursor: pointer;
`

const FormActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

interface IProps {
	index: number
	item: TaskChecklistItem
	onSubmit: (item: TaskChecklistItem) => void
	onStartTimerClick: (item: TaskChecklistItem) => void
	onDeleteClick: (item: TaskChecklistItem) => void
	timerEnabled: boolean
}

const ChecklistItem = (props: IProps) => {
	const { t } = useTranslation()
	const { index, item, timerEnabled } = props
	const container = React.useRef<HTMLDivElement>(null)
	const textarea = React.useRef<AutogrowTextarea>(null)
	const [editing, setEditing] = React.useState(false)
	const [name, setName] = React.useState<string>('')

	React.useEffect(() => {
		document.addEventListener('mousedown', onOutsideContainerClick)

		return () => {
			document.removeEventListener('mousedown', onOutsideContainerClick)
		}
	}, [])

	const reset = () => {
		setName(null)
		setEditing(false)
	}

	const onOutsideContainerClick = (e) => {
		if (container.current && !container.current.contains(e.target)) {
			reset()
		}
	}

	const onEditClick = () => {
		// Set item name
		setName(item.name)

		// Set Edit mode
		setEditing(true)

		// Select current value
		setTimeout(() => {
			if (textarea && textarea.current) {
				textarea.current.select()
			}
		}, 50)
	}

	const onCompletedChange = (checked: boolean) => {
		props.onSubmit({ ...item, completed: checked })
	}

	const onNameChange = (e) => {
		setName(e.currentTarget.value)
	}

	const onTextAreaKeyPress = (e) => {
		if (e.which === 13 && !e.shiftKey) {
			e.preventDefault();

			onFormSubmit(e)
		}
	}

	const onFormSubmit = (e) => {
		e.preventDefault()

		if (name !== '') {
			props.onSubmit({ ...item, name: name })

			reset()
		}
	}


	const onCancelClick = () => {
		reset()
	}

	const onStartTimerClick = (e) => {
		e.preventDefault()
		e.stopPropagation()

		props.onStartTimerClick(item)
	}

	const onDeleteClick = (e) => {
		e.preventDefault()
		e.stopPropagation()

		props.onDeleteClick(item)
	}

	return (
		<Draggable key={String(index)} draggableId={String(index)} index={index}>
			{(provided) => {
				return (
					<Container
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<Wrapper
							ref={container}
							editing={editing}
						>
							<CheckboxContainer>
								<ResourceCheckbox
									checked={item.completed}
									onCheckedChange={onCompletedChange}
								/>
							</CheckboxContainer>
							{editing === false && <ItemViewContainer onClick={onEditClick}>
								{item.name}

								<ItemViewActions>
									{!timerEnabled && <ItemViewTimerAction onClick={onStartTimerClick}>
										<Icon icon='start' />
									</ItemViewTimerAction>}

									<ItemViewDeleteAction onClick={onDeleteClick}>
										<Icon icon='trash' />
									</ItemViewDeleteAction>
								</ItemViewActions>
							</ItemViewContainer>}

							{editing === true && <form onSubmit={onFormSubmit}>
								<AutogrowTextarea
									ref={textarea}
									minRows={2}
									maxRows={2}
									value={name}
									placeholder={t('ChecklistItem::Description')}
									onChange={onNameChange}
									onKeyPress={onTextAreaKeyPress}
									autoFocus
								/>

								<FormActions>
									<Button
										type='success'
										text={t('ChecklistItem::Save')}
										onClick={onFormSubmit}
									/>

									<TaskCancelAction onClick={onCancelClick} />
								</FormActions>
							</form>}
						</Wrapper>
					</Container>
				)
			}}
		</Draggable>
	)
}

export default ChecklistItem