import * as React from 'react'
import { closeContactGroupModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Notification from '../../utilities/Notification'
import { ContactGroupsController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CurrentUser, ContactGroup } from '../../types'

interface IStateToProps {
  contactGroup: ContactGroup
  onSubmit?: (contactGroup: ContactGroup) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeContactGroupModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

export enum ContactGroupModalTab {
  DETAILS = 'details',
  RULES = 'rules',
}

interface IState {
  didInitialLoad: boolean
  activeTab: ContactGroupModalTab
  contactGroup: ContactGroup | null
  errors: any
}

class ContactGroupModal extends React.Component<IProps, IState> {
  static defaultProps = {
    contactGroup: {}
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: ContactGroupModalTab.DETAILS,
      contactGroup: null,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onContactGroupModalCloseClick = this.onContactGroupModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { contactGroup: propsContactGroup } = this.props

    try {
      const response = await ContactGroupsController.getForm({ id: propsContactGroup.id })
      const { contact_group } = response

      this.setState({
        contactGroup: {
          ...contact_group,
          ...propsContactGroup
        },
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onNameChange(e) {
    const { contactGroup } = this.state;
    const name = e.currentTarget.value;

    this.setState({
      contactGroup: {
        ...contactGroup,
        name: name
      },
    })
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { contactGroup } = this.state;
    const { close, onSubmit, t } = this.props

    try {

      if (contactGroup.id) { // Do update
        const response = await ContactGroupsController.update(contactGroup)
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });
          Notification.notifyError(t('ContactGroupModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('ContactGroupModal::Contact group successfully updated.'))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
      else {
        const response = await ContactGroupsController.create(contactGroup)
        const { errors } = response;

        if (errors) {
          this.setState({
            errors: errors
          });
          Notification.notifyError(t('ContactGroupModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('ContactGroupModal::Contact group successfully created.'))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onContactGroupModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderDetails() {
    const { t, currentUser } = this.props
    const { activeTab, contactGroup } = this.state;
    const { workspace: { setting } } = currentUser

    if (activeTab !== ContactGroupModalTab.DETAILS) return null;

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-6col'>
            <div className='form-item'>
              <label>
                {t('ContactGroupModal::Name')}
                <span> *</span>
              </label>
              <input
                type='text'
                name='name'
                onChange={this.onNameChange}
                value={contactGroup.name}
                placeholder={t('ContactGroupModal::Name')}
                required
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { contactGroup, t } = this.props
    const { activeTab, didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={contactGroup?.id ? t('ContactGroupModal::Edit contact group') : t('ContactGroupModal::Create contact group')}
          onCloseClick={this.onContactGroupModalCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('ContactGroupModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      contactGroupModal: {
        contactGroup,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    contactGroup: contactGroup,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeContactGroupModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactGroupModal))