import styled from "styled-components";

const OptionRemove = styled.div`
  width: 38px !important;
  height: 38px !important;
  min-width: 38px !important;
  min-height: 38px !important;

  i {
    font-size: 17px !important;
  }
`

export default OptionRemove