import * as React from 'react'
import { ContentBlockType, Playbook, PlaybookSubmission, QuestionAndAnswerBlock, Settings } from '../../types'
import BlocksRenderer, { BlockRenderMode } from '../BlockEditor/BlocksRenderer'
import styled from 'styled-components'

const Container = styled.div``

interface IProps {
  playbook: Playbook
  playbookSubmission: PlaybookSubmission
  setting: Settings
  onAnswerChange: (block: QuestionAndAnswerBlock) => void
}

const PlaybookEditor = (props: IProps) => {
  const { playbook, playbookSubmission, setting, onAnswerChange } = props

  const contentBlocks = [...playbook.content_blocks]

  playbookSubmission
    .answers
    .forEach((answer) => {
      const answerQuestionBlockIndex = contentBlocks.findIndex((block) => block.id === answer.question_id)

      if (answerQuestionBlockIndex !== -1) {
        (contentBlocks[answerQuestionBlockIndex] as QuestionAndAnswerBlock).answer = answer.notes || answer.selected_answers
      }
    })

  return (
    <Container>
      <BlocksRenderer
        renderMode={BlockRenderMode.EDITOR}
        blocks={contentBlocks}
        variables={{}}
        currency={setting.default_currency}
        numberFormat={setting.number_format}
        onItemsBlockItemSelectionChange={undefined}
        onAnswerChange={onAnswerChange}
      />
    </Container>
  )
}

export default PlaybookEditor