import store from "../store";
import { MimeTypes } from "../types";

export default class DocumentHelper {
  static getDocumentMailboxEmail() {
    const { authentication: { currentUser: { workspace } } } = store.getState()
    return `${workspace.id}@document.bizzey.com`
  }

  static getDropzoneMimeTypes(): MimeTypes[] {
    return [
      MimeTypes.JPG,
      MimeTypes.JPEG,
      MimeTypes.PNG,
      MimeTypes.PDF,
      MimeTypes.TEXT_XML,
      MimeTypes.APPLICATION_XML,
      MimeTypes.CSV
    ]
  }
}