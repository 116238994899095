import * as React from 'react';
import { SettingsEmailTemplate } from '../types';
import i18n from '../translations';

export default class EmailTemplateHelper {
  static getTemplateOptions(): { value: string, label: string }[] {
    return Object.keys(SettingsEmailTemplate).map(template => {
      return {
        label: i18n.t(`EmailTemplate::${SettingsEmailTemplate[template]}`),
        value: String(SettingsEmailTemplate[template])
      }
    })
  }
}