import * as React from 'react'
import { saveAs } from 'file-saver'
import copy from 'copy-to-clipboard';

import { closeAttachmentsViewerModal, closeTaskModal, showAttachmentsViewerModal, showConfirmModal, showPlaybookSubmissionModal, showSendEmailModal, showTimeEntryModal } from '../../store/modals/actions'
import ModalWindowPart from './Parts/ModalWindow'
import { AppState } from '../../store';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Icon from '../Icons/Icon'
import { BoardLabelsController, ContactsController, DevicesController, ProjectsController, TasksController, TimeEntriesController } from '../../controllers';
import Button from '../Button/Button';
import ModalLoader from './Parts/ModalLoader';
import moment from '../../utilities/Moment';
import FileHelper from '../../helpers/FileHelper';
import BoardHelper from '../../helpers/BoardHelper';
import { WithTranslation, withTranslation } from 'react-i18next';
import LabelsPopover from '../TaskModal/LabelsPopover';
import TaskButton from '../TaskModal/TaskButton';
import DatePopover from '../TaskModal/DatePopover';
import ChecklistPopover from '../TaskModal/ChecklistPopover';
import TaskChecklistSection from '../TaskModal/TaskChecklist';
import TaskSection from '../TaskModal/TaskSection';
import TaskSectionTitle from '../TaskModal/TaskSectionTitle';
import TaskSectionIcon from '../TaskModal/TaskSectionIcon';
import TaskSectionContent from '../TaskModal/TaskSectionContent';
import { startTimer, stopTimer } from '../../store/timer/actions';
import WorkspaceChannelHelper from '../../helpers/WorkspaceChannelHelper';
import CustomFieldsPopover from '../TaskModal/CustomFieldsPopover';
import Utils from '../../utilities/Utils'
import TimeEntryHelper from '../../helpers/TimeEntryHelper'
import ActionCableConsumer from '../../consumers/ActionCableConsumer'
import { AttachmentsViewerOptions } from '../../store/modals/types'
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import Editor, { MINIMAL_EDITOR_CONFIG } from '../Editor/Editor'
import { BizzeyFile, BizzeyFileType, BoardLabel, Contact, ContactType, CurrentUser, CustomField, Playbook, PlaybookableType, Project, Task, TaskChecklist, TaskChecklistItem, TimeEntry, UserWorkspaceSettingScope, WorkspaceCableEventType, WorkspaceChannelEvent, WorkspaceChannelEventType } from '../../types'
import Avatar from '../Avatar/Avatar'
import TaskHelper from '../../helpers/TaskHelper'
import AssigneesPopover from '../TaskModal/AssigneesPopover'
import ContactPopover from '../TaskModal/ContactPopover'
import ProjectPopover from '../TaskModal/ProjectPopover'
import ReactTooltip from 'react-tooltip'
import ResourceCheckbox from '../Resource/ResourceCheckbox'
import LocationPopover from '../TaskModal/LocationPopover'
import Map from '../Map/Map'
import ProjectHelper from '../../helpers/ProjectHelper';
import MapContainer from '../Map/MapContainer';
import MovePopover from '../TaskModal/MovePopover';
import AvatarStack from '../Avatar/AvatarStack';
import EstimatePopover from '../TaskModal/EstimatePopover';
import TimeFormatter from '../../utilities/TimeFormatter';
import TaskTimesheet, { TaskTimesheet as TaskTimesheetClass } from '../TaskModal/TaskTimesheet';
import CallPopover from '../TaskModal/CallPopover';
import Images from '../../images';
import TelephoneNumberInput from '../Form/TelephoneNumberInput';
import Notification from '../../utilities/Notification';
import PowerSelect from '../Form/PowerSelect';
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect';
import PlaybookPopover from '../TaskModal/PlaybookPopover';

const ModalCoverContainer = styled.div`
  background-size: contain;
  background-origin: content-box;
  box-sizing: border-box;
  background-position: center center;
  background-color: rgb(44, 60, 76);
  background-repeat: no-repeat;
  height: 160px;
  min-height: 160px;
  padding: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

interface IModalCoverProps {
  cover: string
  onClick?: () => void
}

const ModalCover = (props: IModalCoverProps) => {
  const { onClick } = props
  const [cover, setCover] = React.useState(null)

  React.useEffect(() => {
    if (cover === null) setCover(props.cover)

    if (cover && props.cover && FileHelper.getFilename(cover) !== FileHelper.getFilename(props.cover)) {
      setCover(props.cover)
    }
  }, [props.cover])

  return <ModalCoverContainer
    style={{ backgroundImage: `url(${cover})` }}
    onClick={onClick}
  />
}


const enum TaskDescriptionMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

const ModalWindow = styled(ModalWindowPart)`
  width: 100%;
  max-width: 768px;
  margin-top: 48px;
  margin-bottom: 80px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      max-width: initial;
      overflow-y: auto;
      margin-top: 0;
      margin-bottom: 0;
  }
`

const ModalDraggingFilesOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: hsla(0,0%,100%,.7);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  z-index: 11;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`

const ModalDraggingFilesOverlayContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: grabbing;
`

const ModalHeader = styled.div`
  margin: 12px 40px 8px 56px;
  min-height: 32px;
  position: relative;
  z-index: 1;
`

const ModalTaskIcon = styled.div`
  left: -40px;
  position: absolute;
  top: 17px;

  svg {
    width: 24px;
    height: 24px;
  }
`

const ModalTitle = styled.div`
  margin: 4px 0 0;
  padding: 8px 0 0;

  input {
    color: rgb(23, 43, 77);
    background: transparent;
    border-radius: 3px;
    box-shadow: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin: -4px -8px;
    min-height: 24px;
    padding: 4px 8px;
    resize: none;
    border: none !important;

    &:focus {
        outline: ${Style.color.brandPrimary};
        box-shadow: 0 0 0 2px ${Style.color.brandPrimary} !important;
    }
  }
`

const ModalClose = styled.div`
  position: absolute;
  right: -24px;
  top: 0;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      flex-direction: column;
      flex: 1;
  }
`

const ModalContentWrapper = styled.div`
    min-height: 24px;
    padding: 0 16px 8px 16px;
    width: 100%;
    position: relative;
    flex: 1;
    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        flex: initial;
    }
`

const TaskDetails = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-left: 40px;
    width: 100%;

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        margin-left: 0;
    }
`

const TaskDetail = styled.div`
  margin: 0 12px 8px 0;
  max-width: 100%;
`

const TaskDetailHeader = styled.div`
    color: #5e6c84;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 16px;
    margin-top: 16px;
    text-transform: uppercase;
    display: block;
    line-height: 20px;
    margin: 0 8px 4px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
`

const TaskDetailItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
  width: 100%;
`

const AddItem = styled.div`
    background-color: rgba(9,30,66,.04);
    box-shadow: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: block;
    margin: 0 8px 8px 0;
    transition-property: background-color,border-color,box-shadow;
    transition-duration: 85ms;
    transition-timing-function: ease;
    width: 32px;
    height: 32px;
    margin: 0 4px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    svg {
        width: 16px;
        height: 16px;
        font-size: 16px;
        color: #42526e;
        fill: #42526e;
    }
    &:hover {
        background-color: rgba(9,30,66,.08);
        box-shadow: none;
        border: none;
    }
`

const TaskMemberItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-radius: 25em;
    color: #172b4d;
    cursor: pointer;
    display: block;
    height: 32px;
    margin: 0 4px 0 0;
    overflow: visible;
    position: relative;
    text-decoration: none;
    z-index: 0;
    border-radius: 50%;

    img {
        height: 32px;
        width: 32px;
        border-radius: 25em;
        object-fit: cover;
        max-width: 100%;
    }
`

const AddTaskMemberItem = styled(AddItem)`
  border-radius: 50%;
`

const TaskLabels = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const TaskLabel = styled.div<{ color?: string }>`
  background-color: #b3bac5;
  color: #fff;
  display: block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  float: left;
  font-weight: 600;
  height: 32px;
  min-height: 32px;
  line-height: 32px;
  margin: 0 4px 4px 0;
  min-width: 40px;
  padding: 0 12px;
  width: auto;
  cursor: pointer;

  &:hover {
      opacity: .8;
  }

  ${props => props.color && css`
    background-color: ${props.color};
  `}
`

const TaskAddLabel = styled(AddItem)``

const TaskDetailDueDate = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const TaskDetailStatus = styled.div<{ type: 'success' | 'warning' | 'danger' }>`
  color: #5e6c84;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  min-height: 20px;
  overflow: hidden;
  position: relative;
  padding: 2px 4px;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: top;
  font-size: 12px;
  font-weight: 500;
  margin-left: ${Style.spacing.x1};

  border-radius: 3px;
  color: #fff;

  svg, i {
      font-size: 12px;
      width: 12px;
      height: 12px;
      fill: currentColor;
      margin-right: 4px;
  }

  ${props => props.type === 'success' && css`
    background-color: ${Style.color.brandSuccess};
    color: white;
  `}

  ${props => props.type === 'warning' && css`
    background-color: ${Style.color.brandWarning};
    color: white;
  `}

  ${props => props.type === 'danger' && css`
    background-color: ${Style.color.brandDanger};
    color: white;
  `}
`

const TaskDetailButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(9,30,66,.04);
  box-shadow: none;
  border: none;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 32px;
  overflow: hidden;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition-property: background-color,border-color,box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  i {
    font-size: 14px;
    margin-right: 8px;
  }

  &:hover {
    background-color: rgba(9,30,66,.08);
    color: #091e42;

    svg {
      fill: #091e42;
    }
  }
`

const TaskDescriptionEmpty = styled.div`
    cursor: pointer;
    background-color: rgba(9,30,66,.04);
    box-shadow: none;
    border: none;
    border-radius: 3px;
    display: block;
    min-height: 40px;
    padding: 8px 12px;
    text-decoration: none;
    padding-bottom: 40px;
`

const TaskDescriptionEdit = styled.div`
    display: flex;
    flex-direction: column;
`

const TaskDescriptionEditActions = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${Style.spacing.x1};
`

const TaskAttachments = styled.div`
    display: flex;
    flex-direction: column;
`

const TaskAttachmentsActions = styled.div`
    margin-top: ${Style.spacing.x1};
`



const TaskAttachmentContent = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 8px 8px 8px 128px;
    min-height: 80px;
    margin: 0;
    z-index: 0;
`

const TaskAttachment = styled.div`
    border-radius: 3px;
    min-height: 80px;
    margin: 0 0 8px;
    overflow: hidden;
    position: relative;
    &:hover {
        ${TaskAttachmentContent} {
            background-color: rgba(9,30,66,.04);
        }
    }
`

const TaskAttachmentPreview = styled.div<{ cover?: string }>`
  background-color: rgba(9,30,66,.04);
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 3px;
  height: 80px;
  margin-top: -40px;
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  text-decoration: none;
  z-index: 1;
  width: 112px;
  cursor: pointer;

  ${props => props.cover && css`
    background-image: url(${props.cover});
  `}

  span {
      color: #5e6c84;
      display: block;
      font-size: 18px;
      font-weight: 700;
      height: 100%;
      line-height: 80px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      width: 100%;
  }
`


const TaskAttachmentName = styled.div`
    font-weight: bold;
    color: #172b4d;
    font-size: 14px;
`

const TaskAttachmentMeta = styled.div`
    color: #5e6c84;
    font-size: 14px;
    a {
        color: #5e6c84;
        text-decoration: underline;
        &:hover {
            color: #172b4d;
        }
    }
`

const ModalSidebar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px 8px 8px;
    max-width: 168px;
    width: 100%;

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        max-width: initial;
        padding: 0 8px 8px 16px;
    }
`

const ModalSidebarActions = styled.div`
    margin-top: 8px;
    width: 100%;
`

const ModalSidebarActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${TaskButton} {
        width: 100%;
    }
    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        flex-direction: row;
        flex-wrap: wrap;
        > div {
            flex-basis: calc(50% - ${Style.spacing.x1});
            margin-right: ${Style.spacing.x1};
        }
    }
`

const TelephoneNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const TelephoneNumberContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  ${TaskButton} {
    margin-bottom: 0;
    height: 100%;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
  }

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
  }
`

const TelephoneNumberContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;

  .iti__tel-input {
    background: rgba(9,30,66,.04) !important;
    border: none !important;
    box-shadow: none;
    outline: none;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
`

const TelephoneNumberActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      width: 100%;
  }

  ${TaskButton} {
    width: 100%;

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      
      span {
        width: fit-content;
      }
    }
  }
`

const PlaybookContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  
  ${TaskButton} {
    margin-bottom: 0;
    overflow: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    height: 36px;
  }

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;

    ${TaskButton} {
      width: 100%;
    }
  }
`

const PlaybookSelectContainer = styled.div`
  width: 100%;

  > div {
    width: 100%;
    max-height: 36px;
    height: 36px;
    min-height: inherit;

    > div {
      background: rgba(9, 30, 66, 0.04);
      border: none;
      min-height: 36px;
      max-height: 36px;
      height: 36px;
    }
  }

  div[class*="-MenuList"] {
    background: rgba(9, 30, 66, 0.04);
  }
`

const InstallMobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border: 1px solid ${Style.color.border};
  border-radius: ${Style.variables.baseBorderRadius};
  gap: 16px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
  }
`

const InstallMobileContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`

const InstallMobileTitle = styled.div`
  font-weight: bold;
`

const InstallMobileDescription = styled.div`
`

const InstallMobileAction = styled.a`
  img {
    max-width: 100px;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  task: Task
  contactDisabled?: boolean,
  projectDisabled?: boolean,
  activeTimeEntry?: TimeEntry
  onSubmit?: (task: Task) => void
  onDelete?: (task: Task) => void
}

interface IDispatchToProps {
  close: typeof closeTaskModal
  startTimer: typeof startTimer
  stopTimer: typeof stopTimer
  showConfirmModal: typeof showConfirmModal
  showAttachmentsViewerModal: typeof showAttachmentsViewerModal
  closeAttachmentsViewerModal: typeof closeAttachmentsViewerModal
  showTimeEntryModal: typeof showTimeEntryModal
  showPlaybookSubmissionModal: typeof showPlaybookSubmissionModal
  showSendEmailModal: typeof showSendEmailModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  task: Task
  contact: Contact | null
  project: Project | null
  labels: BoardLabel[]
  customFields: CustomField[]
  didInitialLoad: boolean
  errors: any
  descriptionMode: TaskDescriptionMode
  isDraggingFiles: boolean
  hasMobileApp: boolean
}

class TaskModal extends React.Component<IProps, IState> {
  private submitTimeout: number | any
  private nameInput = React.createRef<HTMLInputElement>()
  private editDescriptionContainer = React.createRef<HTMLDivElement>()
  private attachmentInput = React.createRef<HTMLInputElement>()
  private timesheet = React.createRef<TaskTimesheetClass>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      task: null,
      contact: null,
      project: null,
      labels: [],
      customFields: [],
      errors: {},
      descriptionMode: TaskDescriptionMode.VIEW,
      isDraggingFiles: false,
      hasMobileApp: false,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onMousedown = this.onMousedown.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onTaskModalCloseClick = this.onTaskModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.onNameKeyPress = this.onNameKeyPress.bind(this)
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onDescriptionModeChange = this.onDescriptionModeChange.bind(this)
    this.onDescriptionSubmit = this.onDescriptionSubmit.bind(this)
    this.onPopoverContactSubmit = this.onPopoverContactSubmit.bind(this)
    this.onPopoverProjectSubmit = this.onPopoverProjectSubmit.bind(this)
    this.onPopoverAssigneeSubmit = this.onPopoverAssigneeSubmit.bind(this)
    this.onPopoverEstimateSubmit = this.onPopoverEstimateSubmit.bind(this)
    this.onPopoverPhoneNumberSubmit = this.onPopoverPhoneNumberSubmit.bind(this)
    this.onPopoverLocationSubmit = this.onPopoverLocationSubmit.bind(this)
    this.onPopoverLabelToggle = this.onPopoverLabelToggle.bind(this)
    this.onPopoverLabelSubmit = this.onPopoverLabelSubmit.bind(this)
    this.onPopoverLabelDelete = this.onPopoverLabelDelete.bind(this)
    this.onPopoverChecklistSubmit = this.onPopoverChecklistSubmit.bind(this)
    this.onPopoverDateSubmit = this.onPopoverDateSubmit.bind(this)
    this.onCompletedChange = this.onCompletedChange.bind(this)
    this.onAttachmentsDragEnter = this.onAttachmentsDragEnter.bind(this)
    this.onAttachmentDragOver = this.onAttachmentDragOver.bind(this)
    this.onAttachmentsDragLeave = this.onAttachmentsDragLeave.bind(this)
    this.onAttachmentsDrop = this.onAttachmentsDrop.bind(this)
    this.onAddAttachmentClick = this.onAddAttachmentClick.bind(this)
    this.onAttachmentChange = this.onAttachmentChange.bind(this)
    this.onAttachmentClick = this.onAttachmentClick.bind(this)
    this.onAttachmentCoverClick = this.onAttachmentCoverClick.bind(this)
    this.onAttachmentRemoveCoverClick = this.onAttachmentRemoveCoverClick.bind(this)
    this.onAttachmentDownloadClick = this.onAttachmentDownloadClick.bind(this)
    this.onAttachmentDeleteClick = this.onAttachmentDeleteClick.bind(this)
    this.onChecklistNameFormSubmit = this.onChecklistNameFormSubmit.bind(this)
    this.onChecklistDeleteClick = this.onChecklistDeleteClick.bind(this)
    this.onAddChecklistItem = this.onAddChecklistItem.bind(this)
    this.onUpdateChecklistItem = this.onUpdateChecklistItem.bind(this)
    this.onChecklistItemStartTimerClick = this.onChecklistItemStartTimerClick.bind(this)
    this.onChecklistItemDeleteClick = this.onChecklistItemDeleteClick.bind(this)
    this.onNewTimeEntryClick = this.onNewTimeEntryClick.bind(this)
    this.onStartTimerClick = this.onStartTimerClick.bind(this)
    this.onStopTimerClick = this.onStopTimerClick.bind(this)
    this.onPopoverMoveSubmit = this.onPopoverMoveSubmit.bind(this)
    this.onDeleteClick = this.onDeleteClick.bind(this)
    this.onCopyTelephoneNumberClick = this.onCopyTelephoneNumberClick.bind(this)
    this.onSendToMobileClick = this.onSendToMobileClick.bind(this)
    this.onPopoverPlaybookSubmit = this.onPopoverPlaybookSubmit.bind(this)
    this.onViewPlaybookSubmissionClick = this.onViewPlaybookSubmissionClick.bind(this)
    this.onStartCallClick = this.onStartCallClick.bind(this)
    this.onEmailClick = this.onEmailClick.bind(this)

    // Action cable
    this.onActionCableConnected = this.onActionCableConnected.bind(this)
    this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
    this.onActionCableReceived = this.onActionCableReceived.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }
  componentDidMount() {
    this.fetchForm()

    document.addEventListener('mousedown', this.onMousedown);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onMousedown);
  }

  queueFormSubmit() {
    if (this.submitTimeout) clearTimeout(this.submitTimeout)
    this.submitTimeout = setTimeout(this.onFormSubmit, 1000)
  }

  async fetchForm() {
    const { task } = this.props

    try {
      const response = await TasksController.getForm({ id: task.id })
      const { task: responseTask, labels, custom_fields, has_mobile_app } = response
      const { contact, contact_id, project, project_id } = responseTask

      let propContact: Contact = null

      if (task.contact_id) {
        propContact = await ContactsController.getContact(task.contact_id)
      }

      let propProject: Project = null
      if (task.project_id) {
        propProject = await ProjectsController.getProject(task.project_id)
      }

      this.setState({
        task: {
          ...responseTask,
          ...task,
          contact_id: task.contact_id ? task.contact_id : contact_id,
          contact: task.contact_id ? propContact : contact,
          project_id: task.project_id ? task.project_id : project_id,
          project: task.project_id ? propProject : project,
        },
        labels: labels,
        customFields: custom_fields,
        contact: task.contact_id ? propContact : contact,
        project: task.project_id ? propProject : project,
        hasMobileApp: has_mobile_app,
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  syncTaskSubmit() {
    // Synchronising the updated task with underlying form submitter
    const { onSubmit } = this.props
    const { task } = this.state

    if (onSubmit) onSubmit(task)
  }

  onActionCableConnected() {
    console.log('[WorkspaceChannel - TaskModal] connected')
  }

  onActionCableDisconnected() {
    console.log('[WorkspaceChannel - TaskModal] Disconnected')
  }

  onActionCableReceived(event: WorkspaceChannelEvent) {
    const { task } = this.state
    console.log('[WorkspaceChannel - TaskModal] received event', event)

    switch (event.type) {
      case WorkspaceChannelEventType.BOARD_DELETE:
        if (task.board_id === event.data.board_id) {
          this.props.close()
        }
        break
      case WorkspaceChannelEventType.BOARD_LABEL_CREATE:
        if (task.board_id === event.data.board_label.board_id) {
          const labelIndex = this.state.labels.findIndex(label => label.id === event.data.board_label.id)

          if (labelIndex === -1) {
            this.setState({ labels: [...this.state.labels, event.data.board_label] })
          } else {
            const labels = [...this.state.labels]

            labels[labelIndex] = event.data.board_label

            this.setState({ labels: labels })
          }
        }
        break
      case WorkspaceChannelEventType.BOARD_LABEL_UPDATE:
        if (task.board_id === event.data.board_label.board_id) {
          const labelUpdateIndex = this.state.labels.findIndex(label => label.id === event.data.board_label.id)

          if (labelUpdateIndex !== -1) this.state.labels[labelUpdateIndex] = event.data.board_label
          this.setState({ labels: [...this.state.labels] })
        }
        break
      case WorkspaceChannelEventType.BOARD_LABEL_DELETE:
        if (task.board_id === event.data.board_id) {
          this.setState({ labels: [...this.state.labels.filter(label => label.id !== event.data.board_label_id)] })
        }
        break
      case WorkspaceChannelEventType.TASK_UPDATE:
        if (task.id === event.data.task.id) {
          // Set new data on current task
          this.setState({ task: event.data.task }, () => {
            if (task?.time_spent !== event.data.task?.time_spent) this.timesheet.current.refresh()
          })
        }
        break
      case WorkspaceChannelEventType.TASK_DELETE:
        if (this.state.task.id === event.data.task_id) {
          this.props.close()
        }
        break
    }
  }

  async uploadAttachments(files: File[]) {
    const { task: currentTask } = this.state

    try {
      let coverCallExecuted = false
      for (const file of files) {
        const attachment = await TasksController.createAttachment(currentTask.id, { name: file.name, attachment: file, type: BizzeyFileType.FILE })

        // If no attachments present and uploaded attachment is displayable set it as cover
        if (!coverCallExecuted && currentTask.attachments.length === 0 && FileHelper.displayable(attachment)) {
          // Do remote call to set cover in this scenario
          TasksController.update({ id: currentTask.id, cover_id: attachment.id })

          // Ignore second run through
          coverCallExecuted = true
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onFormSubmit() {
    const { t } = this.props
    const { task } = this.state;

    try {
      if (task.id) {
        const response = await TasksController.update(task)
        const { errors } = response;

        if (errors) {
          this.setState({ errors: errors });
        }
        else {
          this.setState({ task: response, }, this.syncTaskSubmit)
        }
      } else {
        const response = await TasksController.create(task)
        const { errors } = response;

        if (errors) {
          this.setState({ errors: errors });
        }
        else {
          this.setState({ task: response }, this.syncTaskSubmit)
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onTaskModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  onNameChange(e) {
    const { task } = this.state

    this.setState({
      task: {
        ...task,
        name: e.currentTarget.value,
      },
    }, this.queueFormSubmit)
  }

  onNameKeyPress(e) {
    if (e.which === 13 && !e.shiftKey) {
      if (this.nameInput && this.nameInput.current) {
        this.nameInput.current.blur()
      }
      this.queueFormSubmit()
    }
  }

  onDescriptionChange(description: string) {
    const { task } = this.state
    this.setState({
      task: {
        ...task,
        description: description
      },
    })
  }

  onDescriptionModeChange(e: React.MouseEvent<HTMLDivElement, MouseEvent>, descriptionMode: TaskDescriptionMode) {
    // @ts-ignore
    if (e.target.tagName.toLowerCase() === 'a') {
      e.preventDefault()
      e.stopPropagation()

      // @ts-ignore
      const link = e.target.href

      if (link.length > 0) {
        window.open(link, '_blank');
      }
    } else {
      this.setState({ descriptionMode: descriptionMode })
    }
  }

  onDescriptionSubmit() {
    this.setState({ descriptionMode: TaskDescriptionMode.VIEW })
    this.onFormSubmit()
  }

  onPopoverLabelToggle(label: BoardLabel) {
    const { task } = this.state

    let updatedLabelIds = task.label_ids

    if (task.label_ids.includes(label.id)) {
      updatedLabelIds = task.label_ids.filter(id => id !== label.id)
    } else {
      updatedLabelIds = [...task.label_ids, label.id]
    }

    this.setState({
      task: {
        ...task,
        label_ids: updatedLabelIds
      }
    }, this.onFormSubmit)
  }

  async onPopoverLabelSubmit(label: BoardLabel) {
    const { task, labels } = this.state

    try {
      const labelIndex = labels.findIndex(l => l.id === label.id)

      if (labelIndex !== -1) {
        labels[labelIndex] = label

        // Update local cache
        this.setState({ labels: [...labels] })

        // Update remove
        const updatedLabel = await BoardLabelsController.update(label)

        // Update again at index
        labels[labelIndex] = updatedLabel

        // Update local cache again
        this.setState({ labels: [...labels] }, this.onFormSubmit)
      } else {
        const createdLabel = await BoardLabelsController.create({ ...label, board_id: task.board_id })

        this.setState({ labels: [...labels, createdLabel] }, this.onFormSubmit)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onPopoverContactSubmit(contactId?: string, contact?: Contact) {
    const { task } = this.state

    try {
      this.setState({
        task: {
          ...task,
          contact_id: contactId || null,
          contact: contact || null,
          project_id: null,
          project: null
        },
      }, this.onFormSubmit)
    } catch (ex) {
      console.error(ex)
    }
  }

  async onPopoverProjectSubmit(projectId?: string, project?: Project) {
    const { task } = this.state

    try {
      this.setState({
        task: {
          ...task,
          project_id: projectId || null,
          project: project || null
        },
      }, this.onFormSubmit)

    } catch (ex) {
      console.error(ex)
    }
  }

  async onPopoverAssigneeSubmit(assigneeIds: string[]) {
    const { task } = this.state

    try {
      this.setState({
        task: {
          ...task,
          assignee_ids: assigneeIds
        },
      }, this.onFormSubmit)

    } catch (ex) {
      console.error(ex)
    }
  }

  async onPopoverEstimateSubmit(timeEstimate: number | null) {
    const { task } = this.state

    try {
      this.setState({
        task: {
          ...task,
          time_estimate: timeEstimate
        },
      }, this.onFormSubmit)

    } catch (ex) {
      console.error(ex)
    }
  }

  async onPopoverPhoneNumberSubmit(telephoneNumber: string) {
    const { task } = this.state

    try {
      this.setState({
        task: {
          ...task,
          telephone_number: telephoneNumber
        },
      }, this.onFormSubmit)
    } catch (ex) {
      console.error(ex)
    }
  }

  async onPopoverLocationSubmit(location: string) {
    const { task } = this.state

    try {
      this.setState({
        task: {
          ...task,
          location: location
        },
      }, this.onFormSubmit)
    } catch (ex) {
      console.error(ex)
    }
  }

  onPopoverLabelDelete(label: BoardLabel) {
    const { task, labels } = this.state

    const labelIndex = labels.findIndex(l => l.id === label.id);

    labels.splice(labelIndex, 1);

    const updatedTask: Task = { ...task, label_ids: task.label_ids.filter(labelId => labelId !== label.id) }

    BoardLabelsController.delete(label.id).catch(console.error)

    this.setState({
      labels: [...labels],
      task: updatedTask,
    }, this.onFormSubmit)
  }

  async onPopoverChecklistSubmit(name: string) {
    const { task } = this.state

    // Create checklist with name
    try {
      this.setState({
        task: {
          ...task,
          checklists: [
            ...task.checklists,
            {
              name: name,
              items: [],
              position: BoardHelper.getCreatePosition(task.checklists),
            }
          ]
        }
      }, this.onFormSubmit)
    } catch (ex) {
      console.error(ex)
    }
  }

  onCompletedChange(e) {
    const { task } = this.state

    this.setState({
      task: { ...task, completed: !task.completed }
    }, this.onFormSubmit)
  }

  async onPopoverDateSubmit(result: {
    start_on: string | Date | any,
    due_on: string | Date | any,
  }) {
    const { start_on, due_on } = result
    const { task } = this.state

    try {
      // Create updated task
      const updatedTask: Task = {
        ...task,
        start_on: start_on,
        due_on: due_on,
      }

      // Update local cache again
      this.setState({ task: updatedTask }, this.onFormSubmit)
    } catch (ex) {
      console.error(ex)
    }
  }

  onAttachmentsDragEnter(e) {
    e.preventDefault()

    this.setState({
      isDraggingFiles: true
    })
  }

  onAttachmentDragOver(e) {
    e.preventDefault()

    const { isDraggingFiles: draggingFiles } = this.state

    if (!draggingFiles) {
      this.setState({
        isDraggingFiles: true,
      })
    }
  }

  onAttachmentsDragLeave(e) {
    const { isDraggingFiles } = this.state

    if (isDraggingFiles) {
      this.setState({ isDraggingFiles: false })
    }
  }

  onAttachmentsDrop(e) {
    e.preventDefault()

    // Get dropped attachments
    const files: File[] = Object.values(e.dataTransfer.files)

    // Upload attachments
    this.uploadAttachments(files)

    // Reset overlay
    this.setState({ isDraggingFiles: false })
  }

  onAddAttachmentClick() {
    if (this.attachmentInput.current) {
      this.attachmentInput.current.click()
    }
  }

  onAttachmentChange(e) {
    const files: File[] = Object.values(e.currentTarget.files);

    this.uploadAttachments(files)
  }


  onAttachmentClick(attachment: BizzeyFile) {
    const { t } = this.props
    const { task: { attachments } } = this.state

    const activeIndex = attachments.findIndex(taskAttachment => taskAttachment.id === attachment.id && Boolean(taskAttachment.attachment_url))

    if (activeIndex !== -1) {

      const attachmentsViewerModalOptions: AttachmentsViewerOptions = {
        activeIndex: activeIndex,
        attachments: attachments.map(a => ({
          id: a.id,
          name: a.attachment_file_name,
          url: a.attachment_url,
          file_size: a.attachment_file_size,
          content_type: a.attachment_content_type,
          created_at: a.created_at
        })),
        actions: [
          {
            icon: 'external-link', text: t('TaskModal::Open in new tab'), onClick: (selectedIndex: number) => {
              const selectedAttachment = attachments[selectedIndex]

              if (selectedAttachment) window.open(selectedAttachment.attachment_url, "_blank");
            }
          },
          {
            icon: 'close', text: t('TaskModal::Delete'), onClick: (selectedIndex: number) => {
              const selectedAttachment = attachments[selectedIndex]

              const newActiveIndex = selectedIndex - 1

              if (selectedAttachment) {
                const newAttachments = attachments.filter(a => a.id !== selectedAttachment.id)

                // Delete attachment remotely
                this.onAttachmentDeleteClick(selectedAttachment)

                if (newAttachments.length === 0) {
                  this.props.closeAttachmentsViewerModal()
                } else {

                  // 1. Filter out deleted attachment
                  // 2. Set new active index
                  this.props.showAttachmentsViewerModal({
                    ...attachmentsViewerModalOptions,
                    attachments: newAttachments.map(a => ({
                      id: a.id,
                      name: a.attachment_file_name,
                      url: a.attachment_url,
                      file_size: a.attachment_file_size,
                      content_type: a.attachment_content_type,
                      created_at: a.created_at
                    })),
                    activeIndex: newActiveIndex >= 0 ? newActiveIndex : 0
                  })
                }
              }
            }
          }
        ]
      }

      this.props.showAttachmentsViewerModal(attachmentsViewerModalOptions)
    }
  }

  onAttachmentCoverClick(attachment: BizzeyFile) {
    const { task } = this.state

    const updatedTask: Task = {
      ...task,
      cover_id: attachment.id,
    }

    this.setState({ task: updatedTask }, this.onFormSubmit)
  }

  onAttachmentRemoveCoverClick(attachment: BizzeyFile) {
    const { task } = this.state

    const updatedTask: Task = {
      ...task,
      cover_id: null,
    }

    this.setState({ task: updatedTask }, this.onFormSubmit)
  }

  onAttachmentDownloadClick(attachment: BizzeyFile) {
    if (attachment.attachment_download_url) {
      saveAs(attachment.attachment_download_url, attachment.attachment_file_name)
    }
  }

  onAttachmentDeleteClick(attachment: BizzeyFile) {
    const { task } = this.state

    TasksController
      .deleteAttachment(task.id, attachment.id)
      .then(response => {
        if (response) {
          const attachmentIndex = task.attachments.findIndex(a => a.id === attachment.id);

          if (attachmentIndex !== -1) {
            task.attachments.splice(attachmentIndex, 1);

            if (task.cover && task.cover.id === attachment.id) {
              task.cover_id = null
              task.cover = null
            }

            this.setState({
              task: {
                ...task,
                attachments: [
                  ...task.attachments,
                ]
              }
            }, this.syncTaskSubmit);
          }
        }
      })
      .catch(console.error)
  }

  async onChecklistNameFormSubmit(index: number, checklist: TaskChecklist, name: string) {
    const { task } = this.state
    const checklists = task.checklists

    try {
      // Update checklist locally
      if (checklists) {
        if (index !== -1) {
          // Update locally
          checklists[index].name = name

          this.setState({
            task: {
              ...task,
              checklists: [
                ...checklists
              ]
            }
          }, this.onFormSubmit)
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onChecklistDeleteClick(index: number, checklist: TaskChecklist) {
    const { task } = this.state

    try {
      // Make a copy
      const updatedChecklists = [...task.checklists]

      // Remove at index
      updatedChecklists.splice(index, 1)

      const updatedTask: Task = {
        ...task,
        checklists: [...updatedChecklists],
      }

      // Update local cache and remote
      this.setState({ task: updatedTask }, this.onFormSubmit)
    } catch (ex) {
      console.error(ex)
    }
  }

  async onAddChecklistItem(index: number, item: TaskChecklistItem) {
    const { task } = this.state
    const checklists = [...task.checklists]

    if (index !== -1) {
      // Push item at the bottom
      checklists[index].items.push(item)

      // Update local cache
      this.setState({
        task: {
          ...task,
          checklists: [...checklists],
        }
      }, this.onFormSubmit)

    }
  }

  async onUpdateChecklistItem(index: number, itemIndex: number, item: TaskChecklistItem) {
    const { task } = this.state
    const checklists = [...task.checklists]

    if (index !== -1) {
      if (itemIndex !== -1) {
        // Set updated checklist item
        checklists[index].items[itemIndex] = { ...item }

        // Sort the collection of checklist items by position
        checklists[index].items = Utils.sortByPosition(checklists[index].items)

        const updatedTask = {
          ...task,
          checklists: [...checklists],
        }

        // Update local cache and remote
        this.setState({ task: { ...updatedTask } }, this.onFormSubmit)
      }
    }
  }

  async onChecklistItemStartTimerClick(item: TaskChecklistItem) {
    const { startTimer } = this.props
    const { task, contact, project } = this.state

    try {
      const timeEntry: TimeEntry = {
        started_at: moment().utc().toString(),
        description: item.name,
        billable: ProjectHelper.isBillable(project),
        active: true,
        contact_id: contact ? contact.id : null,
        contact: contact,
        project_id: project ? project.id : null,
        project: project,
        task_id: task.id,
      }
      const { base_rate, rate } = await TimeEntryHelper.getRate(timeEntry)

      const response = await TimeEntriesController.create({ ...timeEntry, base_rate: base_rate, rate: rate })

      if (response.errors) { }
      else {
        WorkspaceChannelHelper.send({ type: WorkspaceCableEventType.START_TIMER, data: { entry: response } })

        // Local event
        startTimer(response)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onChecklistItemDeleteClick(index: number, itemIndex: number, item: TaskChecklistItem) {
    const { task } = this.state

    const updatedChecklists = [...task.checklists]

    if (index !== -1) {
      if (itemIndex !== -1) {
        updatedChecklists[index].items.splice(itemIndex, 1)
      }
    }

    this.setState({
      task: {
        ...task,
        checklists: [...updatedChecklists]
      },
    }, this.onFormSubmit)
  }

  getNewTaskTimeEntry(): TimeEntry {
    const { task } = this.state

    return {
      started_at: moment().utc().toString(),
      description: task.name,
      billable: ProjectHelper.isBillable(task.project),
      contact_id: task.contact_id,
      contact: task.contact,
      project_id: task.project_id,
      project: task.project,
      task_id: task.id,
    }
  }

  async onEmailClick() {
    const { task } = this.state

    this.props.showSendEmailModal({
      email: {
        to: task.contact ? [task.contact.id] : [],
      },
    })
  }

  async onNewTimeEntryClick() {
    const { task } = this.state
    try {
      this.props.showTimeEntryModal({
        timeEntry: { ...this.getNewTaskTimeEntry() },
        contactDisabled: Boolean(task.contact_id),
        projectDisabled: Boolean(task.project_id),
        onSubmit: () => {
          if (this.timesheet.current) this.timesheet.current.refresh()
        }
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onStartTimerClick() {
    const { startTimer } = this.props
    const { task, contact, project } = this.state

    try {
      const timeEntry: TimeEntry = {
        ...this.getNewTaskTimeEntry(),
        active: true,
      }

      const { base_rate, rate } = await TimeEntryHelper.getRate(timeEntry)
      const response = await TimeEntriesController.create({ ...timeEntry, base_rate: base_rate, rate: rate })

      if (response.errors) { }
      else {
        WorkspaceChannelHelper.send({ type: WorkspaceCableEventType.START_TIMER, data: { entry: response } })

        // Local event
        startTimer(response)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onPopoverMoveSubmit(boardId: string, listId: string, position: number) {
    const { task } = this.state
    try {
      let labelIds = []
      let labels = []

      if (task.board_id === boardId) {
        labelIds = task.label_ids
        labels = task.labels
      }

      const updatedTask = await TasksController.update({
        ...task,
        board_id: boardId,
        list_id: listId,
        position: position,
        label_ids: labelIds,
        labels: labels
      })

      this.setState({ task: { ...updatedTask } }, this.fetchForm)
    } catch (ex) {
      console.error(ex)
    }
  }


  async onStopTimerClick() {
    const { activeTimeEntry, stopTimer } = this.props

    if (activeTimeEntry) {
      try {
        const response = await TimeEntriesController.update({ ...activeTimeEntry, active: false })

        if (response.errors) { }
        else {
          WorkspaceChannelHelper.send({
            type: WorkspaceCableEventType.STOP_TIMER,
            data: {
              entry: response
            }
          })

          stopTimer()
        }
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  async onDeleteClick() {
    const { onDelete, close, t, showConfirmModal } = this.props
    const { task } = this.state

    showConfirmModal({
      title: t('TaskModal::Delete task'),
      description: t('TaskModal::You are about to delete this task. This task and all its associated will be permanently deleted. Are you sure you want to continue?'),
      action: { label: t('TaskModal::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          await TasksController.delete(task.id)

          // Execute onDelete callback if provided
          if (onDelete) onDelete(task)

          // Close modal
          close()
        } catch (ex) {
          console.error(ex)
        }
      }
    })

  }

  onMousedown(e) {
    const { descriptionMode } = this.state

    if (descriptionMode === TaskDescriptionMode.EDIT && this.editDescriptionContainer && !this.editDescriptionContainer.current.contains(e.target)) {
      this.setState({ descriptionMode: TaskDescriptionMode.VIEW }, this.onFormSubmit)
    }
  }

  onCopyTelephoneNumberClick(e) {
    const { t } = this.props
    const { task } = this.state

    copy(task.telephone_number)
    Notification.notifySuccess(t('TaskModal::Copied to clipboard'))
  }

  async onSendToMobileClick() {
    const { task } = this.state

    try {
      this.showPlaybookSubmissionModal()
      await DevicesController.call(task.telephone_number)
    } catch (ex) {
      console.error(ex)
    }
  }

  async onPopoverPlaybookSubmit(playbookId?: string, playbook?: Playbook) {
    const { task } = this.state
    try {
      this.setState({
        task: { ...task, playbook_id: playbookId || null }
      }, this.onFormSubmit)
    } catch (ex) {
      console.error(ex)
    }
  }

  onViewPlaybookSubmissionClick() {
    this.showPlaybookSubmissionModal()
  }

  async onStartCallClick() {
    const { task } = this.state

    document.location.href = `tel:${task.telephone_number}`
    this.showPlaybookSubmissionModal()
  }

  showPlaybookSubmissionModal() {
    const { task } = this.state

    if (UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PLAYBOOK) && task.playbook_id) {
      this.props.showPlaybookSubmissionModal({
        playbookSubmission: {
          playbook_id: task.playbook_id,
          playbookable_id: task.id,
          playbookable_type: PlaybookableType.TASK,
        },
      })
    }
  }

  render() {
    const { t, activeTimeEntry, currentUser, contactDisabled, projectDisabled } = this.props
    const { workspace: { setting } } = currentUser
    const {
      didInitialLoad,
      task,
      labels,
      isDraggingFiles,
      descriptionMode,
      customFields,
      hasMobileApp,
    } = this.state

    let isOverdue = false
    let isDueSoon = false
    if (task) {
      const nowMoment = moment()
      const momentDueOn = moment(task.due_on)
      isOverdue = momentDueOn.isValid() && momentDueOn.isBefore(nowMoment, 'day')
      isDueSoon = momentDueOn.isValid() && momentDueOn.diff(nowMoment, 'day') <= 2
    }

    return (
      <ModalWindow
        onDragEnter={this.onAttachmentsDragEnter}
        onDragOver={this.onAttachmentDragOver}
        onDrop={this.onAttachmentsDrop}
      >
        {isDraggingFiles && <ModalDraggingFilesOverlay onDragLeave={this.onAttachmentsDragLeave}>
          <ModalDraggingFilesOverlayContent>
            {t('TaskModal::Drop files to upload')}
          </ModalDraggingFilesOverlayContent>
        </ModalDraggingFilesOverlay>}
        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ActionCableConsumer
          channel={{ channel: 'WorkspaceChannel', id: currentUser.workspace.id }}
          onConnected={this.onActionCableConnected}
          onDisconnected={this.onActionCableDisconnected}
          onReceived={this.onActionCableReceived}
        >
          {task.cover && <ModalCover cover={task.cover.attachment_url} onClick={() => this.onAttachmentClick(task.cover)} />}
          <ModalHeader>
            <ModalTaskIcon>
              <ResourceCheckbox
                checked={Boolean(task.completed)}
                disabled={false}
                onCheckedChange={this.onCompletedChange}
              />
            </ModalTaskIcon>

            <ModalTitle>
              <input
                ref={this.nameInput}
                type='text'
                value={task.name}
                onChange={this.onNameChange}
                onKeyPress={this.onNameKeyPress}
                placeholder={t('TaskModal::Task name')}
              />
            </ModalTitle>

            <ModalClose>
              <a href='javascript://' className='modal-close' onClick={this.onTaskModalCloseClick}>
                <Icon icon='close' className='modal-close-icon' />
              </a>
            </ModalClose>
          </ModalHeader>

          <ModalContent>
            <ModalContentWrapper>
              <TaskDetails>
                {task?.contact && <TaskDetail>
                  <TaskDetailHeader>{t('TaskModal::Contact')}</TaskDetailHeader>
                  <TaskDetailItem>
                    <ContactPopover
                      activator={
                        <TaskDetailButton>
                          <Icon icon={task.contact.type === ContactType.PERSON ? 'user' : 'company'} />
                          <span>{task?.contact?.name}</span>
                        </TaskDetailButton>
                      }
                      contactId={task.contact_id}
                      contactDisabled={contactDisabled}
                      onSubmit={this.onPopoverContactSubmit}
                    />
                  </TaskDetailItem>
                </TaskDetail>}

                {task?.project && <TaskDetail>
                  <TaskDetailHeader>{t('TaskModal::Project')}</TaskDetailHeader>
                  <TaskDetailItem>
                    <ProjectPopover
                      activator={
                        <TaskDetailButton>
                          <Icon icon='project' />
                          <span>{task?.project?.name}</span>
                        </TaskDetailButton>
                      }
                      contactId={task.contact_id}
                      projectId={task.project_id}
                      projectDisabled={projectDisabled}
                      onSubmit={this.onPopoverProjectSubmit}
                    />
                  </TaskDetailItem>
                </TaskDetail>}

                {task?.assignees?.length > 0 && <TaskDetail>
                  <TaskDetailHeader>{t('TaskModal::Assignees')}</TaskDetailHeader>
                  <TaskDetailItem>
                    <AssigneesPopover
                      activator={
                        <TaskMemberItem>
                          <AvatarStack width={30}>
                            {task?.assignees?.map(assignee => (
                              <Avatar
                                rounded={true}
                                name={TaskHelper.getAssigneeName(assignee)}
                                width={30}
                              />
                            ))}
                          </AvatarStack>
                        </TaskMemberItem>
                      }
                      assigneeIds={task.assignee_ids}
                      onSubmit={this.onPopoverAssigneeSubmit}
                    />

                    <AssigneesPopover
                      activator={
                        <AddTaskMemberItem>
                          <Icon icon='plus' />
                        </AddTaskMemberItem>
                      }
                      assigneeIds={task.assignee_ids}
                      onSubmit={this.onPopoverAssigneeSubmit}
                    />
                  </TaskDetailItem>
                </TaskDetail>}


                {task?.time_estimate > 0 && <TaskDetail>
                  <TaskDetailHeader>{t('TaskModal::Estimate')}</TaskDetailHeader>
                  <TaskDetailItem>
                    <EstimatePopover
                      activator={
                        <TaskDetailButton>
                          <Icon icon='hourglass' />
                          <span>{TimeFormatter.formatToHumanReadableDuration(task.time_estimate)}</span>
                          {task?.time_spent > task?.time_estimate && <>
                            <span style={{ marginLeft: 4 }}>+</span>
                            <span>
                              <TaskDetailStatus type='danger'>
                                {TimeFormatter.formatToHumanReadableDuration(Math.abs(task.time_estimate - task.time_spent))}
                              </TaskDetailStatus>
                            </span>
                          </>}
                        </TaskDetailButton>
                      }
                      estimate={task.time_estimate}
                      onSubmit={this.onPopoverEstimateSubmit}
                    />
                  </TaskDetailItem>
                </TaskDetail>}

                {labels && labels.length > 0 && <TaskDetail>
                  <TaskDetailHeader>{t('TaskModal::Labels')}</TaskDetailHeader>
                  <TaskLabels>
                    {labels.filter(label => task.label_ids.includes(label.id)).map(label => {
                      return (
                        <LabelsPopover
                          key={label.id}
                          activator={<TaskLabel color={label.color}>
                            {label.name}
                          </TaskLabel>}
                          labels={labels}
                          labelIds={task.label_ids}
                          onToggleLabel={this.onPopoverLabelToggle}
                          onSubmit={this.onPopoverLabelSubmit}
                          onDelete={this.onPopoverLabelDelete}
                        />
                      )
                    })}
                    {!(task.labels.length === labels.length) && <LabelsPopover
                      key='add-label'
                      activator={<TaskAddLabel>
                        <Icon icon='plus' />
                      </TaskAddLabel>}
                      labels={labels}
                      labelIds={task.label_ids}
                      onToggleLabel={this.onPopoverLabelToggle}
                      onSubmit={this.onPopoverLabelSubmit}
                      onDelete={this.onPopoverLabelDelete}
                    />
                    }
                  </TaskLabels>
                </TaskDetail>}

                {(task.start_on || task.due_on) && <TaskDetail>
                  <TaskDetailHeader>
                    {task.start_on && task.due_on && t('TaskModal::Dates')}
                    {task.start_on && !task.due_on && t('TaskModal::Start date')}
                    {!task.start_on && task.due_on && t('TaskModal::Due date')}
                  </TaskDetailHeader>
                  <DatePopover
                    startOn={task.start_on}
                    dueOn={task.due_on}
                    activator={<TaskDetailDueDate>
                      <TaskDetailButton>
                        <Icon icon='calendar-day' />
                        {task.start_on && task.due_on && `${moment(task.start_on).format('MMM D, YYYY')} - ${moment(task.due_on).format(`MMM D, YYYY`)}`}
                        {task.start_on && !task.due_on && moment(task.start_on).format(`MMM D, YYYY`)}
                        {!task.start_on && task.due_on && moment(task.due_on).format(`MMM D, YYYY`)}

                        {task.completed && <TaskDetailStatus type='success'>{t('TaskModal::COMPLETED')}</TaskDetailStatus>}
                        {!task.completed && !isOverdue && isDueSoon && <TaskDetailStatus type='warning'>{t('TaskModal::DUE SOON')}</TaskDetailStatus>}
                        {!task.completed && isOverdue && <TaskDetailStatus type='danger'>{t('TaskModal::OVERDUE')}</TaskDetailStatus>}
                        <Icon icon='chevron-down' />
                      </TaskDetailButton>
                    </TaskDetailDueDate>}
                    dateFormat={setting.date_format}
                    onSubmit={this.onPopoverDateSubmit}
                  />
                </TaskDetail>}
              </TaskDetails>

              <TaskSection>
                <TaskSectionTitle>
                  <TaskSectionIcon>
                    <Icon icon='align-justify' />
                  </TaskSectionIcon>

                  {t('TaskModal::Description')}
                </TaskSectionTitle>

                <TaskSectionContent>
                  {descriptionMode === TaskDescriptionMode.VIEW && <div onClick={(e) => this.onDescriptionModeChange(e, TaskDescriptionMode.EDIT)}>
                    {task.description.length === 0 && <TaskDescriptionEmpty>
                      {t('TaskModal::Add a more detailed description')}
                    </TaskDescriptionEmpty>}
                    {task.description.length > 0 && <div className='text-editor'>
                      <FroalaEditorView model={task.description} />
                    </div>}
                  </div>}
                  {descriptionMode === TaskDescriptionMode.EDIT && <TaskDescriptionEdit ref={this.editDescriptionContainer}>
                    <Editor
                      model={task.description}
                      onModelChange={this.onDescriptionChange}
                      config={{
                        ...MINIMAL_EDITOR_CONFIG,
                        placeholderText: t('TaskModal::Add a more detailed description')
                      }}
                    />
                    <TaskDescriptionEditActions>
                      <Button text={t('TaskModal::Save')} type='success' onClick={this.onDescriptionSubmit} />
                    </TaskDescriptionEditActions>
                  </TaskDescriptionEdit>}
                </TaskSectionContent>
              </TaskSection>

              {customFields.length > 0 && Object.keys(task.custom_fields).length > 0 && <TaskSection>
                <TaskSectionTitle>
                  <TaskSectionIcon>
                    <Icon icon='magic' />
                  </TaskSectionIcon>

                  {t('TaskModal::Custom Fields')}
                </TaskSectionTitle>

                <TaskSectionContent>
                </TaskSectionContent>
              </TaskSection>}

              {task.attachments && task.attachments.length > 0 && <TaskSection>
                <TaskSectionTitle>
                  <TaskSectionIcon>
                    <Icon icon='attachment' />
                  </TaskSectionIcon>
                  {t('TaskModal::Attachments')}
                </TaskSectionTitle>

                <TaskSectionContent>
                  <TaskAttachments>
                    {task.attachments.map(attachment => {
                      const canDisplay = FileHelper.displayable(attachment)

                      return (
                        <TaskAttachment key={attachment.id}>
                          <TaskAttachmentPreview cover={canDisplay ? attachment.attachment_url : null} onClick={() => this.onAttachmentClick(attachment)}>
                            {!canDisplay && <span>{FileHelper.getExtension(attachment.name)}</span>}
                          </TaskAttachmentPreview>
                          <TaskAttachmentContent>
                            <TaskAttachmentName>{attachment.name}</TaskAttachmentName>
                            <TaskAttachmentMeta>
                              <div>
                                {`${moment(attachment.created_at).fromNow()}`}
                              </div>
                              {canDisplay && !task.cover && <>
                                <a
                                  href='javascript://'
                                  onClick={() => this.onAttachmentCoverClick(attachment)}>
                                  {t('TaskModal::Set as cover')}
                                </a>
                                <span style={{ marginLeft: 4, marginRight: 4 }}>-</span>
                              </>}

                              {task.cover && task.cover.id === attachment.id && <>
                                <a
                                  href='javascript://'
                                  onClick={() => this.onAttachmentRemoveCoverClick(attachment)}>
                                  {t('TaskModal::Remove cover')}
                                </a>
                                <span style={{ marginLeft: 4, marginRight: 4 }}>-</span>
                              </>}
                              <a
                                href='javascript://'
                                onClick={() => this.onAttachmentDownloadClick(attachment)}>
                                {t('TaskModal::Download')}
                              </a>
                              <span style={{ marginLeft: 4, marginRight: 4 }}>-</span>
                              <a
                                href='javascript://'
                                onClick={() => this.onAttachmentDeleteClick(attachment)}
                              >
                                {t('TaskModal::Delete')}
                              </a>
                            </TaskAttachmentMeta>
                          </TaskAttachmentContent>
                        </TaskAttachment>
                      )
                    })}
                  </TaskAttachments>

                  <TaskAttachmentsActions>
                    <TaskButton
                      onClick={this.onAddAttachmentClick}
                      fitContent
                      title={t('TaskModal::Add an attachment')}
                    >
                      <span>{t('TaskModal::Add an attachment')}</span>
                    </TaskButton>
                  </TaskAttachmentsActions>
                </TaskSectionContent>
              </TaskSection>}

              {task?.telephone_number?.length > 0 && <TaskSection>
                <TaskSectionTitle>
                  <TaskSectionIcon>
                    <Icon icon='phone' />
                  </TaskSectionIcon>
                  {t('TaskModal::Call')}
                </TaskSectionTitle>

                <TaskSectionContent>
                  <TelephoneNumberContainer>
                    <TelephoneNumberContent>
                      <TelephoneNumberContentWrapper>
                        <TelephoneNumberInput
                          initialCountry={currentUser.workspace.country_code}
                          initialValue={task.telephone_number}
                          disabled
                        />
                        <TaskButton onClick={this.onCopyTelephoneNumberClick} data-tip={t('TaskModal::Copy')}>
                          <Icon icon='copy' />
                        </TaskButton>
                        {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.PLAYBOOK) && <PlaybookPopover
                          activator={
                            <TaskButton data-tip={t('TaskModal::Call playbook')}>
                              <Icon icon='book' />
                            </TaskButton>
                          }
                          playbookId={task.playbook_id}
                          onSubmit={this.onPopoverPlaybookSubmit}
                          onViewPlaybookSubmission={this.onViewPlaybookSubmissionClick}
                        />}
                        {hasMobileApp && <TaskButton onClick={this.onSendToMobileClick} data-tip={t('TaskModal::Send to mobile')}>
                          <Icon icon='mobile-alt' />
                        </TaskButton>}
                      </TelephoneNumberContentWrapper>
                      <TelephoneNumberActions>
                        <TaskButton onClick={this.onStartCallClick}>
                          <Icon icon='phone' />
                          <span>{t('TaskModal::Start call')}</span>
                        </TaskButton>
                      </TelephoneNumberActions>
                    </TelephoneNumberContent>

                    {!hasMobileApp && <InstallMobileContainer>
                      <InstallMobileContent>
                        <InstallMobileTitle>{t('TaskModal::Install {{__appName}} mobile app')}</InstallMobileTitle>
                        <InstallMobileDescription>
                          {t("TaskModal::To start calls from the {{__appName}} app, install it on your smartphone first. You'll also have convenient access to all data and schedules, even offline.")}
                        </InstallMobileDescription>
                      </InstallMobileContent>
                      <InstallMobileAction href='https://www.bizzey.com/en/blog/installing-bizzey-on-your-smartphone' target='_blank'>
                        <img src={Images.DOWNLOAD_MOBILE_APP} />
                      </InstallMobileAction>
                    </InstallMobileContainer>}
                  </TelephoneNumberContainer>
                </TaskSectionContent>
              </TaskSection>}

              {task?.location?.length > 0 && <TaskSection>
                <TaskSectionTitle>
                  <TaskSectionIcon>
                    <Icon icon='location-dot' />
                  </TaskSectionIcon>
                  {t('TaskModal::Location')}
                </TaskSectionTitle>

                <TaskSectionContent>
                  <MapContainer>
                    <Map location={task.location} />
                  </MapContainer>
                </TaskSectionContent>
              </TaskSection>}

              {task.checklists && task.checklists.length > 0 && task.checklists.map((checklist, index) => {
                return (
                  <TaskChecklistSection
                    key={index}
                    index={index}
                    checklist={checklist}
                    activeTimeEntry={activeTimeEntry}
                    onNameFormSubmit={(checklist: TaskChecklist, name: string) => this.onChecklistNameFormSubmit(index, checklist, name)}
                    onDeleteClick={(checklist) => this.onChecklistDeleteClick(index, checklist)}
                    onAddChecklistItem={(item) => this.onAddChecklistItem(index, item)}
                    onUpdateChecklistItem={(itemIndex, item) => this.onUpdateChecklistItem(index, itemIndex, item)}
                    onChecklistItemStartTimerClick={this.onChecklistItemStartTimerClick}
                    onChecklistItemDeleteClick={(itemIndex, item) => this.onChecklistItemDeleteClick(index, itemIndex, item)}
                  />
                )
              })}

              {task?.time_spent > 0 && <TaskTimesheet
                ref={this.timesheet}
                task={task}
                onNewTimeEntryClick={this.onNewTimeEntryClick}
              />}
            </ModalContentWrapper>

            <ModalSidebar>
              <ModalSidebarActions>
                <TaskDetailHeader>
                  {t('TaskModal::ADD TO TASK')}
                </TaskDetailHeader>

                <ModalSidebarActionsWrapper>
                  <ContactPopover
                    activator={
                      <TaskButton title={t('TaskModal::Contact')}>
                        <Icon icon='id-badge' />
                        <span>{t('TaskModal::Contact')}</span>
                      </TaskButton>
                    }
                    contactId={task.contact_id}
                    contactDisabled={contactDisabled}
                    onSubmit={this.onPopoverContactSubmit}
                  />
                  <ProjectPopover
                    activator={
                      <TaskButton title={t('TaskModal::Project')}>
                        <Icon icon='briefcase' />
                        <span>{t('TaskModal::Project')}</span>
                      </TaskButton>
                    }
                    contactId={task.contact_id}
                    projectId={task.project_id}
                    projectDisabled={projectDisabled}
                    onSubmit={this.onPopoverProjectSubmit}
                  />
                  <AssigneesPopover
                    activator={
                      <TaskButton title={t('TaskModal::Assignees')}>
                        <Icon icon='user' />
                        <span>{t('TaskModal::Assignees')}</span>
                      </TaskButton>
                    }
                    assigneeIds={task.assignee_ids}
                    onSubmit={this.onPopoverAssigneeSubmit}
                  />
                  <EstimatePopover
                    activator={
                      <TaskButton title={t('TaskModal::Estimate')}>
                        <Icon icon='hourglass' />
                        <span>{t('TaskModal::Estimate')}</span>
                      </TaskButton>
                    }
                    estimate={task.time_estimate}
                    onSubmit={this.onPopoverEstimateSubmit}
                  />
                  <CallPopover
                    activator={
                      <TaskButton title={t('TaskModal::Call')}>
                        <Icon icon='phone' />
                        <span>{t('TaskModal::Call')}</span>
                      </TaskButton>
                    }
                    countryCode={currentUser.workspace.country_code}
                    telephoneNumber={task.telephone_number}
                    onSubmit={this.onPopoverPhoneNumberSubmit}
                  />
                  <LocationPopover
                    activator={
                      <TaskButton title={t('TaskModal::Location')}>
                        <Icon icon='location-dot' />
                        <span>{t('TaskModal::Location')}</span>
                      </TaskButton>
                    }
                    location={task.location}
                    onSubmit={this.onPopoverLocationSubmit}
                  />
                  {task.board_id && <LabelsPopover
                    activator={
                      <TaskButton title={t('TaskModal::Labels')}>
                        <Icon icon='tag' />
                        <span>{t('TaskModal::Labels')}</span>
                      </TaskButton>}
                    labels={labels}
                    labelIds={task.label_ids}
                    onToggleLabel={this.onPopoverLabelToggle}
                    onSubmit={this.onPopoverLabelSubmit}
                    onDelete={this.onPopoverLabelDelete}
                  />}
                  <ChecklistPopover
                    activator={
                      <TaskButton title={t('TaskModal::Checklists')}>
                        <Icon icon='tasks' />
                        <span>{t('TaskModal::Checklists')}</span>
                      </TaskButton>}
                    onSubmit={this.onPopoverChecklistSubmit}
                  />

                  <DatePopover
                    startOn={task.start_on}
                    dueOn={task.due_on}
                    activator={
                      <TaskButton title={t('TaskModal::Dates')}>
                        <Icon icon='calendar-day' />
                        <span>{t('TaskModal::Dates')}</span>
                      </TaskButton>
                    }
                    dateFormat={setting.date_format}
                    onSubmit={this.onPopoverDateSubmit}
                  />

                  <TaskButton title={t('TaskModal::Attachment')} onClick={this.onAddAttachmentClick}>
                    <Icon icon='attachment' />
                    <span>{t('TaskModal::Attachment')}</span>
                    <input
                      type='file'
                      ref={this.attachmentInput}
                      style={{ display: 'none' }}
                      onChange={this.onAttachmentChange}
                      multiple
                    />
                  </TaskButton>

                  {false && <CustomFieldsPopover
                    activator={
                      <TaskButton title={t('TaskModal::Custom Fields')}>
                        <Icon icon='magic' />
                        <span>{t('TaskModal::Custom Fields')}</span>
                      </TaskButton>}
                    onSubmit={() => { }}
                  />}
                </ModalSidebarActionsWrapper>
              </ModalSidebarActions>

              <ModalSidebarActions>
                <TaskDetailHeader>
                  {t('TaskModal::ACTIONS')}
                </TaskDetailHeader>
                <ModalSidebarActionsWrapper>
                  <TaskButton title={t('TaskModal::Email')} onClick={this.onEmailClick}>
                    <Icon icon='envelope' />
                    {t('TaskModal::Email')}
                  </TaskButton>
                  {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.TIME_TRACKING) && <>
                    <TaskButton title={t('TaskModal::Log time')} onClick={this.onNewTimeEntryClick}>
                      <Icon icon='clock-solid' />
                      {t('TaskModal::Log time')}
                    </TaskButton>
                    {!activeTimeEntry && <TaskButton title={t('TaskModal::Start Timer')} onClick={this.onStartTimerClick}>
                      <Icon icon='stopwatch' />
                      <span>{t('TaskModal::Start Timer')}</span>
                    </TaskButton>}
                    {activeTimeEntry && activeTimeEntry?.task_id === task.id && <TaskButton title={t('TaskModal::Stop Timer')} onClick={this.onStopTimerClick} destructive={true}>
                      <Icon icon='stopwatch' />
                      {t('TaskModal::Stop Timer')}
                    </TaskButton>}
                  </>}
                  <MovePopover
                    activator={
                      <TaskButton title={t('TaskModal::Move')}>
                        <Icon icon='arrow-right' />
                        <span>{t('TaskModal::Move')}</span>
                      </TaskButton>
                    }
                    boardId={task.board_id}
                    listId={task.list_id}
                    position={task.position}
                    onSubmit={this.onPopoverMoveSubmit}
                  />

                  <TaskButton title={t('TaskModal::Delete')} onClick={this.onDeleteClick} destructive>
                    <Icon icon='trash' />
                    <span>{t('TaskModal::Delete')}</span>
                  </TaskButton>
                </ModalSidebarActionsWrapper>
              </ModalSidebarActions>
            </ModalSidebar>
          </ModalContent>
        </ActionCableConsumer>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      taskModal: {
        task,
        contactDisabled,
        projectDisabled,
        onSubmit,
        onDelete,
      }
    },
    timer: {
      entry
    },
  } = state

  return {
    currentUser: currentUser,
    task: task,
    contactDisabled: contactDisabled,
    projectDisabled: projectDisabled,
    onSubmit: onSubmit,
    onDelete: onDelete,
    activeTimeEntry: entry,
  }
}

const mapDispatchToProps: IDispatchToProps = {
  close: closeTaskModal,
  startTimer: startTimer,
  stopTimer: stopTimer,
  showConfirmModal: showConfirmModal,
  showAttachmentsViewerModal: showAttachmentsViewerModal,
  closeAttachmentsViewerModal: closeAttachmentsViewerModal,
  showTimeEntryModal: showTimeEntryModal,
  showPlaybookSubmissionModal: showPlaybookSubmissionModal,
  showSendEmailModal: showSendEmailModal
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TaskModal))
