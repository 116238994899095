import store from '../store'
import { WorkspaceCableEvent } from '../types'

export default class WorkspaceChannelHelper {
	static send(event: WorkspaceCableEvent) {
		const {
			channels: {
				workspaceChannel,
			},
		} = store.getState()

		if (workspaceChannel) {
			workspaceChannel.send(event)
		}
	}
}