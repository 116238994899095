import * as React from 'react'
import { closeDocumentModal, showAttachmentsViewerModal } from '../../store/modals/actions'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { ActiveStorageController, DocumentsController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import DateInput, { DateInput as DateInputClass } from '../Form/DateInput'
import moment from '../../utilities/Moment'
import MoneyInput from '../Form/MoneyInput'
import UrlHelper from '../../helpers/UrlHelper'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import { withTranslation, WithTranslation } from 'react-i18next'
import AttachmentPreviewer from './AttachmentsViewer/AttachmentsPreviewer'
import SidebarHeader from '../Sidebar/SidebarHeader'
import SidebarClose from '../Sidebar/SidebarClose'
import SidebarContent from '../Sidebar/SidebarContent'
import SidebarContainerComponent from '../Sidebar/SidebarContainer'
import SidebarDivider from '../Sidebar/SidebarDivider'
import TooltipError from '../Tooltips/ErrorTooltip'
import Button from '../Button/Button'
import SidebarFooter from '../Sidebar/SidebarFooter'
import Loader from '../Loaders/Loader'
import { CurrentUser, Document, PreviewAttachment } from '../../types'
import { saveAs } from 'file-saver'
import ResourceModalDropzone from '../Form/ResourceModalDropzone'
import DocumentHelper from '../../helpers/DocumentHelper'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Tooltip from '../Tooltips/Tooltip'

const MobileDocumentModalDropzone = styled.div`
  display: none;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: block;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  width: 100%;
`

const SidebarContainer = styled(SidebarContainerComponent)`
  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    width: 100%;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const PreviewContainer = styled.div`
  position: relative;
	height: 100%;
  width: 100%;
  pointer-events: all;
  cursor: auto;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: none;
  }
`

const PreviewDropzoneContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  pointer-events: all;

  > div {
    border: none;
    height: 100vh;
  }
`

const PreviewWrapper = styled.div<{ noPadding?: boolean }>`
 	padding: 48px 24px;
	outline: none !important;
  overflow: auto;
  height: 100vh;

  ${(props) => props.noPadding && css`
    padding: 0;
  `}



	img, iframe {
		border-radius: 3px;
		width: 100%;
    max-width: 100%;
    position: relative;
    transition: transform .15s,opacity .4s;
    cursor: auto;
    pointer-events: all;
	}

	iframe {
		height: 100vh;
		border: none;
	}
`

const PreviewAction = styled.div<{ destructive?: boolean }>`
  position: relative;
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 9999;
  background: ${Style.color.brandDanger};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #edeff0;
  border: 1px solid ${Style.color.border};

  svg, i {
    color: black;
    fill: black;
  }

  svg {
    width: 20px;
  }

  i {
    font-size: 16px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${props => props.destructive && css`
    background: ${Style.color.brandDanger};

    svg, i {
      color: white;
      fill: white;
    }
  `}
`

const PreviewActions = styled.div`
  position: absolute;
  bottom: 5px;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${PreviewAction}:not(:last-child) {
    margin-right: ${Style.spacing.x1};
  }
`

const AttachmentExpand = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 44px;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${Style.color.brandPrimary};
	color: white;
	border-radius: 50%;
	transition: transform 0.1s ease 0s;
	transform: translate(-50%, -50%) scale(0);

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		transform: translate(-50%, -50%) scale(1);
	}

	i {
		font-size: 20px;
		fill: currentColor;
	}
`

const AttachmentPreviewContainer = styled.div`
	position: relative;
	max-width: 100%;
  min-height: 250px;
	padding-bottom: 31px;
	cursor: pointer;
  display: none;

	&:hover {
		${AttachmentExpand} {
			transform: translate(-50%, -50%) scale(1);
		}
	}

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: block;
  }

  iframe {
    width: 100%;
    min-height: 250px;
  }
`

const AttachmentPreviewDelete = styled.a`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	background-color: ${Style.color.brandDanger};
	font-weight: 600;
	color: #FFF;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
`

interface IStateToProps {
  currentUser: CurrentUser
  document?: Document
  onSubmit?: (document: Document) => void
}

interface IDispatchToProps {
  close: typeof closeDocumentModal
  showAttachmentsViewerModal: typeof showAttachmentsViewerModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation & RouteComponentProps<any>

interface IState {
  didInitialLoad: boolean
  document: Document | null
  errors: any
  isSubmitting: boolean
}

class DocumentModal extends React.Component<IProps, IState> {
  private netAmountInput = React.createRef<MoneyInput>()
  private amountInput = React.createRef<MoneyInput>()
  private fileUploadInput = React.createRef<HTMLInputElement>()
  private invoicedOnInput = React.createRef<DateInputClass>()
  private paidOnInput = React.createRef<DateInputClass>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      document: null,
      errors: {},
      isSubmitting: false,
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onDateChange = this.onDateChange.bind(this);
    this.onBookedOnChange = this.onBookedOnChange.bind(this);
    this.onDocumentTagsChange = this.onDocumentTagsChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onFileButtonClick = this.onFileButtonClick.bind(this);
    this.onFileDownloadClick = this.onFileDownloadClick.bind(this);
    this.onFileDeleteClick = this.onFileDeleteClick.bind(this);
    this.onFileExpandClick = this.onFileExpandClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onDocumentModalClose = this.onDocumentModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    try {
      const { document: propDocument } = this.props

      const response = await DocumentsController.getForm(propDocument.id)

      const { document } = response

      this.setState({
        document: {
          ...document,
          ...propDocument,
        },
        didInitialLoad: true
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  onNameChange(e) {
    const { document } = this.state;

    const newName = e.currentTarget.value;

    this.setState({
      document: {
        ...document,
        name: newName,
      }
    });
  }

  onDescriptionChange(e) {
    const { document } = this.state;

    const description = e.currentTarget.value

    this.setState({
      document: {
        ...document,
        description: description
      }
    })
  }

  onDateChange(value) {
    const { document } = this.state;

    const date = value ? moment(value).format('YYYY-MM-DD') : null

    this.setState({
      document: {
        ...document,
        date: date,
      },
    })
  }

  onBookedOnChange(value) {
    const { document } = this.state;

    const date = value ? moment(value).format('YYYY-MM-DD') : null

    this.setState({
      document: {
        ...document,
        booked_on: date,
      },
    })
  }

  onDocumentTagsChange(tagIds: string[]) {
    const { document } = this.state;

    this.setState({
      document: {
        ...document,
        tag_ids: tagIds
      }
    })
  }

  async onFileChange(files) {
    const { document } = this.state;

    const file = files[0];

    ActiveStorageController.upload(file, async (error, blob) => {
      if (error) { console.error(error) }
      if (blob) {
        const { url } = await ActiveStorageController.getBlobUrl(blob)

        this.setState({
          document: {
            ...document,
            name: document.name || file.name,
            attachment: blob.signed_id,
            attachment_file_name: blob.filename,
            attachment_content_type: blob.content_type,
            attachment_file_size: blob.byte_size,
            attachment_url: url,
          }
        })
      }
    })
  }

  onFileButtonClick(e) {
    e.preventDefault();
    this.fileUploadInput.current.click();
  }

  onFileDownloadClick() {
    const { document } = this.state

    if (document.attachment_download_url) {
      saveAs(document.attachment_download_url, document.attachment_file_name)
    }
  }

  onFileDeleteClick(e) {
    e.preventDefault()
    e.stopPropagation()

    const { document } = this.state;

    this.setState({
      document: {
        ...document,
        attachment: null,
        attachment_url: null,
      }
    });
  }

  onFileExpandClick(e) {
    e.preventDefault()
    const { t } = this.props
    const { document } = this.state

    const attachments: PreviewAttachment[] = [
      {
        id: document.id,
        name: document.attachment_file_name,
        url: document.attachment_url,
        file_size: document.attachment_file_size,
        content_type: document.attachment_content_type,
        created_at: document.created_at
      }
    ]

    this.props.showAttachmentsViewerModal({
      attachments: attachments,
      actions: [{
        icon: 'external-link', text: t('DocumentModal::Open in new tab'), onClick: (selectedIndex: number) => {
          const selectedAttachment = attachments[selectedIndex]

          if (selectedAttachment) window.open(selectedAttachment.url, "_blank");
        }
      }]
    })
  }

  onFormSubmit(e?: any) {
    if (e) e.preventDefault();
    const { document } = this.state;
    const { close, onSubmit, t } = this.props

    this.setState({ isSubmitting: true })
    if (document.id) { // Do update
      DocumentsController
        .update(document)
        .then(response => {
          const { errors, error_full_messages } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('DocumentModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('DocumentModal::Document successfully updated'))
            if (onSubmit) onSubmit(response)
            close()
          }

          this.setState({ isSubmitting: false })
        })
        .catch(error => console.error(error))
    }
    else {
      DocumentsController
        .create(document)
        .then(response => {
          const { errors } = response;

          if (errors) {
            this.setState({
              errors: errors
            });
            Notification.notifyError(t('DocumentModal::Oops something went wrong'))
          }
          else {
            Notification.notifySuccess(t('DocumentModal::Document successfully created'))
            if (onSubmit) onSubmit(response)
            close()
          }

          this.setState({ isSubmitting: false })
        })
        .catch(console.error)
    }
  }

  onDocumentModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderDetails() {
    const { currentUser: { workspace: { setting } }, t } = this.props
    const { document } = this.state

    return (
      <>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('DocumentModal::Name')} <span>*</span>
              </label>
              <input
                type='text'
                name='name'
                value={document.name}
                onChange={this.onNameChange}
                placeholder={t('DocumentModal::Name')}
                autoComplete='off'
                required
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('DocumentModal::Date')} <span>*</span>
              </label>
              <DateInput
                name='date'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(document.date)}
                onChange={this.onDateChange}
                inputProps={{ placeholder: setting.date_format }}
                closeOnSelect={true}
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('DocumentModal::Booked on')}

                <Tooltip
                  content={t('DocumentModal::The date the document was booked in your accounting software. Note: Using integrations sets this date automatically.')}
                  containerStyle={{ marginLeft: 8 }}
                />
              </label>

              <DateInput
                name='booked_on'
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={moment(document.booked_on)}
                onChange={this.onBookedOnChange}
                inputProps={{ placeholder: setting.date_format }}
                closeOnSelect={true}
              />
            </div>
          </div>

          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>
                {t('DocumentModal::Description')}
              </label>
              <textarea
                onChange={this.onDescriptionChange}
                value={document.description}
                placeholder={t('DocumentModal::Enter a description here...')}
              />
            </div>
          </div>
        </div>

        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('DocumentModal::Tags')}</label>
              <ResourceCreatablePowerSelect
                type='document_tag'
                value={document.tag_ids}
                onChange={this.onDocumentTagsChange}
                isClearable={true}
                placeholder={t('DocumentModal::Select tags...')}
                isMulti
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  renderAttachment() {
    const { t } = this.props
    const { document } = this.state

    return (
      <>
        {!document.attachment_url && <MobileDocumentModalDropzone>
          <ResourceModalDropzone
            onDrop={this.onFileChange}
            accept={DocumentHelper.getDropzoneMimeTypes()}
          />
        </MobileDocumentModalDropzone>}

        {document.attachment_url && <AttachmentPreviewContainer onClick={this.onFileExpandClick}>
          <AttachmentPreviewer
            filename={document.attachment_file_name}
            source={document.attachment_url}
            contentType={document.attachment_content_type}
          />
          {!UrlHelper.isDataUrl(document.attachment_url) && <AttachmentExpand>
            <Icon icon='expand' />
          </AttachmentExpand>}

          <AttachmentPreviewDelete href='javascript://' onClick={this.onFileDeleteClick}>
            {t('DocumentModal::Delete')}
          </AttachmentPreviewDelete>
        </AttachmentPreviewContainer>}
      </>
    )

  }

  render() {
    const { t } = this.props
    const { document, didInitialLoad, errors, isSubmitting } = this.state

    return (
      <Container>
        {!didInitialLoad && <LoaderContainer>
          <Loader
            color='white'
            size='large'
          />
        </LoaderContainer>}
        {didInitialLoad && <>
          <PreviewContainer>
            {!document.attachment_url && <PreviewDropzoneContainer>
              <ResourceModalDropzone
                onDrop={this.onFileChange}
                accept={DocumentHelper.getDropzoneMimeTypes()}
              />
            </PreviewDropzoneContainer>}
            {document.attachment_url && <PreviewWrapper noPadding={document.attachment_content_type === 'application/pdf'}>
              <AttachmentPreviewer
                filename={document.attachment_file_name}
                source={document.attachment_url}
                contentType={document.attachment_content_type}
              />
            </PreviewWrapper>}


            {document.attachment_url && <PreviewActions>
              <PreviewAction onClick={this.onFileDownloadClick} data-tip={t('DocumentModal::Download')}>
                <Icon icon='download-circle' />
              </PreviewAction>
              <PreviewAction onClick={this.onFileDeleteClick} destructive data-tip={t('DocumentModal::Remove attachment')}>
                <Icon icon='trash' />
              </PreviewAction>
            </PreviewActions>}
          </PreviewContainer>

          <SidebarContainer>
            <SidebarHeader>
              {document.id ? t('DocumentModal::Edit document') : t('DocumentModal::Create document')}
              <SidebarClose onClick={this.onDocumentModalClose} />
            </SidebarHeader>

            <SidebarDivider />

            <SidebarContent>
              <form onSubmit={this.onFormSubmit}>
                {this.renderDetails()}
                {this.renderAttachment()}
                <input type='submit' style={{ display: 'none' }} />
              </form>
            </SidebarContent>

            <SidebarDivider />

            <SidebarFooter>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />

                <Button
                  type='success'
                  text={t('DocumentModal::Save')}
                  isLoading={isSubmitting}
                  onClick={this.onFormSubmit}
                  style={{ width: '100%' }}
                />
              </div>
            </SidebarFooter>
          </SidebarContainer>
        </>}
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      documentModal: {
        document,
        onSubmit,
      }
    }
  } = state

  return {
    document: document,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeDocumentModal()),
    showAttachmentsViewerModal: (options) => dispatch(showAttachmentsViewerModal(options)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DocumentModal)))