import * as React from 'react'
import { NavigateAction, ToolbarProps, View } from 'react-big-calendar'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Style } from '../../styles'

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 4px;
	background: #F7F7F7;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

const Title = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 24px;
	line-height: 35px;
	padding: 15px;
	margin: 0px;
	font-weight: 700;
	width: 100%;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		padding: 5px;
	}
`

const NavigateButton = styled.svg`
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translate(0%, -50%);
	border-radius: 100%;
	z-index: 1;

	path {
		color: #9a9a9a;
	}

	&:hover {
		background: #E3E3E3;

	}

	@media print {
		display: none;
	}
`

const NavigateButtonLeft = styled(NavigateButton)`
	left: unset;
	right: 75%;
	transform: translate(0%, -50%) rotate(180deg);
`

const NavigateButtonRight = styled(NavigateButton)`
	right: unset;
	left: 75%;
`


type IProps = ToolbarProps & { hide?: boolean }

const CalendarToolbar = (props: IProps) => {
	const { t } = useTranslation()
	let {
		view,
		label,
		hide
	} = props

	if (['week', 'agenda'].includes(view) || hide) return null

	const navigate = (action: NavigateAction) => {
		props.onNavigate(action)
	}

	return (
		<Container>
			<NavigateButtonLeft onClick={() => navigate('PREV')} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="m10.75 8.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></NavigateButtonLeft>
			<Title>
				{label}
			</Title>
			<NavigateButtonRight onClick={() => navigate('NEXT')} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="40" width="40" aria-hidden="true" ><path d="m10.75 8.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></NavigateButtonRight>
		</Container>
	)
}

export { Title, NavigateButton }
export default CalendarToolbar