import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Contact } from '../../types'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'

interface IProps {
	activator: JSX.Element
	contactId?: string
	contactDisabled?: boolean
	onSubmit: (contactId?: string, contact?: Contact) => void
}

const ContactPopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator, contactDisabled } = props

	const [popoverActive, setPopoverActive] = React.useState(false)
	const [contactId, setcontactId] = React.useState(null)

	const onTogglePopover = () => {
		if (!popoverActive) {
			setcontactId(props.contactId)
		}

		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
	}

	const onContactChange = (contactId, assignee) => {
		setcontactId(contactId)
	}

	const onFormSubmit = () => {
		// Submit form
		props.onSubmit(contactId)

		// Close popover
		onPopoverClose()
	}

	const onRemoveClick = () => {
		// Submit form
		props.onSubmit(null)

		// Close popover
		onPopoverClose()
	}

	return (
		<Popover
			active={popoverActive}
			activator={
				<div onClick={onTogglePopover}>
					{activator}
				</div>
			}
			onClose={onPopoverClose}
			placement='bottom-start'
		>
			<TaskPopoverContainer>
				<TaskPopoverHeader>
					<TaskPopoverTitle>{t('TaskModal::Contact')}</TaskPopoverTitle>
				</TaskPopoverHeader>
				<TaskPopoverContent>
					<ResourceCreatablePowerSelect
						type='contact'
						value={contactId}
						params={{ archived: false }}
						onChange={onContactChange}
						isClearable={true}
						isDisabled={Boolean(contactDisabled)}
					/>

					{!contactDisabled && <>
						<TaskButton
							onClick={onFormSubmit}
							style={{ marginTop: 12 }}
							success
							center
						>
							{t('TaskModal::Save')}
						</TaskButton>

						<TaskButton
							onClick={onRemoveClick}
							style={{ marginTop: 8 }}
							center
						>
							{t('TaskModal::Remove')}
						</TaskButton>
					</>}
				</TaskPopoverContent>
			</TaskPopoverContainer>
		</Popover>
	)
}

export default ContactPopover