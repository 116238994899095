import styled, { css } from 'styled-components'

const EditorContainer = styled.div<{ toolbarBottom?: boolean }>`
	.fr-toolbar {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;

		${props => props.toolbarBottom && css`
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			position: absolute;
			left: 0;
			right: 0;
		`}
	}

	.fr-wrapper {
	}

	.fr-second-toolbar {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
`

export default EditorContainer