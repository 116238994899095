import * as React from 'react'
import { ActiveStorageController, DocumentsController } from '../../controllers'
import Notification from '../../utilities/Notification'
import PowerSelect from '../Form/PowerSelect'
import ModalHeader from './Parts/ModalHeader'
import ModalLoader from './Parts/ModalLoader'
import ModalMiddle from './Parts/ModalMiddle'
import ModalWindow from './Parts/ModalWindow'
import ModalContent from './Parts/ModalContent'
import TooltipError from '../Tooltips/ErrorTooltip'
import Button from '../Button/Button'
import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { closeUploadDocumentsModal } from '../../store/modals/actions'
import { CurrentUser, Document, ExportTimePeriod } from '../../types'
import moment from '../../utilities/Moment'
import { AppState } from '../../store'
import Dropzone from '../Dropzone/Dropzone'
import ExpenseHelper from '../../helpers/ExpenseHelper'
import { DropEvent, FileRejection } from 'react-dropzone'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import styled from 'styled-components'
import PageLoader from '../Page/PageLoader'
import DocumentHelper from '../../helpers/DocumentHelper'

const DropzoneContainer = styled.div`
  > div {
    margin: 0;
    min-height: 250px;
  }

`

interface IStateToProps {
  currentUser: CurrentUser
  onSubmit: (documents: Document[]) => void
}

interface IDispatchToProps {
  close: typeof closeUploadDocumentsModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  tagIds: string[]
  errors: any
  isSubmitting: boolean
}

class UploadDocumentsModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      tagIds: [],
      errors: {},
      isSubmitting: false,
    }

    this.onDocumentTagsChange = this.onDocumentTagsChange.bind(this)
    this.onFileDrop = this.onFileDrop.bind(this)
    this.onDocumentExportModalClose = this.onDocumentExportModalClose.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    requestAnimationFrame(() => { this.setState({ didInitialLoad: true }) })
  }

  onDocumentTagsChange(tagIds: string[]) {
    this.setState({ tagIds: tagIds })
  }

  onFileDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    const { tagIds } = this.state
    const promises = acceptedFiles.map(async (file: any) => {
      try {
        const blob = await ActiveStorageController.uploadPromise(file)

        const newDocument: Document = {
          name: file.name,
          date: moment().format('YYYY-MM-DD'),
          tag_ids: tagIds,
          attachment: blob.signed_id,
          attachment_file_name: blob.filename,
          attachment_content_type: blob.content_type,
          attachment_file_size: blob.byte_size,
        }

        return await DocumentsController.create(newDocument)
      } catch (ex) {
        console.error(ex)
      }
    })

    this.setState({ isSubmitting: true })
    Promise.all(promises)
      .then(responses => {
        this.props.onSubmit(responses)
        this.onDocumentExportModalClose()
      })
      .catch(console.error)
      .finally(() => {
        this.setState({ isSubmitting: false })
      })
  }

  onDocumentExportModalClose() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  render() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const {
      didInitialLoad,
      tagIds,
      isSubmitting
    } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={t('UploadDocumentsModal::Upload Documents')}
          onCloseClick={this.onDocumentExportModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('DocumentModal::Tags')}</label>
                    <ResourceCreatablePowerSelect
                      type='document_tag'
                      value={tagIds}
                      onChange={this.onDocumentTagsChange}
                      isClearable={true}
                      placeholder={t('DocumentModal::Select tags...')}
                      isMulti
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('DocumentModal::Files')}</label>
                    <DropzoneContainer>
                      {isSubmitting && <PageLoader />}

                      {!isSubmitting && <Dropzone
                        dropzoneProps={{
                          onDrop: this.onFileDrop,
                          multiple: true,
                          accept: DocumentHelper.getDropzoneMimeTypes(),
                        }}
                      />}
                    </DropzoneContainer>
                  </div>
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      uploadDocumentsModal: {
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeUploadDocumentsModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UploadDocumentsModal))