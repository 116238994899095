import * as React from 'react'
import * as ActionCable from 'actioncable'

interface IProps {
	cable: ActionCable.Cable
	children: React.ReactNode
}

export const ActionCableContext = React.createContext<{ cable: ActionCable.Cable } | undefined>(undefined)

const ActionCableProvider = (props: IProps) => {
	const value = { cable: props.cable }

	return (
		<ActionCableContext.Provider value={value}>
			{props.children}
		</ActionCableContext.Provider>
	)
}

export const useActionCable = () => {
	const context = React.useContext(ActionCableContext)

	if (context === undefined) {
		throw new Error('useActionCable must be used within a ActionCableProvider')
	}

	return context
}

export default ActionCableProvider