import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'

interface IProps {
	activator: JSX.Element
	assigneeIds?: string[]
	onSubmit: (assigneeIds: string[]) => void
}

const AssigneesPopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator } = props

	const [popoverActive, setPopoverActive] = React.useState(false)
	const [assigneeIds, setAssigneeIds] = React.useState<string[]>([])

	const onTogglePopover = () => {
		if (!popoverActive) {
			setAssigneeIds(props.assigneeIds)
		}

		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
	}

	const onAssigneeChange = (assigneeIds, assignees) => {
		setAssigneeIds(assigneeIds ? assigneeIds : [])
	}

	const onFormSubmit = () => {
		// Submit form
		props.onSubmit(assigneeIds)

		// Close popover
		onPopoverClose()
	}

	const onRemoveClick = () => {
		// Submit form
		props.onSubmit([])

		// Close popover
		onPopoverClose()
	}

	return (
		<Popover
			active={popoverActive}
			activator={
				<div onClick={onTogglePopover}>
					{activator}
				</div>
			}
			onClose={onPopoverClose}
			placement='bottom-start'
		>
			<TaskPopoverContainer>
				<TaskPopoverHeader>
					<TaskPopoverTitle>{t('TaskModal::Assignees')}</TaskPopoverTitle>
				</TaskPopoverHeader>
				<TaskPopoverContent>
					<ResourceCreatablePowerSelect
						type='user'
						value={assigneeIds}
						onChange={onAssigneeChange}
						isClearable={true}
						isMulti={true}

						placeholder={t('TaskModal::Add an assignee')}
						isValidNewOption={() => false}
					/>

					<TaskButton
						onClick={onFormSubmit}
						style={{ marginTop: 12 }}
						success
						center
					>
						{t('TaskModal::Save')}
					</TaskButton>

					<TaskButton
						onClick={onRemoveClick}
						style={{ marginTop: 8 }}
						center
					>
						{t('TaskModal::Remove')}
					</TaskButton>
				</TaskPopoverContent>
			</TaskPopoverContainer>
		</Popover>
	)
}

export default AssigneesPopover