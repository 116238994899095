import Api from './Api';
import Utils from '../utilities/Utils';
import { ReferralSource } from '../types';

export default class ReferralSourcesController extends Api {
	static getSources(params: object) {
		return fetch(`/api/referral_sources?${Utils.encodeQueryData(params)}`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		})
			.then(res => res.json())
	}

	static create(referralSource: ReferralSource) {
		return fetch('/api/referral_sources', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ referral_source: referralSource })
		})
			.then(res => res.json())
	}

	static update(referralSource: ReferralSource) {
		return fetch(`/api/referral_sources/${referralSource.id}`, {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
			body: JSON.stringify({ referral_source: referralSource })
		})
			.then(res => res.json())
	}

	static delete(id: string) {
		return fetch(`/api/referral_sources/${id}`, {
			'method': 'DELETE',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Authorization': this.token
			},
			credentials: 'same-origin',
		}).then(res => res.json())
	}
}