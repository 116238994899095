import * as queryString from 'query-string'

export default class UrlHelper {
	static getParams(url: string): any {
		return queryString.parse(url)
	}

	static isDataUrl(url: string) {
		const regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
		return !!url.match(regex);
	}
}