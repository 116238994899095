import * as React from 'react'
import Icon from '../Icons/Icon'
import ReactTooltip from 'react-tooltip'

interface IProps {
  content: string
  containerStyle?: React.CSSProperties
}

const Tooltip = (props: IProps) => {
  const { content, containerStyle } = props

  React.useEffect(() => {
    ReactTooltip.rebuild()
  })

  return (
    <span style={containerStyle} >
      <span data-tip={content}>
        <Icon icon='info' className='tooltip-info-icon' />
      </span>
    </span>
  )
}

export default Tooltip