import * as React from "react";
import copy from "copy-to-clipboard";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import styled, { css } from "styled-components";
import Loader from "../Loaders/Loader";
import { GOOGLE_MAPS_KEY } from "../../Constants";
import { Style } from "../../styles";
import Icon from "../Icons/Icon";
import { useTranslation } from "react-i18next";
import Notification from "../../utilities/Notification";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 350px;
  width: 100%;
`;

const MapWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LocationActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Location = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${Style.spacing.x1} ${Style.spacing.x2};
`;

const LocationAction = styled.div<{ hideDesktop?: boolean }>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border-color: grey;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #82828c;
  }

  i {
    color: #82828c;
  }

  ${(props) =>
    props.hideDesktop &&
    css`
      display: none;

      @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        display: flex;
      }
    `}
`;

interface IProps {
  location: string;
}

interface IState {
  center: google.maps.LatLngLiteral;
}

const DEFAULT_CENTER = { lat: 51.2092, lng: 3.2248 };

const Map = (props: IProps) => {
  const { t } = useTranslation();
  const { location } = props;
  const { isLoaded: mapLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_KEY,
    libraries: ['places']
  });
  const [state, setState] = React.useState<IState>({
    center: DEFAULT_CENTER,
  });
  const { center } = state;

  const fetchLongitudeAndLatitude = async (address: string) => {
    try {
      const geocoder = new google.maps.Geocoder();
      const response = await geocoder.geocode({ address: location });

      if (response?.results?.length > 0) {
        const { lat, lng } = response.results[0].geometry.location;

        setState({
          ...state,
          center: { lat: lat(), lng: lng() },
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  React.useEffect(() => {
    if (mapLoaded && location?.length > 0) {
      fetchLongitudeAndLatitude(location).catch(console.error);
    }
  }, [mapLoaded, location]);

  const onMapLoad = (map) => { };

  const onCopyClick = () => {
    copy(location);

    Notification.notifySuccess(t("Map::Copied to clipboard"));
  };

  const onGoogleMapsClick = () => {
    window.open(
      encodeURI(
        `https://www.google.com/maps/dir/?api=1&destination=${location}`
      ),
      "_blank"
    );
  };

  const onWazeClick = () => {
    window.open(encodeURI(`https://www.waze.com/ul?q=${location}`));
  };

  return (
    <Container>
      <MapContainer>
        {!mapLoaded && <Loader />}
        {mapLoaded && (
          <MapWrapper>
            <GoogleMap
              center={center}
              zoom={10}
              mapContainerStyle={{
                width: "100%",
                height: "100%",
              }}
              onLoad={onMapLoad}
              options={{
                controlSize: 26,
                mapTypeControl: false,
                streetViewControl: false,
              }}
            >
              <Marker position={center} />
            </GoogleMap>
          </MapWrapper>
        )}
      </MapContainer>
      <Location>
        {location}

        <LocationActions>
          <LocationAction data-tip={t("Map::Copy")} onClick={onCopyClick}>
            <Icon icon="copy" />
          </LocationAction>
          <LocationAction
            data-tip={t("Map::Open in Google Maps")}
            onClick={onGoogleMapsClick}
          >
            <Icon icon="map-location-dot" />
          </LocationAction>
          <LocationAction
            data-tip={t("Map::Open in Waze")}
            onClick={onWazeClick}
            hideDesktop
          >
            <Icon icon="waze" />
          </LocationAction>
        </LocationActions>
      </Location>
    </Container>
  );
};

export default Map;
