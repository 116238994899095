import * as React from 'react'
import { closeContentBlockTemplateModal, showContactModal, showConfirmModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import Notification from '../../utilities/Notification'
import { ResourceContentBlockTemplatesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import { ContentBlockTemplate, CurrentUser } from '../../types'
import ModalFooterActionIcons from './Parts/ModalFooterActionIcons'
import ModalFooterActionIcon from './Parts/ModalFooterAction'

interface IStateToProps {
  contentBlockTemplate: ContentBlockTemplate
  contentBlockId: string
  onSubmit?: (contentBlockTemplate: ContentBlockTemplate) => void
  onDelete?: (contentBlockTemplate: ContentBlockTemplate) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeContentBlockTemplateModal,
  showConfirmModal: typeof showConfirmModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  contentBlockTemplate: ContentBlockTemplate
  errors: any
}

class ContentBlockTemplateModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      contentBlockTemplate: null,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.onDeleteTemplateClick = this.onDeleteTemplateClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  async fetchForm() {
    const { contentBlockTemplate } = this.props

    try {
      const response = await ResourceContentBlockTemplatesController.getForm({ id: contentBlockTemplate.id })
      const { content_block_template } = response

      this.setState({
        contentBlockTemplate: {
          ...content_block_template,
          ...contentBlockTemplate,
        },
        didInitialLoad: true,
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  onNameChange(e) {
    const { contentBlockTemplate } = this.state

    this.setState({
      contentBlockTemplate: {
        ...contentBlockTemplate,
        name: e.currentTarget.value
      }
    })
  }

  async onDeleteTemplateClick() {
    const { t, onDelete, close } = this.props
    const { contentBlockTemplate } = this.state

    this.props.showConfirmModal({
      title: t('ContentBlockTemplateModal::Delete template'),
      description: t('ContentBlockTemplateModal::You are about to delete this template. Are you sure?'),
      action: {
        label: t('ContentBlockTemplateModal::Delete'),
        isDestructive: true,
      },
      onConfirm: async () => {
        try {
          const response = await ResourceContentBlockTemplatesController.delete(contentBlockTemplate.id)

          if (response.success) {
            if (onDelete) onDelete(contentBlockTemplate)
            close()
          }
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { contentBlockTemplate } = this.state;
    const { close, onSubmit, t, contentBlockId } = this.props

    if (contentBlockTemplate.id) {
      const response = await ResourceContentBlockTemplatesController.update(contentBlockTemplate)
      const { errors } = response;

      if (errors) {
        this.setState({
          errors: errors
        });
        Notification.notifyError(t('ContentBlockTemplateModal::Oops something went wrong'))
      }
      else {
        Notification.notifySuccess(t('ContentBlockTemplateModal::Template successfully updated'))
        if (onSubmit) onSubmit(response)
        close()
      }
    }
    else {
      const response = await ResourceContentBlockTemplatesController.create(contentBlockTemplate, contentBlockId)
      const { errors } = response;

      if (errors) {
        this.setState({
          errors: errors
        });
        Notification.notifyError(t('ContentBlockTemplateModal::Oops something went wrong'))
      }
      else {
        Notification.notifySuccess(t('ContentBlockTemplateModal::Template successfully created'))
        if (onSubmit) onSubmit(response)
        close()
      }
    }
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  render() {
    const { close, t } = this.props
    const { didInitialLoad, errors, contentBlockTemplate } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={contentBlockTemplate?.id ? t('ContentBlockTemplateModal::Edit template') : t('ContentBlockTemplateModal::Create template')}
          onCloseClick={close}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('ContentBlockTemplateModal::Name')} <span>*</span></label>
                    <input
                      type='text'
                      value={contentBlockTemplate?.name}
                      onChange={this.onNameChange}
                      placeholder={t('ContentBlockTemplateModal::Name')}
                    />
                  </div>
                </div>
              </div>
              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <ModalFooterActionIcons>
              {contentBlockTemplate?.id && <ModalFooterActionIcon
                icon='trash'
                active={false}
                onClick={this.onDeleteTemplateClick}
                tooltip={t('ContentBlockTemplateModal::Delete template')}
              />}
            </ModalFooterActionIcons>
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <a href='javascript://' className='button button-success' onClick={this.onFormSubmit}>
                  {t('ContentBlockTemplateModal::Save')}
                </a>
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      contentBlockTemplateModal: {
        contentBlockTemplate,
        contentBlockId,
        onSubmit,
        onDelete,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    contentBlockTemplate: contentBlockTemplate,
    contentBlockId: contentBlockId,
    onSubmit: onSubmit,
    onDelete: onDelete
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeContentBlockTemplateModal()),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContentBlockTemplateModal))