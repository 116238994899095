import * as React from 'react'
import PageLoader from '../../Page/PageLoader'
import AttachmentPreviewer from './AttachmentsPreviewer'

interface IProps {
	filename: string
	source: string
	contentType: string
}

interface IState {
	isLoaded: boolean
	contentType: string
	base64Content: string
}

/**
 * This is a component that attempts to render the attachment from a remote file
 * inside of the XML and display it
 * @param props 
 * @returns 
 */
const XMLEmbedPreview = (props: IProps) => {
	const { filename } = props
	const [state, setState] = React.useState<IState>({
		isLoaded: false,
		contentType: null,
		base64Content: null
	})
	const { isLoaded, contentType, base64Content } = state

	const fetchXML = async () => {
		try {
			const response = await fetch(props.source);
			if (response.ok) {
				const result = await response.text();
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(result, "text/xml");

				// Check if the XML is an invoice or a credit note
				if (xmlDoc.getElementsByTagName('Invoice').length > 0 || xmlDoc.getElementsByTagName('CreditNote').length > 0) {
					const attachment = xmlDoc.getElementsByTagName('cbc:EmbeddedDocumentBinaryObject')[0]

					if (attachment) {
						const contentType = attachment?.getAttribute('mimeCode')
						const base64Content = attachment?.textContent

						setState({ isLoaded: true, contentType: contentType, base64Content: base64Content })
					} else {
						setState({ ...state, isLoaded: true })
					}
				} else {
					setState({ ...state, isLoaded: true })
				}
			}
		} catch (ex) {
			console.error(ex)
			setState({ ...state, isLoaded: true })
		}
	}

	React.useEffect(() => {
		if (!isLoaded) fetchXML().catch(console.error)
		return () => { }
	}, [isLoaded])

	const renderPreview = () => {
		switch (contentType) {
			case 'application/pdf':
				return (
					<AttachmentPreviewer
						filename={filename}
						contentType={contentType}
						source={`data:${contentType};base64,${base64Content}`}
					/>
				)
			// Fallback to the regular atachment previewer
			default: return <AttachmentPreviewer
				filename={filename}
				contentType='unknown'
				source={props.source}
			/>
		}
	}

	return (
		<>
			{!isLoaded && <PageLoader />}
			{isLoaded && renderPreview()}
		</>
	)
}

export default XMLEmbedPreview