import * as React from 'react'
import { closeDealStageModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Button from '../Button/Button'
import { DealStagesController } from '../../controllers'
import { CurrentUser, DisplayableError, DealStage, DealStageAction, DealStatus } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'
import PowerSelect from '../Form/PowerSelect'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import Tooltip from '../Tooltips/Tooltip'
import DealHelper from '../../helpers/DealHelper'

interface IStateToProps {
  currentUser: CurrentUser
  dealStage?: DealStage
  onSubmit: (dealStage: DealStage) => void
}

interface IDispatchToProps {
  close: typeof closeDealStageModal
}

type IProps = IDispatchToProps & IStateToProps

interface IState {
  didInitialLoad: boolean
  dealStage: DealStage
  currencies: { label: string, value: string }[]
  errors: DisplayableError[]
}

const DealStageModal = (props: IProps) => {
  const { t } = useTranslation()
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    dealStage: null,
    currencies: [],
    errors: [],
  })
  const { didInitialLoad, dealStage, currencies, errors } = state

  const statusOptions = DealHelper.getStatusOptions()
  const selectedStatusOption = statusOptions.find(option => option.value === dealStage?.status)

  const actionOptions = [
    { label: t(`DealStageAction::${DealStageAction.EVENT}`), value: DealStageAction.EVENT },
    { label: t(`DealStageAction::${DealStageAction.EMAIL}`), value: DealStageAction.EMAIL },
    { label: t(`DealStageAction::${DealStageAction.CALL}`), value: DealStageAction.CALL },
    { label: t(`DealStageAction::${DealStageAction.APPOINTMENT}`), value: DealStageAction.APPOINTMENT },
    { label: t(`DealStageAction::${DealStageAction.TASK}`), value: DealStageAction.TASK },
  ]
  const selectedActionOption = actionOptions.find(option => option.value === dealStage?.action)

  // @ts-ignore
  const inactivityAlertOptions = [...Array(180).keys()]
    .filter(day => day !== 0)
    .map(day => ({ label: t('DealStageModal::{{count}} day', { count: day }), value: day }))

  const selectedInactivityAlertOption = inactivityAlertOptions.find(option => option.value === dealStage?.inactivity_alert)

  React.useEffect(() => {
    getForm().catch(console.error)
  }, [])

  const getForm = async () => {
    try {
      const { deal_stage } = await DealStagesController.getForm({ id: props.dealStage ? props.dealStage.id : null })

      setState({
        ...state,
        dealStage: {
          ...deal_stage,
          ...props.dealStage,
        },
        currencies: currencies,
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onNameChange = (e) => {
    const newName = e.currentTarget.value

    setState({ ...state, dealStage: { ...dealStage, name: newName } })
  }

  const onStatusChange = (option) => {
    setState({ ...state, dealStage: { ...dealStage, status: option?.value } })
  }

  // const onSuccessRateChange = (successRate) => {
  //   setState({ ...state, dealStage: { ...dealStage, success_rate: successRate } })
  // }

  const onActionChange = (option) => {
    setState({ ...state, dealStage: { ...dealStage, action: option?.value } })
  }

  const onInactivityAlertChange = (option) => {
    setState({ ...state, dealStage: { ...dealStage, inactivity_alert: option?.value || null } })
  }

  const onCloseClick = () => {
    props.close()
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
      let response = null
      if (dealStage.id) {
        response = await DealStagesController.update(dealStage)
      } else {
        response = await DealStagesController.create(dealStage)
      }

      if (response.errors) {
        setState({ ...state, errors: response.errors })
      } else {
        props.onSubmit(response)
        props.close()
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  const onErrorsDismiss = () => {
    setState({ ...state, errors: [] })
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={dealStage?.id ? t('DealStageModal::Update stage') : t('DealStageModal::Create stage')}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            <div className='grid'>
              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>{t('DealStageModal::Name')} <span>*</span></label>
                  <input
                    type='text'
                    placeholder={t('DealStageModal::Name')}
                    onChange={onNameChange}
                    value={dealStage.name}
                  />
                </div>
              </div>

              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>
                    {t('DealStageModal::Status')}
                    <Tooltip
                      content={t('DealStageModal::Automatically assign a status to a deal when it reaches this stage.')}
                      containerStyle={{ marginLeft: 4 }}
                    />
                  </label>
                  <PowerSelect
                    options={statusOptions}
                    value={selectedStatusOption}
                    onChange={onStatusChange}
                    theme={ReactSelectTheme}
                    placeholder={t('DealStageModal::Add status')}
                  />
                </div>
              </div>

              {/* <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>{t('DealStageModal::Success rate')}</label>
                  <PercentInput
                    name='secondary_tax'
                    defaultValue={dealStage?.success_rate || ''}
                    placeholder='0 %'
                    onChange={onSuccessRateChange}
                    onBlur={onSuccessRateChange}
                  />
                </div>
              </div> */}
              <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>
                    {t('DealStageModal::Action')}
                    <Tooltip
                      content={t('DealStageModal::Moving a deal to this stage will prompt the follow-up action configured below for the deal.')}
                      containerStyle={{ marginLeft: 4 }}
                    />
                  </label>
                  <PowerSelect
                    options={actionOptions}
                    value={selectedActionOption}
                    onChange={onActionChange}
                    theme={ReactSelectTheme}
                    placeholder={t('DealStageModal::Add follow-up action')}
                    isClearable
                  />
                </div>
              </div>
              {![DealStatus.WON, DealStatus.LOST].includes(dealStage?.status) && <div className='grid-cell with-6col'>
                <div className='form-item'>
                  <label>
                    {t('DealStageModal::Inactivity alert')}
                    <Tooltip
                      content={t('DealStageModal::Setup an alert to be notified when inactivity on a deal exceeds the selected interval.')}
                      containerStyle={{ marginLeft: 4 }}
                    />
                  </label>
                  <PowerSelect
                    options={inactivityAlertOptions}
                    value={selectedInactivityAlertOption}
                    onChange={onInactivityAlertChange}
                    theme={ReactSelectTheme}
                    placeholder={t('DealStageModal::Add inactivity alert')}
                    isClearable
                  />
                </div>
              </div>}
            </div>

            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <div />
          <div key='main-action' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={onErrorsDismiss}
            />
            <Button
              type='success'
              text={dealStage?.id ? t('DealStageModal::Update stage') : t('DealStageModal::Create stage')}
              onClick={onFormSubmit}
            />
          </div>
        </div>
      </ModalMiddle>}
    </ModalWindow >
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      dealStageModal: {
        dealStage,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    dealStage: dealStage,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeDealStageModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DealStageModal)