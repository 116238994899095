import styled, { css } from 'styled-components'

const AvatarStack = styled.div<{ width: number }>`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  > div {
    overflow: hidden;
    margin-left: -8px;
    border: 2px solid #fff;

    ${props => props.width > 0 && css`
      margin-left: ${props.width / 3};
    `}
  }
`

export default AvatarStack