import * as React from 'react'
import { HeaderProps } from 'react-big-calendar'
import styled, { css } from 'styled-components'
import moment from '../../utilities/Moment'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8px 0;
	align-items: center;
`

const Weekday = styled.div`
	color: #333333;
	font-size: 14px;
	font-weight: 500;
`

const DayIndicator = styled.div<{ today?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 4px;
	font-weight: 800;
	font-size: 15px;
`

type IProps = HeaderProps

const CalendarWeekHeader = (props: IProps) => {
	const { date } = props

	const dateMoment = moment(date)
	const isToday = dateMoment.isSame(moment(), 'day')

	return (
		<Container>
			<Weekday>{dateMoment.format('ddd')}</Weekday>
			<DayIndicator today={isToday}>{dateMoment.format('MMM D')}</DayIndicator>
		</Container>
	)
}

export default CalendarWeekHeader