import * as React from 'react'
import { closeEmailViewerModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { EmailsController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CurrentUser, Email } from '../../types'
import styled from 'styled-components'
import { Style } from '../../styles'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import moment from '../../utilities/Moment'

const EmailModalWindow = styled(ModalWindow)`
  max-width: 800px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}){
    max-width: 100%;
    width: 100%;
    
  }
`

const EmailHeaders = styled.div`
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
  border-bottom: 1px solid ${Style.color.border};
`

const EmailHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 16px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}){
    flex-direction: column;
  } 
`

const EmailHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: bold;
  margin-right: ${Style.spacing.x1};
  min-width: 60px;`

const Emails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  span {
    white-space: nowrap;
    margin-right: ${Style.spacing.x1};
  }
`

const EmailBody = styled.div`
  padding: ${Style.spacing.x2};
`



interface IStateToProps {
  email: Email
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeEmailViewerModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  email: Email | null
}

class EmailViewerModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      email: null,
    }

    this.fetchEmail = this.fetchEmail.bind(this)
    this.onEmailModalCloseClick = this.onEmailModalCloseClick.bind(this)
  }

  componentDidMount() {
    this.fetchEmail()
  }

  async fetchEmail() {
    const { email: propsEmail } = this.props

    try {
      const email = await EmailsController.getEmail(propsEmail.id)

      this.setState({ email: email, didInitialLoad: true })
    } catch (ex) {
      console.error(ex)
    }
  }

  onEmailModalCloseClick() {
    this.props.close()
  }

  render() {
    const { t, currentUser } = this.props
    const { didInitialLoad, email } = this.state
    const { workspace: { setting } } = currentUser

    return (
      <EmailModalWindow>
        <ModalHeader
          title={t('EmailViewerModal::Email')}
          onCloseClick={this.onEmailModalCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent style={{ padding: 0 }}>
            <EmailHeaders>
              <EmailHeader>
                <EmailHeaderTitle>
                  {t('EmailViewerModal::From:')}
                </EmailHeaderTitle>
                <Emails>
                  <span>{email?.from?.full}</span>
                </Emails>
              </EmailHeader>
              <EmailHeader>
                <EmailHeaderTitle>
                  {t('EmailViewerModal::To:')}
                </EmailHeaderTitle>
                <Emails>
                  {email?.to?.map((email, index) => (<span key={index}>{email?.full}</span>))}
                </Emails>
              </EmailHeader>
              {email?.cc?.length > 0 && <EmailHeader>
                <EmailHeaderTitle>
                  {t('EmailViewerModal::CC:')}
                </EmailHeaderTitle>
                <Emails>
                  {email.cc.map((email, index) => (<span key={index}>{email?.full}</span>))}
                </Emails>
              </EmailHeader>}
              <EmailHeader>
                <EmailHeaderTitle>
                  {t('EmailViewerModal::Date:')}
                </EmailHeaderTitle>
                <Emails>
                  {moment(email.created_at).format(`${setting.date_format} hh:mm a`)}
                </Emails>
              </EmailHeader>
              <EmailHeader>
                <EmailHeaderTitle>
                  {t('EmailViewerModal::Subject:')}
                </EmailHeaderTitle>
                <Emails>
                  {email.subject}
                </Emails>
              </EmailHeader>
            </EmailHeaders>

            <EmailBody>
              <FroalaEditorView model={email.raw_html} />
            </EmailBody>
          </ModalContent>
        </ModalMiddle>}
      </EmailModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      emailViewerModal: {
        email,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    email: email,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeEmailViewerModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailViewerModal))