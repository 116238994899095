import styled from 'styled-components'
import { Style } from '../../../styles'

const VariableListContainer = styled.div`
  position: relative;
  background: white;
  border: 1px solid ${Style.color.border};
  border-radius: ${Style.variables.baseBorderRadius};
`

export default VariableListContainer