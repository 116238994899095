import { DropResult } from "react-beautiful-dnd";
import { IPositionable } from "../types";

export default class DroppableHelper {
  static DEFAULT_POSITION = 16384;

  static getCreatePosition<T extends IPositionable>(droppables: T[]) {
    if (droppables.length === 0) return this.DEFAULT_POSITION;

    return droppables[droppables.length - 1].position + this.DEFAULT_POSITION;
  }

  static getCreatePositionFromIndex<T extends IPositionable>(
    items: T[],
    index: number
  ): number {
    if (items.length === 0) return this.DEFAULT_POSITION;

    const indexPositionable = items[index];
    const destinationPositionable = items[index + 1];

    if (destinationPositionable) {
      // Moved in between
      return (
        (indexPositionable.position + destinationPositionable.position) / 2
      );
    } else {
      // Moved to last position
      return indexPositionable.position + this.DEFAULT_POSITION;
    }
  }

  static getNewPositionBasedOnDropResult<T extends IPositionable>(
    items: T[],
    dropResult: DropResult
  ) {
    const { source, destination } = dropResult;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const movingDown = destinationIndex > sourceIndex;
    const destinationItem = items[destinationIndex];
    let destinationLeftNeighbourItem = items[destinationIndex - 1];
    let destinationRightNeighbourItem = items[destinationIndex + 1];

    // If moving down neighbours is different
    if (movingDown) {
      destinationLeftNeighbourItem = destinationItem;
    } else {
      destinationRightNeighbourItem = destinationItem;
    }
    // console.log('=== Items===')
    // console.log(items)
    // console.log('=== DROP RESULT ===')
    // console.log(dropResult)
    // console.log('=== Destination Item (should become right neighbour) ===')
    // console.log(destinationItem)
    // console.log('=== Left neighbour item ===')
    // console.log(destinationLeftNeighbourItem)
    // console.log('=== Right neighbour (neighbour) item ===')
    // console.log(destinationRightNeighbourItem)

    // Moved item to first position
    if (destinationItem && destinationIndex === 0) {
      return destinationItem.position / 2;
      // Moved item to last position
    } else if (destinationItem && destinationIndex === items.length - 1) {
      return destinationItem.position + this.DEFAULT_POSITION;
      // Moved item to in between position
    } else if (destinationLeftNeighbourItem && destinationRightNeighbourItem) {
      // Moved item in between 2 items
      return (
        (destinationLeftNeighbourItem.position +
          destinationRightNeighbourItem.position) /
        2
      );
    }
  }

  static getNewPositionBetweenListsBasedOnDropResult<T extends IPositionable>(
    items: T[],
    dropResult: DropResult
  ) {
    const { source, destination } = dropResult;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const movingDown = destinationIndex > sourceIndex;
    const destinationItem = items[destinationIndex];
    let destinationLeftNeighbourItem = items[destinationIndex - 1];
    let destinationRightNeighbourItem = destinationItem;

    // If moving down neighbours is different
    // console.log('=== Items===')
    // console.log(items)
    // console.log('=== DROP RESULT ===')
    // console.log(dropResult)
    // console.log('=== Destination Item (should become right neighbour) ===')
    // console.log(destinationItem)
    // console.log('=== Left neighbour item ===')
    // console.log(destinationLeftNeighbourItem)
    // console.log('=== Right neighbour (neighbour) item ===')
    // console.log(destinationRightNeighbourItem)

    // Moved item to first position
    if (destinationItem && destinationIndex === 0) {
      return destinationItem.position / 2;
      // Moved item to last position
    } else if (
      destinationLeftNeighbourItem &&
      destinationIndex === items.length
    ) {
      return destinationLeftNeighbourItem.position + this.DEFAULT_POSITION;
      // Moved item to in between position
    } else if (destinationLeftNeighbourItem && destinationRightNeighbourItem) {
      // Moved item in between 2 items
      return (
        (destinationLeftNeighbourItem.position +
          destinationRightNeighbourItem.position) /
        2
      );
    }
  }

  static sort<T extends IPositionable>(
    droppables: T[],
    sort: "ascending" | "descending"
  ): T[] {
    if (sort === "ascending") {
      return droppables.sort((c1, c2) => c1.position - c2.position);
    } else {
      return droppables.sort((c1, c2) => c2.position - c1.position);
    }
  }
}
