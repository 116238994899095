import * as React from 'react'
import Utils from '../../utilities/Utils'

export default class DocumentWrapper extends React.Component {
	private wrapper = React.createRef<HTMLDivElement>()
	private width?: number
	private height?: number

	constructor(props) {
		super(props)

		this.calculateWidth = Utils.debounce(this.calculateWidth.bind(this), 50)
	}

	componentDidMount() {
		this.calculateWidth()
	}

	componentWillMount() {
		window.addEventListener('resize', this.calculateWidth)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.calculateWidth)
	}

	calculateWidth() {
		if (this.wrapper.current) {
			const { width, height } = this.wrapper.current.getBoundingClientRect()

			this.width = width
			this.height = height
			this.forceUpdate()
		}
	}

	render() {
		return (
			<div ref={this.wrapper} className='document-wrapper'>
				{
					// @ts-ignore
					this.props.children({ width: this.width, height: this.height })
				}
			</div>
		)
	}
}