import * as React from 'react'

interface IProps {
	type: 'success' | 'info' | 'warning' | 'danger'
	text: string | JSX.Element
}

export default class Alert extends React.PureComponent<IProps> {
	render () {
		const { type, text } = this.props

		return (
			<div className={`alert alert-${type}`}>
				{text}
			</div>
		)
	}
}