import styled from 'styled-components'

const TaskPopoverFormActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	> div {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
`

export default TaskPopoverFormActions