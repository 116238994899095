import * as React from 'react'
import Badge from '../components/Badge/Badge'
import { IconTypes } from '../components/Icons/Icon'
import i18n from '../translations'
import { Project, ProjectBillableType, ProjectBudgetType, ProjectStatus } from '../types'

export default class ProjectHelper {
  static ACTIVE_STATUSES: ProjectStatus[] = [
    ProjectStatus.PROPOSAL,
    ProjectStatus.ACTIVE
  ]

  static ARCHIVED_STATUS: ProjectStatus[] = [
    ProjectStatus.COMPLETED,
    ProjectStatus.CANCELLED
  ]

  static getStatuses() {
    return [
      ...this.ACTIVE_STATUSES,
      ...this.ARCHIVED_STATUS
    ]
  }

  static getStatusTitle(status: ProjectStatus) {
    switch (status) {
      case ProjectStatus.PROPOSAL: return i18n.t('ProjectHelper::Proposal')
      case ProjectStatus.ACTIVE: return i18n.t('ProjectHelper::Active')
      case ProjectStatus.COMPLETED: return i18n.t('ProjectHelper::Completed')
      case ProjectStatus.CANCELLED: return i18n.t('ProjectHelper::Cancelled')
    }
  }

  static getStatusBadge(project: Project): JSX.Element | null {
    const text = ProjectHelper.getStatusTitle(project.status)

    switch (project.status) {
      case ProjectStatus.PROPOSAL: return <Badge type='primary' text={text} />
      case ProjectStatus.ACTIVE: return <Badge type='success' text={text} />
      case ProjectStatus.COMPLETED: return <Badge type='success' text={text} />
      case ProjectStatus.CANCELLED: return <Badge type='danger' text={text} />
    }

    return null
  }

  static hasActiveStatus(project: Project) {
    return this.ACTIVE_STATUSES.includes(project.status)
  }

  static getStatusOptions(excludeStatuses: ProjectStatus[] = []) {
    const statusOptions = [
      {
        label: i18n.t('ProjectHelper::Active Statuses'),
        value: 'active',
        options: [
          { label: this.getStatusTitle(ProjectStatus.PROPOSAL), value: ProjectStatus.PROPOSAL, icon: this.getStatusIcon(ProjectStatus.PROPOSAL) },
          { label: this.getStatusTitle(ProjectStatus.ACTIVE), value: ProjectStatus.ACTIVE, icon: this.getStatusIcon(ProjectStatus.ACTIVE) },
        ]
      },
      {
        label: i18n.t('ProjectHelper::Archived Statuses'),
        value: 'archived',
        options: [
          { label: this.getStatusTitle(ProjectStatus.COMPLETED), value: ProjectStatus.COMPLETED, icon: this.getStatusIcon(ProjectStatus.COMPLETED) },
          { label: this.getStatusTitle(ProjectStatus.CANCELLED), value: ProjectStatus.CANCELLED, icon: this.getStatusIcon(ProjectStatus.CANCELLED) },
        ]
      }
    ]

    statusOptions
      .forEach(option => {
        option.options = option.options.filter(o => !excludeStatuses.includes(o.value))
      })

    return statusOptions.filter(option => option.options.length > 0)
  }

  static getStatusIcon(status: ProjectStatus): IconTypes {
    switch (status) {
      case ProjectStatus.PROPOSAL:
      case ProjectStatus.ACTIVE:
        return 'heartbeat'
      case ProjectStatus.COMPLETED:
      case ProjectStatus.CANCELLED:
        return 'archive'
    }
  }

  static getStatusOption(statuses: ProjectStatus[], status: ProjectStatus) {
    const options = statuses.map(status => ({ label: this.getStatusTitle(status), value: status, icon: this.getStatusIcon(status) }))
    const selectedStatusOption = options.find(s => s.value === status)

    if (selectedStatusOption) return selectedStatusOption

    return null
  }

  static getProjectBillableTypeLabel(billableType: ProjectBillableType) {
    return i18n.t(`ProjectBillableType::${billableType}`)
  }

  static getProjectBillableTypeOptions(): { label: string, value: string }[] {
    return Object.keys(ProjectBillableType).map(key => (
      { label: i18n.t(`ProjectBillableType::${ProjectBillableType[key]}`), value: ProjectBillableType[key] }
    ))
  }

  static getProjectBudgetTypeLabel(budgetType: ProjectBudgetType) {
    return i18n.t(`ProjectBudgetType::${budgetType}`)
  }

  static getBudgetTypeOptions(): { label: string, value: string }[] {
    return Object.keys(ProjectBudgetType).map(key => (
      { label: i18n.t(`ProjectBudgetType::${ProjectBudgetType[key]}`), value: ProjectBudgetType[key] }
    ))
  }

  static isBillable(project: Project) {
    return project?.billable_type === ProjectBillableType.TIME_AND_MATERIALS
  }
}