import Mustache from "mustache";

export default class MustacheHelper {
	static process(content: string, variables = {}): string {
		try {
			return Mustache.render(content, variables)
		} catch (ex) {
			return content
		}
	}
}