import * as React from 'react'
import { closeDealModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Button from '../Button/Button'
import { DealsController } from '../../controllers'
import { CurrentUser, DisplayableError, Deal, Project } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'
import PowerSelect from '../Form/PowerSelect'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import NumberFormatter from '../../utilities/NumberFormatter'
import MoneyInput from '../Form/MoneyInput'
import moment from '../../utilities/Moment'
import DateInput from '../Form/DateInput'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import PercentInput from '../Form/PercentInput'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import Icon from '../Icons/Icon'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { NOTES_EDITOR_CONFIG } from '../Editor/Editor'
import CreatablePowerSelect from '../Form/CreatablePowerSelect'
import Tooltip from '../Tooltips/Tooltip'

export type DealModalTab = 'details' | 'notes' | 'planning'

interface IStateToProps {
  currentUser: CurrentUser
  deal?: Deal
  contactDisabled?: boolean
  projectDisabled?: boolean
  activeTab?: DealModalTab
  onSubmit: (deal: Deal) => void
}

interface IDispatchToProps {
  close: typeof closeDealModal
}

type IProps = IDispatchToProps & IStateToProps

interface IState {
  didInitialLoad: boolean
  activeTab: DealModalTab
  deal: Deal
  currencies: { label: string, value: string }[]
  errors: DisplayableError[]
}

const DealModal = (props: IProps) => {
  const { t } = useTranslation()
  const { currentUser, contactDisabled, projectDisabled } = props
  const { setting } = currentUser.workspace
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    activeTab: 'details',
    deal: null,
    currencies: [],
    errors: [],
  })
  const { didInitialLoad, deal, currencies, errors, activeTab } = state

  const currencyOptions = currencies.map(c => ({ label: c.label, value: c.value }))
  const selectedCurrencyOption = currencies.find(option => option.value === deal?.currency)

  React.useEffect(() => {
    getForm().catch(console.error)
  }, [])

  const getForm = async () => {
    try {
      const { deal, currencies } = await DealsController.getForm({ id: props.deal ? props.deal.id : null })

      setState({
        ...state,
        deal: {
          ...deal,
          ...props.deal,
        },
        currencies: currencies,
        didInitialLoad: true,
        activeTab: props.activeTab ? props.activeTab : activeTab,
      })
    } catch (ex) {
      console.error(ex)
    }
  }


  const onNameChange = (e) => {
    setState({ ...state, deal: { ...deal, name: e.currentTarget.value } })
  }

  const onDealContactChange = (value?: string, resource?: any) => {
    const contactId = value || null

    setState({
      ...state,
      deal: {
        ...deal,
        contact_id: contactId,
        project: null,
        project_id: null
      }
    })
  }

  const onDealProjectChange = (value?: string, resource?: Project) => {
    const projectId = value || null

    setState({
      ...state,
      deal: {
        ...deal,
        project_id: projectId,
        project: resource
      }
    })

  }

  const onAmountChange = (amount: number) => {
    setState({ ...state, deal: { ...deal, amount: amount } })
  }

  const onCurrencyChange = (option) => {
    setState({
      ...state,
      deal: {
        ...deal,
        currency: option.value
      }
    })
  }

  const onDealStageChange = (value, resource) => {
    setState({
      ...state,
      deal: { ...deal, deal_stage: resource, deal_stage_id: value }
    })
  }

  const onSuccessRateChange = (successRate) => {
    setState({ ...state, deal: { ...deal, success_rate: successRate } })
  }

  const onAssigneeChange = (assigneeId, assignee) => {
    setState({
      ...state,
      deal: {
        ...deal,
        assignee: assignee,
        assignee_id: assigneeId
      }
    })
  }

  const onDueOnChange = (value) => {
    const dueOn = moment(value);

    setState({
      ...state,
      deal: {
        ...deal,
        due_on: dueOn.isValid() ? dueOn.format('YYYY-MM-DD') : null
      }
    })
  }

  const onNotesChange = (updatedNotes) => {
    setState({
      ...state,
      deal: {
        ...state.deal,
        notes: updatedNotes
      }
    })
  }

  const onCloseClick = () => {
    props.close()
  }

  const onNavigationItemClick = (tab: DealModalTab) => {
    setState({ ...state, activeTab: tab })
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
      let response = null
      if (deal.id) {
        response = await DealsController.update(deal)
      } else {
        response = await DealsController.create(deal)
      }

      if (response.errors) {
        setState({ ...state, errors: response.errors })
      } else {
        if (props.onSubmit) props.onSubmit(response)
        props.close()
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  const onErrorsDismiss = () => {
    setState({ ...state, errors: [] })
  }

  const renderNavigation = () => {
    return (
      <ModalNavigation>
        <ModalNavigationItem active={activeTab === 'details'} onClick={() => onNavigationItemClick('details')}>
          <Icon icon='info' />
          <span>
            {t('DealModal::Details')}
          </span>
        </ModalNavigationItem>

        <ModalNavigationItem active={activeTab === 'notes'} onClick={() => onNavigationItemClick('notes')}>
          <Icon icon='pencil' />
          <span>
            {t('DealModal::Notes')}
          </span>
        </ModalNavigationItem>
        <ModalNavigationItem active={activeTab === 'planning'} onClick={() => onNavigationItemClick('planning')}>
          <Icon icon='calendar' />
          <span>
            {t('DealModal::Planning')}
          </span>
        </ModalNavigationItem>
      </ModalNavigation>
    )
  }

  const renderDetails = () => {
    if (activeTab !== 'details') return null

    return (
      <div className='grid'>
        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('DealModal::Name')} <span>*</span></label>
            <input
              type='text'
              placeholder={t('DealModal::Name')}
              onChange={onNameChange}
              value={deal.name}
            />
          </div>
        </div>

        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('DealModal::Stage')} <span>*</span></label>
            <ResourceCreatablePowerSelect
              type='deal_stage'
              value={deal.deal_stage_id}
              onChange={onDealStageChange}
            />
          </div>
        </div>

        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('DealModal::Contact')}</label>
            <ResourceCreatablePowerSelect
              type='contact'
              params={{ archived: false }}
              value={deal.contact_id}
              onChange={onDealContactChange}
              isClearable={true}
              isDisabled={Boolean(contactDisabled)}
            />
          </div>
        </div>

        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('DealModal::Project')} </label>
            <ResourceCreatablePowerSelect
              type='project'
              value={deal.project_id}
              onChange={onDealProjectChange}
              params={{ 'contact_id': deal.contact_id }}
              createParams={{ contact_id: deal.contact_id }}
              isClearable={true}
              isDisabled={Boolean(projectDisabled)}
            />
          </div>
        </div>

        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('DealModal::Amount')}</label>
            <MoneyInput
              name='rate'
              currency={deal?.currency}
              numberFormat={setting.number_format}
              placeholderValue={t('DealModal::{{amount}}', { amount: NumberFormatter.formatCurrency(deal.currency, setting.number_format, 0) })}
              value={deal?.amount}
              onBlur={onAmountChange}
              optional
            />
          </div>
        </div>

        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('DealModal::Currency')}</label>
            <PowerSelect
              options={currencyOptions}
              value={selectedCurrencyOption}
              onChange={onCurrencyChange}
              noOptionsMessage={(value) => String(t('DealModal::No currency option found'))}
              theme={ReactSelectTheme}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderNotes = () => {
    if (activeTab !== 'notes') return null

    return (
      <div className='grid'>
        <div className='grid-cell with-12col'>
          <div className='form-item'>
            <EditorContainer>
              <Editor
                model={deal.notes}
                onModelChange={onNotesChange}
                config={{
                  ...NOTES_EDITOR_CONFIG,
                  placeholderText: t('DealModal::Add notes this deal (only visible to you and your team members)'),
                  heightMin: 120,
                  heightMax: 265
                }}
              />
            </EditorContainer>
          </div>
        </div>
      </div>
    )
  }

  const renderPlanning = () => {
    if (activeTab !== 'planning') return null

    return (
      <div className='grid'>
        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>{t('DealModal::Assignee')}</label>
            <ResourceCreatablePowerSelect
              type='user'
              value={deal.assignee_id}
              onChange={onAssigneeChange}
              isClearable={true}
              isValidNewOption={() => false}
            />
          </div>
        </div>
        {false && <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>
              {t('DealModal::Success rate')}
              <Tooltip
                content={t('DealModal::Success rate is calculated automatically by moving a deal through the different stages of the sales cycle.')}
                containerStyle={{ marginLeft: 8 }}
              />
            </label>
            <PercentInput
              name='secondary_tax'
              defaultValue={deal?.success_rate || ''}
              placeholder='0 %'
              onChange={onSuccessRateChange}
              onBlur={onSuccessRateChange}
              disabled={true}
            />
          </div>
        </div>}

        <div className='grid-cell with-6col'>
          <div className='form-item'>
            <label>
              {t('DealModal::Decision deadline')}
            </label>
            <DateInput
              name='due_on'
              dateFormat={setting.date_format}
              timeFormat={false}
              initialValue={moment(deal.due_on)}
              inputProps={{ placeholder: setting.date_format }}
              onChange={onDueOnChange}
              closeOnSelect
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={deal?.id ? t('DealModal::Update deal') : t('DealModal::Create deal')}
        navigation={renderNavigation()}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            {renderDetails()}
            {renderNotes()}
            {renderPlanning()}
            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <div />
          <div key='main-action' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={onErrorsDismiss}
            />
            <Button
              type='success'
              text={deal?.id ? t('DealModal::Update deal') : t('DealModal::Create deal')}
              onClick={onFormSubmit}
            />
          </div>
        </div>
      </ModalMiddle>}
    </ModalWindow >
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      dealModal: {
        deal,
        contactDisabled,
        projectDisabled,
        activeTab,
        onSubmit,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    deal: deal,
    contactDisabled: contactDisabled,
    projectDisabled: projectDisabled,
    activeTab: activeTab,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeDealModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DealModal)