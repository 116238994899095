
import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import Icon from '../Icons/Icon'
import TaskSectionContent from './TaskSectionContent'
import TaskSectionTitle from './TaskSectionTitle'
import TaskSection from './TaskSection'
import TaskSectionIcon from './TaskSectionIcon'
import ResourceTable, { ResourceTableHeader } from '../Resource/ResourceTable'
import CardEmptyInfo from '../Card/CardEmptyInfo'
import ResourceTableRow from '../Resource/ResourceTableRow'
import ResourceTableRowData from '../Resource/ResourceTableRowData'
import { CurrentUser, Task, TaskSummaryTimeTrackingReportDataRow, TaskTimeTrackingReportData, TaskTimeTrackingReportDataRow, TaskTimeTrackingViewReportType, TimeEntry } from '../../types'
import { TasksController, TimeEntriesController } from '../../controllers'
import TimeFormatter from '../../utilities/TimeFormatter'
import { Style } from '../../styles'
import ResourceTableRowActions from '../Resource/ResourceTableRowActions'
import { IActionListItem } from '../ActionList/ActionList'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { showConfirmModal, showTimeEntryModal } from '../../store/modals/actions'
import { connect } from 'react-redux'
import ButtonGroup from '../Button/ButtonGroup'
import ButtonPanel from '../Button/ButtonPanel'

interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps {
	showTimeEntryModal: typeof showTimeEntryModal
	showConfirmModal: typeof showConfirmModal
}

type IProps = {
	task: Task
	onNewTimeEntryClick: () => void
} & WithTranslation & IStateToProps & IDispatchToProps

interface IState {
	didInitialLoad: boolean
	type: TaskTimeTrackingViewReportType
	data?: TaskTimeTrackingReportData
}

class TaskTimesheet extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			didInitialLoad: false,
			type: TaskTimeTrackingViewReportType.SUMMARY,
			data: null,
		}

		this.renderSummaryRow = this.renderSummaryRow.bind(this)
		this.fetchReport = this.fetchReport.bind(this)
		this.refresh = this.refresh.bind(this)
		this.onTimeEntryUpdateSubmit = this.onTimeEntryUpdateSubmit.bind(this)
		this.onReportTypeChange = this.onReportTypeChange.bind(this)
		this.onTableTimeEntryActionClick = this.onTableTimeEntryActionClick.bind(this)
		this.onTableTimeEntryClick = this.onTableTimeEntryClick.bind(this)
		this.onTableTimeEntryEditClick = this.onTableTimeEntryEditClick.bind(this)
		this.onTableTimeEntryDeleteClick = this.onTableTimeEntryDeleteClick.bind(this)
	}

	componentDidMount(): void {
		this.fetchReport()
	}

	refresh() {
		this.fetchReport().catch(console.error)
	}

	async fetchReport() {
		const { task } = this.props
		const { type } = this.state

		try {
			const response = await TasksController.getTimeEntriesReport({
				taskId: task.id,
				type: type,
			})

			this.setState({
				didInitialLoad: true,
				data: response
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	getTypeHeaders(): ResourceTableHeader[] {
		const { t } = this.props
		const { type } = this.state
		switch (type) {
			case TaskTimeTrackingViewReportType.SUMMARY:
				return [
					{ title: t('TimeTrackingReport::Name') },
					{ title: '' },
					{ title: t('TimeTrackingReport::Time spent'), align: 'right' },
				]
			case TaskTimeTrackingViewReportType.TIMESHEET:
				return [
					{ title: t('TimeTrackingReport::Name') },
					{ title: '' },
					{ title: t('TimeTrackingReport::Time spent'), align: 'right' },
					{ title: '' },
				]
		}
	}

	onTableTimeEntryActionClick(key: string, timeEntry: TimeEntry) {
		switch (key) {
			case 'view': this.onTableTimeEntryClick(timeEntry)
				break
			case 'edit': this.onTableTimeEntryEditClick(timeEntry)
				break
			case 'delete': this.onTableTimeEntryDeleteClick(timeEntry)
				break
			default:
				throw Error('[TaskTimesheet] Unimplemented onTableTimeEntryActionClick')
		}
	}

	onTableTimeEntryEditClick(timeEntry: TimeEntry) {
		const { showTimeEntryModal, task } = this.props

		requestAnimationFrame(() => {
			showTimeEntryModal({
				timeEntry: { id: timeEntry.id },
				contactDisabled: Boolean(task.contact_id),
				projectDisabled: Boolean(task.project_id),
				onSubmit: this.onTimeEntryUpdateSubmit
			})
		})
	}

	onTableTimeEntryDeleteClick(timeEntry: TimeEntry) {
		const { showConfirmModal, t } = this.props

		showConfirmModal({
			title: t('TaskTimesheet::Delete time entry'),
			description: t('TaskTimesheet::You are about to delete a time entry. Deletion is permanent and irreversible. Are you sure?'),
			action: { label: t('TaskTimesheet::Delete'), isDestructive: true },
			onConfirm: async () => {
				try {
					await TimeEntriesController.delete(timeEntry.id)

					const { data } = this.state

					const timeEntryIndex = data.entries.findIndex(i => i.id === timeEntry.id);

					data.entries.splice(timeEntryIndex, 1);

					this.setState({ data: { ...data } });
				} catch (ex) {
					console.error(ex)
				}
			}
		})
	}

	onTableTimeEntryClick(timeEntry) {
		const { showTimeEntryModal, task } = this.props

		requestAnimationFrame(() => {
			showTimeEntryModal({
				timeEntry: { id: timeEntry.id },
				contactDisabled: Boolean(task.contact_id),
				projectDisabled: Boolean(task.project_id),
				onSubmit: this.onTimeEntryUpdateSubmit
			})
		})
	}

	onTimeEntryUpdateSubmit(timeEntry: TimeEntry) {
		const { data } = this.state

		const contactIndex = data.entries.findIndex(c => c.id === timeEntry.id);

		if (contactIndex !== -1) {
			data.entries[contactIndex] = timeEntry
		}

		this.setState({ data: { ...data } })
	}

	onReportTypeChange() {
		const { type } = this.state

		this.setState({
			type: type === TaskTimeTrackingViewReportType.SUMMARY ? TaskTimeTrackingViewReportType.TIMESHEET : TaskTimeTrackingViewReportType.SUMMARY
		}, this.refresh)
	}

	renderSummaryRow(dataRow: TaskSummaryTimeTrackingReportDataRow, index: number) {
		const { t, task } = this.props
		const { data } = this.state
		const lastRow = data?.entries?.length - 1 === index

		const totalTimeSpent = Number(data.time_spent)
		const totalTimeEstimate = Number(task.time_estimate)
		const totalRemaining = totalTimeEstimate - totalTimeSpent

		return (
			<>
				<ResourceTableRow key={index}>
					<ResourceTableRowData ellipse maxWidth='300px' title={dataRow.name}>
						{dataRow?.name}
					</ResourceTableRowData>
					<ResourceTableRowData></ResourceTableRowData>
					<ResourceTableRowData textAlign='right'>
						<strong>
							{TimeFormatter.formatToHumanReadableDuration(totalTimeSpent)}
						</strong>
					</ResourceTableRowData>
				</ResourceTableRow>
				{lastRow &&
					<>
						<ResourceTableRow key='total'>
							<ResourceTableRowData  >
								<strong>{t('TaskTimesheet::Total')}</strong>
							</ResourceTableRowData>
							<ResourceTableRowData />
							<ResourceTableRowData textAlign='right'>
								<strong>{TimeFormatter.formatToHumanReadableDuration(totalTimeSpent)}</strong>
							</ResourceTableRowData>
						</ResourceTableRow>

						{task?.time_estimate > 0 && <>
							<ResourceTableRow key='estimated'>
								<ResourceTableRowData  >
									<strong>{t('TaskTimesheet::Estimated')}</strong>
								</ResourceTableRowData>
								<ResourceTableRowData />
								<ResourceTableRowData textAlign='right'>
									<strong>{TimeFormatter.formatToHumanReadableDuration(totalTimeEstimate)}</strong>
								</ResourceTableRowData>
							</ResourceTableRow>

							<ResourceTableRow key='remaining'>
								<ResourceTableRowData style={{ color: totalRemaining < 0 ? Style.color.brandDanger : 'inherit' }}>
									<strong>
										{totalRemaining > 0 ? t('TaskTimesheet::Remaining') : t('TaskTimesheet::Overtime')}
									</strong>
								</ResourceTableRowData>
								<ResourceTableRowData />
								<ResourceTableRowData textAlign='right' style={{ color: totalRemaining < 0 ? Style.color.brandDanger : 'inherit' }}>
									<strong>{TimeFormatter.formatToHumanReadableDuration(Math.abs(totalRemaining))}</strong>
								</ResourceTableRowData>
							</ResourceTableRow>
						</>}
					</>}
			</>
		)
	}

	renderTimesheetRow(dataRow: TimeEntry, index: number) {
		const { t } = this.props

		let actions: IActionListItem[] = [
			{ key: 'view', icon: 'eye', content: t('TimeEntriesTable::View') },
			{ key: 'edit', icon: 'edit-solid', content: t('TimeEntriesTable::Edit') },
			{ key: 'delete', icon: 'trash-alt-solid', content: t('TimeEntriesTable::Delete'), destructive: true },
		]

		return (
			<>
				<ResourceTableRow key={index}>
					<ResourceTableRowData ellipse maxWidth='300px' title={dataRow.description} onClick={() => this.onTableTimeEntryEditClick(dataRow)}>
						{dataRow.description?.length > 0 ? dataRow.description : '-'}
					</ResourceTableRowData>
					<ResourceTableRowData onClick={() => this.onTableTimeEntryEditClick(dataRow)} />
					<ResourceTableRowData textAlign='right' onClick={() => this.onTableTimeEntryEditClick(dataRow)}>
						<strong>
							{TimeFormatter.formatToHumanReadableDuration(dataRow.duration)}
						</strong>
					</ResourceTableRowData>
					<ResourceTableRowActions
						actions={actions}
						onActionClick={(key) => this.onTableTimeEntryActionClick(key, dataRow)}
						sticky={true}
						stickyRight='0px'
					/>
				</ResourceTableRow>
			</>
		)
	}

	render() {
		const { t, onNewTimeEntryClick } = this.props
		const { didInitialLoad, data, type } = this.state

		let entries: TaskTimeTrackingReportDataRow[] = []

		if (!didInitialLoad) return null
		if (didInitialLoad && data) {
			// @ts-ignore
			entries = data.entries
		}

		return (
			<TaskSection>
				<TaskSectionTitle>
					<TaskSectionIcon>
						<Icon icon='stopwatch' />
					</TaskSectionIcon>

					{t('TaskModal::Timesheet')}
				</TaskSectionTitle>

				<TaskSectionContent>
					<ResourceTable
						data={entries}
						headers={this.getTypeHeaders()}
						actionsLeft={[
							<ButtonPanel
								icon='plus'
								text={t('TimeEntriesTable::New entry')}
								onClick={onNewTimeEntryClick}
							/>,
						]}
						actionsRight={[
							<ButtonGroup
								items={[
									{ element: <span data-tip={t('TaskTimesheet::Summary')}>Summary</span>, onClick: this.onReportTypeChange, active: type === TaskTimeTrackingViewReportType.SUMMARY },
									{ element: <span data-tip={t('TaskTimesheet::Timesheet')}>Timesheet</span>, onClick: this.onReportTypeChange, active: type === TaskTimeTrackingViewReportType.TIMESHEET }
								]}
							/>
						]}
						renderRow={(dataRow: TaskSummaryTimeTrackingReportDataRow, index) => {
							switch (data.type) {
								case TaskTimeTrackingViewReportType.SUMMARY:
									return this.renderSummaryRow(dataRow as TaskSummaryTimeTrackingReportDataRow, index)
								case TaskTimeTrackingViewReportType.TIMESHEET:
									return this.renderTimesheetRow(dataRow as TimeEntry, index)
								default: return null
							}
						}}
						renderEmpty={<CardEmptyInfo
							icon='stopwatch'
							description={t('TimeTrackingReport::No entries found')}
						/>}
						isLoading={false}
						stickyHeader={true}
						maxHeight='30vh'
					/>
				</TaskSectionContent>
			</TaskSection>
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		},
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
	return {
		showTimeEntryModal: (options) => dispatch(showTimeEntryModal(options)),
		showConfirmModal: (options) => dispatch(showConfirmModal(options))
	}
}

export { TaskTimesheet }
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withTranslation(null, { withRef: true })(TaskTimesheet))