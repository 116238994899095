import { saveAs } from 'file-saver'
import { BizzeyFile, MimeTypes } from '../types';
export default class FileHelper {
  static getFilename(filename: string) {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  }

  static getExtension(filename: string) {
    const index = filename.lastIndexOf(".");
    return (
      filename.substring(index, filename.length).replace(".", "") || filename
    );
  }

  static displayable(file: BizzeyFile) {
    return [
      String(MimeTypes.JPEG),
      String(MimeTypes.JPG),
      String(MimeTypes.PNG),
      String(MimeTypes.GIF)
    ].includes(file.attachment_content_type)
  }
}
