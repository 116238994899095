import * as React from 'react'
import IntlTelInput, { IntlTelInputRef } from "intl-tel-input/react";
import "intl-tel-input/styles";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  .iti {
    width: 100% !important;
  }

  .iti__tel-input {
    padding-left: 46px !important;
  }
`

interface IProps {
  onChangeNumber?: (telephoneNumber: string) => void
  onChangeValidity?: (valid: boolean) => void
  initialValue?: string
  initialCountry?: string
  disabled?: boolean
}

const TelephoneNumberInput = (props: IProps) => {
  const { onChangeNumber, onChangeValidity, initialValue, initialCountry, disabled } = props
  return (
    <Container>
      <IntlTelInput
        onChangeNumber={onChangeNumber}
        onChangeValidity={onChangeValidity}
        initialValue={initialValue}
        initOptions={{
          initialCountry: initialCountry,
          formatOnDisplay: true,
          formatAsYouType: true,
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.0/build/js/utils.js",
        }}
        inputProps={{
          disabled: disabled
        }}
      />
    </Container>
  )
}

export default TelephoneNumberInput