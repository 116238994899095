import * as React from 'react'
import { closeBulkContactGroupModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import TooltipError from '../Tooltips/ErrorTooltip'
import Icon from '../Icons/Icon'
import Notification from '../../utilities/Notification'
import { ContactGroupsController, ContactsController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalNavigation from './Parts/ModalNavigation'
import ModalNavigationItem from './Parts/ModalNavigationItem'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { withTranslation, WithTranslation } from 'react-i18next'
import { CurrentUser, ContactGroup } from '../../types'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import Button from '../Button/Button'

interface IStateToProps {
  action: 'add' | 'remove'
  contactIds: string[]
  onSubmit?: (contactGroups: ContactGroup[]) => void
  currentUser: CurrentUser
}

interface IDispatchToProps {
  close: typeof closeBulkContactGroupModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

export enum BulkContactGroupModalTab {
  DETAILS = 'details',
}

interface IState {
  didInitialLoad: boolean
  activeTab: BulkContactGroupModalTab
  selectedContactGroups: ContactGroup[]
  errors: any
}

class BulkContactGroupModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      activeTab: BulkContactGroupModalTab.DETAILS,
      selectedContactGroups: [],
      errors: {},
    }

    this.onContactGroupsChange = this.onContactGroupsChange.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onBulkContactGroupModalCloseClick = this.onBulkContactGroupModalCloseClick.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
  }

  componentDidMount(): void {
    setTimeout(() => {
      this.setState({ didInitialLoad: true })

    }, 250)
  }

  onContactGroupsChange(_contactGroupIds, contactGroups) {
    this.setState({
      selectedContactGroups: contactGroups || []
    })
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { selectedContactGroups } = this.state;
    const { close, onSubmit, t, action, contactIds } = this.props

    try {
      let success = false
      if (action === 'add') {
        const response = await ContactsController.bulkAddGroups(contactIds, selectedContactGroups.map(c => c.id))
        success = response.success
      } else if (action === 'remove') {
        const response = await ContactsController.bulkRemoveGroups(contactIds, selectedContactGroups.map(c => c.id))
        success = response.success
      }

      if (success) {
        if (onSubmit) onSubmit(selectedContactGroups)
        close()
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onBulkContactGroupModalCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({
      errors: {}
    })
  }

  renderDetails() {
    const { t, currentUser } = this.props
    const { activeTab, selectedContactGroups } = this.state;
    const { workspace: { setting } } = currentUser

    if (activeTab !== BulkContactGroupModalTab.DETAILS) return null;

    return (
      <div data-tab='details'>
        <div className='grid'>
          <div className='grid-cell with-12col'>
            <div className='form-item'>
              <label>{t('ContactModal::Groups')}</label>
              <ResourceCreatablePowerSelect
                type='contact_group'
                value={selectedContactGroups.map(group => group.id)}
                onChange={this.onContactGroupsChange}
                isClearable={true}
                placeholder={t('ContactModal::Select contact groups...')}
                isMulti
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { t, action } = this.props
    const { activeTab, didInitialLoad, errors } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={action === 'add' ? t('BulkContactGroupModal::Add to group') : t('BulkContactGroupModal::Remove from group')}
          onCloseClick={this.onBulkContactGroupModalCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              {this.renderDetails()}
              <input type='submit' style={{ display: 'none' }} onClick={this.onFormSubmit} />
            </form>
          </ModalContent>

          <div className='modal-footer'>
            <div />
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <Button
                  type='success'
                  text={t('BulkContactGroupModal::Save')}
                  onClick={this.onFormSubmit}
                />
              </div>
            </div>
          </div>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      bulkContactGroupModal: {
        action,
        contactIds,
        onSubmit
      }
    }
  } = state

  return {
    currentUser: currentUser,
    action: action,
    contactIds: contactIds,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeBulkContactGroupModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BulkContactGroupModal))