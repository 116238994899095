import '../translations'
import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { saveAs } from 'file-saver'
import Button from '../components/Button/Button'
import { InvoicesController } from '../controllers'
import Sound, { Sounds } from '../sounds'
import AwardSuccess from '../components/Awards/AwardSuccess'
import Notification from '../utilities/Notification'
import UrlHelper from '../helpers/UrlHelper'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import DocumentWrapper from '../components/LedgerItem/DocumentWrapper'
import DocumentPreview from '../components/LedgerItem/DocumentPreview'
import { Body } from '../components/Body/Body'
import { DEFAULT_LOCALE, SENTRY_DSN } from '../Constants'
import { Helmet } from 'react-helmet'
import { loadStripe } from '@stripe/stripe-js'
import { IntegrationType, LedgerItem, LedgerItemType, Workspace } from '../types'
import ContactPortal from '../components/ContactPortal/ContactPortal'
import ContactDocumentContainer from '../components/ContactPortal/ContactDocumentContainer'
import ReactTooltip from 'react-tooltip'
import IconButtonGroup from '../components/Button/IconButtonGroup'
import AppLoader from '../components/Loaders/AppLoader'
import { WithTranslation, withTranslation } from 'react-i18next'
import i18next from 'i18next'
import Modals from '../components/Modals/Modals'
import { Provider, connect } from 'react-redux'
import store, { AppState } from '../store'
import { showSignatureModal } from '../store/modals/actions'
import { Dispatch } from 'redux'
import LedgerItemHelper from '../helpers/LedgerItemHelper'

Sentry.init({ dsn: SENTRY_DSN })

interface IDispatchToProps {
  showSignatureModal: typeof showSignatureModal
}

type IProps = {
  querySearch?: string
  workspace: Workspace
  invoice: LedgerItem
  paymentsEnabled: boolean
} & WithTranslation & IDispatchToProps

interface IState {
  invoice: LedgerItem
  paymentLoading: boolean
}

class Application extends React.Component<IProps, IState> {
  private award = React.createRef<AwardSuccess>()
  private document = React.createRef<DocumentPreview>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      invoice: props.invoice,
      paymentLoading: false,
    }

    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.onApproveQuotationClick = this.onApproveQuotationClick.bind(this);
    this.onPayInvoiceClick = this.onPayInvoiceClick.bind(this)
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  componentDidMount() {
    const { querySearch, t } = this.props

    if (querySearch) {
      const params = UrlHelper.getParams(querySearch)

      if (params.paymentSuccess) {
        // Show success
        this.showAward()
        // Extra notification
        Notification.notifySuccess(t('Contact::Your invoice was paid successfully.'))
      }
    }

    i18next.changeLanguage(this.props.invoice?.contact?.locale || DEFAULT_LOCALE, (err, t) => { });
  }

  showAward() {
    this.award.current.show()
    Sound.play(Sounds.COMPLETE)
  }

  onDownloadClick() {
    const { invoice } = this.state;

    LedgerItemHelper.download(invoice)
  }

  async onApproveQuotationClick(e) {
    e.preventDefault()
    const { invoice } = this.state

    this.props.showSignatureModal({
      onSubmit: async (signature) => {
        try {
          const response = await InvoicesController.approveQuotation(invoice.id, signature)
          this.setState({ invoice: response }, this.showAward)
        } catch (ex) {
          console.error(ex)
        }
      },
    })
  }

  async onPayInvoiceClick(e) {
    e.preventDefault()
    const { invoice } = this.state

    this.setState({ paymentLoading: true })

    try {
      const form = await InvoicesController.getPaymentForm(invoice.id)

      this.setState({ paymentLoading: false })

      switch (form.type) {
        case IntegrationType.MOLLIE:
          // @ts-ignore
          if (form.data._links) window.location = form.data._links.checkout.href
          return
        case IntegrationType.STRIPE:
          if (form.data) {
            const { public_key, account_id, session_id } = form.data

            const stripe = await loadStripe(public_key, { stripeAccount: account_id })

            const result = await stripe.redirectToCheckout({ sessionId: session_id })

            if (result.error && result.error.message) {
              Notification.notifyError(result.error.message)
            }
          }
          return
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ paymentLoading: false })
    }
  }

  render() {
    const { invoice, paymentLoading } = this.state
    const { workspace, paymentsEnabled, t } = this.props

    let actions: React.ReactNode[] = [
      <IconButtonGroup
        buttons={[
          {
            icon: <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-alt-circle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm-32-316v116h-67c-10.7 0-16 12.9-8.5 20.5l99 99c4.7 4.7 12.3 4.7 17 0l99-99c7.6-7.6 2.2-20.5-8.5-20.5h-67V140c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12z"></path></svg>,
            tooltip: t('Contact::Download'),
            onClick: this.onDownloadClick
          }
        ]}
      />
    ]

    if (invoice?.type === LedgerItemType.QUOTATION && !invoice?.approved_on) {
      actions.push(
        <Button
          type='default'
          icon='thumbs-up'
          text={t('Contact::Approve')}
          onClick={this.onApproveQuotationClick}
        />
      )
    }

    if (invoice?.type === LedgerItemType.INVOICE && !invoice?.paid_on && Number(invoice?.amount) > 0 && paymentsEnabled) {
      actions.push(
        <Button
          type='default'
          isLoading={paymentLoading}
          icon='money-bill'
          text={t('Contact::Pay')}
          onClick={this.onPayInvoiceClick}
        />
      )
    }

    return (
      <>
        <ContactPortal
          companyName={workspace?.business_name}
          title={invoice?.number}
          actions={actions}
        >
          <Helmet>
            <title>{invoice ? `Bizzey | ${invoice.number}` : `Bizzey`}</title>
          </Helmet>
          <ScrollToTopOnMount />
          <Body />
          <ContactDocumentContainer
            style={{
              marginTop: 60,
              marginBottom: 60,
              paddingLeft: 16,
              paddingRight: 16
            }}
          >
            <DocumentWrapper>
              {({ width }) => {
                return (
                  <DocumentPreview
                    ref={this.document}
                    file={invoice.pdf_attachment_url}
                    width={width}
                    allPages
                    withBorder
                    withBorderRadius
                  />
                )
              }}
            </DocumentWrapper>
          </ContactDocumentContainer>
          <AwardSuccess ref={this.award} />
        </ContactPortal>
        <ReactTooltip effect='solid' />
        <Modals />
      </>
    )
  }
}


const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showSignatureModal: (options) => dispatch(showSignatureModal(options)),
  }
}

const ApplicationWithTranslations = connect(null, mapDispatchToProps)(withTranslation()(Application))

const ApplicationProvider = (props: IProps) => {
  return (
    <React.Suspense fallback={<AppLoader />}>
      <Provider store={store}>
        <ApplicationWithTranslations {...props} />
      </Provider>
    </React.Suspense>
  )
}



export default ApplicationProvider