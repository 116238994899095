import * as React from 'react'
import { closeDocumentTagModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Button from '../Button/Button'
import { DocumentTagsController, DocumentsController } from '../../controllers'
import { CurrentUser, DisplayableError, DocumentTag } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'

interface IStateToProps {
  currentUser: CurrentUser
  documentTag?: DocumentTag
  onSubmit: (documentTag: DocumentTag) => void
  onDelete: (documentTag: DocumentTag) => void
}

interface IDispatchToProps {
  close: typeof closeDocumentTagModal
}

type IProps = IDispatchToProps & IStateToProps

interface IState {
  didInitialLoad: boolean
  documentTag: DocumentTag
  errors: DisplayableError[]
}

const DocumentTagModal = (props: IProps) => {
  const { t } = useTranslation()
  const { currentUser } = props
  const { workspace: { setting } } = currentUser
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    documentTag: null,
    errors: [],
  })
  const { didInitialLoad, documentTag, errors } = state

  React.useEffect(() => {
    getForm().catch(console.error)
  }, [])

  const getForm = async () => {
    try {
      const { document_tag } = await DocumentTagsController.getForm(props.documentTag.id)

      setState({
        ...state,
        documentTag: {
          ...document_tag,
          ...props.documentTag,
        },
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onNameChange = (e) => {
    const newName = e.currentTarget.value

    setState({ ...state, documentTag: { ...documentTag, name: newName } })
  }

  const onCloseClick = () => {
    props.close()
  }

  const onDeleteClick = async () => {
    try {
      await DocumentTagsController.delete(documentTag.id)
      props.onDelete(documentTag)
      onCloseClick()
    } catch (ex) {
      console.error(ex)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
      let response = null
      if (documentTag.id) {
        response = await DocumentTagsController.update(documentTag)
      } else {
        response = await DocumentTagsController.create(documentTag)
      }

      if (response.errors) {
        setState({ ...state, errors: response.errors })
      } else {
        props.onSubmit(response)
        props.close()
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  const onErrorsDismiss = () => {
    setState({ ...state, errors: [] })
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={documentTag?.id ? t('DocumentTagModal::Update tag') : t('DocumentTagModal::Create tag')}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('DocumentTagModal::Name')} <span>*</span></label>
                  <input
                    type='text'
                    placeholder={t('DocumentTagModal::Name')}
                    onChange={onNameChange}
                    value={documentTag.name}
                    disabled={Boolean(documentTag.type)}
                  />
                </div>
              </div>
            </div>

            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        {!Boolean(documentTag.type) && <div className='modal-footer'>
          <div>
            {documentTag?.id && !Boolean(documentTag?.type) && <Button
              type='danger'
              text={t('DocumentTagModal::Delete')}
              onClick={onDeleteClick}
            />}
          </div>
          <div key='main-action' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={onErrorsDismiss}
            />
            <Button
              type='success'
              text={t('DocumentTagModal::Save')}
              onClick={onFormSubmit}
            />
          </div>
        </div>}
      </ModalMiddle>}
    </ModalWindow >
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      documentTagModal: {
        documentTag,
        onSubmit,
        onDelete,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    documentTag: documentTag,
    onSubmit: onSubmit,
    onDelete: onDelete
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeDocumentTagModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTagModal)