import styled from "styled-components";

const EditorTextAreaContainer = styled.div`

  border-width: 1px;
  border-style: solid;
  border-color: rgb(196, 205, 213);
  border-image: initial;
  border-radius: 4px;

  > .fr-box {
    padding: 6px 7px;
  }
`

export default EditorTextAreaContainer