import * as React from 'react'
import { EventProps, View } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CalendarViewEvent, CalendarViewEventType } from '../../types'
import moment from '../../utilities/Moment'
import Icon, { IconTypes } from '../Icons/Icon'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`

const IconContainer = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin-right: 4px;
`
const Content = styled.div`
	max-width: 100%;
	overflow: hidden;
	margin-right: 4px;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;

	svg {
		width: 14px;
		height: 14px;
		fill: currentColor;
	}
`

const MonthTime = styled.div`
	color: currentColor;
`

type IProps = EventProps<CalendarViewEvent> & { view: View }

const CalendarEventView = (props: IProps) => {
	const { view, event } = props
	const { t } = useTranslation()

	let icon: IconTypes = null

	switch (event.type) {
		case CalendarViewEventType.CALENDAR_EVENT:
			icon = 'calendar-day'
			break
		case CalendarViewEventType.PROJECT:
			icon = 'project'
			break
		case CalendarViewEventType.QUOTATION:
			icon = 'invoice'
			break
		case CalendarViewEventType.INVOICE:
			icon = 'invoice'
			break
		case CalendarViewEventType.RECURRING_INVOICE:
			icon = 'invoice'
			break
		case CalendarViewEventType.TASK:
		case CalendarViewEventType.PLANNED_TASK:
			icon = 'tasks'
			break
		case CalendarViewEventType.DEAL:
			icon = 'check-circle'
			break
	}

	return (
		<Container>
			<Content>
				{icon && <IconContainer>
					<Icon icon={icon} />
				</IconContainer>}
				{event?.title?.length > 0 ? event.title : t('CalendarEventView::(No title)')}
			</Content>

			{view === 'month' && !event.all_day && <MonthTime>{moment(event.start).format('H:mm A')}</MonthTime>}
		</Container>
	)
}

export default CalendarEventView