import * as React from 'react'
import { closePaymentInitiationModal, showConfirmModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Button from '../Button/Button'
import { IntegrationsController, PaymentInitiationsController } from '../../controllers'
import { CurrentUser, DisplayableError, RemittanceInformationType, PaymentInitiation, PaymentInitiationIntegrationMetadata, PaymentInitiationStatus, Account, IntegrationType } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'
import OgmHelper from '../../helpers/OgmHelper'
import ResourceModalTable from '../Resource/Modal/ResourceModalTable'
import ResourceModalTableRow from '../Resource/Modal/ResourceModalTableRow'
import ResourceModalTableRowHeader from '../Resource/Modal/ResourceModalTableRowHeader'
import ResourceModalTableRowData from '../Resource/Modal/ResourceModalTableRowData'
import NumberFormatter from '../../utilities/NumberFormatter'
import DateInput from '../Form/DateInput'
import moment from '../../utilities/Moment'
import ModalFooterActionIcons from './Parts/ModalFooterActionIcons'
import ModalFooterActionIcon from './Parts/ModalFooterAction'
import Images from '../../images'
import { useHistory } from 'react-router-dom'
import ERoute from '../../ERoute'
import RouteHelper from '../../helpers/RouteHelper'
import ModalDescription from './Parts/ModalDescription'
import Tooltip from '../Tooltips/Tooltip'
import InputAlert from '../Form/InputAlert'
import Alert from '../Alert/Alert'
import PaymentHelper from '../../helpers/PaymentHelper'
import { Style } from '../../styles'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'

enum IntegrationScenario {
  ENABLE = 'enable',
  REQUEST = 'request',
  REQUEST_PENDING = 'pending',
  ACTIVE = 'active',
}

interface IStateToProps {
  currentUser: CurrentUser
  paymentInitiation?: PaymentInitiation
  onSubmit: (paymentInitiation: PaymentInitiation) => void
  onDelete: (paymentInitiation: PaymentInitiation) => void
}

interface IDispatchToProps {
  close: typeof closePaymentInitiationModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IDispatchToProps & IStateToProps

interface IState {
  didInitialLoad: boolean
  paymentInitiation: PaymentInitiation
  paymentInitiationIntegrationMetadata: PaymentInitiationIntegrationMetadata
  errors: DisplayableError[]
}

const PaymentInitiationModal = (props: IProps) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { currentUser } = props
  const { setting } = currentUser.workspace
  const [state, setState] = React.useState<IState>({
    didInitialLoad: false,
    paymentInitiation: null,
    paymentInitiationIntegrationMetadata: null,
    errors: [],
  })
  const { didInitialLoad, paymentInitiation, paymentInitiationIntegrationMetadata, errors } = state
  const suggestedExecutionDate = PaymentHelper.getSuggestedExecutionDateForPayments(paymentInitiation?.payments || [])
  let integrationScenario: IntegrationScenario = null

  if (paymentInitiationIntegrationMetadata) {
    const { integration_enabled, payments_activation_requested, payments_activated } = paymentInitiationIntegrationMetadata

    if (!integration_enabled) {
      integrationScenario = IntegrationScenario.ENABLE
    } else if (payments_activated) {
      integrationScenario = IntegrationScenario.ACTIVE
    } else if (payments_activation_requested) {
      integrationScenario = IntegrationScenario.REQUEST_PENDING
    } else {
      integrationScenario = IntegrationScenario.REQUEST
    }
  }

  React.useEffect(() => {
    getForm().catch(console.error)
  }, [])

  const getForm = async () => {
    try {
      const { payment_initiation, payment_initiation_metadata } = await PaymentInitiationsController.getForm({ id: props.paymentInitiation ? props.paymentInitiation.id : null })

      if (!payment_initiation.id && props.paymentInitiation.payments) {
        payment_initiation.payments = [...props.paymentInitiation.payments]
      }

      setState({
        ...state,
        paymentInitiation: payment_initiation,
        paymentInitiationIntegrationMetadata: payment_initiation_metadata,
        didInitialLoad: true,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  const getTitle = () => {
    switch (integrationScenario) {
      case IntegrationScenario.ENABLE:
        return t('PaymentInitiationModal::Enable payments')
      case IntegrationScenario.REQUEST:
        return t('PaymentInitiationModal::Payment activation request')
      case IntegrationScenario.REQUEST_PENDING:
        return t('PaymentInitiationModal::Payment activation request pending')
      default:
        return paymentInitiation?.id ? t('PaymentInitiationModal::Payment information') : t('PaymentInitiationModal::Schedule payment')
    }
  }

  const onAccountChange = (accountId?: string, account?: Account) => {
    setState({ ...state, paymentInitiation: { ...paymentInitiation, account_id: accountId } })
  }

  const onReferenceChange = (e) => {
    const newName = e.currentTarget.value

    setState({ ...state, paymentInitiation: { ...paymentInitiation, reference: newName } })
  }

  const onExecutionDateChange = (value) => {
    const executionDate = moment(value);

    setState({
      ...state,
      paymentInitiation: {
        ...paymentInitiation,
        execution_date: executionDate.isValid() ? executionDate.format('YYYY-MM-DD') : null
      }
    })
  }

  const onNotesChange = (e) => {
    const notes = e.currentTarget.value

    setState({ ...state, paymentInitiation: { ...paymentInitiation, notes: notes } })
  }

  const onApplySuggestedExecutionDateClick = () => {
    if (suggestedExecutionDate) {
      const suggestedExecutionDateMoment = moment(suggestedExecutionDate)

      setState({
        ...state,
        paymentInitiation: {
          ...paymentInitiation,
          execution_date: suggestedExecutionDateMoment.isValid() ? suggestedExecutionDateMoment.format('YYYY-MM-DD') : null
        }
      })
    }
  }

  const onCloseClick = () => {
    props.close()
  }

  const onSignPaymentClick = () => {
    window.location.assign(paymentInitiation.sign_redirect_url)
  }

  const onEnableIntegrationClick = async () => {
    try {
      const { form_data: formData } = await IntegrationsController.getForm(IntegrationType.PONTO)
      window.location.assign(`/auth/ponto?onboarding_details_id=${formData.onboarding_details_id}&action=accounts`);
    } catch (ex) {
      console.error(ex)
    }
  }

  const onPaymentActivationRequestClick = async () => {
    try {
      const response = await PaymentInitiationsController.activate()

      if (response.errors) {
        setState({ ...state, errors: response.errors })
      } else if (response.redirect_url) {
        window.location.assign(response.redirect_url)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    try {
      let response = null
      if (paymentInitiation.id) {
        response = await PaymentInitiationsController.update(paymentInitiation)
      } else {
        response = await PaymentInitiationsController.create(paymentInitiation, paymentInitiation?.payments.map(payment => payment.id))
      }

      if (response.errors) {
        setState({ ...state, errors: response.errors })
      } else {
        if (!paymentInitiation.id) {
          const createdPaymentInitation: PaymentInitiation = response
          window.location.assign(createdPaymentInitation.sign_redirect_url)
        } else {
          if (props.onSubmit) props.onSubmit(response)
          props.close()
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const onDeletePaymentClick = () => {
    props.showConfirmModal({
      title: t('PaymentInitiationModal::Delete scheduled payment'),
      description: t('PaymentInitiationModal::You are about to delete this scheduled payment. By deleting this payment your scheduled payment will be stopped and removed from our systems. Are you sure?'),
      action: {
        label: t('PaymentInitiationModal::Delete'),
        isDestructive: true,
      },
      onConfirm: async () => {
        try {
          await PaymentInitiationsController.delete(paymentInitiation.id)
          if (props.onDelete) props.onDelete(paymentInitiation)
          props.close()
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  const onErrorsDismiss = () => {
    setState({ ...state, errors: [] })
  }

  const renderIntegrationScenario = () => {
    let description = ''
    switch (integrationScenario) {
      case IntegrationScenario.ENABLE:
        description = t('PaymentInitiationModal::Enable our payment integration to pay your invoices securely in bulk with a single signature.')
        break
      case IntegrationScenario.REQUEST:
        description = t('PaymentInitiationModal::To activate the payment feature, please submit a request. As part of our compliance with anti-money laundering (AML) regulations, we are required to conduct Know Your Customer (KYC) checks. These checks ensure the safety and legality of your transactions. Please provide the necessary identification documents when prompted.')
        break
      case IntegrationScenario.REQUEST_PENDING:
        description = t('PaymentInitiationModal::Your payment activation request has been received and is currently under review. We are conducting the necessary checks as part of our compliance with anti-money laundering (AML) regulations. You will be notified once the review is complete and your payment feature is activated, or if we require any additional information.')
        break
    }

    return (
      <ModalDescription>
        {description}
      </ModalDescription>
    )
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={getTitle()}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          {integrationScenario !== IntegrationScenario.ACTIVE && <>
            {renderIntegrationScenario()}
          </>}
          {integrationScenario === IntegrationScenario.ACTIVE && <>
            <form onSubmit={onFormSubmit}>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('PaymentInitiationModal::Account')} <span>*</span></label>
                    <ResourceCreatablePowerSelect
                      type='account'
                      value={paymentInitiation.account_id}
                      onChange={onAccountChange}
                      isDisabled={Boolean(paymentInitiation.id)}
                      placeholder={t('PaymentInitiationModal::Select an account')}
                      isClearable={true}
                    />
                  </div>
                </div>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>
                      {t('PaymentInitiationModal::Reference')} <span>*</span>
                      <Tooltip
                        content={t('PaymentInitiationModal::Provide an internal reference to this payment for your own records')}
                        containerStyle={{ marginLeft: 8 }}
                      />
                    </label>
                    <input
                      type='text'
                      value={paymentInitiation.reference}
                      onChange={onReferenceChange}
                      placeholder={t('PaymentInitiationModal::Reference')}
                      disabled={Boolean(paymentInitiation.id)}
                    />
                  </div>
                </div>
                <div className='grid-cell with-6col'>
                  <div className='form-item'>
                    <label>{t('PaymentInitiationModal::Execution date')} <span>*</span></label>
                    {paymentInitiation.id && <input type='text' value={moment(paymentInitiation.execution_date).format(setting.date_format)} disabled={true} />}
                    {!paymentInitiation.id && <DateInput
                      name='execution_date'
                      dateFormat={setting.date_format}
                      inputProps={{ placeholder: setting.date_format }}
                      timeFormat={false}
                      value={moment(paymentInitiation.execution_date)}
                      onChange={onExecutionDateChange}
                      closeOnSelect
                    />}

                    {suggestedExecutionDate && Boolean(paymentInitiation.id) && paymentInitiation?.status !== PaymentInitiationStatus.SIGNED && !moment(suggestedExecutionDate).isSame(moment(paymentInitiation.execution_date)) && <div style={{ marginTop: Style.spacing.x1 }}>
                      <Alert
                        type='warning'
                        text={<InputAlert>
                          <div>
                            <Tooltip
                              content={t("PaymentInitiationModal::We've determined the ideal execution date to pay your selected payments.")}
                              containerStyle={{ marginRight: 8 }}
                            />
                            {moment(suggestedExecutionDate).format(setting.date_format)}
                          </div>

                          <a href='javascript://' onClick={onApplySuggestedExecutionDateClick}>
                            {`${t('PaymentInitiationModal::Apply')}`}
                          </a>
                        </InputAlert>}
                      />
                    </div>}
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <label>{t('PaymentInitiationModal::Notes')}</label>
                    <textarea
                      placeholder={t('PaymentInitiationModal::Add notes (only visible to you)')}
                      onChange={onNotesChange}
                      value={paymentInitiation.notes}
                      disabled={Boolean(paymentInitiation.id)}
                    />
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  {paymentInitiation?.payments.length > 0 && <ResourceModalTable>
                    <ResourceModalTableRow header={true}>
                      <ResourceModalTableRowHeader>
                        {t('PaymentInitiationModal::Account')}
                      </ResourceModalTableRowHeader>
                      <ResourceModalTableRowHeader>
                        {t('PaymentInitiationModal::Remittance information')}
                      </ResourceModalTableRowHeader>
                      <ResourceModalTableRowHeader>
                        {t('PaymentInitiationModal::Amount')}
                      </ResourceModalTableRowHeader>
                    </ResourceModalTableRow>

                    {paymentInitiation?.payments.map((payment, index) => (
                      <ResourceModalTableRow key={payment.id}>
                        <ResourceModalTableRowData>
                          {payment.creditor_name}
                        </ResourceModalTableRowData>
                        <ResourceModalTableRowData>
                          {payment.remittance_information.length === 0 && '-'}
                          {payment.remittance_information.length > 0 && payment.remittance_information_type === RemittanceInformationType.STRUCTURED ? OgmHelper.format(payment.remittance_information) : payment.remittance_information}
                        </ResourceModalTableRowData>
                        <ResourceModalTableRowData>
                          {NumberFormatter.formatCurrency(payment.currency, setting.number_format, payment.amount)}
                        </ResourceModalTableRowData>
                      </ResourceModalTableRow>
                    ))}
                  </ResourceModalTable>}
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
            </form>
          </>}
        </ModalContent>

        {[IntegrationScenario.ENABLE, IntegrationScenario.REQUEST, IntegrationScenario.ACTIVE].includes(integrationScenario) && <div className='modal-footer'>
          {integrationScenario !== IntegrationScenario.ACTIVE && <>
            <div></div>
            <div>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={onErrorsDismiss}
                />
                {integrationScenario === IntegrationScenario.ENABLE && <>
                  <Button
                    type='success'
                    text={t('PaymentInitiationModal::Enable integration')}
                    onClick={onEnableIntegrationClick}
                  />
                </>}
                {integrationScenario === IntegrationScenario.REQUEST && <>
                  <Button
                    type='success'
                    text={t('PaymentInitiationModal::Request payment activation')}
                    onClick={onPaymentActivationRequestClick}
                  />
                </>}
              </div>
            </div>
          </>}
          {integrationScenario === IntegrationScenario.ACTIVE && <>
            <ModalFooterActionIcons>
              {paymentInitiation && paymentInitiation.id && paymentInitiation.status !== PaymentInitiationStatus.SIGNED && <ModalFooterActionIcon
                icon='trash'
                active={false}
                onClick={onDeletePaymentClick}
                tooltip={t('PaymentInitiationModal::Delete payment')}
              />}
            </ModalFooterActionIcons>
            <div>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={onErrorsDismiss}
                />
                {paymentInitiation && paymentInitiation.id && [PaymentInitiationStatus.UNSIGNED].includes(paymentInitiation.status) && <>
                  <Button
                    type='success'
                    text={t('PaymentInitiationModal::Sign payment')}
                    onClick={onSignPaymentClick}
                  />
                </>}
                {paymentInitiation && !paymentInitiation.id && <Button
                  type='success'
                  text={t('PaymentInitiationModal::Sign payment')}
                  onClick={onFormSubmit}
                  disabled={!paymentInitiation.account_id || !paymentInitiation.reference || !paymentInitiation.execution_date}
                />}
              </div>
            </div>
          </>}
        </div>}
      </ModalMiddle>}
    </ModalWindow >
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      paymentInitiationModal: {
        paymentInitiation,
        onSubmit,
        onDelete,
      }
    }
  } = state

  return {
    currentUser: currentUser,
    paymentInitiation: paymentInitiation,
    onSubmit: onSubmit,
    onDelete: onDelete
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closePaymentInitiationModal()),
    showConfirmModal: (options) => dispatch(showConfirmModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInitiationModal)