import * as React from 'react'
import styled from 'styled-components'
import { Style } from '../../styles'

const EditorPreviewPlaceholder = styled.div`
	border-radius: 6px;
  background: linear-gradient(to right, rgb(242, 245, 250) 5%, rgb(234, 238, 246) 15%, rgb(242, 245, 250) 25%) 0% 0% / 100vw 100vh;
  width: 100%;
	height: 100%;
	border: 1px solid ${Style.color.border};
	overflow: hidden;
	margin: 0 auto;

	img {
		width: 100%;
		height: 100%;
	}

	.editor-preview-canvas-container & {
		max-height: 85vh;
    width: initial !important;
    max-width: 610px;
	}

	.template-picker-preview-container & {
		max-height: 87vh;
    max-width: 510px;
    width: initial !important;

		@media screen and (max-width: ${Style.breakpoints.MEDIUM}) {
      max-width: 85vh;
      max-height: 695px;
    }
	}
`

export default () => {
	return (
		<EditorPreviewPlaceholder>
			<img src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHdpZHRoPSI1NzIiIGhlaWdodD0iNzg2IiB2aWV3Qm94PSIwIDAgNTcyIDc4NiI+PHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNiAwaDU2MGE2IDYgMCAwIDEgNiA2djc3NGE2IDYgMCAwIDEtNiA2SDZhNiA2IDAgMCAxLTYtNlY2YTYgNiAwIDAgMSA2LTZ6bTU4IDI3NHYxNGg0NDR2LTE0SDY0em0wIDMwdjE0aDQ0NHYtMTRINjR6bTAgMzB2MTRoNDQ0di0xNEg2NHptMCAzMHYxNGgyMDR2LTE0SDY0em0wLTIyNnYxNGg0NDR2LTE0SDY0em0wIDMwdjE0aDQ0NHYtMTRINjR6bTAgMzB2MTRoNDQ0di0xNEg2NHptMCAzMHYxNGg0NDR2LTE0SDY0ek0yMzYgODZ2MTJoMTAwVjg2SDIzNnptLTUwLTM2djI0aDIwMFY1MEgxODZ6Ii8+PC9zdmc+Cg==' />
		</EditorPreviewPlaceholder>
	)
}