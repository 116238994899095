import * as React from 'react'
import { ActiveStorageController, DocumentsController, ProductsController } from '../../controllers'
import ModalHeader from './Parts/ModalHeader'
import ModalLoader from './Parts/ModalLoader'
import ModalMiddle from './Parts/ModalMiddle'
import ModalWindow from './Parts/ModalWindow'
import ModalContent from './Parts/ModalContent'
import { connect } from 'react-redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { closeProductImportModal } from '../../store/modals/actions'
import { CurrentUser } from '../../types'
import { AppState } from '../../store'
import Dropzone from '../Dropzone/Dropzone'
import { DropEvent, FileRejection } from 'react-dropzone'
import styled from 'styled-components'
import PageLoader from '../Page/PageLoader'
import ProductHelper from '../../helpers/ProductHelper'
import { Style } from '../../styles'
import Notification from '../../utilities/Notification'

const DropzoneContainer = styled.div`
  > div {
    margin: 0;
    min-height: 250px;
  }
`

const Samples = styled.div`
  a {
    color: ${Style.color.brandPrimary};
    text-decoration: underline;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  onSubmit: (blob: ActiveStorage.Blob) => void
}

interface IDispatchToProps {
  close: typeof closeProductImportModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  isSubmitting: boolean
}

class ProductImportModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      isSubmitting: false,
    }

    this.onFileDrop = this.onFileDrop.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
  }

  componentDidMount() {
    requestAnimationFrame(() => { this.setState({ didInitialLoad: true }) })
  }

  async onFileDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    const { t } = this.props
    try {
      if (acceptedFiles?.length > 0) {
        this.setState({ isSubmitting: true })
        const spreadsheet = acceptedFiles[0]
        const blob = await ActiveStorageController.uploadPromise(spreadsheet)
        await ProductsController.import(blob.id)

        Notification.notifySuccess(t('ProductImportModal::Products are being imported.'))
        this.onModalClose()
      }
    } catch (ex) {
      console.error(ex)
    } finally {
      this.setState({ isSubmitting: false })

    }
  }

  onModalClose() {
    this.props.close()
  }

  render() {
    const { t, currentUser: { workspace: { setting } } } = this.props
    const { didInitialLoad, isSubmitting } = this.state

    return (
      <ModalWindow>
        <ModalHeader
          title={t('ProductImportModal::Upload products')}
          onCloseClick={this.onModalClose}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form>
              <div className='grid'>
                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <p>
                      {t('ProductImportModal::Upload your products in CSV / XSLS format according to the sample below.')}
                      <Samples>
                        <a href='/samples/products.csv' download>{t('ProductImportModal::CSV sample')}</a>, <a href='/samples/products.xlsx' download>{t('ProductImportModal::Excel sample')}</a>
                      </Samples>
                    </p>
                  </div>
                </div>

                <div className='grid-cell with-12col'>
                  <div className='form-item'>
                    <DropzoneContainer>
                      {isSubmitting && <PageLoader />}

                      {!isSubmitting && <Dropzone
                        dropzoneProps={{
                          onDrop: this.onFileDrop,
                          multiple: false,
                          accept: ProductHelper.getDropzoneMimeTypes(),
                        }}
                      />}
                    </DropzoneContainer>
                  </div>
                </div>
              </div>

              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>
        </ModalMiddle>}
      </ModalWindow>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      productImportModal: {
        onSubmit
      }
    }
  } = state

  return {
    currentUser: currentUser,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeProductImportModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductImportModal))