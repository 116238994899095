import * as React from 'react'
import Swatch from './Swatch';
import randomColor from '../../utilities/randomColor';
import Popover from '../Popover/Popover';
import { BlockPicker, ColorResult } from 'react-color';
import styled from 'styled-components';
import { Style } from '../../styles';

const ColorButton = styled.span`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: ${Style.variables.baseBorderRadius};
  transition: all 150ms;
  width: 38px;
  height: 38px;
  cursor: pointer;
`

const BlockPickerContainer = styled.div`
  .block-picker {
    box-shadow: none !important;

    input {
      box-shadow: none !important;
      border-radius: ${Style.variables.baseBorderRadius} !important;
      color: black !important;
      border: 1px solid ${Style.color.border} !important;
    }

    > div{
      &:first-child {
        @media screen and (max-width: ${Style.breakpoints.SMALL}){
          display: none;
        }
      }

      &:nth-child(3) {
        border: 1px solid ${Style.color.border};
        border-top: none;
        border-bottom-left-radius: ${Style.variables.baseBorderRadius};
        border-bottom-right-radius: ${Style.variables.baseBorderRadius};

        > div {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 9px;
          margin-right: 0 !important;
          margin-bottom: 0 !important;

          > span {
            > div {
              margin-right: 0 !important;
              margin-bottom: 0 !important;
            }
          }

        }
      }
    }
  }
`

const BlockPickerWrapper = styled.div`
  padding: 8px;
  padding-top: 11px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    padding-top: 8px;
  }
`

interface IProps {
  color: string
  onChange: (color: string) => void
}

interface IState {
  show: boolean
}

const SwatchPicker = (props: IProps) => {
  const [state, setState] = React.useState<IState>({ show: false })
  const { show } = state
  const { color, onChange } = props

  const onColorChange = (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
    onPopoverClose()
    onChange(color.hex)
  }

  const onToggle = (e) => {
    e.preventDefault();

    setState({ show: !show });
  }

  const onPopoverClose = () => {
    setState({ show: false })
  }

  return (
    <span className='hint--top hint--rounded' aria-label='Change color'>
      <Popover
        activator={
          <ColorButton
            style={{
              backgroundColor: color === '' ? '#ccc' : color,
            }}
            onClick={onToggle}
          />
        }
        active={show}
        placement='bottom'
        onClose={onPopoverClose}
      >
        <BlockPickerContainer>
          <BlockPickerWrapper>
            <BlockPicker
              colors={[
                randomColor({}),
                randomColor({}),
                randomColor({}),
                randomColor({}),
                randomColor({}),
                randomColor({}),
                randomColor({}),
                randomColor({}),
                randomColor({}),
                randomColor({}),
              ]}
              color={color}
              onChangeComplete={onColorChange}
            />
          </BlockPickerWrapper>
        </BlockPickerContainer>
      </Popover>
    </span>
  )
}

export default SwatchPicker