import * as React from 'react'
import { closeEmailTemplateModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import ModalMiddle from './Parts/ModalMiddle'
import TooltipError from '../Tooltips/ErrorTooltip'
import { ActiveStorageController, EmailTemplatesController } from '../../controllers'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import Notification from '../../utilities/Notification'
import ModalLoader from './Parts/ModalLoader'
import ModalContent from './Parts/ModalContent'
import { WithTranslation, withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Style } from '../../styles'
import EditorContainer from '../Editor/EditorContainer'
import Editor, { EMAIL_EDITOR_CONFIG } from '../Editor/Editor'
import EditorHelper from '../../helpers/EditorHelper'
import Dropzone, { DropEvent, DropzoneRef, FileRejection } from 'react-dropzone'
import { MAX_EMAIL_ATTACHMENTS_SIZE, MAX_EMAIL_ATTACHMENT_SIZE } from '../../Constants'
import EmailAttachment from '../EmailPreview/EmailAttachment'
import EmailAttachments from '../EmailPreview/EmailAttachments'
import ReactTooltip from 'react-tooltip'
import Alert from '../Alert/Alert'
import ModalFooterActionIcons from './Parts/ModalFooterActionIcons'
import ModalFooterActionIcon from './Parts/ModalFooterAction'
import { CurrentUser, EmailTemplate, MimeTypes, Settings } from '../../types'
import Button from '../Button/Button'
import ModalWindow from './Parts/ModalWindow'
import ModalFooter from './Parts/ModalFooter'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import MustacheHelper from '../../helpers/MustacheHelper'
import EmailPreviewContainer from '../Editor/EmailPreviewContainer'

const EmailTemplateModalContainer = styled(ModalWindow)`
  height: 100vh;
  max-width: initial;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    max-width: initial;
  }

  ${ModalMiddle} {
    overflow-y: auto;
    overflow-x: visible;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
  emailTemplate: EmailTemplate
  onSubmit?: (emailTemplate: EmailTemplate) => void
}

interface IDispatchToProps {
  close: typeof closeEmailTemplateModal
}

type IProps = IDispatchToProps & IStateToProps & WithTranslation

interface IState {
  didInitialLoad: boolean
  emailTemplate: EmailTemplate
  variables: object
  isSaving: boolean
  errors: any
  preview: boolean
}

class EmailTemplateModal extends React.Component<IProps, IState> {
  private dropzone = React.createRef<DropzoneRef>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      didInitialLoad: false,
      emailTemplate: null,
      variables: {},
      isSaving: false,
      preview: false,
      errors: {},
    }

    this.fetchForm = this.fetchForm.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onErrorsDismiss = this.onErrorsDismiss.bind(this)
    this.onNameChange = this.onNameChange.bind(this)
    this.onSubjectChange = this.onSubjectChange.bind(this)
    this.onBodyChange = this.onBodyChange.bind(this)
    this.onTogglePreviewClick = this.onTogglePreviewClick.bind(this)
    this.onAddAttachmentClick = this.onAddAttachmentClick.bind(this)
    this.onAttachmentDrop = this.onAttachmentDrop.bind(this)
    this.onAttachmentDeleteClick = this.onAttachmentDeleteClick.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
  }

  componentDidMount() {
    this.fetchForm()
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    ReactTooltip.rebuild()
  }

  async fetchForm() {
    const { emailTemplate: propsEmailTemplate } = this.props

    try {
      const { email_template, variables } = await EmailTemplatesController.getForm({ id: propsEmailTemplate?.id })

      this.setState({
        didInitialLoad: true,
        emailTemplate: { ...email_template, ...propsEmailTemplate, },
        variables: variables,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onFormSubmit(e) {
    e.preventDefault();
    const { emailTemplate } = this.state;
    const { close, onSubmit, t } = this.props

    try {
      if (emailTemplate.id) {
        const response = await EmailTemplatesController.update(emailTemplate)
        const { errors } = response;

        if (errors) {
          this.setState({ errors: errors });
          Notification.notifyError(t('EmailTemplateModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('EmailTemplateModal::Email template successfully updated.'))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
      else {
        const response = await EmailTemplatesController.create(emailTemplate)
        const { errors } = response;

        if (errors) {
          this.setState({ errors: errors });
          Notification.notifyError(t('EmailTemplateModal::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('EmailTemplateModal::Email template successfully created.'))
          if (onSubmit) onSubmit(response)
          close()
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  onCloseClick() {
    this.props.close()
  }

  onErrorsDismiss() {
    this.setState({ errors: {} })
  }

  onNameChange(e) {
    const { emailTemplate } = this.state
    e.preventDefault();

    this.setState({
      emailTemplate: {
        ...emailTemplate,
        name: e.currentTarget.value
      }
    });
  }

  onSubjectChange(e) {
    const { emailTemplate } = this.state
    e.preventDefault();

    const newSubject = e.currentTarget.value;

    this.setState({
      emailTemplate: {
        ...emailTemplate,
        subject: newSubject
      }
    });
  }

  onBodyChange(body: string) {
    const { emailTemplate } = this.state

    this.setState({
      emailTemplate: {
        ...emailTemplate,
        body: body
      }
    });
  }

  onTogglePreviewClick() {
    const { preview } = this.state
    this.setState({ preview: !preview })
  }

  onAddAttachmentClick() {
    if (this.dropzone.current) {
      this.dropzone.current.open()
    }
  }

  onAttachmentDrop(acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) {
    const { t } = this.props
    acceptedFiles.forEach(async (file: any) => {
      try {
        ActiveStorageController.upload(file, async (error, blob) => {
          if (error) { console.error(error) }
          if (blob) {
            this.setState({
              emailTemplate: {
                ...this.state.emailTemplate,
                attachments: [...this.state.emailTemplate.attachments, blob],
                attachment_ids: [...this.state.emailTemplate.attachment_ids, blob.signed_id]
              }
            })
          }
        })
      } catch (ex) {
        console.error(ex)
      }
    })

    // Display the file names and errors
    fileRejections.forEach((fileRejection) => {
      fileRejection.errors.forEach((err) => {
        if (err.code === "file-too-large") Notification.notifyError(t('EmailTemplateModal::File {{filename}} is too large', { filename: fileRejection.file.name }))
        if (err.code === "file-invalid-type") Notification.notifyError(t('EmailTemplateModal::File {{filename}} is an invalid filetype', { filename: fileRejection.file.name }))
      });
    });
  }

  onAttachmentDeleteClick(index: number) {
    const { emailTemplate } = this.state

    emailTemplate.attachments.splice(index, 1)
    emailTemplate.attachment_ids.splice(index, 1)

    this.setState({
      emailTemplate: {
        ...emailTemplate,
      },
    })
  }

  render() {
    const { t, currentUser } = this.props
    const {
      didInitialLoad,
      errors,
      variables,
      isSaving,
      emailTemplate,
      preview
    } = this.state

    const attachmentsSize = emailTemplate?.attachments?.reduce((acc, attachment) => acc + attachment.byte_size, 0)
    const emailAttachmentLimitReached = attachmentsSize >= MAX_EMAIL_ATTACHMENTS_SIZE
    const saveDisabled = emailTemplate?.name?.length === 0
    let tooltipReason = null
    const variableOptions = EditorHelper.getVariableOptions(variables)

    return (
      <EmailTemplateModalContainer>
        <ModalHeader
          title={emailTemplate?.id ? t('EmailTemplateModal::Update email template') : t('EmailTemplateModal::Create email template')}
          onCloseClick={this.onCloseClick}
        />

        {!didInitialLoad && <ModalLoader />}
        {didInitialLoad && <ModalMiddle>
          <ModalContent>
            <form onSubmit={this.onFormSubmit}>
              <div key='content'>
                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('EmailTemplateModal::Name')} <span>*</span></label>

                      <input
                        type='text'
                        value={emailTemplate?.name}
                        onChange={this.onNameChange}
                        placeholder={t('EmailTemplateModal::Name')}
                        name='name'
                        required
                      />
                    </div>
                  </div>

                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('EmailTemplateModal::Subject')}</label>

                      <input
                        type='text'
                        value={preview ? MustacheHelper.process(emailTemplate.subject, variables) : emailTemplate?.subject}
                        onChange={this.onSubjectChange}
                        placeholder={t('EmailTemplateModal::Type a subject for your email')}
                        name='subject'
                        disabled={preview}
                      />
                    </div>
                  </div>
                </div>

                <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('EmailTemplateModal::Content')}</label>
                      {!preview && <EditorContainer toolbarBottom>
                        <Editor
                          key={String(variableOptions.length)}
                          model={emailTemplate?.body}
                          onModelChange={this.onBodyChange}
                          config={{
                            ...EMAIL_EDITOR_CONFIG,
                            editorClass: 'user-email',
                            toolbarBottom: true,
                            heightMin: '57vh',
                            heightMax: '57vh',
                            variableOptions: variableOptions,
                            placeholderText: t('EmailTemplateModal::Type your email here'),
                          }}
                        />
                      </EditorContainer>}

                      {preview && <EmailPreviewContainer style={{ minHeight: '57vh', maxHeight: '57vh' }} >
                        <FroalaEditorView
                          model={MustacheHelper.process(emailTemplate?.body, variables)}
                        />
                      </EmailPreviewContainer>}
                    </div>
                  </div>
                </div>

                {emailTemplate?.attachments?.length > 0 && <div className='grid'>
                  <div className='grid-cell with-12col'>
                    <div className='form-item'>
                      <label>{t('EmailTemplateModal::Attachments')}</label>

                      {emailAttachmentLimitReached && <Alert
                        type='warning'
                        text={t('EmailTemplateModal::You have reached the average maximum size limit for most email providers. The template will be created but we can\'t guarantee it will arrive at its destination when used.')}
                      />}

                      <EmailAttachments>
                        {emailTemplate?.attachments?.map((attachment, index) => {
                          return (
                            <EmailAttachment
                              key={index}
                              file={attachment}
                              onDeleteClick={() => this.onAttachmentDeleteClick(index)}
                            />
                          )
                        })}
                      </EmailAttachments>
                    </div>
                  </div>
                </div>}

                <Dropzone
                  ref={this.dropzone}
                  onDrop={this.onAttachmentDrop}
                  multiple={true}
                  accept={[
                    MimeTypes.JPEG,
                    MimeTypes.JPG,
                    MimeTypes.PNG,
                    MimeTypes.PDF,
                    MimeTypes.CSV,
                    MimeTypes.EXCEL
                  ]}
                  maxSize={MAX_EMAIL_ATTACHMENT_SIZE}
                >
                  {({ getInputProps, open }) => (
                    <input {...getInputProps()} />
                  )}
                </Dropzone>
              </div>
              <input type='submit' style={{ display: 'none' }} />
            </form>
          </ModalContent>
        </ModalMiddle>}
        <ModalFooter>
          <div className='modal-footer'>
            <div className='modal-footer-actions'>
              <div key='main-action' className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={this.onErrorsDismiss}
                />
                <Button
                  type='success'
                  text={t('EmailTemplateModal::Save')}
                  onClick={this.onFormSubmit}
                  disabled={saveDisabled}
                  tooltip={tooltipReason}
                  isLoading={isSaving}
                />
              </div>
            </div>
            <ModalFooterActionIcons>
              <ModalFooterActionIcon
                icon='eye'
                onClick={this.onTogglePreviewClick}
                tooltip={t('EmailTemplateModal::Toggle preview')}
                active={preview}
              />
              <ModalFooterActionIcon
                icon='attachment'
                onClick={this.onAddAttachmentClick}
                tooltip={t('EmailTemplateModal::Add attachments')}
              />
            </ModalFooterActionIcons>
          </div>
        </ModalFooter>
      </EmailTemplateModalContainer >
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    modals: {
      emailTemplateModal: {
        emailTemplate,
        onSubmit,
      },
    }
  } = state

  return {
    emailTemplate: emailTemplate,
    onSubmit: onSubmit,
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeEmailTemplateModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailTemplateModal))