import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import FixedSidebarContainer from '../Sidebar/FixedSidebarContainer'
import SidebarDivider from '../Sidebar/SidebarDivider'
import SidebarHeader from '../Sidebar/SidebarHeader'
import SidebarClose from '../Sidebar/SidebarClose'
import SidebarContent from '../Sidebar/SidebarContent'
import SidebarSection from '../Sidebar/SidebarSection'
import SidebarSectionItem from '../Sidebar/SidebarSectionItem'
import SidebarSectionItemCheck from '../Sidebar/SidebarSectionItemCheck'
import { BoardLabel } from '../../types'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'

const Caption = styled.div`
	font-size: 14px;
`

const LabelItemColor = styled.div`
	left: 4px;
	position: absolute;
	top: 4px;
	height: 30px;
	width: 30px;
	padding: 0;
	margin: 0;
	border-radius: 4px;
`

const LabelItemName = styled.div`
	font-weight: 500;
`

export const enum BoardFilterDueCompletionState {
	// t('BoardSidebarFilter::due_next_day')
	DUE_NEXT_DAY = 'due_next_day',
	// t('BoardSidebarFilter::due_next_week')
	DUE_NEXT_WEEK = 'due_next_week',
	// t('BoardSidebarFilter::due_next_month')
	DUE_NEXT_MONTH = 'due_next_month',
	// t('BoardSidebarFilter::overdue')
	OVERDUE = 'overdue',
	// t('BoardSidebarFilter::no_due_date')
	NO_DUE_DATE = 'no_due_date',
	// t('BoardSidebarFilter::due_marked_complete')
	DUE_MARKED_COMPLETE = 'due_marked_complete',
	// t('BoardSidebarFilter::not_marked_as_complete')
	NOT_MARKED_AS_COMPLETE = 'not_marked_as_complete'
}

export const enum BoardFilterMatchLabelsAndMembers {
	ANY = 'any',
	ALL = 'all'
}

export interface IBoardFilter {
	searchValue: string
	assigneeIds: string[]
	labelIds: string[]
	dueOrCompletionState: BoardFilterDueCompletionState | null
	matchLabelsAndMembers: BoardFilterMatchLabelsAndMembers
}

interface IProps {
	active: boolean
	filter: IBoardFilter
	labels: BoardLabel[]
	onFilterChange: (filter: IBoardFilter) => void
	onCloseClick: () => void
}

const BoardSidebarFilter = (props: IProps) => {
	const { active, filter, labels } = props
	const { t } = useTranslation()
	const container = React.useRef<HTMLDivElement>()
	const input = React.useRef<HTMLInputElement>()
	const { searchValue } = filter;

	const dueCompletionStateOptions: BoardFilterDueCompletionState[] = [
		BoardFilterDueCompletionState.DUE_NEXT_DAY,
		BoardFilterDueCompletionState.DUE_NEXT_WEEK,
		BoardFilterDueCompletionState.DUE_NEXT_MONTH,
		BoardFilterDueCompletionState.OVERDUE,
		BoardFilterDueCompletionState.NO_DUE_DATE,
		// BoardFilterDueCompletionState.DUE_MARKED_COMPLETE,
		// BoardFilterDueCompletionState.NOT_MARKED_AS_COMPLETE,
	]

	React.useEffect(() => {
		document.addEventListener('mousedown', onOutsideClick);

		// Temporarily disable because on mobile it opens keyboard which annoying
		if (active && input.current) input.current.select()

		return () => {
			document.removeEventListener('mousedown', onOutsideClick);
		}
	}, [active])

	const onOutsideClick = (e) => {
		if (active && container && !container.current.contains(e.target)) {
			props.onCloseClick()
		}
	}

	const onCloseClick = () => {
		props.onCloseClick()
	}

	const onSearchChange = (e) => {
		props.onFilterChange({ ...filter, searchValue: e.currentTarget.value })
	}

	const onAssigneesChange = (assigneeIds: string[] | null) => {
		props.onFilterChange({ ...filter, assigneeIds: assigneeIds ? assigneeIds : [] })
	}

	const onLabelToggle = (label: BoardLabel) => {
		let updatedActiveLabelIds = filter.labelIds

		if (updatedActiveLabelIds.includes(label.id)) {
			updatedActiveLabelIds = updatedActiveLabelIds.filter(labelId => labelId !== label.id)
		} else {
			updatedActiveLabelIds.push(label.id)
		}

		props.onFilterChange({ ...filter, labelIds: [...updatedActiveLabelIds] })
	}

	const onDueCompletionStateToggle = (newDueCompletionState: BoardFilterDueCompletionState) => {
		let currentDueCompletionState = filter.dueOrCompletionState

		props.onFilterChange({
			...filter,
			dueOrCompletionState: newDueCompletionState !== currentDueCompletionState ? newDueCompletionState : null
		})
	}

	const onSearchMatchChange = (matcher: BoardFilterMatchLabelsAndMembers) => {
		if (props.filter.matchLabelsAndMembers !== matcher) {
			props.onFilterChange({
				...filter,
				matchLabelsAndMembers: matcher
			})
		}
	}

	const clearFilters = () => {
		props.onFilterChange({
			searchValue: '',
			assigneeIds: [],
			labelIds: [],
			dueOrCompletionState: null,
			matchLabelsAndMembers: BoardFilterMatchLabelsAndMembers.ANY
		})
	}

	return (
		<FixedSidebarContainer ref={container} active={active}>
			<SidebarHeader>
				{t('BoardSidebarFilter::Search')}

				<SidebarClose onClick={onCloseClick} />
			</SidebarHeader>

			<SidebarDivider />

			<SidebarContent>
				<input
					ref={input}
					type='text'
					value={searchValue}
					onChange={onSearchChange}
					placeholder={t('Board::Search...')}
					style={{ marginBottom: Style.spacing.x1 }}
				/>

				<Caption>{t('BoardSidebarFilter::Search by name, label, attachment, member or due time')}</Caption>

				<SidebarDivider />

				<SidebarSection>
					<label>{t('BoardSidebarFilter::Assignees')}</label>
					<ResourceCreatablePowerSelect
						type='user'
						value={filter.assigneeIds}
						onChange={onAssigneesChange}
						isClearable={true}
						isValidNewOption={() => false}
						isMulti={true}
						placeholder={t('BoardSidebarFilter::Select assignees')}
					/>
				</SidebarSection>

				<SidebarDivider />

				<SidebarSection>
					{labels.map(label => {
						const selected = filter.labelIds.includes(label.id)
						return (
							<SidebarSectionItem key={label.id} onClick={() => onLabelToggle(label)} style={{ paddingLeft: 42 }}>
								<LabelItemColor style={{ backgroundColor: label.color }} />
								<LabelItemName>{label.name}</LabelItemName>
								{selected && <SidebarSectionItemCheck />}
							</SidebarSectionItem>
						)
					})}
				</SidebarSection>

				<SidebarDivider />

				<SidebarSection>
					{dueCompletionStateOptions.map(dueCompleteState => {
						return (
							<SidebarSectionItem key={dueCompleteState} onClick={() => onDueCompletionStateToggle(dueCompleteState)}>
								{t(`BoardSidebarFilter::${dueCompleteState}`)}
								{filter.dueOrCompletionState === dueCompleteState && <SidebarSectionItemCheck />}
							</SidebarSectionItem>
						)
					})}
				</SidebarSection>

				<SidebarDivider />

				<SidebarSection>
					<SidebarSectionItem onClick={() => onSearchMatchChange(BoardFilterMatchLabelsAndMembers.ANY)}>
						{t('BoardSidebarFilter::Matches any label')}
						{filter.matchLabelsAndMembers === BoardFilterMatchLabelsAndMembers.ANY && <SidebarSectionItemCheck />}
					</SidebarSectionItem>
					<SidebarSectionItem onClick={() => onSearchMatchChange(BoardFilterMatchLabelsAndMembers.ALL)}>
						{t('BoardSidebarFilter::Matches all labels')}
						{filter.matchLabelsAndMembers === BoardFilterMatchLabelsAndMembers.ALL && <SidebarSectionItemCheck />}
					</SidebarSectionItem>
				</SidebarSection>

				<SidebarDivider />

				<SidebarSectionItem onClick={clearFilters}>
					{t('BoardSidebarFilter::Clear filters')}
				</SidebarSectionItem>
			</SidebarContent>
		</FixedSidebarContainer>
	)
}

export default BoardSidebarFilter