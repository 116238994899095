import Fuse from 'fuse.js'
import { DealBoardFilterDueState, DealStatusFilter, IDealBoardFilter } from "../components/Deals/DealSidebarFilter";
import store from '../store';
import { showCalendarEventModal, showSendEmailModal, showTaskModal } from '../store/modals/actions';
import { CalendarEvent, CalendarEventType, Deal, DealStage, DealStageAction, DealStatus, IPositionable, Task } from "../types";
import moment from '../utilities/Moment';
import i18n from '../translations';

export default class DealHelper {
  static POSITION_STEP_INDEX = 16384

  static getStatusOptions() {
    return Object.keys(DealStatus).map(key => (
      { label: i18n.t(`DealStatus::${DealStatus[key]}`), value: DealStatus[key] }
    ))
  }

  static getCreatePosition(resources: IPositionable[]): number {
    if (resources.length === 0) return this.POSITION_STEP_INDEX

    return resources[resources.length - 1].position + this.POSITION_STEP_INDEX
  }

  static getDealsFromStage(dealStage: DealStage, deals: Deal[], filter?: IDealBoardFilter): Deal[] {
    let stageDeals = deals
      .filter(deal => deal.deal_stage_id === dealStage.id)
      .sort((t1, t2) => t1.position - t2.position)

    // If no filters provided return all tasks from the list
    if (!filter) return stageDeals

    const fuse = new Fuse(stageDeals, {
      shouldSort: true,
      threshold: 0.5,
      location: 0,
      distance: 100,
      minMatchCharLength: 1,
      keys: [
        'name',
        'notes',
        'amount',
        'contact.name',
        'project.name',
        'assignee.email',
        'assignee.first_name',
        'assignee.last_name'
      ]
    })

    const { searchValue, assigneeIds, status, dueState } = filter;

    // Search tasks based on searchValue
    if (searchValue.length > 0) {
      stageDeals = fuse.search(searchValue).map(result => result.item)
    }

    // Search assignees
    if (assigneeIds && assigneeIds.length > 0) {
      stageDeals = stageDeals.filter(deal => assigneeIds.includes(deal.assignee_id))
    }

    if (status) {
      switch (status) {
        case DealStatusFilter.ALL:
          // Do nothing. No filtering should be applied
          break
        case DealStatusFilter.OPEN:
          stageDeals = stageDeals.filter(deal => deal.status === DealStatus.OPEN)
          break
        case DealStatusFilter.WON:
          stageDeals = stageDeals.filter(deal => deal.status === DealStatus.WON)
          break
        case DealStatusFilter.LOST:
          stageDeals = stageDeals.filter(deal => deal.status === DealStatus.LOST)
          break
      }
    }

    if (dueState) {
      const nowMoment = moment()

      switch (dueState) {
        case DealBoardFilterDueState.DUE_NEXT_DAY:
          const dueNextDayMoment = moment(nowMoment).endOf('day').add(1, 'day')
          stageDeals = stageDeals.filter(task => task.due_on && moment(task.due_on).isBetween(nowMoment, dueNextDayMoment))
          break
        case DealBoardFilterDueState.DUE_NEXT_WEEK:
          const dueNextWeekMoment = moment(nowMoment).endOf('day').add(7, 'day')
          stageDeals = stageDeals.filter(task => task.due_on && moment(task.due_on).isBetween(nowMoment, dueNextWeekMoment))
          break
        case DealBoardFilterDueState.DUE_NEXT_MONTH:
          const dueNextMonthMoment = moment(nowMoment).endOf('day').add(1, 'month')
          stageDeals = stageDeals.filter(task => task.due_on && moment(task.due_on).isBetween(nowMoment, dueNextMonthMoment))
          break
        case DealBoardFilterDueState.OVERDUE:
          stageDeals = stageDeals.filter(task => task.due_on && moment(task.due_on).isBefore(nowMoment))
          break
        case DealBoardFilterDueState.NO_DUE_DATE:
          stageDeals = stageDeals.filter(task => !task.due_on)
          break
      }
    }

    return stageDeals
  }

  static triggerDealStageActionForDeal(deal: Deal, dealStage: DealStage, callbacks?: {
    onCalendarEventSubmit?: (event: CalendarEvent) => void;
    onCalendarEventDelete?: (eventId: string) => void;
    onTaskSubmit?: (task: Task) => void;
    onTaskDelete?: (task: Task) => void;
  }) {
    if (dealStage.action) {
      switch (dealStage.action) {
        case DealStageAction.EVENT:
          requestAnimationFrame(() => {
            store.dispatch(
              showCalendarEventModal({
                calendarEvent: {
                  type: CalendarEventType.EVENT,
                  deal_id: deal.id,
                  contact_id: deal.contact_id,
                  project_id: deal.project_id
                },
                contactDisabled: Boolean(deal.contact_id),
                projectDisabled: Boolean(deal.project_id),
                onSubmit: callbacks?.onCalendarEventSubmit,
                onDelete: callbacks?.onCalendarEventDelete
              })
            )
          })

        case DealStageAction.APPOINTMENT:
          requestAnimationFrame(() => {
            store.dispatch(
              showCalendarEventModal({
                calendarEvent: {
                  type: CalendarEventType.APPOINTMENT,
                  deal_id: deal.id,
                  contact_id: deal.contact_id,
                  project_id: deal.project_id
                },
                contactDisabled: Boolean(deal.contact_id),
                projectDisabled: Boolean(deal.project_id),
                onSubmit: callbacks?.onCalendarEventSubmit,
                onDelete: callbacks?.onCalendarEventDelete
              })
            )
          })
        case DealStageAction.CALL:
          requestAnimationFrame(() => {
            store.dispatch(
              showCalendarEventModal({
                calendarEvent: {
                  type: CalendarEventType.CALL,
                  deal_id: deal.id,
                  contact_id: deal.contact_id,
                  project_id: deal.project_id
                },
                contactDisabled: Boolean(deal.contact_id),
                projectDisabled: Boolean(deal.project_id),
                onSubmit: callbacks?.onCalendarEventSubmit,
                onDelete: callbacks?.onCalendarEventDelete
              })
            )
          })
          break
        case DealStageAction.TASK:
          requestAnimationFrame(() => {
            store.dispatch(
              showTaskModal({
                task: {
                  contact_id: deal.contact_id,
                  contact: deal.contact,
                  project_id: deal.project_id,
                  project: deal.project,
                },
                contactDisabled: Boolean(deal.contact_id),
                projectDisabled: Boolean(deal.project_id),
                onSubmit: callbacks?.onTaskSubmit,
                onDelete: callbacks?.onTaskDelete
              })
            )
          })
          break
        case DealStageAction.EMAIL:
          requestAnimationFrame(() => {
            store.dispatch(
              showSendEmailModal({
                email: {
                  to: deal.contact_id ? [deal.contact.id] : [],
                }
              })
            )
          })
          break
      }
    }

  }

  static getDealStageForStatus(dealStages: DealStage[], status: DealStatus) {
    return dealStages.find(dealStage => dealStage.status === status)
  }
}