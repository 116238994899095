import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Popover from '../Popover/Popover'
import TaskButton from './TaskButton'
import TaskPopoverContainer from './TaskPopoverContainer'
import TaskPopoverContent from './TaskPopoverContent'
import TaskPopoverHeader from './TaskPopoverHeader'
import TaskPopoverTitle from './TaskPopoverTitle'
import DurationInput from '../Form/DurationInput'

interface IProps {
	activator: JSX.Element
	estimate?: number
	onSubmit: (estimate: number) => void
}

const EstimatePopover = (props: IProps) => {
	const { t } = useTranslation()
	const { activator } = props

	const [popoverActive, setPopoverActive] = React.useState(false)
	const [estimate, setEstimate] = React.useState(null)

	React.useEffect(() => {
		setEstimate(props.estimate)
	}, [popoverActive, props.estimate])

	const onTogglePopover = () => {
		setPopoverActive(!popoverActive)
	}

	const onPopoverClose = () => {
		setPopoverActive(false)
	}

	const onEstimateChange = (estimate: number | null) => {
		setEstimate(estimate)
	}

	const onFormSubmit = (e) => {
		if (e) e.preventDefault()
		// Submit form
		props.onSubmit(estimate)

		// Close popover
		onPopoverClose()
	}

	const onRemoveClick = () => {
		props.onSubmit(0)

		onPopoverClose()
	}

	return (
		<Popover
			active={popoverActive}
			activator={
				<div onClick={onTogglePopover}>
					{activator}
				</div>
			}
			onClose={onPopoverClose}
			placement='bottom-start'
		>
			<TaskPopoverContainer>
				<TaskPopoverHeader>
					<TaskPopoverTitle>{t('TaskModal::Estimate')}</TaskPopoverTitle>
				</TaskPopoverHeader>
				<TaskPopoverContent>
					<form onSubmit={onFormSubmit}>
						<DurationInput
							value={estimate}
							onChange={onEstimateChange}
						/>

						<TaskButton
							onClick={onFormSubmit}
							style={{ marginTop: 12 }}
							success
							center
						>
							{t('TaskModal::Save')}
						</TaskButton>

						<TaskButton
							onClick={onRemoveClick}
							style={{ marginTop: 8 }}
							center
						>
							{t('TaskModal::Remove')}
						</TaskButton>
						<input type='submit' style={{ display: 'none' }} />
					</form>
				</TaskPopoverContent>
			</TaskPopoverContainer>
		</Popover>
	)
}

export default EstimatePopover 