import styled from 'styled-components'
import { Style } from '../../styles'

const TaskSectionContent = styled.div`
  margin-left: ${Style.spacing.x5};

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      margin-left: 0;
  }
`

export default TaskSectionContent