import * as React from 'react'
import { closeDiscountModal } from '../../store/modals/actions'
import ModalHeader from './Parts/ModalHeader'
import { AppState } from '../../store'
import { Dispatch } from 'redux'
import { connect, useSelector } from 'react-redux'
import ModalWindow from './Parts/ModalWindow'
import ModalMiddle from './Parts/ModalMiddle'
import ModalContent from './Parts/ModalContent'
import { useTranslation } from 'react-i18next'
import ModalLoader from './Parts/ModalLoader'
import Button from '../Button/Button'
import { DisplayableError, WorkType } from '../../types'
import TooltipError from '../Tooltips/ErrorTooltip'
import SummaryContainer from '../Summary/SummaryContainer'
import SummaryItem from '../Summary/SummaryItem'
import NumberFormatter from '../../utilities/NumberFormatter'
import PercentInput from '../Form/PercentInput'
import { DEFAULT_CURRENCY } from '../../Constants'

interface IStateToProps {
  currency: string
  total: number
  discount: number
  onSubmit?: (discount: number) => void
}

interface IDispatchToProps {
  close: typeof closeDiscountModal
}

type IProps = IDispatchToProps & IStateToProps

interface IState {
  didInitialLoad: boolean
  discount: number
  errors: DisplayableError[]
}

const DiscountModal = (props: IProps) => {
  const { discount: propsDiscount, currency, total } = props
  const { t } = useTranslation()
  const currentUser = useSelector((state: AppState) => state.authentication.currentUser)
  const [state, setState] = React.useState<IState>({
    didInitialLoad: true,
    discount: propsDiscount || 0,
    errors: [],
  })
  const { workspace: { setting } } = currentUser
  const { didInitialLoad, discount, errors } = state

  const discountTotal = (total * discount) || 0
  const newTotal = total - discountTotal

  const onCloseClick = () => {
    props.close()
  }

  const onDiscountChange = (discount) => {
    setState({ ...state, discount: discount })
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    if (props.onSubmit) props.onSubmit(discount || 0)

    props.close()
  }

  const onErrorsDismiss = () => {
    setState({ ...state, errors: [] })
  }

  return (
    <ModalWindow>
      <ModalHeader
        title={t('DiscountModal::Discount')}
        onCloseClick={onCloseClick}
      />

      {!didInitialLoad && <ModalLoader />}
      {didInitialLoad && <ModalMiddle>
        <ModalContent>
          <form onSubmit={onFormSubmit}>
            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('DiscountModal::Discount')} <span>*</span></label>
                  <PercentInput
                    defaultValue={discount}
                    placeholder={t('DiscountModal::Discount')}
                    onChange={onDiscountChange}
                    style={{ textAlign: 'left' }}
                  />
                </div>
              </div>
            </div>

            <div className='fields-section is-last'>
              <div className='fieldset'>
                <SummaryContainer columnCount={3}>
                  <SummaryItem
                    title={t('DiscountModal::Total')}
                    label={NumberFormatter.formatCurrency(currency || DEFAULT_CURRENCY, setting.number_format, total)}
                  />
                  <SummaryItem
                    title={t('DiscountModal::Discount')}
                    label={NumberFormatter.formatCurrency(currency || DEFAULT_CURRENCY, setting.number_format, discountTotal, { forcePrecision: 2 })}
                  />
                  <SummaryItem
                    title={t('DiscountModal::New total')}
                    label={NumberFormatter.formatCurrency(currency || DEFAULT_CURRENCY, setting.number_format, newTotal, { forcePrecision: 2 })}
                  />
                </SummaryContainer>
              </div>
            </div>

            <input type='submit' style={{ display: 'none' }} onClick={onFormSubmit} />
          </form>
        </ModalContent>

        <div className='modal-footer'>
          <div />
          <div key='main-action' className='popover-wrapper'>
            <TooltipError
              errors={errors}
              onDismiss={onErrorsDismiss}
            />
            <Button
              type='success'
              text={t('DiscountModal::Save')}
              onClick={onFormSubmit}
            />
          </div>
        </div>
      </ModalMiddle>}
    </ModalWindow >
  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser
    },
    modals: {
      discountModal: {
        currency,
        total,
        discount,
        onSubmit,
      }
    }
  } = state

  return {
    currency: currency,
    total: total,
    discount: discount,
    onSubmit: onSubmit,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    close: () => dispatch(closeDiscountModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscountModal)